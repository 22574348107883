import * as Yup from "yup";

export const useCreateSchema = (activeItems: string[]) => {
  const shape: Record<string, Yup.AnySchema> = {};

  activeItems.forEach((item) => {
    switch (item) {
      case "email":
        shape.email = Yup.string()
          .email("Email is not valid")
          .required("This field is required");
        break;
      case "first_name":
        shape.first_name = Yup.string().required("This field is required");
        break;
      case "last_name":
        shape.last_name = Yup.string().required("This field is required");
        break;
      case "phone_number":
        const phoneRegExp =
          /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        shape.phone_number = Yup.string()
          .nullable()
          .test("is-valid-phone", "Phone number is not valid", (value) => {
            if (!value) return true; // Allow empty values
            return phoneRegExp.test(value); // Validate if value exists
          });
        break;
      case "password":
        shape.password = Yup.string().required("This field is required");
        break;
      case "old_password":
        shape.old_password = Yup.string().required("This field is required");
        break;
      case "password1":
        shape.password1 = Yup.string()
          .required("This field is required")
          .min(8, "Password is too short")
          .matches(/^(?=.*[0-9])/, "Password must contain at least one number")
          .matches(
            /^(?=.*[A-Za-z])/,
            "Password must contain at least one letter",
          );
        break;
      case "password2":
        shape.password2 = Yup.string()
          .required("This field is required")
          .oneOf([Yup.ref("password1")], "Repeat password does not match");
        break;
      case "address":
        shape.address = Yup.string().required("This field is required");
        break;
      default:
        break;
    }
  });

  return Yup.object().shape(shape);
};
