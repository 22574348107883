//@ts-nocheck
import { Box, useMediaQuery, useTheme } from "@mui/material";
import WordCloud from "react-d3-cloud";
import { scaleOrdinal, scaleLinear } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { WorldcloudProps } from "./interfaces";

const WordCloudComponent: React.FC<WorldcloudProps> = ({ data }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

  const minValue = Math.min(...data.map((d) => d.value));
  const maxValue = Math.max(...data.map((d) => d.value));

  const fontSizeScale = scaleLinear()
    .domain([minValue, maxValue])
    .range([5, 40]);

  return (
    <Box
      sx={{
        height: downLg ? "280px" : "400px",
      }}
    >
      <WordCloud
        data={data}
        width={505}
        height={240}
        font="Poppins"
        // fontSize={(word) => Math.log2(word.value) * 5}
        fontSize={(word) => fontSizeScale(word.value)}
        spiral="rectangular"
        padding={1}
        fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
      />
    </Box>
  );
};

export default WordCloudComponent;
