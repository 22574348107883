import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import SubPagesLayout from "../../layout/subPagesLayout";
import styles from "./subscriptionManagement.module.scss";
import CardTitle from "../../components/common/title/CardTitle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../features/store";
import { useEffect, useMemo, useState } from "react";
import Empty from "../../components/common/empty/Empty";
import useAuth from "../../utils/useAuth";
import {
  cancelSubscription,
  fetchInvoicesList,
  fetchSubscriptionsList,
  fetchTransactionsList,
} from "../../features/membership/membershipSlice";
import Loading from "../../components/common/loading";
import { getDifferenceInMonth } from "../../utils/functions";
import DefaultModal from "../../components/common/modal";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import SubscriptionTable from "../../components/subscription/subscriptionTable";
import TransactionTable from "../../components/subscription/transactionTable";
import InvoiceTable from "../../components/subscription/invoiceTable";
import moment from "moment";

const activeSubscriptionTHead = [
  "Plan",
  "Duration",
  "Creation date",
  "Expiration date",
  "Status",
  "Action",
];

const subscriptionsListTHeads = [
  "Plan",
  "Duration",
  "Creation date",
  "Expiration date",
  "Status",
];

const transactionsListTHeads = [
  "Name",
  "Duration",
  "Creation date",
  "expiration date",
];

const invoiceListTHeads = [
  "Name",
  "Duration",
  "Creation date",
  "expiration date",
];

type SubscriptionType = {
  current_period_end: string;
  current_period_start: string;
  status: string;
  id: string;
  plan: string;
};

const SubscriptionManagement = () => {
  const theme = useTheme();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const membershipState: any = useSelector(
    (state: RootState) => state.membership
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isCancelSubsLoading, setCancelSubsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthValid) {
      if (!membershipState.subscriptionsList) {
        dispatch(fetchSubscriptionsList());
      }

      if (!membershipState.transactionsList) {
        dispatch(fetchTransactionsList());
      }

      if (!membershipState.invoicesList) {
        dispatch(fetchInvoicesList());
      }
    }
  }, [isAuthValid]);

  const activeSubscriptionData = useMemo<SubscriptionType | undefined>(() => {
    if (
      membershipState.subscriptionsList &&
      membershipState.subscriptionsList.length > 0
    ) {
      return membershipState.subscriptionsList?.find(
        (subscription: SubscriptionType) =>
          subscription?.status === "active" ||
          subscription?.status === "trialing"
      );
    }
  }, [membershipState.subscriptionsList]);

  const handleDeactiveSubscription = async () => {
    setCancelSubsLoading(true);
    await dispatch(cancelSubscription(activeSubscriptionData?.id ?? ""))
      .unwrap()
      .then(async (res) => {
        setCancelSubsLoading(false);
        toast.success("Subscription successfully deactivated");
        setOpenModal(false);
        if (res.status === 200) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          dispatch(fetchSubscriptionsList());
        }
      })
      .catch((err) => {
        setCancelSubsLoading(false);
        toast.error("Somethings went wrong, please try again later");
      });
  };

  const deactivateModalContent = (
    <Box>
      <Typography mb={5} mt={1} color={theme.palette.text.primary}>
        Are you sure you want to deactivate your current subscription?
      </Typography>
      <Box display={"flex"} gap={2}>
        <Button variant="contained" onClick={() => setOpenModal(false)}>
          Keep Subscription
        </Button>
        <LoadingButton
          variant="contained"
          color={"error"}
          onClick={handleDeactiveSubscription}
          loading={isCancelSubsLoading}
        >
          Confirm Deactivation
        </LoadingButton>
      </Box>
    </Box>
  );

  return (
    <SubPagesLayout title="Coinfident | Subscription Management">
      <Box mt={3}>
        <CardTitle isCenter>Subscription Management</CardTitle>

        <Box className={styles.activeSubscription}>
          <Typography
            variant="h6"
            color={theme.palette.primary.main}
            borderBottom={`1px solid ${theme.palette.primary.main}`}
          >
            Active Subscription
          </Typography>
          <Box mb={2}>
            {membershipState?.subscriptionsListLoading ? (
              <Loading />
            ) : activeSubscriptionData ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {activeSubscriptionTHead.map(
                        (head: string, index: number) => (
                          <TableCell
                            key={head}
                            align={index === 0 ? "left" : "center"}
                          >
                            {head}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <Typography
                          variant="caption"
                          fontWeight={100}
                          color={theme.palette.text.primary}
                        >
                          {activeSubscriptionData?.plan}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="caption"
                          fontWeight={100}
                          color={theme.palette.text.primary}
                        >
                          {`${getDifferenceInMonth(activeSubscriptionData?.current_period_start, activeSubscriptionData?.current_period_end).durationIntervalCount} ${getDifferenceInMonth(activeSubscriptionData?.current_period_start, activeSubscriptionData?.current_period_end).durationIntervalType}`}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="caption"
                          fontWeight={100}
                          color={theme.palette.text.primary}
                        >
                          {moment(
                            activeSubscriptionData?.current_period_start
                          ).format("YYYY-MM-DD HH:mm:ss")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="caption"
                          fontWeight={100}
                          color={theme.palette.text.primary}
                        >
                          {moment(
                            activeSubscriptionData?.current_period_end
                          ).format("YYYY-MM-DD HH:mm:ss")}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="caption"
                          fontWeight={100}
                          color={theme.palette.text.primary}
                        >
                          {activeSubscriptionData?.status}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          // disabled={
                          //   activeSubscriptionData?.status === "trialing"
                          // }
                          variant="contained"
                          color="error"
                          size="small"
                          onClick={() => setOpenModal(true)}
                        >
                          Cancel
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Empty text="There is no active subscription" />
            )}
          </Box>
        </Box>

        <Box className={styles.subscriptionHistory}>
          <Typography
            variant="h6"
            color={theme.palette.primary.main}
            borderBottom={`1px solid ${theme.palette.primary.main}`}
          >
            Subscription History
          </Typography>
          <Box mb={2}>
            {membershipState?.subscriptionsListLoading ? (
              <Loading />
            ) : membershipState.subscriptionsList &&
              membershipState.subscriptionsList.length > 0 ? (
              <SubscriptionTable
                tHead={subscriptionsListTHeads}
                tBody={membershipState.subscriptionsList}
              />
            ) : (
              <Empty text="There is no subscriptions" />
            )}
          </Box>
        </Box>

        <Box className={styles.transactionsList}>
          <Typography
            variant="h6"
            color={theme.palette.primary.main}
            borderBottom={`1px solid ${theme.palette.primary.main}`}
          >
            Transactions List
          </Typography>
          <Box mb={2}>
            {membershipState.transactionsListLoading ? (
              <Loading />
            ) : membershipState?.transactionsList &&
              membershipState?.transactionsList.length > 0 ? (
              <TransactionTable
                tHead={transactionsListTHeads}
                tBody={membershipState.transactionsList}
              />
            ) : (
              <Empty text="There is no transactions" />
            )}
          </Box>
        </Box>

        <Box className={styles.invoiceList}>
          <Typography
            variant="h6"
            color={theme.palette.primary.main}
            borderBottom={`1px solid ${theme.palette.primary.main}`}
          >
            Invoice List
          </Typography>
          <Box mb={2}>
            {membershipState.invoicesListLoading ? (
              <Loading />
            ) : membershipState.invoicesList &&
              membershipState.invoicesList.length > 0 ? (
              <InvoiceTable
                tHead={invoiceListTHeads}
                tBody={membershipState.invoicesList}
              />
            ) : (
              <Empty text="There is no invoices" />
            )}
          </Box>
        </Box>
      </Box>

      <DefaultModal
        open={openModal}
        setOpen={setOpenModal}
        title="Deactivate Subscription"
        content={deactivateModalContent}
      />
    </SubPagesLayout>
  );
};

export default SubscriptionManagement;
