interface InfluencerSearch {
  screen_name: string;
  name: string;
  id_str: number;
}

export const influencers: InfluencerSearch[] = [
  { screen_name: "themlpx", name: "Martin", id_str: 1345743469600411648 },
  { screen_name: "koolk123456", name: "SolHub", id_str: 634075747 },
  { screen_name: "LeonidasNFT", name: "Leonidas", id_str: 720831897031421952 },
  { screen_name: "CryptoThro", name: "CRYPTO THRO", id_str: 2738087632 },
  {
    screen_name: "NewsAsset",
    name: "Digital Asset News",
    id_str: 1202030125195374598,
  },
  { screen_name: "yunaincrypto", name: "Yuna", id_str: 1551084743961088000 },
  {
    screen_name: "MartiniGuyYT",
    name: "That Martini Guy",
    id_str: 782946231551131648,
  },
  { screen_name: "MatthewHyland_", name: "Matthew Hyland", id_str: 889273682 },
  { screen_name: "Ralvero", name: "RALV\u039eRO", id_str: 37794688 },
  { screen_name: "Z_Humphries", name: "Zach Humphries", id_str: 51231402 },
  {
    screen_name: "goblintown",
    name: "goblintown",
    id_str: 1524825844677545987,
  },
  { screen_name: "DStarkand", name: "Daniel Starkand", id_str: 83716168 },
  {
    screen_name: "coinbureau",
    name: "Coin Bureau",
    id_str: 906230721513181184,
  },
  {
    screen_name: "TheDustyBC",
    name: "DustyBC Crypto",
    id_str: 913778551555608576,
  },
  { screen_name: "binance", name: "Binance", id_str: 877807935493033984 },
  {
    screen_name: "DanielTetsuyama",
    name: "Daniel Friedman \u20b3\u03a3 \ud83c\uddfa\ud83c\uddf8\ud83c\uddef\ud83c\uddf5\u26e9\ufe0f",
    id_str: 837285665914019840,
  },
  { screen_name: "barronsonline", name: "Barron's", id_str: 20449296 },
  { screen_name: "kucoincom", name: "KuCoin", id_str: 910110294625492992 },
  { screen_name: "gate_io", name: "Gate.io", id_str: 912539725071777792 },
  {
    screen_name: "BetOnline_ag",
    name: "BetOnline",
    id_str: 852155385213571077,
  },
  {
    screen_name: "itsCryptoWolf",
    name: "Crypto Wolf \ud83d\udc3a Gem Hunter",
    id_str: 251729727,
  },
  { screen_name: "MoonOverlord", name: "moon", id_str: 938606626017370112 },
  {
    screen_name: "cryptodramz",
    name: "\ud83d\udc41\ufe0f\u2764\ufe0f\ud83d\uded2\ud83d\uded2\ud83d\uded2",
    id_str: 934870719158140928,
  },
  {
    screen_name: "CryptoWizardd",
    name: "WIZZ\ud83e\udd77 ( beware scammers )",
    id_str: 972970759416111104,
  },
  { screen_name: "eliz883", name: "EliZ", id_str: 993962483332329472 },
  { screen_name: "TheMoonCarl", name: "The Moon", id_str: 978732571738755072 },
  {
    screen_name: "Casper_Network",
    name: "Casper",
    id_str: 1061731562637553664,
  },
  { screen_name: "metaplex", name: "Metaplex \ud83e\uddbe", id_str: 158758371 },
  {
    screen_name: "blknoiz06",
    name: "Ansem \ud83d\udc02\ud83c\udc04\ufe0f",
    id_str: 973261472,
  },
  {
    screen_name: "DriftProtocol",
    name: "BET on Drift",
    id_str: 1395713029593989121,
  },
  { screen_name: "adam__xyz", name: "Adam Delehanty", id_str: 22782759 },
  { screen_name: "mikealfred", name: "Mike Alfred", id_str: 95257097 },
  { screen_name: "betty_nft", name: "BETTY.DFZ", id_str: 1418431052704411653 },
  {
    screen_name: "intocryptoverse",
    name: "Benjamin Cowen",
    id_str: 943506806931734528,
  },
  { screen_name: "MeteoraAG", name: "Meteora", id_str: 1384042881808166924 },
  { screen_name: "dYdX", name: "dYdX", id_str: 909929047626354688 },
  { screen_name: "UNKJDgames", name: "UNKJD", id_str: 1444636163055181825 },
  {
    screen_name: "coinage_media",
    name: "Coinage",
    id_str: 1473043149970759680,
  },
  { screen_name: "mrjasonchoi", name: "Jason Choi", id_str: 174981490 },
  {
    screen_name: "pyquantnews",
    name: "PyQuant News \ud83d\udc0d",
    id_str: 3187132960,
  },
  {
    screen_name: "comparemcap",
    name: "CompareMarketCap",
    id_str: 1497939463703568390,
  },
  { screen_name: "gainzy222", name: "gainzy", id_str: 4107711 },
  {
    screen_name: "GordonGoner",
    name: "GordonGoner.eth (Wylie Aronow) \ud83c\udf4c",
    id_str: 1362625187489976321,
  },
  { screen_name: "okxweb3", name: "OKX Wallet", id_str: 1422839229592178689 },
  { screen_name: "pranksy", name: "Pranksy \ud83d\udce6", id_str: 2621412174 },
  { screen_name: "0xSisyphus", name: "Sisyphus", id_str: 1366930865574584323 },
  {
    screen_name: "NFTsAreNice",
    name: "MenaceToSociety \ud83e\udd76",
    id_str: 1173744255099166725,
  },
  { screen_name: "DaoKwonDo", name: "Books", id_str: 314560827 },
  {
    screen_name: "sobylife",
    name: "soby is on xai \ud83c\udfae\u26d3\ufe0f",
    id_str: 1081694326034391041,
  },
  { screen_name: "JoshRoomsburg", name: "Joshuwa Roomsburg", id_str: 90828940 },
  {
    screen_name: "BTCsessions",
    name: "BTC Sessions \ud83d\ude0e",
    id_str: 2298103730,
  },
  {
    screen_name: "CryptoGarga",
    name: "Garga.eth (Greg Solano)",
    id_str: 1384711969912102912,
  },
  {
    screen_name: "SwftCoin",
    name: "SWFT Blockchain",
    id_str: 916038000827449344,
  },
  {
    screen_name: "HouseofChimera",
    name: "House of Chimera",
    id_str: 1044164759610957824,
  },
  { screen_name: "auroraisnear", name: "Aurora", id_str: 1360461034377654272 },
  {
    screen_name: "RyanSAdams",
    name: "RYAN S\u039eAN ADAMS - rsa.eth \ud83e\udd84",
    id_str: 14571055,
  },
  {
    screen_name: "HoizonArt",
    name: "Hoizon.\u1d31\u1d40\u1d34",
    id_str: 1432749573747970051,
  },
  {
    screen_name: "SallyMayweather",
    name: "Sal the Agorist",
    id_str: 114060003,
  },
  {
    screen_name: "StealthEX_io",
    name: "StealthEX",
    id_str: 1050368337425965056,
  },
  { screen_name: "CryptoLimbo_", name: "Limbo", id_str: 1205153155027107840 },
  { screen_name: "The_Real_Fly", name: "The_Real_Fly", id_str: 15079601 },
  { screen_name: "RugRadio", name: "Rug Radio", id_str: 1443200444017283072 },
  {
    screen_name: "icebergy_",
    name: "icebergy \u2744\ufe0f",
    id_str: 239518063,
  },
  {
    screen_name: "BoredApeYC",
    name: "Bored Ape Yacht Club \ud83c\udf4c",
    id_str: 1381699264011771906,
  },
  { screen_name: "SolJakey", name: "Jakey", id_str: 1502733964808650754 },
  { screen_name: "econoar", name: "eric.eth", id_str: 14101591 },
  {
    screen_name: "AdvocateAda",
    name: "\u20b3DA_ \u20b3dvocate",
    id_str: 1365304554590523395,
  },
  {
    screen_name: "TIMEPieces",
    name: "TIMEPieces",
    id_str: 1442556256221220875,
  },
  { screen_name: "RealVision", name: "Real Vision", id_str: 2320755170 },
  {
    screen_name: "Altcoinbuzzio",
    name: "Altcoin Buzz",
    id_str: 893306219091374080,
  },
  { screen_name: "OGDfarmer", name: "DFarmer", id_str: 1362163416216055809 },
  {
    screen_name: "EricCryptoman",
    name: "Eric Cryptoman",
    id_str: 826381583489855490,
  },
  { screen_name: "naiivememe", name: "naiive", id_str: 1465903746408321026 },
  {
    screen_name: "CryptoMiners_Co",
    name: "Crypto Miners",
    id_str: 1396901359925047305,
  },
  {
    screen_name: "Degentraland",
    name: "Degentraland",
    id_str: 1440798044891463685,
  },
  {
    screen_name: "DegenerateNews",
    name: "DEGEN NEWS",
    id_str: 1505874016761831430,
  },
  {
    screen_name: "zmanian",
    name: "Zaki \u269b\ufe0f\ud83c\udf77",
    id_str: 7910872,
  },
  {
    screen_name: "gandalfcryptto",
    name: "GandalfCrypto",
    id_str: 1519997309546811393,
  },
  {
    screen_name: "CryptoDefiLord",
    name: "CryptoLord NE \ud83d\udcca\ud83d\udcc8",
    id_str: 2440332189,
  },
  {
    screen_name: "swrmai",
    name: "swrm \ud83d\udd0d",
    id_str: 1345519567074861057,
  },
  {
    screen_name: "PeterMcCormack",
    name: "Peter McCormack \ud83c\udff4\u200d\u2620\ufe0f",
    id_str: 893818632089763840,
  },
  { screen_name: "MEXC_Official", name: "MEXC", id_str: 978566222282444800 },
  {
    screen_name: "BitMartExchange",
    name: "BitMart",
    id_str: 937166242208763904,
  },
  {
    screen_name: "ChartFuMonkey",
    name: "ChartFu\ud83d\udc12",
    id_str: 1056015925667942400,
  },
  {
    screen_name: "AutismCapital",
    name: "Autism Capital \ud83e\udde9",
    id_str: 1297651178256257024,
  },
  { screen_name: "Dogetoshi", name: "Steven", id_str: 3833416227 },
  { screen_name: "KaminoFinance", name: "Kamino", id_str: 1549038204178911233 },
  { screen_name: "DrSolanaNFT", name: "DrSolana", id_str: 782438123703832577 },
  {
    screen_name: "LBank_Exchange",
    name: "LBank.com",
    id_str: 913302048564637696,
  },
  { screen_name: "henokcrypto", name: "Henok", id_str: 1411153087142809603 },
  {
    screen_name: "SilverBulletBTC",
    name: "crypto bullet \ud83d\udcc8",
    id_str: 1394583744,
  },
  {
    screen_name: "Bitcoinsensus",
    name: "Bitcoinsensus",
    id_str: 1163859351087591424,
  },
  {
    screen_name: "CryptoLawUS",
    name: "CryptoLaw",
    id_str: 1364275650513747974,
  },
  {
    screen_name: "Flowslikeosmo",
    name: "Emperor Osmo \ud83d\udc02 \ud83c\udfaf",
    id_str: 1373788838250876928,
  },
  { screen_name: "bitfinex", name: "Bitfinex", id_str: 886832413 },
  { screen_name: "xrpmickle", name: "mickle", id_str: 1431041023048921093 },
  {
    screen_name: "itstylersays",
    name: "tmas \ud83d\udc41",
    id_str: 2289922827,
  },
  {
    screen_name: "PhilakoneCrypto",
    name: "Philakone",
    id_str: 715133222976270336,
  },
  { screen_name: "lcx", name: "LCX", id_str: 968864939699064833 },
  { screen_name: "seth_fin", name: "Seth", id_str: 1338554201241243655 },
  {
    screen_name: "notEezzy",
    name: "notEezzy \ud83e\uddf8",
    id_str: 992590209752629249,
  },
  { screen_name: "patty_fi", name: "PattyIce", id_str: 2760158505 },
  {
    screen_name: "CoinDCX",
    name: "CoinDCX: India's Safest Crypto Platform",
    id_str: 968118294174666752,
  },
  { screen_name: "JBSDC", name: "Justin Slaughter", id_str: 16569497 },
  {
    screen_name: "CryptoVonDoom",
    name: "VonDoom \u2615\ufe0f",
    id_str: 949458210494140416,
  },
  { screen_name: "TradeTalks", name: "TradeTalks", id_str: 4265284845 },
  {
    screen_name: "0xMert_",
    name: "mert | helius.dev",
    id_str: 1309886201944473600,
  },
  {
    screen_name: "Avalanche_Space",
    name: "\ud83d\udd3a \ud835\uddd4\ud835\ude03\ud835\uddee\ud835\uddf9\ud835\uddee\ud835\uddfb\ud835\uddf0\ud835\uddf5\ud835\uddf2 \ud835\udde6\ud835\uddfd\ud835\uddee\ud835\uddf0\ud835\uddf2 \ud83d\udd3a",
    id_str: 1409531617773723650,
  },
  {
    screen_name: "JasonYanowitz",
    name: "Yano \ud83d\udfea",
    id_str: 1107518478,
  },
  {
    screen_name: "CryptoDefiGems",
    name: "Crypto Defi Gems",
    id_str: 1409063853246365696,
  },
  {
    screen_name: "RuleXRP",
    name: "James Rule XRP \ud83d\udc4a\ud83d\ude0e",
    id_str: 1220135485680619524,
  },
  {
    screen_name: "whale_alert",
    name: "Whale Alert",
    id_str: 1039833297751302144,
  },
  { screen_name: "TO", name: "trevor.btc", id_str: 6635012 },
  {
    screen_name: "Crypto_Dep",
    name: "\ud83c\uddfa\ud83c\udde6 CryptoDep #StandWithUkraine \ud83c\uddfa\ud83c\udde6",
    id_str: 1439608646,
  },
  {
    screen_name: "CarlBMenger",
    name: "Carl \u20bf MENGER \u26a1\ufe0f\ud83c\uddf8\ud83c\uddfb",
    id_str: 1081549387522887680,
  },
  { screen_name: "HaileyLennonBTC", name: "Hailey Lennon", id_str: 3740778132 },
  {
    screen_name: "crypto_linn",
    name: "\ud83c\ude50 Crypto Linn",
    id_str: 1139174563802226688,
  },
  {
    screen_name: "cryptogems555",
    name: "Crypto GEMs \ud83d\udcc8\ud83d\ude80",
    id_str: 4473212565,
  },
  {
    screen_name: "inevitable360",
    name: "inevitable360",
    id_str: 1405156811796357120,
  },
  { screen_name: "MrBarryCrypto", name: "Mr Barry Crypto", id_str: 190390941 },
  { screen_name: "Tradermayne", name: "Mayne", id_str: 2446024556 },
  {
    screen_name: "MrBigWhaleREAL",
    name: "Mr. BIG WHALE",
    id_str: 1415324327113805830,
  },
  {
    screen_name: "SolanaSensei",
    name: "Solana Sensei",
    id_str: 731402158512476161,
  },
  {
    screen_name: "BitcoinFear",
    name: "Bitcoin Fear and Greed Index",
    id_str: 1151046460688887808,
  },
  {
    screen_name: "WeirdOne_NFT",
    name: "\ud83c\udd86\ud83c\udd74\ud83c\udd78\ud83c\udd81\ud83c\udd73-\ud83c\udd7e\ud83c\udd7d\ud83c\udd74 .eth |",
    id_str: 924258664575270912,
  },
  {
    screen_name: "CryptoMutant",
    name: "CryptoMutant",
    id_str: 979790805119455238,
  },
  { screen_name: "CryptoKans", name: "Kans", id_str: 1380127953464532995 },
  { screen_name: "CryptoKans", name: "Kans", id_str: 1380127953464532995 },
  {
    screen_name: "pnxgrp",
    name: "PHOENIX - Crypto News & Analytics",
    id_str: 1377202490643517444,
  },
  {
    screen_name: "CryptoNCoffeee",
    name: "Jess \u2615\ufe0f",
    id_str: 984634995582136321,
  },
  {
    screen_name: "Gateio_Startup",
    name: "Gate Startup",
    id_str: 1514973302280048647,
  },
  {
    screen_name: "MackAttackXRP",
    name: "\ud83c\uddf3\ud83c\uddf1 MackAttackXRP\u00ae \ud83c\uddf3\ud83c\uddf1",
    id_str: 1129664768753709056,
  },
  {
    screen_name: "WalkerAmerica",
    name: "Walker\u26a1\ufe0f",
    id_str: 273096737,
  },
  {
    screen_name: "ZssBecker",
    name: "Alex Becker \ud83c\udf4a\ud83c\udfc6\ud83e\udd47",
    id_str: 1243470638477697024,
  },
  { screen_name: "fejau_inc", name: "fejau", id_str: 3195785191 },
  { screen_name: "cryptocevo", name: "cevo", id_str: 1962290894 },
  {
    screen_name: "crypto_banter",
    name: "Crypto Banter",
    id_str: 1319881520727969793,
  },
  {
    screen_name: "leap_cosmos",
    name: "Leap Cosmos \u269b\ufe0f",
    id_str: 1529899884710490113,
  },
  {
    screen_name: "travalacom",
    name: "Travala.com \ud83c\udfe8 \u2708\ufe0f",
    id_str: 961827567740239872,
  },
  {
    screen_name: "GutterCatGang",
    name: "Gutter Cat Gang",
    id_str: 1391917111614877696,
  },
  {
    screen_name: "JumperWave",
    name: "WAVE JUMPER",
    id_str: 1292201662531211264,
  },
  {
    screen_name: "crypto_inez",
    name: "Inez \ud83c\udf3f",
    id_str: 1553832103187709952,
  },
  { screen_name: "nylo44", name: "Max DeGilio", id_str: 1108833240 },
  {
    screen_name: "coryklippsten",
    name: "Cory Klippsten \ud83e\udda2 Swan.com",
    id_str: 3260096383,
  },
  { screen_name: "Scroll_ZKP", name: "Scroll", id_str: 1361909631187001350 },
  {
    screen_name: "yourcryptodj",
    name: "Your Crypto DJ",
    id_str: 1265223205230383104,
  },
  { screen_name: "ETHNews_com", name: "ETHNews", id_str: 329151463 },
  {
    screen_name: "EthereumFear",
    name: "Ethereum Fear and Greed Index",
    id_str: 1401773837386010629,
  },
  {
    screen_name: "koreanjewcrypto",
    name: "KJ Crypto",
    id_str: 873190778910253056,
  },
  { screen_name: "i_am_jackis", name: "JACKIS", id_str: 938723248774270977 },
  {
    screen_name: "Crypto__emily",
    name: "Emily\u2019s Universe \ud83d\udc8e",
    id_str: 1033315094539776002,
  },
  {
    screen_name: "SuperVerse",
    name: "SuperVerse",
    id_str: 1350220454674329600,
  },
  { screen_name: "TheFlowHorse", name: "HORSE", id_str: 936224248586211329 },
  { screen_name: "jacksondahl", name: "Jackson Dahl", id_str: 15746580 },
  { screen_name: "Overdose_AI", name: "OverDose", id_str: 1333533748072165376 },
  {
    screen_name: "Dynamo_Patrick",
    name: "Patrick Scott | Dynamo DeFi",
    id_str: 1377775070156840960,
  },
  { screen_name: "Utoday_en", name: "U.Today", id_str: 953559767242498049 },
  {
    screen_name: "Thecryptomist",
    name: "The Cryptomist",
    id_str: 976929593042063360,
  },
  { screen_name: "Brett_Crypto_X", name: "Brett", id_str: 1472303180033703944 },
  {
    screen_name: "crypto_birb",
    name: "Adrian Zdu\u0144czyk, CMT",
    id_str: 897446848004227072,
  },
  {
    screen_name: "wizardofsoho",
    name: "Wizard Of SoHo (\ud83c\udf77,\ud83c\udf77)",
    id_str: 1175781003245191178,
  },
  {
    screen_name: "RichardMcCrackn",
    name: "Rick McCracken DIGI \ud83c\uddfa\ud83c\uddf8",
    id_str: 437745089,
  },
  { screen_name: "Parcl", name: "Parcl", id_str: 1397334699308113923 },
  { screen_name: "JubaJeeves", name: "J U B A", id_str: 1359386370435928066 },
  {
    screen_name: "lex_node",
    name: "_gabrielShapir0",
    id_str: 954015928924057601,
  },
  { screen_name: "Jamyies", name: "James CryptoGuru", id_str: 27602050 },
  { screen_name: "almaasinobi", name: "Alma Asinobi", id_str: 2739813942 },
  {
    screen_name: "scottmelker",
    name: "The Wolf Of All Streets",
    id_str: 17351167,
  },
  { screen_name: "PeterLBrandt", name: "Peter Brandt", id_str: 247857712 },
  { screen_name: "lphaCentauriKid", name: "a.c.k.", id_str: 3284423738 },
  {
    screen_name: "matiwinnetou",
    name: "$matiwinnetou | C\u20b3rdano F\u20b3ns (CRF\u20b3)",
    id_str: 2582379505,
  },
  { screen_name: "Coachkcrypto", name: "Coach K Crypto", id_str: 393833726 },
  {
    screen_name: "ShibBender",
    name: "$Shib Bender \ud83c\udf2c\ufe0f\ud83d\udca8\ud83c\udf2a\ufe0f\ud83d\udcc8\ud83d\udef8\u2728",
    id_str: 1395248395137204225,
  },
  {
    screen_name: "MagicCraftGame",
    name: "MagicCraft",
    id_str: 1440205262783070213,
  },
  { screen_name: "Grayscale", name: "Grayscale", id_str: 2800345565 },
  {
    screen_name: "CosmosATOMDaily",
    name: "Cosmos Daily \u269b\ufe0f",
    id_str: 1406995344483704832,
  },
  {
    screen_name: "CosmosATOMDaily",
    name: "Cosmos Daily \u269b\ufe0f",
    id_str: 1406995344483704832,
  },
  {
    screen_name: "Legitcryptonerd",
    name: "Crypto nerd",
    id_str: 933762169526734848,
  },
  { screen_name: "punk9059", name: "Stats", id_str: 1449164448321605632 },
  {
    screen_name: "ProTheDoge",
    name: "SlumDOGE Millionaire",
    id_str: 1358176095850229761,
  },
  {
    screen_name: "BitMartResearch",
    name: "BitMart Research\uff5c$BMX HODLer",
    id_str: 1443107132467937285,
  },
  { screen_name: "Rothmus", name: "Rothmus \ud83c\udff4", id_str: 294293982 },
  {
    screen_name: "CoinexFilipino",
    name: "CoinEx Filipino",
    id_str: 1355724320182398980,
  },
  {
    screen_name: "RealCryptoGraph",
    name: "CryptoGraph Ratings",
    id_str: 1023796092243079168,
  },
  { screen_name: "solBOOGLE", name: "BOOGLE", id_str: 1423269003309445121 },
  { screen_name: "decentraland", name: "Decentraland", id_str: 3291830170 },
  { screen_name: "zora", name: "Zora", id_str: 1168028988091985920 },
  { screen_name: "0xCygaar", name: "cygaar", id_str: 1287576585353039872 },
  {
    screen_name: "KyleSamani",
    name: "Integrated Kyle e/acc",
    id_str: 46332443,
  },
  { screen_name: "TheSandboxGame", name: "The Sandbox", id_str: 347831597 },
  { screen_name: "HiBlockTalks", name: "BlockTalks", id_str: 3101038465 },
  {
    screen_name: "Missy__Crypto",
    name: "Missy \ud83d\udc9b\ud83d\udc51",
    id_str: 1537777322245656576,
  },
  { screen_name: "TheBlock__", name: "The Block", id_str: 963767159536209921 },
  { screen_name: "leap_xyz", name: "Leap", id_str: 1420433812253708300 },
  {
    screen_name: "ForgottenRunes",
    name: "Forgotten Runes Wizard's Cult",
    id_str: 1395820842337153025,
  },
  { screen_name: "Pledditor", name: "Pledditor", id_str: 1485303993135996928 },
  {
    screen_name: "OldMannCrypto",
    name: "Old Man Crypto",
    id_str: 1271374759670906880,
  },
  { screen_name: "TapTools", name: "TapTools", id_str: 1486777331352055824 },
  {
    screen_name: "EntEthAlliance",
    name: "Enterprise Ethereum Alliance",
    id_str: 831182644234821632,
  },
  { screen_name: "poordart", name: "poordart", id_str: 1289834351803265025 },
  {
    screen_name: "eulerfinance",
    name: "Euler Labs\ud83d\udee2\ufe0f\ud83c\uddec\ud83c\udde7",
    id_str: 1295028541139177482,
  },
  {
    screen_name: "OnChainMonkey",
    name: "OnChainMonkey\u00ae",
    id_str: 1437036457042989061,
  },
  { screen_name: "Yourpop8", name: "YourPOP", id_str: 1347777544649641985 },
  {
    screen_name: "SuperRare",
    name: "SuperRare \ud83d\udc8e",
    id_str: 980935731794403328,
  },
  { screen_name: "IncomeSharks", name: "IncomeSharks", id_str: 4441279246 },
  {
    screen_name: "MinisterOfNFTs",
    name: "MinisterOfNFTs \ud83d\udd2e",
    id_str: 1506607724490022916,
  },
  {
    screen_name: "OffchainLabs",
    name: "Offchain Labs",
    id_str: 1029330822942347265,
  },
  { screen_name: "urbit", name: "Urbit", id_str: 1862972077 },
  {
    screen_name: "VulcanForged",
    name: "Vulcan Forged",
    id_str: 1261684151780081664,
  },
  {
    screen_name: "KingKaranCrypto",
    name: "\ud83d\udc51 \ud835\udd42\ud835\udd5a\ud835\udd5f\ud835\udd58 \ud835\udd42\ud835\udd52\ud835\udd63\ud835\udd52\ud835\udd5f \ud83d\udc51",
    id_str: 1038895492904767488,
  },
  {
    screen_name: "machibigbrother",
    name: "Machi Big Brother",
    id_str: 970014782135844864,
  },
  { screen_name: "yq_acc", name: "YQ", id_str: 265758342 },
  {
    screen_name: "LinksDAO",
    name: "Links Golf Club",
    id_str: 1471201657367273472,
  },
  { screen_name: "jreentertain", name: "JRE", id_str: 1832566346451648512 },
  { screen_name: "CoinDesk", name: "CoinDesk", id_str: 1333467482 },
  { screen_name: "3orovik", name: "borovik", id_str: 1373786106118963200 },
  { screen_name: "0xQuit", name: "Quit", id_str: 2573380344 },
  {
    screen_name: "CryptooIndia",
    name: "Crypto India",
    id_str: 939169550674796544,
  },
  {
    screen_name: "EBlockchainCon",
    name: "European Blockchain Convention",
    id_str: 995363068766052352,
  },
  { screen_name: "360_trader", name: "360Trader", id_str: 1247324561135280128 },
  {
    screen_name: "injective",
    name: "Injective \ud83e\udd77",
    id_str: 1062118922147659776,
  },
  { screen_name: "Helloimmorgan", name: "Morgan", id_str: 128463721 },
  {
    screen_name: "cryptosanthoshK",
    name: "Crypto King\ud83d\udc51\ud83d\udc8e.eth.sol",
    id_str: 1544044068413521922,
  },
  { screen_name: "CryptoBusy", name: "CryptoBusy", id_str: 970698661364715520 },
  { screen_name: "CoinWOfficial", name: "CoinW", id_str: 1090078751646375936 },
  {
    screen_name: "DaanCrypto",
    name: "Daan Crypto Trades",
    id_str: 918138253617790976,
  },
  {
    screen_name: "CryptoInsightsX",
    name: "Crypto Insights",
    id_str: 1463919070735003648,
  },
  {
    screen_name: "unstoppableweb",
    name: "Unstoppable.DREAM",
    id_str: 1101647425867902976,
  },
  { screen_name: "kmoney_69", name: "kmoney", id_str: 1404603419101306881 },
  {
    screen_name: "franklinisbored",
    name: "Franklin",
    id_str: 1323747353308835840,
  },
  {
    screen_name: "cryptopunksbot",
    name: "CryptoPunks Bot",
    id_str: 879737099074887682,
  },
  { screen_name: "assetdash", name: "AssetDash", id_str: 1279918106111025153 },
  {
    screen_name: "grapeprotocol",
    name: "\ud83c\udf47 Grape Protocol",
    id_str: 1387427286362861570,
  },
  {
    screen_name: "0xJesusMartinez",
    name: "Jesus Martinez",
    id_str: 1424636841303629826,
  },
  {
    screen_name: "0xJesusMartinez",
    name: "Jesus Martinez",
    id_str: 1424636841303629826,
  },
  {
    screen_name: "unusual_whales",
    name: "unusual_whales",
    id_str: 1200616796295847936,
  },
  {
    screen_name: "Definews_Info",
    name: "\ud83c\udf10 \ud835\udc03\ud835\udc04\ud835\udc05\ud835\udc08 \ud835\udc0d\ud835\udc04\ud835\udc16\ud835\udc12 \ud83c\udf10",
    id_str: 1275640088559366145,
  },
  {
    screen_name: "TheDonOfApes",
    name: "The Don Of Apes \ud83e\udd8d",
    id_str: 1294405141546897408,
  },
  { screen_name: "gurgavin", name: "GURGAVIN", id_str: 109813243 },
  {
    screen_name: "ChangeNOW_io",
    name: "ChangeNOW",
    id_str: 915716225359114240,
  },
  { screen_name: "AurelienOhayon", name: "AO", id_str: 1494342250544914438 },
  { screen_name: "chainlink", name: "Chainlink", id_str: 1530530365 },
  { screen_name: "Kellykellam", name: "KellyKellam", id_str: 116232741 },
  {
    screen_name: "ThinkingCrypto1",
    name: "Tony Edward (Thinking Crypto Podcast)",
    id_str: 946799059829960706,
  },
  {
    screen_name: "Obol_Collective",
    name: "Obol Collective",
    id_str: 1393178052809592832,
  },
  {
    screen_name: "ApolloDotLoan",
    name: "Apollo Finance",
    id_str: 1286548095027703808,
  },
  { screen_name: "rollbitcom", name: "Rollbit", id_str: 1227923263533305857 },
  { screen_name: "Dagnum_PI", name: "Dagnum P.I.", id_str: 913374738071932933 },
  {
    screen_name: "streamflow_fi",
    name: "Streamflow",
    id_str: 1393137074979713024,
  },
  { screen_name: "AltcoinGordon", name: "Gordon", id_str: 1354400126857605121 },
  {
    screen_name: "Phemex_official",
    name: "Phemex",
    id_str: 1171719416389591041,
  },
  {
    screen_name: "bengbengp2e",
    name: "Beng Beng Gaming",
    id_str: 1426808076015833090,
  },
  {
    screen_name: "crypto_queen_x",
    name: "XRP QUEEN\ud83e\udd0d",
    id_str: 738065552728588289,
  },
  { screen_name: "coolcats", name: "Cool Cats", id_str: 1395553778187718657 },
  { screen_name: "shibburn", name: "Shibburn", id_str: 1400662841439526921 },
  {
    screen_name: "IAmCryptoWolfy",
    name: "IAmCryptoWolfy",
    id_str: 1509206479475298305,
  },
  {
    screen_name: "News1Lead",
    name: "\ud83c\udfc6 \ud835\udc0d\ud835\udc04\ud835\udc16\ud835\udc12\ud835\udc0b\ud835\udc04\ud835\udc00\ud835\udc03",
    id_str: 1309278478123053059,
  },
  { screen_name: "MuroCrypto", name: "Muro", id_str: 230206137 },
  { screen_name: "altstatemachine", name: "ASM", id_str: 1374934393140117506 },
  { screen_name: "CryptoAnglio", name: "Anglio", id_str: 2729214382 },
  { screen_name: "blockchainbob", name: "Blockchain Bob", id_str: 121214200 },
  {
    screen_name: "NakamotoGames",
    name: "Nakamoto.Games",
    id_str: 1430480401030688772,
  },
  {
    screen_name: "Cardanians_io",
    name: "Cardanians (CRDN)",
    id_str: 1159174021893767170,
  },
  {
    screen_name: "GreatMoonshot",
    name: "The Great Moonshot",
    id_str: 1472254830567636992,
  },
  {
    screen_name: "CryptoDiffer",
    name: "\ud83c\uddfa\ud83c\udde6 CryptoDiffer - StandWithUkraine \ud83c\uddfa\ud83c\udde6",
    id_str: 963815487481303040,
  },
  { screen_name: "WhaleFUD", name: "Whale", id_str: 3359503481 },
  { screen_name: "IcedKnife", name: "Iced", id_str: 618539620 },
  { screen_name: "coinexcom", name: "CoinEx Global", id_str: 460537897 },
  { screen_name: "okx", name: "OKX", id_str: 867617849208037377 },
  { screen_name: "CryptoKaleo", name: "K A L E O", id_str: 906234475604037637 },
  { screen_name: "DexGemsReal", name: "lyx.eth", id_str: 848865746 },
  {
    screen_name: "dogecoin_empire",
    name: "\u2b50\ufe0f Alena \u2b50\ufe0f",
    id_str: 1175518965713580035,
  },
  { screen_name: "SugarKoat", name: "Shahriar", id_str: 1250844384947392512 },
  {
    screen_name: "PaikCapital",
    name: "HDF humble defi farmer",
    id_str: 1220850904351399936,
  },
  {
    screen_name: "mentalist420",
    name: "MentARTlist420\ud83e\udec0\u2122",
    id_str: 893341342834712581,
  },
  {
    screen_name: "cryptoWZRD_",
    name: "CRYPTOWZRD",
    id_str: 1100373132135804928,
  },
  {
    screen_name: "GoingParabolic",
    name: "Jason A. Williams",
    id_str: 1194984822,
  },
  {
    screen_name: "CommunityFlare",
    name: "Flare Community \u2600\ufe0f",
    id_str: 1345358847087620100,
  },
  {
    screen_name: "BTC_Archive",
    name: "Bitcoin Archive",
    id_str: 970994516357472257,
  },
  { screen_name: "LayahHeilpern", name: "Layah Heilpern", id_str: 455937214 },
  { screen_name: "CryptoWendyO", name: "Wendy O", id_str: 935742315389444096 },
  {
    screen_name: "rektcapital",
    name: "Rekt Capital",
    id_str: 918122676195090433,
  },
  {
    screen_name: "UniverseTwenty",
    name: "XRP CAPTAIN",
    id_str: 1359669474769530882,
  },
  {
    screen_name: "BitgetWallet",
    name: "Bitget Wallet \ud83e\ude75",
    id_str: 1040124936843550720,
  },
  { screen_name: "Trader_XO", name: "XO", id_str: 1381341090 },
  {
    screen_name: "BSCGemsAlert",
    name: "\ud83d\udea8BSC Gems Alert\ud83d\udea8",
    id_str: 2424259633,
  },
  {
    screen_name: "MasonVersluis",
    name: "MASON VERSLUIS",
    id_str: 1039692343794970624,
  },
  {
    screen_name: "CryptoRank_io",
    name: "CryptoRank.io",
    id_str: 1057984132930789378,
  },
  {
    screen_name: "MarketRebels",
    name: "Market Rebellion",
    id_str: 817007725666242561,
  },
  {
    screen_name: "XRP_Wizardry",
    name: "XRP THE WIZARD\ud83c\udf19",
    id_str: 828660369644072960,
  },
  {
    screen_name: "HodlDogecoin",
    name: "Dogecoin and Bitcoin HODL",
    id_str: 1081583907705888768,
  },
  {
    screen_name: "crypto__mak",
    name: "Crypto Mak",
    id_str: 1059910799458754561,
  },
  { screen_name: "CryptoBoss1984", name: "CryptoBoss", id_str: 2735504155 },
  {
    screen_name: "kilday_morgan",
    name: "Proudly K-hive \u2764\ufe0f\ud83d\udc9c\ud83d\udc9b\ud83d\udc1d",
    id_str: 1449388922652536838,
  },
  { screen_name: "XMaximist", name: "MANDO CT", id_str: 1344614912803426305 },
  {
    screen_name: "CryptoTony__",
    name: "Crypto Tony",
    id_str: 893111826254356481,
  },
  {
    screen_name: "analyticsinme",
    name: "Analytics Insight",
    id_str: 713325433006063616,
  },
  {
    screen_name: "PennyBritish",
    name: "British Miss",
    id_str: 961838556611149825,
  },
  {
    screen_name: "SolaniansHub",
    name: "Solanians",
    id_str: 1363057160142213124,
  },
  { screen_name: "BitrueOfficial", name: "Bitrue", id_str: 978184299122892800 },
  {
    screen_name: "sentosumosaba",
    name: "\ud83c\udf38Crypto Eri \ud83e\ude9dCarpe Diem",
    id_str: 830711834848043008,
  },
  {
    screen_name: "AltcoinDailyio",
    name: "Altcoin Daily",
    id_str: 958118843636854784,
  },
  {
    screen_name: "edz_lozano",
    name: "Geri Crypto/NFT Promotions",
    id_str: 1361234721930289153,
  },
  { screen_name: "cryptomanran", name: "Ran Neuner", id_str: 58487473 },
  {
    screen_name: "cryptoassetsguy",
    name: "Crypto Assets Guy",
    id_str: 580054883,
  },
  {
    screen_name: "ArtvisionNFT",
    name: "Artvision\u1d3a\u1da0\u1d40",
    id_str: 1431768395993780229,
  },
  {
    screen_name: "AltCryptoGems",
    name: "Sjuul | AltCryptoGems",
    id_str: 1372196901936848901,
  },
  {
    screen_name: "TOPDOGE007",
    name: "\ud835\udce3 \ud835\udcde \ud835\udcdf \ud835\udcd3 \ud835\udcde \ud835\udcd6 \ud835\udcd4",
    id_str: 1365938037746720768,
  },
  {
    screen_name: "MetisL2",
    name: "Metis\ud83c\udf3f",
    id_str: 1290738140219572225,
  },
  {
    screen_name: "LynAldenContact",
    name: "Lyn Alden",
    id_str: 823766058909761536,
  },
  { screen_name: "proof_xyz", name: "PROOF", id_str: 1420417970749149188 },
  { screen_name: "AlgoFamily", name: "MJ.algo", id_str: 1367563846953668613 },
  {
    screen_name: "XRP_Avengers",
    name: "\u2694\ufe0f XRP Avengers \u2694\ufe0f",
    id_str: 1486037614754996224,
  },
  {
    screen_name: "bitcoinlfgo",
    name: "BITCOINLFG\u00ae",
    id_str: 1296337092302139396,
  },
  {
    screen_name: "IamPhoenix747",
    name: "PhoeniXoge\ud83e\udeac $ELS \ud83d\ude80 $XOGE",
    id_str: 1021907605180960768,
  },
  { screen_name: "doge511", name: "Doll", id_str: 1387595890110582785 },
  { screen_name: "demexchange", name: "Demex", id_str: 1255758231290343424 },
  {
    screen_name: "xrp334",
    name: "xrpnews.crypto\u00ae \u26a1\ufe0f\ud83d\udc3a",
    id_str: 795322679637196800,
  },
  {
    screen_name: "rovercrc",
    name: "Crypto Rover",
    id_str: 1353384573435056128,
  },
  {
    screen_name: "ShardiB2",
    name: "Don't follow Shardi B if you hate Money",
    id_str: 1091099554605395968,
  },
  {
    screen_name: "TheCryptoDog",
    name: "The Crypto Dog \ud83d\udcc8",
    id_str: 887748030304329728,
  },
  {
    screen_name: "Dennis_Porter_",
    name: "Dennis Porter",
    id_str: 1291243382128304133,
  },
  {
    screen_name: "MicahPW20",
    name: "Micah XRP (DEATON FOR SENATE)",
    id_str: 1148672970501623808,
  },
  { screen_name: "iambroots", name: "MOON KING B-ROOTS", id_str: 839993569 },
  {
    screen_name: "doctorow",
    name: "Cory Doctorow NONCONSENSUAL BLUE TICK",
    id_str: 2729061,
  },
  {
    screen_name: "crypto_rand",
    name: "Crypto Rand",
    id_str: 859484337850523648,
  },
  { screen_name: "ZeroHedge_", name: "Wick", id_str: 838438099520716801 },
  {
    screen_name: "kieranmlkelly",
    name: "Kieran Kelly \ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc65\udb40\udc6e\udb40\udc67\udb40\udc7f\ud83c\udde7\ud83c\udde7",
    id_str: 930967593476935680,
  },
  { screen_name: "financialjuice", name: "FinancialJuice", id_str: 381696140 },
  { screen_name: "itsFoxCrypto", name: "Fox Crypto", id_str: 419697640 },
  { screen_name: "cryptocom", name: "Crypto.com", id_str: 864347902029709314 },
  { screen_name: "JustinScerini", name: ".", id_str: 933230923306254336 },
  {
    screen_name: "_TokenHunter",
    name: "TokenHunter",
    id_str: 1310130190266241024,
  },
  { screen_name: "Eljaboom", name: "Elja", id_str: 3296780478 },
  {
    screen_name: "BigImpactHumans",
    name: "LilHumansBigImpact",
    id_str: 2961936246,
  },
  {
    screen_name: "APompliano",
    name: "Anthony Pompliano \ud83c\udf2a",
    id_str: 339061487,
  },
  { screen_name: "linmeitalks", name: "Lin Mei", id_str: 3067800029 },
  { screen_name: "doodles", name: "doodles", id_str: 1405701429646430212 },
  {
    screen_name: "LadyofCrypto1",
    name: "Lady of Crypto",
    id_str: 1435224897299550217,
  },
  { screen_name: "SecretNetwork", name: "Secret Network", id_str: 3305325070 },
  { screen_name: "BillyM2k", name: "Shibetoshi Nakamoto", id_str: 30699048 },
  {
    screen_name: "JackNiewold",
    name: "Jack Niewold \ud83e\udee1",
    id_str: 1256669514042875904,
  },
  { screen_name: "Roobet", name: "Roobet", id_str: 1034166571932045313 },
  { screen_name: "CoinJournal", name: "CoinJournal", id_str: 2554739270 },
  { screen_name: "pierre_crypt0", name: "Pierre", id_str: 590134700 },
  {
    screen_name: "WilderWorld",
    name: "Wilder World",
    id_str: 1336204428509028352,
  },
  { screen_name: "chainyoda", name: "chainyoda", id_str: 112509659 },
  {
    screen_name: "EleanorTerrett",
    name: "Eleanor Terrett",
    id_str: 4182894076,
  },
  {
    screen_name: "CryptoIRELAND1",
    name: "Darren \u2618\ud83c\uddee\ud83c\uddea",
    id_str: 3414808858,
  },
  {
    screen_name: "digitalassetbuy",
    name: "Digital Asset Investor",
    id_str: 991345218678116353,
  },
  { screen_name: "Bang_Garr", name: "Bang Garr", id_str: 1305904514487083008 },
  {
    screen_name: "IIICapital",
    name: "Joe Burnett, MSBA (\ud83d\udd11)\u00b3",
    id_str: 967859267352059911,
  },
  {
    screen_name: "buidlbee",
    name: "buidlbee: make it in web3",
    id_str: 1535935654613360641,
  },
  {
    screen_name: "albertwenger",
    name: "Albert Wenger \ud83c\udf0e\ud83d\udd25\u231b",
    id_str: 7015112,
  },
  { screen_name: "ShiLLin_ViLLian", name: "Alex", id_str: 1064982684060057606 },
  {
    screen_name: "iamDCinvestor",
    name: "DCinvestor",
    id_str: 956670268596015105,
  },
  {
    screen_name: "TrueCrypto28",
    name: "Mr. Anderson",
    id_str: 947954652770844672,
  },
  {
    screen_name: "nftfreaks",
    name: "NFT Freaks \ud83d\udddd\ufe0f\ud83c\udff0\ud83e\uddb8\ud83c\udfff\u200d\u2642\ufe0f",
    id_str: 1346869084105936898,
  },
  {
    screen_name: "nierop_pieter",
    name: "Pieter Nierop - $enginedriver $cardano-express",
    id_str: 722359682443190272,
  },
  {
    screen_name: "valueandtime",
    name: "Value & Time",
    id_str: 1293237781628702726,
  },
  {
    screen_name: "BTC_JackSparrow",
    name: "//Bitcoin \ud835\udd75ack \ud83d\udc10",
    id_str: 380546370,
  },
  {
    screen_name: "TheEliteCrypto",
    name: "Elite Crypto",
    id_str: 1359083046746677254,
  },
  { screen_name: "loopringorg", name: "Loopring\ud83d\udc99", id_str: 9130922 },
  { screen_name: "bitgetglobal", name: "Bitget", id_str: 1098881129057112064 },
  {
    screen_name: "AltcoinSherpa",
    name: "Altcoin Sherpa",
    id_str: 1068237257977544704,
  },
  {
    screen_name: "alancarroII",
    name: "Alan Carroll",
    id_str: 934796999068651521,
  },
  {
    screen_name: "Digital_Diary1",
    name: "\ud835\uddd7\ud835\udddc\ud835\uddda\ud835\udddc\ud835\udde7\ud835\uddd4\ud835\udddf \ud835\uddd7\ud835\udddc\ud835\uddd4\ud835\udde5\ud835\uddec \ud83d\udcd6",
    id_str: 1529368862483169280,
  },
  { screen_name: "stablechen", name: "Will Chen", id_str: 3908383092 },
  {
    screen_name: "GoldTelegraph_",
    name: "Gold Telegraph \u26a1",
    id_str: 837048318865862666,
  },
  {
    screen_name: "Padierfind",
    name: "Patrick Tobler",
    id_str: 1273236976133713924,
  },
  {
    screen_name: "CardanoFeed",
    name: "Cardano Feed ($ADA)",
    id_str: 1373334050048466946,
  },
  { screen_name: "Bagsy", name: "Bagsy", id_str: 998588353384796167 },
  { screen_name: "greg16676935420", name: "greg", id_str: 1356434353623093249 },
  { screen_name: "WuBlockchain", name: "Wu Blockchain", id_str: 111533746 },
  {
    screen_name: "jpgstorestats",
    name: "JPG Store Stats",
    id_str: 1558531254462648320,
  },
  { screen_name: "cryptorecruitr", name: "Dan Gambardello", id_str: 37881270 },
  {
    screen_name: "PancakeSwap",
    name: "PancakeSwap",
    id_str: 1305349277422477313,
  },
  {
    screen_name: "veefriends",
    name: "VeeFriends",
    id_str: 1365372609903419395,
  },
  {
    screen_name: "twobitidiot",
    name: "Ryan Selkis (d/acc) \ud83c\uddfa\ud83c\uddf8",
    id_str: 2193616844,
  },
  {
    screen_name: "Crypto_Maximal",
    name: "Crypto Maximalist | $SC whale :)",
    id_str: 1354116172678103042,
  },
  {
    screen_name: "FrankieIsLost",
    name: "frankie",
    id_str: 1399513177973350403,
  },
  {
    screen_name: "DogecoinNorway",
    name: "Doge Norway",
    id_str: 1376473924842758147,
  },
  { screen_name: "beeple", name: "beeple", id_str: 34097500 },
  {
    screen_name: "xSPECTAR",
    name: "xSPECTAR \u2122",
    id_str: 1495314558566686726,
  },
  {
    screen_name: "JoeConsorti",
    name: "Joe Consorti \u26a1\ufe0f",
    id_str: 3793308794,
  },
  { screen_name: "TylerDurden", name: "Tyler", id_str: 1469851247133945856 },
  {
    screen_name: "dogeofficialceo",
    name: "Sir Doge of the Coin \u2694\ufe0f",
    id_str: 1355945100216573952,
  },
  {
    screen_name: "rocketgirlNFT",
    name: "Rocketgirl",
    id_str: 1391776397245485060,
  },
  {
    screen_name: "SirRobArtII1",
    name: "\ud83d\udcabROB ART\ud83d\ude80CRYPTO COINS CREW",
    id_str: 1340704471656095748,
  },
  {
    screen_name: "CryptoEmpressX",
    name: "\u2102\ud835\udd63\ud835\udd6a\ud835\udd61\ud835\udd65\ud835\udd60\ud835\udd3c\ud835\udd5e\ud835\udd61\ud835\udd63\ud835\udd56\ud835\udd64\ud835\udd64\uea00\ud83e\udd0d",
    id_str: 1390586301565394944,
  },
  {
    screen_name: "CryptoEmpressX",
    name: "\u2102\ud835\udd63\ud835\udd6a\ud835\udd61\ud835\udd65\ud835\udd60\ud835\udd3c\ud835\udd5e\ud835\udd61\ud835\udd63\ud835\udd56\ud835\udd64\ud835\udd64\uea00\ud83e\udd0d",
    id_str: 1390586301565394944,
  },
  { screen_name: "bull_bnb", name: "Bull.TRX", id_str: 1297503202464718850 },
  {
    screen_name: "SatoshiFlipper",
    name: "Satoshi Flipper",
    id_str: 944961548375773184,
  },
  {
    screen_name: "YnahGives",
    name: "Ynah \ud83d\udcab",
    id_str: 1338696962627923968,
  },
  { screen_name: "Cephii1", name: "Cephii", id_str: 1355366118119108612 },
  { screen_name: "nmkr_io", name: "NMKR", id_str: 1384455565187891201 },
  { screen_name: "HTX_Global", name: "HTX", id_str: 914029581610377217 },
  { screen_name: "HTX_Global", name: "HTX", id_str: 914029581610377217 },
  {
    screen_name: "StonksReddit",
    name: "\ud83d\udd0bStonksy\ud83d\udd0b",
    id_str: 1354546688967454725,
  },
  { screen_name: "beaniemaxi", name: "Beanie", id_str: 1351139954525696005 },
  { screen_name: "CNBC", name: "CNBC", id_str: 20402945 },
  { screen_name: "beefyfinance", name: "Beefy", id_str: 1305519876921004033 },
  {
    screen_name: "amtvmedia",
    name: "Christopher Greene \ud83d\ude0e\ud83c\udfdd\ud83d\ude80\u26f5\ufe0f",
    id_str: 183036873,
  },
  { screen_name: "CryptoEights", name: "CryptoEights", id_str: 199776410 },
  {
    screen_name: "evan_van_ness",
    name: "Evan Van Ness \ud83e\uddc9",
    id_str: 189518354,
  },
  { screen_name: "MajesticDrama", name: "MAJESTIC", id_str: 43070676 },
  { screen_name: "D0tSama", name: "DotSama", id_str: 1387978610288570370 },
  {
    screen_name: "RippleXrpie",
    name: "JackTheRippler \u00a9\ufe0f",
    id_str: 930453075457921029,
  },
  {
    screen_name: "ScottLEOWarrior",
    name: "Scott Crypto Warrior #\ud83d\udc99\ud83d\udc99\ud83d\udc99",
    id_str: 2228960582,
  },
  { screen_name: "coingecko", name: "CoinGecko", id_str: 2412652615 },
  {
    screen_name: "CryptoSavingExp",
    name: "CryptoSavingExpert \u00ae",
    id_str: 1442884709432938507,
  },
  { screen_name: "JakeGagain", name: "JAKE", id_str: 1355014327988707329 },
  {
    screen_name: "JustnThePhotog",
    name: "Benis9000 \ud83c\udf46\ud83d\udcf8\ud83d\udd3a",
    id_str: 788097306247561216,
  },
  { screen_name: "hoskytoken", name: "HOSKY", id_str: 1453689655300329472 },
  { screen_name: "CryptoGodJohn", name: "Johnny", id_str: 210623431 },
  {
    screen_name: "DigPerspectives",
    name: "Digital Perspectives",
    id_str: 989178055393497091,
  },
  { screen_name: "Crypto_Ed_NL", name: "Ed_NL", id_str: 893538712113512449 },
  { screen_name: "cardano_whale", name: "whale", id_str: 1378241108401000450 },
  { screen_name: "CoinMarketCap", name: "CoinMarketCap", id_str: 2260491445 },
  { screen_name: "BNBSwap", name: "BNB Swap", id_str: 1300649161025400832 },
  {
    screen_name: "GiveAwayHost",
    name: "Hanabi Crypto \u2600\ufe0f\ud83c\udf4c",
    id_str: 1200355745600069635,
  },
  { screen_name: "CarpeNoctom", name: "#333kByJuly2025", id_str: 22328981 },
  {
    screen_name: "army_shiba",
    name: "$SHIB KNIGHT",
    id_str: 1383802058625609740,
  },
  { screen_name: "elonmusk", name: "Elon Musk", id_str: 44196397 },
  { screen_name: "BR1INFINITE", name: "BR1: INFINITE", id_str: 435077538 },
  {
    screen_name: "0xVersteckt",
    name: "versteckt",
    id_str: 1431818530601766917,
  },
  {
    screen_name: "SolanaLegend",
    name: "Solana Legend \u9f8d \ud83c\udf92",
    id_str: 1422676808911269893,
  },
  { screen_name: "RunemirQi", name: "Runemir", id_str: 14373276 },
  {
    screen_name: "SHIBpossessor",
    name: "SHIB POSSESSOR",
    id_str: 1507210532654559234,
  },
  {
    screen_name: "_Crypto_Barbie",
    name: "\ud835\udcd0\ud835\udcf6\ud835\udcee\ud835\udcf5\ud835\udcf2\ud835\udcee",
    id_str: 1492597243953790987,
  },
  {
    screen_name: "cryptodataspace",
    name: "Crypto Data Space",
    id_str: 775649239988862976,
  },
  {
    screen_name: "TomCrownCrypto",
    name: "Tom Crown",
    id_str: 980467188615319560,
  },
  { screen_name: "BleevesCrypto", name: "Bleeves Crypto", id_str: 26806748 },
  {
    screen_name: "DanteAtkins",
    name: "\ud83d\udd77Dante Atkins\ud83d\udd77",
    id_str: 14873767,
  },
  {
    screen_name: "MrStevenSteele",
    name: "Steven Steele",
    id_str: 1452801284243869697,
  },
  { screen_name: "legomakers1", name: "Legomakers", id_str: 221494506 },
  { screen_name: "CryptoStache", name: "'Stache", id_str: 927327985409040384 },
  { screen_name: "MariusCrypt0", name: "James Bull", id_str: 471940569 },
  { screen_name: "trader1sz", name: "TraderSZ", id_str: 2837666919 },
  {
    screen_name: "Leerzeit",
    name: "Mr. Huber\ud83d\udd25\ud83e\udd85\ud83d\udd25",
    id_str: 297012999,
  },
  {
    screen_name: "BigCheds",
    name: "Cheds (Trading Quotes)",
    id_str: 129935623,
  },
  { screen_name: "laurashin", name: "Laura Shin", id_str: 26377478 },
  {
    screen_name: "StellaSwap",
    name: "StellaSwap \u2604\ufe0f- Top DEX on Polkadot",
    id_str: 1470395935658291211,
  },
  {
    screen_name: "catandradec",
    name: "Catalina Andrade",
    id_str: 1388934498449039360,
  },
  {
    screen_name: "MarketSpotter",
    name: "Market Spotter",
    id_str: 1499684989889400837,
  },
  { screen_name: "cryptovoxels", name: "Voxels", id_str: 981706124582727680 },
  { screen_name: "wealth", name: "Bloomberg Wealth", id_str: 1668256296 },
  { screen_name: "DrNickA", name: "drnick", id_str: 194499127 },
  {
    screen_name: "worldofwomennft",
    name: "World of Women",
    id_str: 1414218489276125185,
  },
  {
    screen_name: "cega_fi",
    name: "cega \ud83e\udd5a",
    id_str: 1474053478557356039,
  },
  { screen_name: "BitKanOfficial", name: "BitKan", id_str: 2219922895 },
  {
    screen_name: "CosmosHOSS",
    name: "\u269b\ufe0fHOSS | Cosmoverse Dubai Oct 19-24 \ud83c\udde6\ud83c\uddea",
    id_str: 1416628772565143561,
  },
  {
    screen_name: "esatoshiclub",
    name: "Satoshi Club",
    id_str: 1274803199040462848,
  },
  {
    screen_name: "ZachRector7",
    name: "Zach Rector",
    id_str: 1487123954670059521,
  },
  {
    screen_name: "Alejandro_XBT",
    name: "Alejandro\u20bfTC",
    id_str: 4001513242,
  },
  {
    screen_name: "SolanaPlays",
    name: "Solana Plays",
    id_str: 1454109164125442050,
  },
  {
    screen_name: "topherAlba26",
    name: "\u00d0oge~Panda",
    id_str: 1354607432954236936,
  },
  { screen_name: "metaversejoji", name: "joji", id_str: 1392124029914566666 },
  {
    screen_name: "iWantCoinNews",
    name: "iWantCoinNews\ud83d\udcc8",
    id_str: 1396825095042183173,
  },
  {
    screen_name: "Cosmos_Tic",
    name: "Crypto EVERYTHING \u269b\ufe0f",
    id_str: 1495776062638329858,
  },
  { screen_name: "RvCrypto", name: "RVCrypto", id_str: 1155762475582787584 },
  {
    screen_name: "ShibaInformer",
    name: "$SHIB INFORMER",
    id_str: 1473694732916334601,
  },
  {
    screen_name: "EthereumThaila1",
    name: "Mahachai \ud83e\udd87\ud83d\udd0a",
    id_str: 1414864127911022592,
  },
  {
    screen_name: "EOSNetworkFDN",
    name: "EOS Network Foundation",
    id_str: 1074128622083104768,
  },
  {
    screen_name: "cryptonator1337",
    name: "CR1337",
    id_str: 861889353869557762,
  },
  {
    screen_name: "ourcryptotalk",
    name: "Our Crypto Talk",
    id_str: 1457232952996237317,
  },
  {
    screen_name: "Crypto_Jargon",
    name: "Neel (Crypto Jargon)",
    id_str: 1373900425540165635,
  },
  { screen_name: "alexoncrypto", name: "Alexander Lorenzo", id_str: 901706048 },
  {
    screen_name: "TheCryptoLemon",
    name: "Lemon \ud83c\udf4b",
    id_str: 1361275592939884545,
  },
  { screen_name: "DNVR_Avalanche", name: "DNVR Avalanche", id_str: 3180838268 },
  { screen_name: "AJ_cryptoo", name: "AJ Crypto", id_str: 1438968356099477516 },
  {
    screen_name: "TheCheemsToken",
    name: "Cheems",
    id_str: 1385664505947082755,
  },
  {
    screen_name: "CryptoAnup",
    name: "Anup Dhungana",
    id_str: 1226470578137243648,
  },
  {
    screen_name: "CryptoCloaks",
    name: "CryptoCloaks\u2122",
    id_str: 968880161675448320,
  },
  {
    screen_name: "ARB_Universe",
    name: "Arbitrum Universe",
    id_str: 1664941423647391744,
  },
  {
    screen_name: "nsquaredvalue",
    name: "Timothy Peterson",
    id_str: 983820686576713728,
  },
  {
    screen_name: "crypto__kermit",
    name: "Kermit \ud83d\udc38",
    id_str: 1403761673060618244,
  },
  {
    screen_name: "Engineercryptoo",
    name: "Crypto Engineer",
    id_str: 1568854472163758080,
  },
  {
    screen_name: "Trader_Jibon",
    name: "Trader_J",
    id_str: 1409903853743480832,
  },
  { screen_name: "Gayu_BTC", name: "GAYU_BTC", id_str: 964430048160698369 },
  { screen_name: "CryptoErwinNL", name: "Erwin", id_str: 1239987918 },
  {
    screen_name: "sircryptotips",
    name: "Toby Cunningham",
    id_str: 1291321946060267526,
  },
  {
    screen_name: "CryptoPlayersIn",
    name: "Crypto Players",
    id_str: 970163320941551617,
  },
  {
    screen_name: "LoveIsBitcoin21",
    name: "Love Is Bitcoin \ud83e\udde1\ud83d\udc8a",
    id_str: 1395196557922869252,
  },
  {
    screen_name: "Crypto_Texan",
    name: "Crypto Texan | Aggregated",
    id_str: 1338528886234247168,
  },
  { screen_name: "Neon_EVM", name: "Neon", id_str: 1658444200206008322 },
  { screen_name: "BSVBlockchain", name: "BSV Blockchain", id_str: 31803295 },
  { screen_name: "3PeaksTrading", name: "Jason", id_str: 63061242 },
  {
    screen_name: "VellaCryptoX",
    name: "VeLLa Crypto",
    id_str: 1477495069628252163,
  },
  { screen_name: "CryptoLalit151", name: "Crypto Lalit", id_str: 4785073223 },
  {
    screen_name: "CryptoPunjab09",
    name: "MECHrypto Engineer",
    id_str: 1189725019070287872,
  },
  {
    screen_name: "DogecoinFear",
    name: "Doge Fear and Greed Index",
    id_str: 1412978338650484736,
  },
  { screen_name: "SOL_mfer", name: "Browski", id_str: 100325195 },
  {
    screen_name: "SolanaFloor",
    name: "SolanaFloor",
    id_str: 1435897632581357572,
  },
  {
    screen_name: "MultiversXToday",
    name: "MultiversX Today",
    id_str: 1414935699254566914,
  },
  {
    screen_name: "MyfirstBitcoin_",
    name: "Mi Primer Bitcoin",
    id_str: 1441478918846955521,
  },
  {
    screen_name: "Daily_Pickens",
    name: "\u26be\ufe0f\ud83d\udd25\ud83c\udfc0",
    id_str: 1248756592314478593,
  },
  {
    screen_name: "StarPlatinumSOL",
    name: "StarPlatinum",
    id_str: 1500166468851412998,
  },
  {
    screen_name: "CryptoBullAtom",
    name: "Crypto aTOm\ud83d\udc7d",
    id_str: 1459882995817537536,
  },
  { screen_name: "CryptoYapper", name: "Crypto Yapper", id_str: 2613826850 },
  {
    screen_name: "Cryptonic_Bull",
    name: "Cryptonic Bull \ud83e\udde0",
    id_str: 1575534595822555137,
  },
  { screen_name: "MDBitcoin", name: "MDB", id_str: 992650901470044160 },
  {
    screen_name: "PapayonTrading",
    name: "No Flo Papayon",
    id_str: 1287017943084171264,
  },
  { screen_name: "bobocoineth", name: "Bobo", id_str: 1632196491392479232 },
  {
    screen_name: "SweeperSolana",
    name: "SolanaSweeper",
    id_str: 1519109749266165760,
  },
  {
    screen_name: "cryptondo",
    name: "Crypto Ndo! \ud83c\uddee\ud83c\udde9",
    id_str: 1471393708478058505,
  },
  {
    screen_name: "OracleAltcoin",
    name: "The \u20b3ltcoin Oracle",
    id_str: 1430637336308686850,
  },
  {
    screen_name: "BitcoinCashTV",
    name: "Bitcoin Cash TV",
    id_str: 1562845170109796354,
  },
  {
    screen_name: "MindCrypto_",
    name: "Mind Crypto \ud83c\udf65",
    id_str: 216525605,
  },
  {
    screen_name: "ICP_insider",
    name: "ICP Insider",
    id_str: 1430809450244317190,
  },
  {
    screen_name: "ASX__Trader",
    name: "ASX Trader (CFTe) - stocks, commodities & crypto",
    id_str: 1482336369347547139,
  },
  { screen_name: "DaxxTrader", name: "Daxx", id_str: 1040459029183574016 },
  {
    screen_name: "RaritySniperNFT",
    name: "Rarity Sniper",
    id_str: 1416070452372459523,
  },
  {
    screen_name: "CoinChapter",
    name: "CoinChapter | News",
    id_str: 1042088652837453825,
  },
  {
    screen_name: "NEOZEN_NFT",
    name: "NEOZEN (\u30cd\u30aa\u30bc\u30f3)",
    id_str: 1553155380846493696,
  },
  {
    screen_name: "AusCryptoCon",
    name: "Australian Crypto Convention",
    id_str: 1491615931818455040,
  },
  {
    screen_name: "fxnction",
    name: "f\ud835\udd4fnction",
    id_str: 1414330132022452231,
  },
  {
    screen_name: "TheUnderDoge3",
    name: "The Under Doge",
    id_str: 1392904146731347972,
  },
  { screen_name: "EdnStuff", name: "Ed n' Stuff", id_str: 1517580841 },
  {
    screen_name: "AdrianoFeria",
    name: "AdrianoFeria.eth \ud83e\udd87\ud83d\udd0a \ud83d\udee1\ufe0f",
    id_str: 757446632,
  },
  {
    screen_name: "Miller_19k",
    name: "Joshua Miller",
    id_str: 986726837660205056,
  },
  {
    screen_name: "CoinbaseAssets",
    name: "Coinbase Assets \ud83d\udee1\ufe0f",
    id_str: 1422537905885442055,
  },
  { screen_name: "IOV_OWL", name: "King Solomon", id_str: 4637858597 },
  {
    screen_name: "X__Anderson",
    name: "Anders \ud83c\udfc1\ud83c\udf0f",
    id_str: 943060751668805633,
  },
  {
    screen_name: "davidgokhshtein",
    name: "David Gokhshtein",
    id_str: 170049408,
  },
  {
    screen_name: "CoinUpOfficials",
    name: "CoinUp.io",
    id_str: 1397076543659290633,
  },
  {
    screen_name: "SunriseTrader",
    name: "Sunrise Trader",
    id_str: 787439438964068352,
  },
  {
    screen_name: "WhaleCoinTalk",
    name: "Whale Coin Talk",
    id_str: 950440075246129152,
  },
  { screen_name: "DanSpuller", name: "Dan Spuller", id_str: 2634274458 },
  { screen_name: "NurBrks", name: "NurBrks", id_str: 1450420037584576521 },
  {
    screen_name: "Fame21Moore",
    name: "crypto.Darren\ud83e\ude9d \ud83c\udff4\u200d\u2620\ufe0f",
    id_str: 497174361,
  },
  {
    screen_name: "SimpleSwap_io",
    name: "SimpleSwap",
    id_str: 984114517226545152,
  },
  {
    screen_name: "natbrunell",
    name: "Natalie Brunell \u26a1\ufe0f",
    id_str: 543483223,
  },
  {
    screen_name: "CaptainCrypto33",
    name: "CaptainCrypto",
    id_str: 904112673826250754,
  },
  { screen_name: "WOO_ecosystem", name: "WOO", id_str: 1641046720946782209 },
  {
    screen_name: "Astrones2",
    name: "ProfessorAstrones",
    id_str: 1191441760385470467,
  },
  {
    screen_name: "TheBlockBull",
    name: "\ud83d\udcc8BLOCK BULL\ud83d\udcc8",
    id_str: 1974772254,
  },
  {
    screen_name: "JW100x",
    name: "JW \u2694\ufe0f\ud83e\udd77\ud83c\udfff",
    id_str: 1462983512475127810,
  },
  {
    screen_name: "Bullishhh",
    name: "Bullish\ud83d\udcc8",
    id_str: 1393631010609192960,
  },
  {
    screen_name: "nansen_ai",
    name: "Nansen \ud83e\udded",
    id_str: 1260559704335568896,
  },
  { screen_name: "VALELORDX", name: "KINGVALEX", id_str: 1522585288412905475 },
  {
    screen_name: "santiagoroel",
    name: "Santiago R Santos | #9159",
    id_str: 737132550,
  },
  { screen_name: "CirrusNFT", name: "Cirrus", id_str: 834123445856145408 },
  { screen_name: "waxnear", name: "Wax", id_str: 1471856279626858510 },
  {
    screen_name: "_CryptoManny",
    name: "MrFinance \u2b55\ufe0f",
    id_str: 1358510490939367424,
  },
  { screen_name: "StaniKulechov", name: "Stani", id_str: 952921795316912133 },
  { screen_name: "ElonMuskPDA", name: "Elon Musk (Parody)", id_str: 628253959 },
  { screen_name: "WOLF_Financial", name: "WOLF", id_str: 1306359926164516864 },
  { screen_name: "honey_xbt", name: "Honey", id_str: 1304288255253254144 },
  { screen_name: "ali_charts", name: "Ali", id_str: 997932926649200640 },
  {
    screen_name: "tonfish_tg",
    name: "Ton Fish - #FREEDUROV",
    id_str: 1717872386299842560,
  },
  {
    screen_name: "RAFAELA_RIGO_",
    name: "\u2b50R\u15e9\u15b4\u15e9E\u14aa\u15e9 \ud835\udde5\ud835\udddc\ud835\uddda\ud835\udde2 \u2b50",
    id_str: 3291601361,
  },
  {
    screen_name: "StonksBatman",
    name: "The Wild Bat - 1-28-2021",
    id_str: 1385339715839275015,
  },
  {
    screen_name: "tradinglord",
    name: "tradinglord \ud83c\udf6c",
    id_str: 841016244270706688,
  },
  {
    screen_name: "knowerofmarkets",
    name: "knower",
    id_str: 1359201532810571779,
  },
  { screen_name: "fmong", name: "frank\ud83c\udf88", id_str: 30811154 },
  { screen_name: "damxton", name: "DamX", id_str: 1202243746831704064 },
  { screen_name: "Gotzeuus", name: "Gotzeuus", id_str: 962547846 },
  { screen_name: "warobusiness", name: "Waro", id_str: 355560008 },
  {
    screen_name: "KirkLubimov",
    name: "Kirk Lubimov",
    id_str: 761934870432010241,
  },
  {
    screen_name: "CamPauldingYT",
    name: "C\u0245M",
    id_str: 1468546250333372418,
  },
  { screen_name: "JayXRP2", name: "JayXRP", id_str: 1397572896227213314 },
  {
    screen_name: "Kingpincrypto12",
    name: "Kingpin Crypto",
    id_str: 1419643611856080896,
  },
  {
    screen_name: "cryptobits72",
    name: "CryptoBits",
    id_str: 949685739935158272,
  },
  {
    screen_name: "rbswingtrader",
    name: "RBswingtrader",
    id_str: 1381048937352548352,
  },
  {
    screen_name: "cardanolands",
    name: "CardanoLands | KINGDOM IS LIVE!",
    id_str: 1490213941833875458,
  },
  {
    screen_name: "Coincuofficial",
    name: "Coincu News",
    id_str: 1421748481719373829,
  },
  { screen_name: "MaxBecauseBTC", name: "Max", id_str: 1319145334849810432 },
  { screen_name: "SU__Crypto", name: "S.U", id_str: 1508695288936116226 },
  { screen_name: "JmainCrypto", name: "Jmain", id_str: 1505744730847690754 },
  {
    screen_name: "music_ripple",
    name: "RippleMusic",
    id_str: 1453571302707499020,
  },
  { screen_name: "DashV_", name: "Dash Valentine", id_str: 2660571252 },
  {
    screen_name: "Altcoin_Hero_",
    name: "Altcoin Hero",
    id_str: 1345361532591476748,
  },
  {
    screen_name: "Crypto_peet",
    name: "Cryptopeet",
    id_str: 1524437076850135041,
  },
  {
    screen_name: "CryptoCurrent77",
    name: "Crypto Current (CryptoRick)",
    id_str: 1523299290269237249,
  },
  {
    screen_name: "cryptowasta",
    name: "CRYPTO WASTA",
    id_str: 1127705375808139269,
  },
  {
    screen_name: "rogermoonCrypto",
    name: "R O G E R \u2022 M O O N",
    id_str: 1425865319717949444,
  },
  { screen_name: "4cryptoclearly", name: "CC", id_str: 1549638180827271169 },
  { screen_name: "NeonSlice", name: "Neon Slice", id_str: 107198226 },
  {
    screen_name: "C_Traderz",
    name: "ConstructionTrader.eth",
    id_str: 912050677127565312,
  },
  {
    screen_name: "DashBhagu",
    name: "Bhagyalaxmi Dash",
    id_str: 1530211424030425089,
  },
  {
    screen_name: "CryptoDona7",
    name: "Dona.TRX\ud83e\udd77",
    id_str: 1142897938475212800,
  },
  {
    screen_name: "Toni_Bitcoin",
    name: "Tony \u20bfitcoin\u26a1",
    id_str: 1119510135334944769,
  },
  {
    screen_name: "PappyVanCrypto9",
    name: "PappyVanCrypto\ud83c\udf35",
    id_str: 1641631166628233218,
  },
  {
    screen_name: "AktienTrader1",
    name: "Savings-Plan-Trader",
    id_str: 985090779965255681,
  },
  { screen_name: "LTCTheRightCoin", name: "Randy", id_str: 843075727348129796 },
  {
    screen_name: "Crypto_By_Uzair",
    name: "Muhammad Uzair",
    id_str: 1267630116718415872,
  },
  { screen_name: "TheCryptoNexus", name: "Nexus", id_str: 1392152416854765571 },
  {
    screen_name: "MarkCrypto8",
    name: "Crypto Mark \u26a1\ufe0f",
    id_str: 1193586558101786631,
  },
  {
    screen_name: "Quickcryptotip",
    name: "90sCryptoKid",
    id_str: 1304151814045081601,
  },
  {
    screen_name: "TheDreamCrypto",
    name: "TheDream\u269c\ufe0f",
    id_str: 1373732227121565699,
  },
  {
    screen_name: "Queen1Crypto",
    name: "CryptoQueen",
    id_str: 1432159414123905025,
  },
  {
    screen_name: "AlexOttaBTC",
    name: "Alessandro Ottaviani",
    id_str: 1404126890131132417,
  },
  {
    screen_name: "ICPHUBS",
    name: "ICP HUBS NETWORK",
    id_str: 1621556084807970816,
  },
  {
    screen_name: "XRP_DigiGold",
    name: "\ud83d\udc99 X_R_P \ud83e\udd8b V_R_A \ud83d\udc99",
    id_str: 1471338116728299523,
  },
  {
    screen_name: "HouseOfCrypto3",
    name: "The House Of Crypto",
    id_str: 1345932331509567496,
  },
  {
    screen_name: "FLOWInvesting",
    name: 'FLOW "The Big Short" Trading \ud83d\udc3b\ud83d\udcc9',
    id_str: 1336007811742969856,
  },
  {
    screen_name: "FilecoinTLDR",
    name: "Filecoin TL;DR",
    id_str: 1585290039902310405,
  },
  {
    screen_name: "CryptoChat7",
    name: "Crypto Seven",
    id_str: 1298004306751152130,
  },
  {
    screen_name: "cNFTking",
    name: "Showtime \ud83c\udfb2",
    id_str: 1454827412781473798,
  },
  {
    screen_name: "RealAllinCrypto",
    name: "ALLINCRYPTO",
    id_str: 1427666259978637319,
  },
  {
    screen_name: "BaseballFlowsHQ",
    name: "Baseball Flows\u2122",
    id_str: 1495510398572789762,
  },
  {
    screen_name: "LovelaceClubNFT",
    name: "Lovelace Club",
    id_str: 1550809844533592064,
  },
  {
    screen_name: "crypto_ideology",
    name: "Crypto Ideology \u270d\ud83c\udffb",
    id_str: 1288026353934036994,
  },
  {
    screen_name: "Unocoin",
    name: "Unocoin: India\u2019s Bitcoin & Crypto Exchange",
    id_str: 379305414,
  },
  {
    screen_name: "CheekyCrypto",
    name: "Cheeky Crypto",
    id_str: 1303395304767709184,
  },
  {
    screen_name: "24hrscrypto1",
    name: "\ud835\udff8\ud835\udffa\ud835\ude77\ud835\ude81\ud835\ude82\ud835\ude72\ud835\ude81\ud835\ude88\ud835\ude7f\ud835\ude83\ud835\ude7e",
    id_str: 1371986821538836482,
  },
  { screen_name: "gameofbitcoin", name: "Gary", id_str: 915140865286459392 },
  {
    screen_name: "CryptoShadowOff",
    name: "CryptoShadow \ud83d\udc51",
    id_str: 930781655538679808,
  },
  {
    screen_name: "CryptoPrince22",
    name: "Crypto Prince",
    id_str: 1592249308950167554,
  },
  {
    screen_name: "The_NewsCrypto",
    name: "TheNewsCrypto",
    id_str: 1301799016573923328,
  },
  {
    screen_name: "CryptoPlays_",
    name: "\ud83c\udd86\ud83c\udd74\ud83c\udd70\ud83c\udd7b\ud83c\udd83\ud83c\udd77 $",
    id_str: 1456350728910479364,
  },
  {
    screen_name: "THEFLASHTRADING",
    name: "FLASH",
    id_str: 1022733770116526080,
  },
  { screen_name: "DonCryptoDraper", name: "doncrypto", id_str: 27619548 },
  { screen_name: "CryptoYoddha", name: "Yoddha", id_str: 1049973471445508096 },
  {
    screen_name: "TheBTCTherapist",
    name: "The \u20bfitcoin Therapist",
    id_str: 1415064893628297217,
  },
  { screen_name: "CryptoRisHad", name: "RisHad", id_str: 1114538838079102976 },
  {
    screen_name: "CryptoFaibik",
    name: "Captain Faibik \ud83d\udc3a",
    id_str: 1052756564459905025,
  },
  { screen_name: "OpCryptoUK", name: "Operation Crypto", id_str: 305751928 },
  {
    screen_name: "TRONSCAN_ORG",
    name: "TRONSCAN",
    id_str: 1068431255690076161,
  },
  {
    screen_name: "CryptoSalvotore",
    name: "Salvotore Ferregano",
    id_str: 195304053,
  },
  {
    screen_name: "DyorNetCrypto",
    name: "dyor.net",
    id_str: 948551073387642880,
  },
  { screen_name: "JohnnyZcash", name: "JZ", id_str: 896056375 },
  {
    screen_name: "sandeepnailwal",
    name: "Sandeep | AggLayer \ud83d\udc7f",
    id_str: 100798845,
  },
  { screen_name: "chamath", name: "Chamath Palihapitiya", id_str: 3291691 },
  { screen_name: "0xProject", name: "0x", id_str: 826699259441328128 },
  { screen_name: "CryptoGemsHub", name: "Crypto Gems", id_str: 432927724 },
  { screen_name: "cryptowoetoe", name: "Woetoe", id_str: 1319400687025983490 },
  {
    screen_name: "credefi_finance",
    name: "Credefi",
    id_str: 1379047737782628352,
  },
  {
    screen_name: "artblocks_io",
    name: "Art Blocks",
    id_str: 1317828734913753090,
  },
  {
    screen_name: "ardizor",
    name: "ardizor \ud83e\uddd9\u200d\u2642\ufe0f",
    id_str: 1385952907233841160,
  },
  {
    screen_name: "EFDevcon",
    name: "Deva the Devcon Unicorn",
    id_str: 1013526003015184385,
  },
  { screen_name: "dimabuterin", name: "dima.eth", id_str: 14628341 },
  { screen_name: "steve_campbell", name: "stevec.base.eth", id_str: 21104103 },
  { screen_name: "Consensys", name: "Consensys", id_str: 3129477561 },
  { screen_name: "seema_amble", name: "Seema Amble", id_str: 324403141 },
  { screen_name: "DavidSacks", name: "David Sacks", id_str: 1137701 },
  { screen_name: "Jason", name: "@jason", id_str: 3840 },
  {
    screen_name: "sriramk",
    name: "Sriram Krishnan - sriramk.eth",
    id_str: 149763,
  },
  { screen_name: "pankaj", name: "Pankaj Gupta", id_str: 17112843 },
  {
    screen_name: "davidu",
    name: "David Ulevitch \ud83c\uddfa\ud83c\uddf8",
    id_str: 1102,
  },
  { screen_name: "stephsmithio", name: "Steph Smith", id_str: 626691391 },
  {
    screen_name: "GreenDragonNEAR",
    name: "Green Dragon Studio",
    id_str: 1278772248405594113,
  },
  {
    screen_name: "VersanAljarrah",
    name: "Black Swan Capitalist",
    id_str: 2838457297,
  },
  {
    screen_name: "xendfinance",
    name: "Xend Finance ($RWA)",
    id_str: 1305119080656113664,
  },
  {
    screen_name: "spacexcoin_spxc",
    name: "SpaceXCoin Official\ud83c\uddfa\ud83c\uddf2",
    id_str: 1520843672090259458,
  },
  { screen_name: "Jesseeckel", name: "Jesse Eckel", id_str: 31117486 },
  {
    screen_name: "AmEmediong",
    name: "Sweet Senator Em\u2764\ufe0f",
    id_str: 1056686133701214208,
  },
  {
    screen_name: "AssureDefi",
    name: "Assure DeFi - #1 Crypto KYC & Audits",
    id_str: 1370160171822018560,
  },
  {
    screen_name: "BrotherKDG",
    name: "KG | Crypto Expert",
    id_str: 1397813001722400779,
  },
  { screen_name: "DefendDark", name: "Dark Defender", id_str: 1196224574 },
  {
    screen_name: "peachbitcoin",
    name: "Peach Bitcoin \ud83c\udf51",
    id_str: 1418311957875200000,
  },
  {
    screen_name: "ItsBitcoinWorld",
    name: "BitcoinWorld Media",
    id_str: 1271475157970956290,
  },
  { screen_name: "CryptoZaha", name: "Zaha", id_str: 1485928762491510788 },
  {
    screen_name: "TitanSmartTrade",
    name: "Titan Trading Platform",
    id_str: 1640259749538308096,
  },
  { screen_name: "AndreasAtETH", name: "Andreas Wallraff", id_str: 59582985 },
  {
    screen_name: "usdcoinprinter",
    name: "Stablecoin Printer",
    id_str: 1003230945862877186,
  },
  { screen_name: "ViaBTC", name: "ViaBTC", id_str: 763942734130393088 },
  {
    screen_name: "BitcoinSuisseAG",
    name: "Bitcoin Suisse",
    id_str: 2394402734,
  },
  {
    screen_name: "CryptoGhostDog",
    name: "CryptoGhostDog \ud83e\udd88\u20b3 \ud80c\udebd\ud83d\udc7b\u26d3\ufe0f#MELDalorian\ud83d\udd34\u2716\ufe0f\ud83e\udd8d",
    id_str: 1369065833591357440,
  },
  {
    screen_name: "mk_trader",
    name: "MK \u25ab\ufe0f",
    id_str: 1328700394629959683,
  },
  {
    screen_name: "NattiCrypto",
    name: "Natti | Talisman \ud83e\udeac",
    id_str: 750402654803820545,
  },
  { screen_name: "VinnyLingham", name: "Vinny Lingham", id_str: 1854401 },
  {
    screen_name: "CoachCrypto22",
    name: "Coac\u0127_$DOG",
    id_str: 1572357907060256771,
  },
  {
    screen_name: "NFTxCrypto_God",
    name: "NFTxCrypto God",
    id_str: 1250963269503852550,
  },
  {
    screen_name: "CryptoAi777",
    name: "Crypto.AI",
    id_str: 1262573760957353986,
  },
  {
    screen_name: "CryptoChrist10",
    name: "Crypto Christ",
    id_str: 1392007344465367040,
  },
  { screen_name: "Kaizer_Crypto15", name: "Kaizer Chief", id_str: 417825298 },
  { screen_name: "FrysCrypto", name: "Fry Networks", id_str: 547851276 },
  {
    screen_name: "ProofOfCardano",
    name: "Proof of Cardano",
    id_str: 1525544229967220738,
  },
  {
    screen_name: "aCryptoConsult",
    name: "A Crypto Consultant",
    id_str: 914208038696894464,
  },
  { screen_name: "CoinCowArt", name: "CoinCow", id_str: 1424880504185135114 },
  {
    screen_name: "daCoinKing",
    name: "CRYPTO KEN aka the Coin King \ud83d\udc51",
    id_str: 1260314985550528514,
  },
  {
    screen_name: "quantbeckman",
    name: "Quant Beckman",
    id_str: 1146342841272197120,
  },
  {
    screen_name: "StellarDataFeed",
    name: "Stellar XLM Daily Data",
    id_str: 905859108808437760,
  },
  {
    screen_name: "steveocrypto_",
    name: "Cryp\u24e3oS\u24e3eveO \ud83d\udc9c",
    id_str: 581325683,
  },
  {
    screen_name: "monerotopia",
    name: "MoneroTopia",
    id_str: 1351237121835753476,
  },
  {
    screen_name: "DailyGamba",
    name: "DAILY GAMBLING",
    id_str: 1647022772571238400,
  },
  {
    screen_name: "stellargate_io",
    name: "Stellar Gate \u2b50\ufe0f | ISS",
    id_str: 1374918098294345730,
  },
  {
    screen_name: "FitzoCrypto",
    name: "Fitzo Crypto (CRYPTO BULL UNTIL November 2025)",
    id_str: 1382651117411168256,
  },
  { screen_name: "CryptoReda", name: "REDA", id_str: 1430259272865222662 },
  {
    screen_name: "CryptoRosyy",
    name: "\ud83c\udfaf R\u0333.O\u0333.S\u0333.Y\u0333 \ud835\udc02rypto",
    id_str: 1240635471572520962,
  },
  { screen_name: "Darth___ADA", name: "Darth ADA", id_str: 2974979976 },
  {
    screen_name: "AstronautBTC",
    name: "Astronaut",
    id_str: 959399702411259904,
  },
  {
    screen_name: "HBARnanza",
    name: "Nanza.hangry",
    id_str: 1523351373185703936,
  },
  {
    screen_name: "okkucrypto",
    name: "\u5104\u3063\u304f\u3093\u266c",
    id_str: 1321710642777092096,
  },
  {
    screen_name: "unclenutcrypto",
    name: "Uncle Nut \ud83c\udff4\u200d\u2620\ufe0f",
    id_str: 49559547,
  },
  {
    screen_name: "MissBitcoin_sv",
    name: "Alejandra Guajardo | Miss Bitcoin",
    id_str: 1479506754572541953,
  },
  {
    screen_name: "StacksOrg",
    name: "Stacks Foundation \ud83d\udfe7",
    id_str: 1247548810647216130,
  },
  {
    screen_name: "trading__horse",
    name: "TheHorse",
    id_str: 1230145716753842176,
  },
  {
    screen_name: "JimmyCrypto888",
    name: "JimmyCrypto888.eth/AvaRaeArt \u2764\ufe0f",
    id_str: 1417864427391655937,
  },
  {
    screen_name: "coinbeastmedia",
    name: "CoinBeast",
    id_str: 1321582212102250496,
  },
  { screen_name: "SelfCryptoIO", name: "SELF", id_str: 1539734278917046276 },
  {
    screen_name: "LaPetiteADA",
    name: "LaPetiteADA\ud83e\udd8b\ud83c\udf44",
    id_str: 1549327583992549377,
  },
  {
    screen_name: "Sui_allday",
    name: "Sui allday",
    id_str: 1604157410171441152,
  },
  {
    screen_name: "corgi_studio",
    name: "Corgi Studio",
    id_str: 1477366583378923520,
  },
  {
    screen_name: "MultiversX_NFTs",
    name: "MultiversXNFTs",
    id_str: 1513481655591313408,
  },
  {
    screen_name: "Fantom_Univers",
    name: "\ud835\udc05\ud835\udc00\ud835\udc0d\ud835\udc13\ud835\udc0e\ud835\udc0c \ud835\udc14\ud835\udc0d\ud835\udc08\ud835\udc15\ud835\udc04\ud835\udc11\ud835\udc12\ud835\udc04",
    id_str: 1526114197297590274,
  },
  {
    screen_name: "TethereumExc",
    name: "Tethereum",
    id_str: 1568432464338747392,
  },
  {
    screen_name: "NFTDoctor33",
    name: "NFT Doctor",
    id_str: 1090595708691009536,
  },
  { screen_name: "RAF_BTC", name: "RAF", id_str: 1343188621592064001 },
  {
    screen_name: "XRPHealthcare",
    name: "XRP Healthcare",
    id_str: 1568605031489835008,
  },
  {
    screen_name: "CryptoShilllz",
    name: "CryptoShillz",
    id_str: 1189305955810459649,
  },
  { screen_name: "coinspotau", name: "CoinSpot", id_str: 2287904017 },
  {
    screen_name: "_CryptoFanz",
    name: "Crypto Fanz \ud83d\udc9c\ud83d\udc8e",
    id_str: 1393927746070401031,
  },
  {
    screen_name: "TradersMastery",
    name: "Jordan Lindsey",
    id_str: 931642527110742016,
  },
  {
    screen_name: "carlabitcoin",
    name: "CARLA\u26a1\ufe0f",
    id_str: 1395095108421292035,
  },
  { screen_name: "MsCryptomom1", name: "Cryptomom", id_str: 3198427140 },
  { screen_name: "rexnftcrypto", name: "rex", id_str: 1224744948244844551 },
  {
    screen_name: "CastilloTrading",
    name: "Castillo Trading",
    id_str: 769721685645795329,
  },
  {
    screen_name: "el_crypto_prof",
    name: "\ud835\udd44\ud835\udd60\ud835\udd66\ud835\udd64\ud835\udd65\ud835\udd52\ud835\udd54\u24d7\ud835\udd56 \ud83e\uddf2",
    id_str: 938951174874632192,
  },
  {
    screen_name: "HBAR_foundation",
    name: "HBAR Foundation",
    id_str: 1438104846033461249,
  },
  {
    screen_name: "CryptoHornHairs",
    name: "HornHairs \ud83c\udf0a",
    id_str: 857813126326218752,
  },
  {
    screen_name: "nft_cryptogang",
    name: "Crypto Gang NFT",
    id_str: 1425856129767182342,
  },
  { screen_name: "hoss_crypto", name: "Hoss", id_str: 5387882 },
  {
    screen_name: "IamCryptoWolf",
    name: "Wolf \ud83d\udc3a",
    id_str: 898155965899526145,
  },
  {
    screen_name: "Uniswap",
    name: "Uniswap Labs \ud83e\udd84",
    id_str: 984188226826010624,
  },
  {
    screen_name: "CryptoPointHi",
    name: "Crypto Point Hindi \ud83c\uddee\ud83c\uddf3\ud83d\udc8e\ud83d\udcab",
    id_str: 971004561581199362,
  },
  {
    screen_name: "ecoinometrics",
    name: "ecoinometrics",
    id_str: 1178503185545084929,
  },
  {
    screen_name: "SuiPadxyz",
    name: "SuiPad \ud83c\udf0a - Launchpad on Sui",
    id_str: 1557573810827628544,
  },
  { screen_name: "DappRadar", name: "DappRadar", id_str: 962293079012241408 },
  { screen_name: "wormhole", name: "Wormhole", id_str: 1417845872436547587 },
  {
    screen_name: "MacnBTC",
    name: "Mac \ud83d\udc3a",
    id_str: 996294856078188544,
  },
  {
    screen_name: "GMX_IO",
    name: "GMX \ud83e\uded0",
    id_str: 1363739197018828802,
  },
  {
    screen_name: "nufiwallet",
    name: "NuFi Web3 Wallet",
    id_str: 1433765957407686662,
  },
  {
    screen_name: "Justin_Bons",
    name: "Justin Bons",
    id_str: 958495164686917632,
  },
  {
    screen_name: "blockworksres",
    name: "Blockworks Research",
    id_str: 1522646662895783941,
  },
  {
    screen_name: "NOW_Wallet",
    name: "NOW Wallet",
    id_str: 1459274588408406019,
  },
  {
    screen_name: "Coin98Analytics",
    name: "Coin98 Analytics",
    id_str: 1282956858664185856,
  },
  {
    screen_name: "NFAdotcrypto",
    name: "NotFinancialAdvice.Crypto",
    id_str: 1499789273461108737,
  },
  {
    screen_name: "WhiteWhaleDefi",
    name: "White Whale \ud83d\udc33",
    id_str: 1416101532282638348,
  },
  { screen_name: "DuneAnalytics", name: "Dune", id_str: 1039449274344529920 },
  {
    screen_name: "VenusProtocol",
    name: "Venus Protocol",
    id_str: 1304609819504984067,
  },
  {
    screen_name: "orbital_command",
    name: "Orbital Command \ud83c\udf0e",
    id_str: 1379580383562326018,
  },
  {
    screen_name: "Delphi_Digital",
    name: "Delphi Digital",
    id_str: 1037045241084342272,
  },
  { screen_name: "TimBeiko", name: "timbeiko.eth", id_str: 80722677 },
  { screen_name: "emurgo_io", name: "EMURGO", id_str: 868997091871215616 },
  {
    screen_name: "TheGemHunters",
    name: "The Gem Hunters.\u2122 \ud83d\udc8e",
    id_str: 1052829861138509824,
  },
  { screen_name: "ChainLinkGod", name: "Zach Rynes | CLG", id_str: 1035721495 },
  {
    screen_name: "Web3Games_Daily",
    name: "Web3 Games Daily",
    id_str: 1300960681148264455,
  },
  { screen_name: "sonalibasak", name: "Sonali Basak", id_str: 49422080 },
  {
    screen_name: "CoreumOfficial",
    name: "Coreum",
    id_str: 1466301151947620357,
  },
  { screen_name: "ctoLarsson", name: "CTO Larsson", id_str: 5022271 },
  {
    screen_name: "DigitalChamber",
    name: "The Digital Chamber",
    id_str: 2756444533,
  },
  {
    screen_name: "CryptoHayes",
    name: "Arthur Hayes",
    id_str: 983993370048630785,
  },
  {
    screen_name: "cooItimes",
    name: "cooltimes\ud83e\uddca",
    id_str: 381127643,
  },
  {
    screen_name: "MedievalEmpires",
    name: "Medieval Empires",
    id_str: 1526821051983970305,
  },
  { screen_name: "opensea", name: "OpenSea", id_str: 946213559213555712 },
  { screen_name: "ThinkingUSD", name: "Flood", id_str: 809191427645181952 },
  { screen_name: "CertiK", name: "CertiK", id_str: 993673575230996480 },
  {
    screen_name: "GuardaWallet",
    name: "GUARDA \ud83d\udc8e WALLET",
    id_str: 894191182561120256,
  },
  { screen_name: "theRealKiyosaki", name: "Robert Kiyosaki", id_str: 29856819 },
  {
    screen_name: "crypto_caesar1",
    name: "\ud83d\udc51Crypto Caesar\ud83d\udc51\u2122\ufe0f",
    id_str: 1002156933506392065,
  },
  { screen_name: "Biswap_Dex", name: "Biswap", id_str: 1385223705798787073 },
  {
    screen_name: "Isabell_Krypto",
    name: "Isabelle Crypto",
    id_str: 2674692804,
  },
  { screen_name: "Mohsin_71", name: "Mohsin Ali", id_str: 382922433 },
  {
    screen_name: "Gems_1000x",
    name: "CryptoCat GEMs \ud83d\udcc8\ud83d\ude80",
    id_str: 550229684,
  },
  {
    screen_name: "Cryptoboss2000",
    name: "Crypto Boss (CB) \ud83d\ude0e",
    id_str: 948935767,
  },
  {
    screen_name: "saylor",
    name: "Michael Saylor\u26a1\ufe0f",
    id_str: 244647486,
  },
  { screen_name: "amandacassatt", name: "amanda.eth", id_str: 385758190 },
  {
    screen_name: "pete_rizzo_",
    name: "The Bitcoin Historian",
    id_str: 341746855,
  },
  {
    screen_name: "EnginesOfFury",
    name: "Engines of Fury \ud83c\udfae",
    id_str: 1448267429059207170,
  },
  {
    screen_name: "singularity_net",
    name: "Artificial Superintelligence Alliance",
    id_str: 1506157483412369411,
  },
  { screen_name: "hosseeb", name: "Haseeb \uff1e|\uff1c", id_str: 231208649 },
  {
    screen_name: "beniaminmincu",
    name: "Beniamin Mincu | sharding/acc \ud83d\udd25\ud83d\udee0\ufe0f",
    id_str: 1392307531,
  },
  { screen_name: "_VaporFi", name: "VaporFi", id_str: 1388183620255461385 },
  { screen_name: "RichQuack", name: "RichQuack", id_str: 1392385621243535361 },
  {
    screen_name: "cryptogics",
    name: "Cryptogics",
    id_str: 1426885630219456513,
  },
  { screen_name: "danreecer_", name: "Dan Reecer", id_str: 968322505332043776 },
  {
    screen_name: "officialcatcoin",
    name: "Official Catcoin \u2b50",
    id_str: 1562422255161774080,
  },
  {
    screen_name: "CoinListingBot",
    name: "New Coins Listing",
    id_str: 1336791474801872897,
  },
  {
    screen_name: "gonzosol",
    name: "hennydrinker",
    id_str: 1822419177820590080,
  },
  { screen_name: "safuXBT", name: "safu", id_str: 2326975221 },
  {
    screen_name: "Kucoingemfinder",
    name: "Kucoin Gems Finder",
    id_str: 1455062096954081280,
  },
  {
    screen_name: "_Crypto_Dream",
    name: "Crypto Dream",
    id_str: 1216756440414806016,
  },
  { screen_name: "100trillionUSD", name: "PlanB", id_str: 918804624303382528 },
  {
    screen_name: "cryptodude999",
    name: "the dude",
    id_str: 943070242481217536,
  },
  {
    screen_name: "eCashTracker",
    name: "eCash (XEC) Tracker \ud83d\udcca",
    id_str: 1543900903736049665,
  },
  {
    screen_name: "bitcoingem2",
    name: "Bitcoingem",
    id_str: 1338442720163983360,
  },
  {
    screen_name: "CryptoVerse_Co",
    name: "Crypto Verse",
    id_str: 1464999378427408384,
  },
  {
    screen_name: "timbharrison",
    name: "Tim Harrison is at Token2049",
    id_str: 20991503,
  },
  { screen_name: "realXRPwhale", name: "XRP whale", id_str: 3094381903 },
  {
    screen_name: "artonbitcoin",
    name: "\u039b\u20bf.sui",
    id_str: 1125995070639837184,
  },
  { screen_name: "naval", name: "Naval", id_str: 745273 },
  { screen_name: "alecTrading", name: "alec", id_str: 4870877962 },
  { screen_name: "sama", name: "Sam Altman", id_str: 1605 },
  {
    screen_name: "coinbase",
    name: "Coinbase \ud83d\udee1\ufe0f",
    id_str: 574032254,
  },
  {
    screen_name: "Bit2Me_Global",
    name: "Bit2Me Global",
    id_str: 1468234578318041100,
  },
  {
    screen_name: "ZeMariaMacedo",
    name: "Jos\u00e9 Maria Macedo",
    id_str: 254747620,
  },
  {
    screen_name: "COTInetwork",
    name: "COTI Foundation",
    id_str: 913327957904695297,
  },
  { screen_name: "FFVV1211", name: "FFV", id_str: 1379181238502580224 },
  { screen_name: "Dashpay", name: "Dash", id_str: 2338506822 },
  { screen_name: "THEREALESMITTY", name: "E. Smitty", id_str: 63627909 },
  {
    screen_name: "wingriderscom",
    name: "WingRiders",
    id_str: 1466810642359365638,
  },
  {
    screen_name: "SolanaUnivers",
    name: "\ud835\udc12\ud835\udc0e\ud835\udc0b\ud835\udc00\ud835\udc0d\ud835\udc00 \ud835\udc14\ud835\udc0d\ud835\udc08\ud835\udc15\ud835\udc04\ud835\udc11\ud835\udc12\ud835\udc04 \ud83e\uddec",
    id_str: 1526098940482838531,
  },
  {
    screen_name: "Neo_Geo_Forever",
    name: "Neo Geo Forever",
    id_str: 1353082751369568258,
  },
  {
    screen_name: "CoinEdition",
    name: "Coin Edition: Your Crypto News Edge \ufe0f",
    id_str: 1557282915410665472,
  },
  {
    screen_name: "Jahncrypto",
    name: "Jahncrypto",
    id_str: 1461648004830703618,
  },
  {
    screen_name: "Cryptogether51",
    name: "SMART CRYPTO INVESTOR",
    id_str: 1452346021661523975,
  },
  {
    screen_name: "HottieBabeGem",
    name: "hottiebabegem \u2af7\ud83d\udd06\u2af8 \ua9c1IP\ua9c2",
    id_str: 1433617497643646976,
  },
  { screen_name: "JenniferHli", name: "Jennifer Li", id_str: 515179425 },
  { screen_name: "iamjosephyoung", name: "Joseph Young", id_str: 2272640503 },
  {
    screen_name: "maxOSIRISart",
    name: "\ud83d\udd25 Max \u0bd0 Osiris \ud83d\udd25 \u0f12 \u2021 0x0",
    id_str: 58828230,
  },
  { screen_name: "stevesi", name: "Steven Sinofsky", id_str: 13418072 },
  { screen_name: "mcuban", name: "Mark Cuban", id_str: 16228398 },
  { screen_name: "thogge", name: "tyler hogge", id_str: 49774820 },
  {
    screen_name: "alexisohanian",
    name: "Alexis Ohanian \ud83c\udde6\ud83c\uddf2",
    id_str: 811350,
  },
  {
    screen_name: "raffayalvi",
    name: "The Crypto Network",
    id_str: 1358467590834823175,
  },
  {
    screen_name: "LOPN_Avalanche",
    name: "Locked on Avalanche \ud83c\udfd4\ufe0f\ud83c\udf99\ufe0f",
    id_str: 1190325646154772481,
  },
  {
    screen_name: "0xairdropfarmer",
    name: "\ud83d\udc69\u200d\ud83c\udf3e0xAirdropFarmer.eth\ud83e\ude82\u2666\ufe0f",
    id_str: 1269802118686236673,
  },
  {
    screen_name: "vougetrader",
    name: "\u24e5ogue Trader",
    id_str: 1352102898566619138,
  },
  {
    screen_name: "CryptoLordH",
    name: "Crypto Lord",
    id_str: 1468385379669921792,
  },
  {
    screen_name: "Neovisons",
    name: "\ud83c\udf89\ud83c\udf1f NEO \ud83c\udf1f\ud83c\udf89",
    id_str: 1045515706501156865,
  },
  {
    screen_name: "Bonacci_NFT",
    name: "Bonacci | SOLD OUT",
    id_str: 1468361143802597381,
  },
  { screen_name: "erikhieubnb", name: "ErikHieu.BnB", id_str: 44232404 },
  {
    screen_name: "Kiddelta_cNFT",
    name: "Kiddelta Lavigne",
    id_str: 1529573266939072512,
  },
  { screen_name: "ShaneCultra", name: "Domain Shane", id_str: 29106721 },
  {
    screen_name: "hc_capital",
    name: "HC - Capital",
    id_str: 1176130552811802624,
  },
  {
    screen_name: "officialmocossi",
    name: "Mocossi Planet | A Cardano Gaming Project",
    id_str: 1447602326999556097,
  },
  {
    screen_name: "SHIBsecret",
    name: "Shib Secret",
    id_str: 1512283042835943433,
  },
  { screen_name: "KTmBoyle", name: "Katherine Boyle", id_str: 78822934 },
  { screen_name: "andrewchen", name: "andrew chen", id_str: 3283901 },
  { screen_name: "Melt_Dem", name: "Meltem Demirors", id_str: 2363290915 },
  { screen_name: "traderdaye", name: "Daye", id_str: 1310809543153258497 },
  { screen_name: "mdudas", name: "Mike Dudas", id_str: 7184612 },
  { screen_name: "Haezl_Crypto", name: "Haezl", id_str: 1592215105047040001 },
  {
    screen_name: "defi_mochi",
    name: "Defi_Mochi",
    id_str: 1575414573019979776,
  },
  { screen_name: "TheStalwart", name: "Joe Weisenthal", id_str: 14096763 },
  { screen_name: "NateSilver538", name: "Nate Silver", id_str: 16017475 },
  {
    screen_name: "AriDavidPaul",
    name: "Ari Paul \u26d3\ufe0f",
    id_str: 847882255540342784,
  },
  {
    screen_name: "circle_crypto",
    name: "Aztec (The Aggregated)\ud83d\udc7f",
    id_str: 1103233040162086912,
  },
  { screen_name: "fantomfilms", name: "Fantom Tweets", id_str: 273661014 },
  {
    screen_name: "ARiHBARi",
    name: "LinkTOAD General HBARI",
    id_str: 1429901979078336520,
  },
  {
    screen_name: "Crypto_Colombus",
    name: "Columbo (\ud83e\udde0,\ud83e\udd16)",
    id_str: 1296525529215107073,
  },
  { screen_name: "packyM", name: "Packy McCormick", id_str: 21306324 },
  { screen_name: "CryptoRezeh", name: "Rezeh", id_str: 1460011725529522182 },
  {
    screen_name: "cburniske",
    name: "Chris Burniske",
    id_str: 710463119886262272,
  },
  { screen_name: "jchervinsky", name: "Jake Chervinsky", id_str: 47003920 },
  { screen_name: "Bitcasinoio", name: "Bitcasino", id_str: 2253392420 },
  {
    screen_name: "Deebs_DeFi",
    name: "Deebs DeFi \ud83d\udef0",
    id_str: 1478495667031089161,
  },
  {
    screen_name: "CoinSignals_",
    name: "Coin Signals",
    id_str: 1230022260984958978,
  },
  {
    screen_name: "MakersPlace",
    name: "MakersPlace \ud83d\udd33",
    id_str: 986707612157657088,
  },
  { screen_name: "martin_casado", name: "martin_casado", id_str: 16591288 },
  { screen_name: "miyuki__eto", name: "miyu", id_str: 1369810936257601542 },
  {
    screen_name: "Cryptorphic1",
    name: "Cryptorphic",
    id_str: 1013821726977863680,
  },
  {
    screen_name: "PLR_2024",
    name: "PATRICK L. RILEY \ud83c\uddfa\ud83c\uddf2",
    id_str: 1470090195747979267,
  },
  {
    screen_name: "scupytrooples",
    name: "scoopy trooples",
    id_str: 1012777913299689477,
  },
  {
    screen_name: "PuppetzNFT",
    name: "PuppetZ.CDC\ud83c\udfad",
    id_str: 1510960324639440897,
  },
  {
    screen_name: "ocicatcoin",
    name: "Ocicat Coin",
    id_str: 1614236662447448065,
  },
  {
    screen_name: "dydxfoundation",
    name: "dYdX Foundation \ud83e\udd94",
    id_str: 1413217545545568261,
  },
  { screen_name: "galaxyhq", name: "Galaxy", id_str: 1164262615339675648 },
  { screen_name: "Coins_Kid", name: "CoinsKid", id_str: 24671050 },
  {
    screen_name: "SolanaConf",
    name: "Solana Breakpoint \u2600\ufe0f SINGAPORE Sept. 20-21, 2024",
    id_str: 1419653495611875330,
  },
  { screen_name: "depression2019", name: "Jack", id_str: 811266366724567040 },
  {
    screen_name: "MonstersCoins",
    name: "The Crypto Monster \ud83c\udf6a\ud83c\udf6a",
    id_str: 993112885,
  },
  {
    screen_name: "CryptoArchieYT",
    name: "Archie",
    id_str: 1562098369878695938,
  },
  { screen_name: "StellarOrg", name: "Stellar", id_str: 2460502890 },
  {
    screen_name: "CML_Crypto",
    name: "CoinMarketLeague.com",
    id_str: 1384812234866675714,
  },
  {
    screen_name: "MinaMendesTTV",
    name: "Mina Mendes",
    id_str: 1331945909874282498,
  },
  {
    screen_name: "CryptoAtlas_io",
    name: "CryptoAtlas",
    id_str: 1249042570757488648,
  },
  {
    screen_name: "CryptoSpaceX01",
    name: "\ud835\uddd6\ud835\uddff\ud835\ude06\ud835\uddfd\ud835\ude01\ud835\uddfc \ud835\udde6\ud835\uddfd\ud835\uddee\ud835\uddf0\ud835\uddf2\ud835\uddeb",
    id_str: 1484749104672800769,
  },
  {
    screen_name: "cryptopuxiii",
    name: "Puxiii | CryptoBox & Trader",
    id_str: 13150602,
  },
  {
    screen_name: "CollinBrownXRP",
    name: "Collin Brown",
    id_str: 1699287707581595648,
  },
  { screen_name: "LitecoinYagami", name: "Lite", id_str: 1448446626838814724 },
  {
    screen_name: "CryptoJobs3",
    name: "\ud83e\uddd9 Crypto_Jobs\ud83e\uddd9\u200d\u2642\ufe0f TA & FA \ud83c\udfaf",
    id_str: 1293821182282784776,
  },
  {
    screen_name: "CryptoHannibal1",
    name: "Crypto$Hannibal \ud83d\udc99\ud83c\uddfa\ud83c\udde6",
    id_str: 3243348789,
  },
  {
    screen_name: "ShibAlexander",
    name: "$SHIB ALEXANDER\ud83d\udd36\ufe0f",
    id_str: 1392050735148306437,
  },
  {
    screen_name: "Doms_Crypto",
    name: "Dom's Crypto",
    id_str: 1192221279698505728,
  },
  {
    screen_name: "TezosTexas",
    name: "Em \ud83c\udff4\u200d\u2620\ufe0fEx-Tezos",
    id_str: 51362228,
  },
  {
    screen_name: "eBTCprotocol",
    name: "eBTC | Get Paid to Borrow Bitcoin",
    id_str: 1460791634044588033,
  },
  {
    screen_name: "polkadotwhales",
    name: "Polkadot Watch \ud83d\udd2d",
    id_str: 1662721280863346688,
  },
  {
    screen_name: "TeamDogeCoin369",
    name: "#Team\u00d0ogeCoin",
    id_str: 1369360018731110402,
  },
  { screen_name: "x0RADA", name: "Pharaox", id_str: 430934069 },
  {
    screen_name: "BobLoukas",
    name: "Bob Loukas \ud83d\uddfd",
    id_str: 82172203,
  },
  {
    screen_name: "MartaVerse",
    name: "MartaTrades",
    id_str: 1500399704886157314,
  },
  { screen_name: "Ravers", name: "Obey Raves", id_str: 2364233382 },
  { screen_name: "enjin", name: "Enjin", id_str: 20356963 },
  {
    screen_name: "cowboycrypto313",
    name: "Cowboy.Crypto",
    id_str: 1349352238884466689,
  },
  { screen_name: "WeWay_io", name: "WeWay", id_str: 1426181751165046790 },
  { screen_name: "MMCrypto", name: "MMCrypto", id_str: 904700529988820992 },
  {
    screen_name: "DannyCrypto85",
    name: "Danny Marshall",
    id_str: 1440347707332202500,
  },
  {
    screen_name: "TrustWallet",
    name: "Trust Wallet",
    id_str: 911011433147654144,
  },
  { screen_name: "K4KCrypto", name: "K4K", id_str: 1206710867066343425 },
  { screen_name: "CryptoDetonator", name: "Richie", id_str: 2642057661 },
  { screen_name: "ChadXBT_", name: "Chad XBT", id_str: 1409508086230908933 },
  {
    screen_name: "MinaProtocol",
    name: "Mina Protocol (httpz) \ud83e\udeb6",
    id_str: 991439317053591552,
  },
  {
    screen_name: "CyberFrogsNFT",
    name: "Cyber Frogs (!FF)",
    id_str: 1437319224389742594,
  },
  {
    screen_name: "The_BTC_Express",
    name: "The Bitcoin Express",
    id_str: 1253093244302512129,
  },
  {
    screen_name: "AIPunksNFTs",
    name: "AI Punks NFT Collection",
    id_str: 1431898410475565056,
  },
  {
    screen_name: "eCashInformer",
    name: "X\u314c\u3137 Informer \ud83c\udf10",
    id_str: 1447888045689217029,
  },
  {
    screen_name: "Aptos_allday_",
    name: "Aptos allday",
    id_str: 1655576889447022605,
  },
  {
    screen_name: "BrutalBTC",
    name: "Brutal \u270d\ufe0f",
    id_str: 1358704727962357760,
  },
  {
    screen_name: "CryptoPicksPro",
    name: "CRYPTO Picks PRO",
    id_str: 1099646609229598720,
  },
  {
    screen_name: "Fantom_sports",
    name: "Fantom Sports Industries",
    id_str: 1396673701555294210,
  },
  {
    screen_name: "TheCoinfatherYT",
    name: "The Coinfather",
    id_str: 1350555856077811716,
  },
  { screen_name: "lethal_affu", name: "Affu", id_str: 1470840158119419904 },
  {
    screen_name: "davidgerard",
    name: "your #1 source for absurdist true crime \ud83d\udc00 \ud83d\udc0d\ud83d\udc51 \ud83c\udf37",
    id_str: 14723614,
  },
  {
    screen_name: "cryptobyls",
    name: "Cryptobyls",
    id_str: 1465403796130082819,
  },
  { screen_name: "u_mvea", name: "mvea", id_str: 1576801701927219200 },
  {
    screen_name: "jimmysong",
    name: "Jimmy Song (\uc1a1\uc7ac\uc900)",
    id_str: 21263333,
  },
  {
    screen_name: "JasCrypto_",
    name: "Jas \ud83d\udd2e",
    id_str: 1521962487222083589,
  },
  {
    screen_name: "Whale_Of_City",
    name: "\u1d21\u029c\u1d00\u029f\u1d07 \u1d0f\u0493 \u1d04\u026a\u1d1b\u028f \ud83d\udc33",
    id_str: 1360240712806060032,
  },
  {
    screen_name: "coinsniper_net",
    name: "CoinSniper",
    id_str: 1394380008408461315,
  },
  {
    screen_name: "TheDeFinvestor",
    name: "The DeFi Investor \ud83d\udd0e",
    id_str: 1395365515779055616,
  },
  { screen_name: "ShidoGlobal", name: "Shido", id_str: 1478483066490433541 },
  {
    screen_name: "SmaugPool",
    name: "\u7adc SM\u20b3UG",
    id_str: 1272684957463392257,
  },
  {
    screen_name: "koeppelmann",
    name: "Martin K\u00f6ppelmann \ud83e\udd89\ud83d\udcb3",
    id_str: 63064338,
  },
  {
    screen_name: "Chiliz",
    name: "Chiliz - The Sports Blockchain \u26bd\ud83c\udfc6",
    id_str: 2590633042,
  },
  {
    screen_name: "CornucopiasGame",
    name: "CornucopiasGame",
    id_str: 1418315292527169541,
  },
  {
    screen_name: "justinsuntron",
    name: "H.E. Justin Sun\ud83c\udf1e(hiring)",
    id_str: 902839045356744704,
  },
  {
    screen_name: "DigiFinex",
    name: "DigiFinex Global",
    id_str: 989251920610054144,
  },
  { screen_name: "iampaulgrewal", name: "paulgrewal.eth", id_str: 2956378446 },
  {
    screen_name: "CryptoVikings07",
    name: "Crypto Vikings",
    id_str: 1509229419570761730,
  },
  {
    screen_name: "MARBLEXofficial",
    name: "MARBLEX",
    id_str: 1493499376815476741,
  },
  {
    screen_name: "tobialism",
    name: "Oluwatobi Adekunle, Adeyeye",
    id_str: 169973636,
  },
  {
    screen_name: "CryptoGideon_",
    name: "Crypto Gideon(\ud83e\udde0,\ud83e\udde0)",
    id_str: 1610335108455620609,
  },
  { screen_name: "anndylian", name: "Anndy Lian", id_str: 274334177 },
  {
    screen_name: "MikeyTrading",
    name: "Mikey \ud83e\ude93\ud83e\udd86",
    id_str: 1033722349856509957,
  },
  {
    screen_name: "MonikaNFT",
    name: "MonikasArt.eth +tez \ud83c\udf0d\ud83d\udd4a\ufe0f\ud83d\udc95",
    id_str: 1409098927484379137,
  },
  {
    screen_name: "flowverse_",
    name: "Flowverse \ud83c\udf0a - Discover Flow Blockchain",
    id_str: 1409359424989196292,
  },
  { screen_name: "iotatokennews", name: "IOTA News", id_str: 2739265466 },
  { screen_name: "MTI_Trading", name: "Sam Mti", id_str: 1447565862290997250 },
  { screen_name: "Davincij15", name: "Davinci Jeremie", id_str: 285869396 },
  {
    screen_name: "TheADAApe",
    name: 'ADA ape \ud83c\udf0d|| "APE" (Pool)',
    id_str: 2465974779,
  },
  {
    screen_name: "dougboneparth",
    name: "Douglas A. Boneparth",
    id_str: 158049457,
  },
  {
    screen_name: "pet3rpan_",
    name: "Peter / \u2018pet3rpan\u2019",
    id_str: 1001171600,
  },
  { screen_name: "bitcoinrow", name: "Ru", id_str: 1459854935797878787 },
  { screen_name: "NonPlayableCoin", name: "NPC", id_str: 1682073285821972490 },
  { screen_name: "GACryptoO", name: "GA Crypto", id_str: 1259523261395804161 },
  { screen_name: "CryptoBobesh", name: "bobesh", id_str: 1328033601733136386 },
  {
    screen_name: "RealDayTrading",
    name: "Real Day Trading",
    id_str: 1450881776432254977,
  },
  { screen_name: "VespertinoVsp", name: "Vesper", id_str: 1174662089320873985 },
  {
    screen_name: "wacy_time1",
    name: "Al\u039ex Wacy \ud83c\udf10",
    id_str: 1410927296614178816,
  },
  { screen_name: "CryptoB_K", name: "BK \ud83e\udd77", id_str: 1554841082 },
  {
    screen_name: "GonzalitoSNFT",
    name: "Gonzalo Souto NFT",
    id_str: 1664733819541266432,
  },
  {
    screen_name: "MagicDegenSOL",
    name: "Magic Degen \ud83e\ude84",
    id_str: 1448348856526114817,
  },
  {
    screen_name: "elgiveaway",
    name: "EL \ud83c\udf41 sponsor low fee",
    id_str: 1603260198201995264,
  },
  { screen_name: "CryptoLifer33", name: "Sam Price", id_str: 234848990 },
  {
    screen_name: "cryptopigmedia",
    name: "Cryptopig \ud83d\udc37 (Gem hunter & trader)",
    id_str: 1007357709103108096,
  },
  {
    screen_name: "Cryptogripped",
    name: "Cryptovert 3.0",
    id_str: 1355711022984794116,
  },
  {
    screen_name: "CryptoReviewing",
    name: "CryptoReviewing",
    id_str: 1540822016030285824,
  },
  {
    screen_name: "CryptoCoffee369",
    name: "CryptoCoffee.pls",
    id_str: 4914047653,
  },
  {
    screen_name: "NeopinOfficial",
    name: "NEOPIN",
    id_str: 1378992238022197251,
  },
  {
    screen_name: "solana_daily",
    name: "Solana Daily",
    id_str: 1379774059840344064,
  },
  {
    screen_name: "Token_Unlocks",
    name: "Token Unlocks",
    id_str: 1543848144932597760,
  },
  {
    screen_name: "TATrader_Alan",
    name: "Trader Tardigrade",
    id_str: 1491688017207382019,
  },
  {
    screen_name: "DU09BTC",
    name: "Duo Nine \u26a1 YCC",
    id_str: 771255865273823232,
  },
  {
    screen_name: "CryptoChem0000",
    name: "max.arch \u26a1\ufe0f\ud83d\udfe0",
    id_str: 1027291629928017920,
  },
  {
    screen_name: "rendernetwork",
    name: "The Render Network",
    id_str: 900487236315078657,
  },
  {
    screen_name: "Ucan_Coin",
    name: "\ud835\udd80\ud835\udd88\ud835\udd86\ud835\udd93",
    id_str: 1449810845987397636,
  },
  {
    screen_name: "pangolindex",
    name: "Pangolin (V3 coming soon)",
    id_str: 1330644600801865728,
  },
  {
    screen_name: "TheHardCompound",
    name: "The Hard Compound",
    id_str: 1179488984755589122,
  },
  {
    screen_name: "CheddarFlow",
    name: "Cheddar Flow",
    id_str: 1004137473679679488,
  },
  {
    screen_name: "TheBCHPodcast",
    name: "The Bitcoin Cash Podcast",
    id_str: 1366442158241284098,
  },
  {
    screen_name: "SingularityNET",
    name: "SingularityNET",
    id_str: 915590652167061504,
  },
  { screen_name: "onMELD", name: "MELD", id_str: 1371725080275906565 },
  {
    screen_name: "Chainplaygg",
    name: "ChainPlay.GG",
    id_str: 1452923557277626369,
  },
  { screen_name: "NorthmanTrader", name: "Sven Henrich", id_str: 714051110 },
  {
    screen_name: "SafeHavenio",
    name: "Safe Haven (SHA)",
    id_str: 929817868673437697,
  },
  {
    screen_name: "BitcoinPierre",
    name: "Pierre Rochard",
    id_str: 1467491136851873802,
  },
  { screen_name: "nftnow", name: "nft now", id_str: 1353062898419994626 },
  {
    screen_name: "NFTDigiart",
    name: "\ud835\udc12\ud835\udc21\ud835\udc1a\ud835\udc2b\ud835\udc24\u1d48\u1d52\u1d4d \u1d9c\u02b3\u02b8\u1d56\u1d57\u1d52\ud83e\udd88",
    id_str: 1369804175303917572,
  },
  {
    screen_name: "vechainofficial",
    name: "VeChain",
    id_str: 908576143975919616,
  },
  { screen_name: "ETHGlobal", name: "ETHGlobal", id_str: 883476092832681984 },
  { screen_name: "CryptoPopPunk", name: "Pop Punk", id_str: 381444976 },
  {
    screen_name: "Xencryptoio",
    name: "\u24e7 XEN Crypto | News & Updates \ud83d\udede X1",
    id_str: 1573363319846617088,
  },
  { screen_name: "LiquidHbox", name: "hungrybox", id_str: 257817126 },
  {
    screen_name: "OL5ON",
    name: "IrishGirlCrypto #\ud83d\udc9a\ud83d\udc9a\ud83d\udc9a",
    id_str: 90711735,
  },
  {
    screen_name: "WorkingMoneyCH",
    name: "Working Money \ud83d\udcb0",
    id_str: 604946013,
  },
  { screen_name: "latokens", name: "LATOKEN", id_str: 883335021943279618 },
  {
    screen_name: "MessariCrypto",
    name: "Messari | Road to #Mainnet2024 \ud83d\uddfd",
    id_str: 412587524,
  },
  { screen_name: "InputOutputHK", name: "Input Output", id_str: 3021837038 },
  {
    screen_name: "FlareNetworks",
    name: "Flare \u2600\ufe0f",
    id_str: 1098955584907431936,
  },
  {
    screen_name: "NicolasFlamelX",
    name: "Nicolas Flamel",
    id_str: 951386802841145346,
  },
  {
    screen_name: "Crypto4bailout",
    name: "\ud83e\udd5eC4B Freedom\ud83e\udd5e",
    id_str: 1015345440487870464,
  },
  {
    screen_name: "BullyDCrypto",
    name: "Crypto Bully \ud83d\udd25",
    id_str: 1668575352443097088,
  },
  {
    screen_name: "DeFi_Made_Here",
    name: "DeFi Made Here",
    id_str: 1464724421411749891,
  },
  { screen_name: "CryptoKingKeyur", name: "Keyur Rohit", id_str: 315640939 },
  { screen_name: "GarethSoloway", name: "Gareth Soloway", id_str: 259354209 },
  {
    screen_name: "elliotrades",
    name: "EllioTrades",
    id_str: 948736680554409984,
  },
  {
    screen_name: "cryptoleakvn2",
    name: "Cryptoleakvn",
    id_str: 1366853264365424641,
  },
  { screen_name: "speicherx", name: "Speicherx", id_str: 257846146 },
  {
    screen_name: "CryptoStreamHub",
    name: "Crypto Stream",
    id_str: 1438059058683125762,
  },
  { screen_name: "Decu0x", name: "Decu", id_str: 1476664459817070593 },
  {
    screen_name: "hiRavenCrypto",
    name: "Crypto Raven",
    id_str: 1432031280124731392,
  },
  { screen_name: "cryptoian", name: "ian", id_str: 3186479000 },
  { screen_name: "cryptoian", name: "ian", id_str: 3186479000 },
  { screen_name: "Poloniex", name: "Poloniex Exchange", id_str: 2288889440 },
  { screen_name: "danheld", name: "Dan Held", id_str: 1598709350 },
  { screen_name: "alistairmilne", name: "Alistair Milne", id_str: 20902138 },
  { screen_name: "tulioorocha_", name: "T\u00falio", id_str: 2796712523 },
  {
    screen_name: "cryptoworld202",
    name: "Cryptocurrency World",
    id_str: 974317246888710145,
  },
  { screen_name: "TheCryptoLark", name: "Lark Davis", id_str: 30325257 },
  {
    screen_name: "MagicEden",
    name: "Magic Eden \ud83e\ude84",
    id_str: 1433121559057559555,
  },
  { screen_name: "coinmamba", name: "CoinMamba", id_str: 36691434 },
  { screen_name: "swissborg", name: "SwissBorg", id_str: 726757620057563136 },
  { screen_name: "inmortalcrypto", name: "Inmortal", id_str: 571771109 },
  {
    screen_name: "astroboysoup",
    name: "Pete | Beware of Scammers",
    id_str: 7838552,
  },
  {
    screen_name: "IvanOnTech",
    name: "Ivan on Tech \ud83d\ude80 DM if at Token2049",
    id_str: 390627208,
  },
  {
    screen_name: "Tunde_OD",
    name: "Tunde Onakoya",
    id_str: 806583785408131077,
  },
  {
    screen_name: "CasinoCoin",
    name: "CasinoCoin \ud83e\ude9d",
    id_str: 897206587747700740,
  },
  {
    screen_name: "RunOnFlux",
    name: "Flux-Web3 Cloud",
    id_str: 956929025645035522,
  },
  { screen_name: "Nexo", name: "Nexo", id_str: 919174740270637056 },
  {
    screen_name: "CredibleCrypto",
    name: "CrediBULL Crypto",
    id_str: 944746889194364928,
  },
  {
    screen_name: "XRPcryptowolf",
    name: "XRPcryptowolf",
    id_str: 944462285573099520,
  },
  {
    screen_name: "rebel_defi",
    name: "Rebel Defi",
    id_str: 1431173725760720896,
  },
  { screen_name: "krakenfx", name: "Kraken Exchange", id_str: 1399148563 },
  {
    screen_name: "CaitlinLong_",
    name: "Caitlin Long \ud83d\udd11\u26a1\ufe0f\ud83d\udfe0",
    id_str: 717926102614716417,
  },
  {
    screen_name: "milesdeutscher",
    name: "Miles Deutscher",
    id_str: 1530033576,
  },
  {
    screen_name: "symbiosis_fi",
    name: "Symbiosis",
    id_str: 1397123034281041922,
  },
  {
    screen_name: "Breedlove22",
    name: "Robert \u20bfreedlove",
    id_str: 285636352,
  },
  { screen_name: "zGuz", name: "Zack Guzm\u00e1n", id_str: 292565344 },
  { screen_name: "Ledger", name: "Ledger", id_str: 2652239532 },
  {
    screen_name: "blueclarityone",
    name: "Blue \ud83d\udc08\u200d\u2b1b",
    id_str: 2716962677,
  },
  { screen_name: "NFTHawks", name: "Hawks", id_str: 2907692587 },
  { screen_name: "connorcrd", name: "Connor", id_str: 1500271824285970432 },
  {
    screen_name: "flipsidecrypto",
    name: "Flipside \ud83d\udcca",
    id_str: 925712018937712640,
  },
  {
    screen_name: "bitgertbrise",
    name: "Bitgert - $BRISE",
    id_str: 1411205588801908736,
  },
  { screen_name: "Ashcryptoreal", name: "Ash Crypto", id_str: 3185716686 },
  {
    screen_name: "JoelKatz",
    name: 'David "JoelKatz" Schwartz',
    id_str: 35749949,
  },
  {
    screen_name: "Futurist_conf",
    name: "Blockchain Futurist Conference",
    id_str: 1001124623394553857,
  },
  {
    screen_name: "VivalaCoinBTC",
    name: "VivaLaCoin",
    id_str: 1360292871488831489,
  },
  { screen_name: "robustus", name: "Dan McArdle", id_str: 14144398 },
  { screen_name: "ErikVoorhees", name: "Erik Voorhees", id_str: 61417559 },
  {
    screen_name: "CryptoMichNL",
    name: "Micha\u00ebl van de Poppe",
    id_str: 146008010,
  },
  { screen_name: "brian_armstrong", name: "Brian Armstrong", id_str: 14379660 },
  { screen_name: "Jebus", name: "Jebus", id_str: 162573283 },
  {
    screen_name: "Web3WithBinance",
    name: "Binance Web3",
    id_str: 1400391752368992260,
  },
  { screen_name: "ArkhamIntel", name: "Arkham", id_str: 1123403704323805184 },
  {
    screen_name: "ShannonBrayNC",
    name: "Shannon Bray \ud83e\ude99",
    id_str: 1090016912669581315,
  },
  {
    screen_name: "JerzyNFT",
    name: "Jerzy \ud83d\udda4",
    id_str: 1408122299132358668,
  },
  {
    screen_name: "cexplorer_io",
    name: "Cardano Explorer \ud83c\udd70\ufe0f Cexplorer.io",
    id_str: 1330864882120581120,
  },
  {
    screen_name: "AlgoFoundation",
    name: "Algorand Foundation \ud83d\udc0d |\ud83d\udccdTOKEN2049",
    id_str: 1090346259892838400,
  },
  { screen_name: "molly0xFFF", name: "Molly White", id_str: 545445165 },
  {
    screen_name: "tjstebbing",
    name: "Timothy Stebbing",
    id_str: 1419590610210549763,
  },
  { screen_name: "tezos", name: "Tezos", id_str: 745758443324080128 },
  { screen_name: "Awawat_Trades", name: "Awawat", id_str: 1281133175658254336 },
  { screen_name: "TheCryptoSquire", name: "John Squire", id_str: 155629354 },
  {
    screen_name: "MultiversX",
    name: "MultiversX | Sovereign Chains",
    id_str: 986967941685153792,
  },
  {
    screen_name: "FerreNFT",
    name: "Ferre\u26a1\ufe0f",
    id_str: 1398988286597734403,
  },
  {
    screen_name: "CoinbaseWallet",
    name: "Coinbase Wallet \ud83d\udee1\ufe0f",
    id_str: 790627436832579584,
  },
  {
    screen_name: "rollercoin_com",
    name: "RollerCoin \ud83c\udfae",
    id_str: 881818799363813376,
  },
  { screen_name: "iota", name: "IOTA", id_str: 3992601857 },
  {
    screen_name: "TokenPocket_TP",
    name: "TokenPocket",
    id_str: 984117658202226689,
  },
  { screen_name: "BTCCexchange", name: "BTCC", id_str: 2338229497 },
  {
    screen_name: "CryptosR_Us",
    name: "CryptosRus",
    id_str: 877357064724520961,
  },
  { screen_name: "cronos_chain", name: "Cronos", id_str: 1411867326828335107 },
  { screen_name: "bitcoininfo", name: "Bitcoin Info", id_str: 268702954 },
  { screen_name: "CryptoKaduna", name: "Kaduna", id_str: 1103404363861684236 },
  {
    screen_name: "samoyedcoin",
    name: "Samoyedcoin (SAMO)",
    id_str: 1384520091396673537,
  },
  {
    screen_name: "CryptoBullet1",
    name: "CryptoBullet",
    id_str: 1134052146800922625,
  },
  {
    screen_name: "flow_blockchain",
    name: "Flow \ud83c\udf0a",
    id_str: 1167532630746828801,
  },
  { screen_name: "TxDOT", name: "TxDOT", id_str: 16912739 },
  { screen_name: "HerroCrypto", name: "Herro", id_str: 949016786506100736 },
  {
    screen_name: "_TheNFTDaily",
    name: "The NFT Daily \ud83e\uddca",
    id_str: 1243969185559891969,
  },
  { screen_name: "CryptoWorldJosh", name: "Josh", id_str: 725969359089229824 },
  {
    screen_name: "CaleCrypto",
    name: "Cale \ud83e\udd6c",
    id_str: 1379240803441197059,
  },
  {
    screen_name: "CoinGurruu",
    name: "\ud83d\udc90Guru \ud83d\udc90",
    id_str: 1032379192388857861,
  },
  { screen_name: "LowKeyUHTN", name: "Low", id_str: 15726053 },
  {
    screen_name: "Bitcoinbeach",
    name: "Bitcoin Beach",
    id_str: 1158640986181890049,
  },
  {
    screen_name: "allgiveawaysda1",
    name: "ALgiving",
    id_str: 1508194547632951296,
  },
  { screen_name: "mbeNFT", name: "MB\u039e", id_str: 1369706901513138177 },
  {
    screen_name: "corleonescrypto",
    name: "Corleone",
    id_str: 1250118989797302272,
  },
  {
    screen_name: "CryptoMafia420",
    name: "Crypto Mafia",
    id_str: 1388818938025283587,
  },
  { screen_name: "MASTERBTCLTC", name: "master", id_str: 942252916475179008 },
  {
    screen_name: "SrPetersETH",
    name: "Sr Peters",
    id_str: 1544474172750970880,
  },
  {
    screen_name: "TheCryptoDaddi",
    name: "CryptoDaddi",
    id_str: 1225636783418830848,
  },
  { screen_name: "ValCoins", name: "Val Me", id_str: 907898760751132672 },
  {
    screen_name: "tokenmetricsinc",
    name: "Token Metrics",
    id_str: 1136430327176581120,
  },
  { screen_name: "CryptoJelleNL", name: "Jelle", id_str: 787634466 },
  {
    screen_name: "SuperchiefNFT",
    name: "SuperchiefGalleryNFT",
    id_str: 1372627798469316612,
  },
  { screen_name: "MadBitcoins", name: "Mad Bitcoins", id_str: 1389824700 },
  { screen_name: "DailyFaceoff", name: "Daily Faceoff", id_str: 3097954854 },
  {
    screen_name: "Raiinmakerapp",
    name: "Raiinmaker",
    id_str: 1234253548616962048,
  },
  {
    screen_name: "AssetsDaily",
    name: "Digital Assets Daily",
    id_str: 1180958582361251841,
  },
  {
    screen_name: "NFTNURI",
    name: "NFTNURI \ud83e\udd1f",
    id_str: 1460730785305083909,
  },
  {
    screen_name: "CryptoCronkite",
    name: "CRONK (parody) follow for Dumbdicks WL spot",
    id_str: 179158131,
  },
  {
    screen_name: "CryptoZenStudio",
    name: "Birdman",
    id_str: 717214750472593409,
  },
  {
    screen_name: "WLitecoin",
    name: "Why Litecoin",
    id_str: 1398360779905060864,
  },
  {
    screen_name: "CryptoCosX",
    name: "CryptoCos \ud83d\udd25",
    id_str: 276735085,
  },
  {
    screen_name: "kenlivacosmos",
    name: "Crypt\u00f8 judge\u00ae \ud83e\udd77",
    id_str: 1482222583651176455,
  },
  {
    screen_name: "Golden1Team",
    name: "Golden Crypto Signals",
    id_str: 2723851710,
  },
  {
    screen_name: "Dudewhyme_ADA",
    name: "Dudewhyme.ADA",
    id_str: 1460822359015923713,
  },
  { screen_name: "Cryptomememage", name: "SunBuddy TRX", id_str: 2237888488 },
  { screen_name: "Tryrexcrypto", name: "Tryrex", id_str: 1680599724720640001 },
  {
    screen_name: "arbitrum_intern",
    name: "Arbitrum Intern",
    id_str: 1608679062826020869,
  },
  {
    screen_name: "TodayCryptoRj",
    name: "Rananjay Singh",
    id_str: 1352651331140349954,
  },
  {
    screen_name: "CryptoGnomie",
    name: "Gnomie\ud83d\udd3a\ufe0f\u24e5",
    id_str: 1265467070193397760,
  },
  { screen_name: "IGreycrypto", name: "iGREY", id_str: 1447157953459539973 },
  {
    screen_name: "illusionXcrypto",
    name: "Illusion X",
    id_str: 1655194722862878720,
  },
  {
    screen_name: "CryptoTweetie",
    name: "Csilla Brimer \u2764\ufe0f\u200d\ud83d\udd25\ud83d\uddfd\ud83c\udd99",
    id_str: 987374574042996736,
  },
  {
    screen_name: "EuropeanTraders",
    name: "\ud83c\uddeb\ud83c\uddf7 \ud83c\uddea\ud83c\uddfa \ud83c\udde9\ud83c\uddea European Investors News \ud83c\udde9\ud83c\uddea \ud83c\udde8\ud83c\uddf0",
    id_str: 1511686691131637760,
  },
  {
    screen_name: "HogwartsCrypto",
    name: "Crypto Hogwarts \u26a1\ud83e\udd77( \ud83d\udc9c , \ud83c\udfd4\ufe0f )",
    id_str: 1569725138173841409,
  },
  {
    screen_name: "MeowcoinProject",
    name: "Meowcoin",
    id_str: 1564210056270188545,
  },
  {
    screen_name: "cryptokupumps",
    name: "Crypto Wolf Trades",
    id_str: 1529380776055300097,
  },
  {
    screen_name: "flowowoo",
    name: "flow \ud83c\udf4a\ud83e\udda6",
    id_str: 1300403047802130436,
  },
  {
    screen_name: "CryptoRyan17",
    name: "Crypto Ryan",
    id_str: 1397366238934880262,
  },
  {
    screen_name: "dora_shitcoins",
    name: "Dor\ud83c\udd70\ufe0f The \ud83d\udca9 Shitcoin \ud83d\udca9 Explorer",
    id_str: 951575210494488581,
  },
  {
    screen_name: "coinscreener_ai",
    name: "CoinScreener.ai",
    id_str: 1569120919356084225,
  },
  {
    screen_name: "CDemanincor",
    name: "Cypress Demanincor",
    id_str: 1249803887235612674,
  },
  {
    screen_name: "Crypto_Guerrila",
    name: "CryptoGuerrilla \ud83d\udd3a\ufe0f",
    id_str: 947697682969083905,
  },
  {
    screen_name: "CardanoTrain",
    name: "TR\u20b3iN - (ST\u20b3R\u2728LORD)",
    id_str: 1469634353705611264,
  },
  {
    screen_name: "cryptoskullx",
    name: "CryptoSkull \ud83d\udc80 ze last bull standing",
    id_str: 1454559987276124168,
  },
  {
    screen_name: "VEGAS_ADA_Pool",
    name: "VEGAS \u20b3D\u20b3 Pool",
    id_str: 885490321550868480,
  },
  {
    screen_name: "BVBTC",
    name: "Brekkie\u2692\ufe0f",
    id_str: 960091531448217600,
  },
  {
    screen_name: "IdMintThat",
    name: "mickele \u2728",
    id_str: 1249740856967430144,
  },
  {
    screen_name: "coinfinity",
    name: "Coinfinity \u26a1\ufe0f",
    id_str: 2399433313,
  },
  {
    screen_name: "MandoTrading",
    name: "MANDO TRADING",
    id_str: 1298383393521111046,
  },
  {
    screen_name: "CryptoCypher30",
    name: "Crypto Cipher",
    id_str: 1639793205557248000,
  },
  {
    screen_name: "ARBITERxyz",
    name: "ARBITER \ud83e\udd8b",
    id_str: 1546843456152248320,
  },
  { screen_name: "bitpinas", name: "BitPinas", id_str: 913275663544487936 },
  { screen_name: "itzdannyADA", name: "$itzdanny", id_str: 388539552 },
  {
    screen_name: "semestastellar",
    name: "stellar",
    id_str: 1262385214640267273,
  },
  {
    screen_name: "WallStreetBTCX",
    name: "Wall Street Bitcoin",
    id_str: 1083116950589444096,
  },
  {
    screen_name: "LTCUnderground",
    name: "Litecoin Undergroun\u0110",
    id_str: 1449155601863413768,
  },
  {
    screen_name: "FamilyDogecoin",
    name: "The Dogecoin Family",
    id_str: 1371017905350000640,
  },
  {
    screen_name: "kunalcrypto",
    name: "Kunalcrypto",
    id_str: 1421841682966880256,
  },
  {
    screen_name: "tbdbitcoin",
    name: "The Bitcoin Daily",
    id_str: 1595665706128809984,
  },
  {
    screen_name: "Galactic_Mining",
    name: "Galactic Mining Club\ud83c\udff4\u200d\u2620\ufe0f\ud83e\udee1",
    id_str: 1490812048749563911,
  },
  {
    screen_name: "TezosCommons",
    name: "Tezos Commons",
    id_str: 943584854213402624,
  },
  { screen_name: "wescottcrypto", name: "Wescott Crypto", id_str: 2916750329 },
  {
    screen_name: "CryptoKid1981",
    name: "CryptoKid",
    id_str: 1439093414604296198,
  },
  {
    screen_name: "solanapoet",
    name: "degen poet",
    id_str: 1464400811300212736,
  },
  { screen_name: "BitcoinNL", name: "GM.SATS", id_str: 1347440678171783174 },
  {
    screen_name: "Cryptoprofeta1",
    name: "CryptoProfeta\ud83c\udde9\ud83c\uddf4 \ud83d\udfe3",
    id_str: 1385160988178190339,
  },
  {
    screen_name: "CGTNSportsScene",
    name: "CGTN Sports Scene",
    id_str: 866503271602679811,
  },
  {
    screen_name: "CryptoNaija042",
    name: "CryptoNaija042, ACA\ud83e\udea2",
    id_str: 855440868,
  },
  {
    screen_name: "DrEvilofCrypto",
    name: "Dr. Evil of Crypto\u24b7",
    id_str: 832070321679265798,
  },
  {
    screen_name: "BoldBitcoin",
    name: "Bold Bitcoin",
    id_str: 1504324882296975362,
  },
  {
    screen_name: "UniswapVillain",
    name: "\ud83d\udc40",
    id_str: 1301203725416103943,
  },
  { screen_name: "NFT_M0D", name: "Solcet", id_str: 150119736 },
  {
    screen_name: "NFTdavie",
    name: "davie satoshi",
    id_str: 1466580569135923209,
  },
  {
    screen_name: "cryptodailyTS",
    name: "Crypto Daily Trade Signals",
    id_str: 884334126685237249,
  },
  {
    screen_name: "HasMints",
    name: "HasMints \ud83d\udca6",
    id_str: 1471568844418338816,
  },
  {
    screen_name: "Cryptographur",
    name: "Jim, S\ud802\udd0ai \u20bfum \u26f7\ufe0f",
    id_str: 1265745017089515521,
  },
  {
    screen_name: "12Neonlit_stage",
    name: "\ud83d\udcbfNEO\u0418//:",
    id_str: 1230525095300542465,
  },
  {
    screen_name: "CryptoWavesApp",
    name: "CryptoWaves.App \ud83d\udea8 RSI Crypto Alerts",
    id_str: 1395921021962653697,
  },
  {
    screen_name: "bestcryptotoken",
    name: "CryptogemsX\u26a1\ufe0f\ud83c\uddf5\ud83c\uddf9",
    id_str: 1463036754689146881,
  },
  {
    screen_name: "BringMeCoins",
    name: "Bring Me!!",
    id_str: 1551880257153777664,
  },
  { screen_name: "CryptoConan", name: "Conan", id_str: 1472352611797979140 },
  { screen_name: "CryptoLollla", name: "Lola", id_str: 1355733274660753410 },
  {
    screen_name: "coinpapercom",
    name: "Coinpaper",
    id_str: 1500971513687724032,
  },
  {
    screen_name: "Laura__crypto",
    name: "L\u03bbURA-V\u039eRS\u039e",
    id_str: 1393512565712592897,
  },
  {
    screen_name: "cryptoo_alice",
    name: "CRYPTO ALICE",
    id_str: 1500756146642354177,
  },
  {
    screen_name: "acoindetective",
    name: "The Coin Detective",
    id_str: 1211260728822206464,
  },
  {
    screen_name: "CryptoGemRnld",
    name: "Crypto.Ronald",
    id_str: 1606188311001051136,
  },
  {
    screen_name: "LevisNFT",
    name: "Levis\ud83d\udc8e",
    id_str: 1254370661755166720,
  },
  {
    screen_name: "BezosCrypto",
    name: "SHIB Bezos",
    id_str: 1105864595590148097,
  },
  {
    screen_name: "top100token",
    name: "Top100Token",
    id_str: 1449803118909267977,
  },
  { screen_name: "LilMoonLambo", name: "LilMoonLambo", id_str: 2593497397 },
  { screen_name: "SHADOW_NFT15", name: "SHADOW", id_str: 1518688680541659138 },
  { screen_name: "MrManXRP", name: "Mr. Man", id_str: 1515337408501280769 },
  {
    screen_name: "novocrypto",
    name: "Dr. Novo \ud83c\udf4c",
    id_str: 1351440204410867712,
  },
  {
    screen_name: "Kucoinmaster777",
    name: "Bitcoin Niazi",
    id_str: 914353604659699712,
  },
  {
    screen_name: "bitcoinmalaya",
    name: "Bitcoin Malaya",
    id_str: 1427823471115968515,
  },
  {
    screen_name: "Solana_allday",
    name: "Solana allday",
    id_str: 1638748044173926402,
  },
  {
    screen_name: "cryptotitans11",
    name: "The Crypto Titan's",
    id_str: 1492408037297983493,
  },
  {
    screen_name: "DailyCryptoTrad",
    name: "Daily Crypto Trading",
    id_str: 1021196623433650176,
  },
  {
    screen_name: "AllFactsCrypto",
    name: "All Facts Crypto",
    id_str: 1629822280111992833,
  },
  {
    screen_name: "CryptoNewsYes",
    name: "Crypto News Alerts \ud83d\udd25\ud83c\udf99",
    id_str: 1172632615393865730,
  },
  {
    screen_name: "InjectiveQuants",
    name: "Quants",
    id_str: 1672027139233685504,
  },
  {
    screen_name: "CryptozQuamfy",
    name: "Cryptoz\ud83c\uddf2\ud83c\udde6",
    id_str: 1382506773702180867,
  },
  {
    screen_name: "RealCryptoAngel",
    name: "Archangel",
    id_str: 1407872242776674304,
  },
  {
    screen_name: "CryptoBullGod",
    name: "Crypto \u20bfULL God",
    id_str: 999092955448266753,
  },
  {
    screen_name: "tonythebullBTC",
    name: 'Tony "The Bull" Severino, CMT',
    id_str: 1142564614925930501,
  },
  {
    screen_name: "CryptoArmy555",
    name: "Infinite | Shibarium \u26d3\ufe0f",
    id_str: 1403364609336152065,
  },
  {
    screen_name: "BecauseBitcoin",
    name: "BecauseBitcoin.com",
    id_str: 1526418619353255941,
  },
  {
    screen_name: "ArbHubETH",
    name: "Arbitrum Hub",
    id_str: 1444978810915414024,
  },
  {
    screen_name: "AltcoinMiyagi",
    name: "Altcoin Miyagi\ud83e\udd16",
    id_str: 1545410205474775047,
  },
  {
    screen_name: "CryptoGeekNews",
    name: "CryptoGeek",
    id_str: 1379694268541964288,
  },
  {
    screen_name: "cryptoknight890",
    name: "KNIGHT $INJ TO 100$",
    id_str: 1303456551068459010,
  },
  {
    screen_name: "BrawnCrypto_eth",
    name: "BrawnCrypt \ud83d\udfe3",
    id_str: 1384703869461086209,
  },
  {
    screen_name: "CryptoHulk19",
    name: "Crypto Hulk",
    id_str: 1441259450183008258,
  },
  {
    screen_name: "CRYPTOPALACE_",
    name: "The CryptoPalace\ud83d\udd36\ud83e\udd4f",
    id_str: 554175470,
  },
  {
    screen_name: "TheBTTCommunity",
    name: "BitTorrent Community",
    id_str: 1144179802917789696,
  },
  {
    screen_name: "CryptoAnu_",
    name: "Crypto.Anu\ud83c\udf10\ud83e\udeac",
    id_str: 1546613469310492673,
  },
  {
    screen_name: "BitcoinEkasi",
    name: "Bitcoin Ekasi",
    id_str: 1460159787853926405,
  },
  {
    screen_name: "CryptoShillz06",
    name: "Crypto Pump Master",
    id_str: 3757772717,
  },
  {
    screen_name: "coinotagen",
    name: "COINOTAG NEWS",
    id_str: 1587433994496401408,
  },
  {
    screen_name: "CryptoCPriest",
    name: "Crypto Chiefpriest \u26a1",
    id_str: 1461350882231021572,
  },
  { screen_name: "Ajwritescrypto", name: "Aj", id_str: 1085370401717252096 },
  {
    screen_name: "MarzellCrypto",
    name: "Marzell",
    id_str: 1251249987230609409,
  },
  {
    screen_name: "GalaxyTrading_",
    name: "GalaxyTrading",
    id_str: 937433163835625475,
  },
  {
    screen_name: "snek",
    name: "Snek \ud83d\udc0d",
    id_str: 1651081550971252737,
  },
  {
    screen_name: "CoingeckoLineup",
    name: "\ud83e\udd8e Coingecko_Lineup",
    id_str: 1616000764723314688,
  },
  {
    screen_name: "Cryptoinsightuk",
    name: "Cryptoinsightuk",
    id_str: 1645095804,
  },
  {
    screen_name: "misterrcrypto",
    name: "Mister Crypto",
    id_str: 1371883902265069569,
  },
  {
    screen_name: "CryptoJobs",
    name: "cryptojobs.com",
    id_str: 1454023127151742977,
  },
  {
    screen_name: "BarHBARian",
    name: "Coinman the HBARbarian",
    id_str: 1161363858113667073,
  },
  {
    screen_name: "TheCompoundNews",
    name: "The Compound",
    id_str: 982334375357382657,
  },
  {
    screen_name: "HashBastardsNFT",
    name: "HashBastards",
    id_str: 1376261172295561220,
  },
  {
    screen_name: "DaCryptoPirates",
    name: "Crypto Pirates \ud83c\udff4\u200d\u2620\ufe0f",
    id_str: 1120271166713651200,
  },
  {
    screen_name: "Kucoin_Gems",
    name: "Kucoin Gem\ud83d\udc8e Signals",
    id_str: 1460671846055960576,
  },
  {
    screen_name: "lordcrypto__",
    name: "Lord Crypto",
    id_str: 1429727743080550402,
  },
  {
    screen_name: "SimplyBitcoinTV",
    name: "Simply Bitcoin",
    id_str: 1286277333712809989,
  },
  { screen_name: "TheCryptoKazi", name: "Kazi", id_str: 1431632200018112522 },
  { screen_name: "jtraderco", name: "JTrader", id_str: 2192456106 },
  { screen_name: "Trader4lyf", name: "TradersApprentice", id_str: 1572616332 },
  {
    screen_name: "queencryptooo",
    name: "Zoe\ud83d\udd36",
    id_str: 1357395816193916929,
  },
  {
    screen_name: "Livetradingnews",
    name: "LTN \ud83d\udc1d",
    id_str: 28803148,
  },
  {
    screen_name: "CosmosEcosystem",
    name: "\u269b\ufe0f Cosmos Ecosystem \u269b\ufe0f",
    id_str: 1334653878940393477,
  },
  {
    screen_name: "AstroCryptoGuru",
    name: "Crypto Damus",
    id_str: 952978915101831168,
  },
  { screen_name: "quant_arb", name: "Stat Arb", id_str: 1199145208895725573 },
  { screen_name: "CryptoThannos", name: "Crypto Thanos", id_str: 82230563 },
  { screen_name: "casper_smc", name: "Casper", id_str: 1361444029511000066 },
  {
    screen_name: "nulltxnews",
    name: "Null TX \ud83d\udd77",
    id_str: 2509810398,
  },
  { screen_name: "BitmonkCrypto", name: "BitmonkCrypto", id_str: 2862211985 },
  {
    screen_name: "MikybullCrypto",
    name: "Mikybull \ud83d\udc02Crypto",
    id_str: 1396750899452817409,
  },
  {
    screen_name: "DoopieCash",
    name: "DoopieCash\u00ae",
    id_str: 984731455942221825,
  },
  {
    screen_name: "Polygon_Space1",
    name: "\ud83d\udfe3 \ud835\udc0f\ud835\udc28\ud835\udc25\ud835\udc32\ud835\udc20\ud835\udc28\ud835\udc27 \ud835\udc12\ud835\udc29\ud835\udc1a\ud835\udc1c\ud835\udc1e \ud83d\udfe3",
    id_str: 1405501541763584002,
  },
  {
    screen_name: "CKJCryptonews",
    name: "CKJcryptoNews",
    id_str: 935723010744553472,
  },
  {
    screen_name: "theNFTjosh",
    name: "\ud83c\udd5exjoshcrypto || TOKEN 2049",
    id_str: 1285329127982211074,
  },
  {
    screen_name: "Cosmos_Airdrops",
    name: "Cosmos Airdrops \ud83e\ude82",
    id_str: 1445457674305347584,
  },
  {
    screen_name: "BTCGandalf",
    name: "Daniel Sempere Pico",
    id_str: 1359483955695681538,
  },
  {
    screen_name: "Teaching_Crypto",
    name: "Nite\u26a1",
    id_str: 891170454614003713,
  },
  {
    screen_name: "CryptosBatman",
    name: "BATMAN \u26a1",
    id_str: 918957305571528705,
  },
  { screen_name: "coinsph", name: "Coins.ph", id_str: 2323183034 },
  {
    screen_name: "CryptoTeluguO",
    name: "CryptoTelugu",
    id_str: 1264138602159669248,
  },
  {
    screen_name: "TheCoinMonitor_",
    name: "TheCoinMonitor",
    id_str: 904191421158010880,
  },
  {
    screen_name: "cryptobrass",
    name: "Mr Brass \ud83d\udd36",
    id_str: 179466197,
  },
  {
    screen_name: "CryptoRResearch",
    name: "CryptoResearch",
    id_str: 968045148163604480,
  },
  {
    screen_name: "CryptoGirlNova",
    name: "Crypto Nova",
    id_str: 1506634982265135116,
  },
  {
    screen_name: "100xAltcoinGems",
    name: "100x Altcoin Gems",
    id_str: 1517796456949817347,
  },
  { screen_name: "_WOOFi", name: "WOOFi", id_str: 1633752530734706693 },
  { screen_name: "cryptotrader85", name: "Vella", id_str: 719407371928211458 },
  {
    screen_name: "CryptoSays",
    name: "Joe Mitoshi \ud83e\uddd1\u200d\ud83d\ude80",
    id_str: 879546046430552064,
  },
  { screen_name: "HoustonDash", name: "Houston Dash", id_str: 2208252295 },
  {
    screen_name: "SpiderCrypto0x",
    name: "SpiderCrypto \ud83e\uddd1\u200d\ud83c\udf73| token2049",
    id_str: 1295766451530293249,
  },
  {
    screen_name: "CoinTrendz",
    name: "CoinTrendz.com",
    id_str: 734390196771381248,
  },
  { screen_name: "TraderLion_", name: "TraderLion", id_str: 1485052376 },
  {
    screen_name: "CenkCrypto",
    name: "C \u039e N K\u26a1\ufe0f",
    id_str: 2262884168,
  },
  {
    screen_name: "JUST_NFTVERSE",
    name: "JUST | NFT PROMOTER",
    id_str: 1467572703112712197,
  },
  {
    screen_name: "lotta_NFT",
    name: "Lotta | NFT PROMOTER",
    id_str: 1479920843232849924,
  },
  {
    screen_name: "DrProfitCrypto",
    name: "Doctor Profit \ud83c\udde8\ud83c\udded",
    id_str: 1275260909896769538,
  },
  { screen_name: "hedera", name: "Hedera", id_str: 898917341051965441 },
  { screen_name: "Stacks", name: "stacks.btc", id_str: 3285797414 },
  { screen_name: "RenallaNFT", name: "Renalla", id_str: 1550897681480011777 },
  { screen_name: "SuiNetwork", name: "Sui", id_str: 1428872926363754497 },
  { screen_name: "token2049", name: "TOKEN2049", id_str: 933955400986009600 },
  {
    screen_name: "CertiKCommunity",
    name: "CertiK Skynet Rating",
    id_str: 1232319080637616128,
  },
  {
    screen_name: "coreycosta123",
    name: "Corey Costa\u2019s Crypto Coins",
    id_str: 1411717537,
  },
  {
    screen_name: "cryptofernsby",
    name: "Blockchain Fernsby",
    id_str: 1325069524169871360,
  },
  {
    screen_name: "__SeriousGemini",
    name: "serious gemini",
    id_str: 1395573420809621517,
  },
  { screen_name: "rom1_pellerin", name: "Romain Pellerin", id_str: 118804749 },
  { screen_name: "gaborgurbacs", name: "Gabor Gurbacs", id_str: 2809989583 },
  {
    screen_name: "AdamEShelton",
    name: "Adam Shelton (Captain)",
    id_str: 976932744818290689,
  },
  {
    screen_name: "trycarbonio",
    name: "Carbon Browser",
    id_str: 1555156123514281984,
  },
  {
    screen_name: "CoinRabbitLoans",
    name: "CoinRabbit",
    id_str: 1331192609956655104,
  },
  {
    screen_name: "intangiblecoins",
    name: "Alex Thorn",
    id_str: 895400381852811266,
  },
  {
    screen_name: "NOWNodes",
    name: "NOWNodes \u26d3\ufe0f",
    id_str: 1128605469881380866,
  },
  { screen_name: "AlexFinnX", name: "Alex Finn", id_str: 1369348853414178822 },
  {
    screen_name: "Cryptolaxy",
    name: "Cryptolaxy #StandWithUkraine",
    id_str: 1383688591512006657,
  },
  {
    screen_name: "udiWertheimer",
    name: "Udi | BIP-420 \ud83d\udc31",
    id_str: 14527699,
  },
  {
    screen_name: "kevinolearytv",
    name: "Kevin O'Leary aka Mr. Wonderful",
    id_str: 32002507,
  },
  {
    screen_name: "GCryptoBen",
    name: "Ben GCrypto",
    id_str: 1365654402334400512,
  },
  { screen_name: "AshleyDCan", name: "Ashley", id_str: 2872584449 },
  { screen_name: "dunleavy89", name: "Tom Dunleavy", id_str: 26693708 },
  { screen_name: "adamKDean", name: "Adam Dean", id_str: 376887627 },
  {
    screen_name: "Aleph__Zero",
    name: "Aleph Zero",
    id_str: 1013151066299478016,
  },
  { screen_name: "Dezaxe", name: "DEZAXE", id_str: 51045770 },
  { screen_name: "Gemini", name: "Gemini", id_str: 2815661158 },
  {
    screen_name: "CryptoCrunchApp",
    name: "CryptoCrunchApp",
    id_str: 4649374704,
  },
  { screen_name: "BitGo", name: "BitGo", id_str: 2294954370 },
  {
    screen_name: "KeyboardMonkey3",
    name: "Keyboard Monkey -KBM-",
    id_str: 1346599984528023554,
  },
  {
    screen_name: "TheBitcoinConf",
    name: "The Bitcoin Conference",
    id_str: 1069702634960052224,
  },
  {
    screen_name: "MyDogeOfficial",
    name: "MyDoge",
    id_str: 1448728157222162438,
  },
  {
    screen_name: "missteencrypto",
    name: "Randi Hipper",
    id_str: 1253392898638450688,
  },
  {
    screen_name: "QuickswapDEX",
    name: "QuickSwap \ud83d\udc32 DragonFi 2.0",
    id_str: 1311611340767793154,
  },
  {
    screen_name: "jerallaire",
    name: "Jeremy Allaire - jda.eth / jdallaire.sol",
    id_str: 2478756618,
  },
  {
    screen_name: "DripDropz_io",
    name: "\ud83d\udca7DripDropz",
    id_str: 1462139155002048513,
  },
  { screen_name: "fintechfrank", name: "Frank Chaparro", id_str: 2933805082 },
  { screen_name: "prestonjbyrne", name: "Preston Byrne", id_str: 151936663 },
  { screen_name: "exodus_io", name: "Exodus", id_str: 755365986757337088 },
  {
    screen_name: "leshka_eth",
    name: "Leshka.eth \u26e9",
    id_str: 752114515060330496,
  },
  {
    screen_name: "TheCryptoExpres",
    name: "The Crypto Express",
    id_str: 894191480834674688,
  },
  { screen_name: "deezy_BTC", name: "Deezy.eth", id_str: 66809779 },
  { screen_name: "NeerajKA", name: "Neeraj K. Agrawal", id_str: 14710235 },
  {
    screen_name: "cryptojack",
    name: "CryptoJack",
    id_str: 1415997802321256453,
  },
  {
    screen_name: "spencernoon",
    name: "Spencer Noon \ud83d\udd5b",
    id_str: 1527806202,
  },
  {
    screen_name: "officer_cia",
    name: "Vladimir S. | Officer's Notes",
    id_str: 1399447726874673153,
  },
  {
    screen_name: "CryptoCoinCoach",
    name: "CCC.io",
    id_str: 965730710186352640,
  },
  {
    screen_name: "jaydee_757",
    name: "JD \ud83c\uddf5\ud83c\udded",
    id_str: 944593203000500227,
  },
  {
    screen_name: "AlphaSeeker21",
    name: "Alpha Seeker",
    id_str: 985407854822617088,
  },
  {
    screen_name: "MrsolanaB",
    name: "S\u24c4L Boss \ud83e\udd76",
    id_str: 1559279793543303168,
  },
  { screen_name: "easyeight08", name: "BUTCHER", id_str: 881258555432632322 },
  { screen_name: "krugermacro", name: "Alex Kr\u00fcger", id_str: 534493783 },
  {
    screen_name: "Bitfinexed",
    name: "Bitfinex'ed \ud83d\udd25\ud83d\udc27 \u039a\u03b1\u03c3\u03c3\u03ac\u03bd\u03b4\u03c1\u03b1 \ud83c\udffa",
    id_str: 851583986270957568,
  },
  {
    screen_name: "Crouserrr",
    name: "Digi\ud83d\udc51 (Bali arc)",
    id_str: 1463423174947659786,
  },
  {
    screen_name: "JacobCanfield",
    name: "Jacob Canfield",
    id_str: 933567617549455360,
  },
  {
    screen_name: "cwpaulm",
    name: "P\u20b3ul \ud83c\uddee\ud83c\uddea",
    id_str: 877903433117859840,
  },
  { screen_name: "Immutable", name: "Immutable", id_str: 1233171399872638976 },
  {
    screen_name: "MrSolanaGod",
    name: "S\u24c4L GOD \u261d\ufe0f",
    id_str: 1597943731772362752,
  },
  {
    screen_name: "corechaincrypto",
    name: "Core (SatoshiPlus) #BTC, #ETH & #BNB Believers",
    id_str: 1486626706026807296,
  },
  { screen_name: "Crypto_Chase", name: "Crypto Chase", id_str: 236066949 },
  {
    screen_name: "cryptocandy24x",
    name: "Crypto Candy\ud83d\udd25\ud83d\udc8e",
    id_str: 925300618662518786,
  },
  {
    screen_name: "MEonBTC",
    name: "Magic Eden on Bitcoin \ud83d\udfe7",
    id_str: 1636717234054656000,
  },
  {
    screen_name: "AnastasiaNFTart",
    name: "Anastasia NFTart | SuperRare \ud83d\udc8e | NFT.NYC",
    id_str: 1495625777605488642,
  },
  {
    screen_name: "dr_crypto_calls",
    name: "Dr. Crypto",
    id_str: 1458819981521657859,
  },
  { screen_name: "Roman_Trading", name: "Roman", id_str: 1377672959041679367 },
  { screen_name: "SHIB_HEAD", name: "SHIB_HEAD", id_str: 22844878 },
  { screen_name: "Crypto_Scient", name: "Scient", id_str: 912355980821782529 },
  {
    screen_name: "analyse_crypto1",
    name: "Analyse Crypto",
    id_str: 1289122274616864773,
  },
  {
    screen_name: "algorandcasino",
    name: "AlgorandCasino",
    id_str: 1455473908715511808,
  },
  {
    screen_name: "weldingcrypto",
    name: "nieko.sol",
    id_str: 1399498979667238914,
  },
  {
    screen_name: "TSDR_Trading",
    name: "TSDR Trading",
    id_str: 1428534232108961794,
  },
  { screen_name: "coincarpcom", name: "CoinCarp", id_str: 893123368806785025 },
  { screen_name: "_WOO_X", name: "WOO X", id_str: 1007035113065144320 },
  {
    screen_name: "TheCryptoSniper",
    name: "TheCryptoSniper",
    id_str: 870786652532338688,
  },
  {
    screen_name: "BaseDailyTK",
    name: "Base Daily",
    id_str: 1437999786565636099,
  },
  {
    screen_name: "AltCryptoTalk",
    name: "AltCryptoTalk",
    id_str: 1360155409856798720,
  },
  {
    screen_name: "NFTGalIery",
    name: "NFTGallery",
    id_str: 1394407358487478276,
  },
  {
    screen_name: "StockTraderHub",
    name: "The Stock Trader Hub",
    id_str: 1345093906527100929,
  },
  {
    screen_name: "InspoCrypto",
    name: "InspoCrypto",
    id_str: 755731653910200320,
  },
  { screen_name: "tokenfox1", name: "Tokenfox", id_str: 1371002622463852544 },
  { screen_name: "joinFIO", name: "FIO Protocol", id_str: 1050573550791225344 },
  { screen_name: "FMCPay", name: "FMCPAY", id_str: 1428222316878524417 },
  {
    screen_name: "HumbleBitcoiner",
    name: "Hum\u20bfle Warrior \ud83c\uddfa\ud83c\uddf8\u2694\ufe0f\ud83c\udf0f",
    id_str: 1355090221310869504,
  },
  { screen_name: "kadena_io", name: "Kadena", id_str: 772152516893110272 },
  { screen_name: "peterktodd", name: "Peter Todd", id_str: 1471313300 },
  {
    screen_name: "Parafinas1",
    name: "NunoCostaPT.eth \ud83c\uddf5\ud83c\uddf9\ud83d\ude80\ud83c\udf15| NFT.NYC 2023/24 Artist",
    id_str: 1390028697567105025,
  },
  { screen_name: "udao_official", name: "udao", id_str: 1637855803771084817 },
  {
    screen_name: "cryptomorgz",
    name: "Morgzy \ud83c\udf19",
    id_str: 1335658182207803401,
  },
  {
    screen_name: "BreakingWhale",
    name: "Breaking Whale",
    id_str: 1638888557988249603,
  },
  {
    screen_name: "SatsDoji",
    name: "Doji \ud83e\udde0",
    id_str: 1348423200691146752,
  },
  {
    screen_name: "ChainlinkMongol",
    name: "\ud83d\udd30Linkie",
    id_str: 1622639548709601281,
  },
  {
    screen_name: "RippleDarth",
    name: "Darth@Ripple",
    id_str: 949986515295391744,
  },
  { screen_name: "JohalMiles", name: "Miles", id_str: 1369966796573401095 },
  {
    screen_name: "umbrella_uni",
    name: "Umbrella",
    id_str: 1495917535354626048,
  },
  { screen_name: "WhalePanda", name: "WhalePanda", id_str: 2799211554 },
  {
    screen_name: "Malik_Farooq_01",
    name: "Alpha Altfolio \ud83d\ude80",
    id_str: 950785022507839489,
  },
  { screen_name: "CryptoMarkETH", name: "Mark", id_str: 1391733916520697863 },
  {
    screen_name: "blockchainedbb",
    name: "Blockchainedbb",
    id_str: 988719122912628736,
  },
  { screen_name: "pushpendrakum", name: "Pushpendra Singh", id_str: 138073453 },
  {
    screen_name: "BenArmstrongsX",
    name: "BitBoy",
    id_str: 1699124734670028800,
  },
  { screen_name: "GVRCALLS", name: "Crypto GVR", id_str: 1480570589613944832 },
  {
    screen_name: "MarketCoinpedia",
    name: "Coinpedia Markets",
    id_str: 1621096802903818241,
  },
  {
    screen_name: "gnarleysol",
    name: "gnarleyquinn",
    id_str: 1671701550107070464,
  },
  { screen_name: "_CryptoSurf", name: "Surf", id_str: 1257247504258797569 },
  {
    screen_name: "sayinshallah",
    name: "jawz \ud83d\udc41\ufe0f",
    id_str: 1350330558241529858,
  },
  {
    screen_name: "CryptoNightNL",
    name: "Cryp\u03c4onigh\u03c4",
    id_str: 1377780669770764291,
  },
  { screen_name: "GemsofRa", name: "Gems of Ra", id_str: 1373992568132935686 },
  { screen_name: "GaryCardone", name: "Gary Cardone", id_str: 36730901 },
  {
    screen_name: "thedefivillain",
    name: "VIKTOR",
    id_str: 1522870521414787072,
  },
  {
    screen_name: "AltstreetBet",
    name: "\u1d00\u029f\u1d1b\ua731\u1d1b\u0280\u1d07\u1d07\u1d1b \u0299\u1d07\u1d1b\ua731",
    id_str: 998163714640576512,
  },
  {
    screen_name: "AbsGMCrypto",
    name: "Good Morning Crypto",
    id_str: 1424088459627671555,
  },
  {
    screen_name: "AbsGMCrypto",
    name: "Good Morning Crypto",
    id_str: 1424088459627671555,
  },
  {
    screen_name: "kyle_chasse",
    name: "Kyle Chass\u00e9 / DD\ud83d\udc38",
    id_str: 1187664208160124930,
  },
  {
    screen_name: "LisaNEdwards",
    name: "\u2763\ufe0fLisa N Edwards\u2763\ufe0f",
    id_str: 208924932,
  },
  {
    screen_name: "valeriyaApex",
    name: "Valeriya",
    id_str: 1500222062887153665,
  },
  {
    screen_name: "TrendSpider",
    name: "TrendSpider",
    id_str: 969571031030616071,
  },
  { screen_name: "EmperorBTC", name: "Emperor\ud83d\udc51", id_str: 183951857 },
  {
    screen_name: "RealClelland",
    name: "@RealClelland",
    id_str: 1081614905025818625,
  },
  { screen_name: "JonnyMoeTrades", name: "Jonny Moe", id_str: 611151405 },
  { screen_name: "trader_hamza", name: "hamza", id_str: 1415658722039369728 },
  {
    screen_name: "CassiusCuvee",
    name: "Cassius Cuv\u00e9e \ud83d\udc8e\ud83d\ude4c\ud83c\udffd",
    id_str: 1218640093490974720,
  },
  { screen_name: "Margexcom", name: "Margex", id_str: 1148202567857557505 },
  { screen_name: "astekz", name: "Astekz", id_str: 1330137571960172545 },
  {
    screen_name: "top7ico",
    name: "TOP 7 ICO | #StandWithUkraine\ud83c\uddfa\ud83c\udde6",
    id_str: 945716524836409345,
  },
  { screen_name: "exitpumpBTC", name: "exitpump", id_str: 1373615088532328449 },
  { screen_name: "SBCHNEWS1", name: "BCHNEWS", id_str: 1459860967991529477 },
  {
    screen_name: "CryptoDinduz",
    name: "CryptoDinduz\ud83d\udc7b",
    id_str: 2752145503,
  },
  { screen_name: "FILLiquid", name: "FILLiquid", id_str: 1612655895519039488 },
  {
    screen_name: "cryptosenseiXBT",
    name: "Crypto Sensei",
    id_str: 1531311544482508801,
  },
  {
    screen_name: "CryptoMoonMag",
    name: "The Moon Mag",
    id_str: 1538691077473185793,
  },
  { screen_name: "ZeusCrypto_", name: "Zeus", id_str: 1292566836098236419 },
  {
    screen_name: "crypto_bitlord7",
    name: "Crypto Bitlord",
    id_str: 1343224146658914304,
  },
  { screen_name: "CryptoSaheb", name: "Saheb", id_str: 1461248283238535170 },
  {
    screen_name: "CryptoNinja4444",
    name: "Crypto Ninja",
    id_str: 1533480052708737024,
  },
  {
    screen_name: "Polygonians_",
    name: "\ud83c\uddfa\ud83c\udde6 Polygonians $MATIC - StandWithUkraine \ud83c\uddfa\ud83c\udde6",
    id_str: 1399738484525613062,
  },
  {
    screen_name: "tradernmx",
    name: "The Hogfather (hog/pre-rich)",
    id_str: 1084230793885560833,
  },
  { screen_name: "OttoSuwenNFT", name: "Otto Suwen", id_str: 1835943590 },
  {
    screen_name: "SovrynBTC",
    name: "Sovryn | DeFi for Bitcoin",
    id_str: 1292771861512634368,
  },
  {
    screen_name: "CryptoNewsUpd8s",
    name: "Crypto News Updates",
    id_str: 1395444744449404936,
  },
  {
    screen_name: "TraderFlameseN",
    name: "FlameseN \u30a2\u30ca\u30ea\u30b9\u30c8 \u20bf",
    id_str: 1369590758521663489,
  },
  {
    screen_name: "MetaVixensNFT",
    name: "MetaVixens",
    id_str: 1489691581649203204,
  },
  {
    screen_name: "litecoin_bull",
    name: "\u0141itecoin Bull | The News Before The News!",
    id_str: 974435384271630336,
  },
  {
    screen_name: "CryptoJobsList",
    name: "Crypto Jobs List \u2014 DeFi NFT Web3 jobs",
    id_str: 913152549569544192,
  },
  {
    screen_name: "nugupromoter",
    name: "nugu promoter",
    id_str: 1260264067194896392,
  },
  {
    screen_name: "MoonRovingBTC",
    name: "MoonRoving NFTs \ud83d\udc7e\ud83e\uddd1\u200d\ud83d\ude80",
    id_str: 918454085342384129,
  },
  { screen_name: "Naem_Crypto786", name: "NaemCrypto", id_str: 466479642 },
  {
    screen_name: "MinaWhale_Alert",
    name: "Mina Whale Alert \ud83e\udeb6",
    id_str: 1356208787183566849,
  },
  {
    screen_name: "cardanochica",
    name: "Cardano Chica",
    id_str: 1638538247004471296,
  },
  {
    screen_name: "TheTezos",
    name: "TheTezosCommunity",
    id_str: 1620872370851352593,
  },
  {
    screen_name: "CryptoSteveO1",
    name: "cryptosteveo.sol",
    id_str: 1372873852083179548,
  },
  {
    screen_name: "Cryptolusionist",
    name: "theCryptolusionist \ud83e\uddd9\ud83c\udffb",
    id_str: 1384604724138229765,
  },
  {
    screen_name: "GlobeOfcrypto1",
    name: "Globe Of Crypto",
    id_str: 1629516340468826112,
  },
  {
    screen_name: "ChrisOnCrypto1",
    name: "Chris on Crypto\u23eb\u0141\u20bf\u24c2\ufe0f\ud83d\udd78",
    id_str: 1222436314508931072,
  },
  {
    screen_name: "filecoinglobal",
    name: "Filecoinglobal",
    id_str: 1668319231711252480,
  },
  {
    screen_name: "UniverseCrypto7",
    name: "Universe of Crypto",
    id_str: 1388177193445085189,
  },
  {
    screen_name: "CryptoSpotter05",
    name: "Crypto Spotter",
    id_str: 1475079198515478532,
  },
  { screen_name: "SeedifyFund", name: "Seedify", id_str: 1345102860732788740 },
  {
    screen_name: "captainpepe0x69",
    name: "Captain Pepe \ud83d\udc38 (0x69)",
    id_str: 1448744763407147011,
  },
  {
    screen_name: "CryptoCappex",
    name: "\u1455\u15e9\u20b1\u20b1\u039eX \ud83c\udf33",
    id_str: 1065494450,
  },
  {
    screen_name: "EyeOnChain",
    name: "EyeOnChain",
    id_str: 1568899746177368065,
  },
  {
    screen_name: "MDCryptoWorld",
    name: "MDCrypto \u2694\ufe0f\ud83e\udd77",
    id_str: 1429389578264358914,
  },
  {
    screen_name: "CryptoBankerSHX",
    name: "CryptoBanker $SH\ud835\udd4f",
    id_str: 1665359582489714699,
  },
  { screen_name: "Jeremyybtc", name: "Jeremy", id_str: 1456163056829046785 },
  {
    screen_name: "NickNFTn",
    name: "Nick \ud83c\udfc6",
    id_str: 1492484547350564868,
  },
  {
    screen_name: "VegetaCrypto1",
    name: "V\u03a3G\u03a3\u01ac\u039b.TRX",
    id_str: 1205349086594912257,
  },
  { screen_name: "taegiveaway", name: "taeby", id_str: 1353847968919547904 },
  { screen_name: "TraderKoz", name: "TraderKoz", id_str: 1019660554472837120 },
  {
    screen_name: "JaysonCrypto",
    name: "Chanimal \ud83c\udf4c $FAME",
    id_str: 948780889051365376,
  },
  { screen_name: "ollibrocNFT", name: "paul", id_str: 1478792715651399685 },
  {
    screen_name: "gigi_crypto",
    name: "CryptoGigi",
    id_str: 1387480706662735880,
  },
  {
    screen_name: "CryptoniteUae",
    name: "CryptoniteUae",
    id_str: 1453729228805992466,
  },
  {
    screen_name: "CryptoDNA_",
    name: "@CryptoDNA \u2b55",
    id_str: 1440004985216016387,
  },
  {
    screen_name: "NCashOfficial",
    name: "Nick | Crypto Crusader",
    id_str: 1287210256595124226,
  },
  {
    screen_name: "MadDolphNFT",
    name: "Mad Dolph NFT \ud83d\udc2c\ud83c\udf0a| NFTNYC2024",
    id_str: 1508505917578436612,
  },
  {
    screen_name: "CryptoKipTweets",
    name: "Crypto Kip \ud83d\udc7b",
    id_str: 295462843,
  },
  { screen_name: "naija_bitcoin", name: "Ragnar", id_str: 1580541382783107081 },
  {
    screen_name: "cryptonian_0",
    name: "Mr.Bayne \u2615\ufe0f",
    id_str: 1427493072602947585,
  },
  { screen_name: "CryptoCobrex", name: "Crypto Cobrex", id_str: 3221048373 },
  {
    screen_name: "FEGtoken",
    name: "FEG (Feed Every Gorilla)",
    id_str: 1356371036133093378,
  },
  {
    screen_name: "TheDailyHodl",
    name: "The Daily Hodl",
    id_str: 925462796472594434,
  },
  {
    screen_name: "coinage_x_daic",
    name: "Coinage x DAIC",
    id_str: 1362475747559038978,
  },
  {
    screen_name: "IOHK_Charles",
    name: "Charles Hoskinson",
    id_str: 1376161898,
  },
  {
    screen_name: "WatcherGuru",
    name: "Watcher.Guru",
    id_str: 1387497871751196672,
  },
  {
    screen_name: "FamousFoxFed",
    name: "Famous Fox Federation \ud83e\udd8a\ud83d\udfe0",
    id_str: 1388972460456955907,
  },
  {
    screen_name: "Cryptofly777",
    name: "Linda - MALU Pool",
    id_str: 1355244769165926400,
  },
  { screen_name: "wojakcoineth", name: "Wojak", id_str: 1647749534980644864 },
  { screen_name: "garyvee", name: "Gary Vaynerchuk", id_str: 5768872 },
  {
    screen_name: "TraderJoe_xyz",
    name: "Let's F***ing Joe (prev. Trader Joe)",
    id_str: 1380341547154083844,
  },
  { screen_name: "cryptosmithcoco", name: "CoCo", id_str: 623346638 },
  {
    screen_name: "itsloganshippy",
    name: "logan shippy",
    id_str: 1388296321535078400,
  },
  {
    screen_name: "DaCryptoGeneral",
    name: "Crypto General \ud83e\udd84",
    id_str: 1295324707298967552,
  },
  {
    screen_name: "ConquererCrypto",
    name: "Conquerooor \u2694\ufe0f KA \ud83d\udc10",
    id_str: 72556398,
  },
  {
    screen_name: "DavidOnCrypto_",
    name: "\ud835\udc03\ud835\udc1a\ud835\udc2f\ud835\udc22\ud835\udc1d\ud835\udc0e\ud835\udc27\ud835\udc02\ud835\udc2b\ud835\udc32\ud835\udc29\ud835\udc2d\ud835\udc28 \ud83c\udc04\ufe0f",
    id_str: 890017919597514752,
  },
  { screen_name: "LucidCiC", name: "Lucid", id_str: 1092909247686369280 },
  { screen_name: "AcalaNetwork", name: "Acala", id_str: 1169145165531013120 },
  {
    screen_name: "xrpthephoenix",
    name: "XRPhoenix",
    id_str: 1292108467872948226,
  },
  {
    screen_name: "TaikiMaeda2",
    name: "Taiki Maeda",
    id_str: 1064421325186359296,
  },
  {
    screen_name: "JennaXCrypto",
    name: "\ud83c\udf1f J\u0359e\u0359n\u0359n\u0359a\u0359 X\u0359 \ud83c\udf1f",
    id_str: 1384140279846903811,
  },
  {
    screen_name: "OfficialTravlad",
    name: "Travladd Crypto \ud802\udd0a",
    id_str: 1103218563383549952,
  },
  { screen_name: "thecryptomonk", name: "The Crypto Monk", id_str: 576519811 },
  {
    screen_name: "iSafePal",
    name: "SafePal - Crypto Wallet",
    id_str: 1068417957686992896,
  },
  {
    screen_name: "TDogewhisperer",
    name: "Doge Whisperer \ud83d\udc15",
    id_str: 1364067948437061632,
  },
  { screen_name: "VABVOX", name: "Victoria Brownworth", id_str: 138168339 },
  {
    screen_name: "numbersprotocol",
    name: "Numbers Protocol @Token2049 \ud83c\uddf8\ud83c\uddec \u2708\ufe0f",
    id_str: 1095257716191199232,
  },
  { screen_name: "JuicyFruityyy", name: "Juicy", id_str: 1090456117 },
  {
    screen_name: "JohnEDeaton1",
    name: "John E Deaton",
    id_str: 1079853966681346048,
  },
  {
    screen_name: "metalisanft",
    name: "Prof Alex de MetaLisa \ud83c\udf40\ud83d\udca8\ud83c\udfa8",
    id_str: 1376105257168670720,
  },
  {
    screen_name: "XcademyOfficial",
    name: "XCAD Network",
    id_str: 1099758059289800709,
  },
  {
    screen_name: "jacqmelinek",
    name: "Jacquelyn Melinek",
    id_str: 1044207764212518912,
  },
  { screen_name: "investedintel", name: "LSP", id_str: 1090433406 },
  {
    screen_name: "necessarygrowth",
    name: "Naldo \ud83d\udc8e",
    id_str: 1137132440600944641,
  },
  {
    screen_name: "NOWPayments_io",
    name: "NOWPayments",
    id_str: 1132945518961807360,
  },
  { screen_name: "tweeterazzis", name: "CJ \ud83c\udf16", id_str: 1112714850 },
  {
    screen_name: "traderrocko",
    name: "WSB Trader Rocko \ud83d\ude80\ud83d\ude80\ud83d\ude80",
    id_str: 955920554325872640,
  },
  {
    screen_name: "MoonbeamNetwork",
    name: "Moonbeam Network",
    id_str: 1214659473123487744,
  },
  {
    screen_name: "FLOKIARMYKING",
    name: "\u2b50\ufe0fTOKENFIARMY\u2b50\ufe0f",
    id_str: 1345237743606124544,
  },
  {
    screen_name: "tokenterminal",
    name: "Token Terminal",
    id_str: 1204315977720221696,
  },
  {
    screen_name: "KongBTC",
    name: "Kong Trading \ud83e\udd8d",
    id_str: 983046985,
  },
  { screen_name: "BodaToken", name: "BODA", id_str: 1387041289086984203 },
  { screen_name: "prctiu", name: "Buna\u2661", id_str: 1053318427639574528 },
  {
    screen_name: "ShibaXanders",
    name: "Xanders Shib\ud83c\uddfb\ud83c\uddea",
    id_str: 1378458577329864707,
  },
  {
    screen_name: "AlchemyPay",
    name: "Alchemy Pay|$ACH: Fiat-Crypto Payment Gateway",
    id_str: 1019869949039448065,
  },
  { screen_name: "Pryzm_Zone", name: "Pryzm", id_str: 1410666834244902922 },
  { screen_name: "DelCrxpto", name: "Del Crxpto", id_str: 1388535915912499203 },
  {
    screen_name: "ShitcoinGemCall",
    name: "Shitcoin Gems Calls \ud83d\udc8e",
    id_str: 1030427133674283008,
  },
  {
    screen_name: "blocmatesdotcom",
    name: "blocmates.",
    id_str: 1425645020262977540,
  },
  {
    screen_name: "JupiterExchange",
    name: "Jupiter \ud83e\ude90",
    id_str: 1446489618208067586,
  },
  { screen_name: "Lydia78976035", name: "Lydia", id_str: 1274448659367821312 },
  { screen_name: "Bybit_Official", name: "Bybit", id_str: 999947328621395968 },
  {
    screen_name: "genie_trades",
    name: "The Genie \ud83e\uddde\u200d\u2642\ufe0f\ud83e\udd77",
    id_str: 1269842057704570881,
  },
  {
    screen_name: "FesCrypto",
    name: "FES \ud83d\udc02 \ud83e\udd77",
    id_str: 1226189980382289920,
  },
  {
    screen_name: "tradingtulips",
    name: "tradingtulips\ud83c\udf37",
    id_str: 1287633121823698944,
  },
  {
    screen_name: "ReySantoscrypto",
    name: "Rey Santos Crypto | #Moonwell",
    id_str: 1194689420701491202,
  },
  {
    screen_name: "SharksCoins",
    name: "SharksCoins \ud83e\udd88 Gem Hunter",
    id_str: 1568227771,
  },
  {
    screen_name: "Unique_NFTchain",
    name: "Unique Network",
    id_str: 1390238548733747202,
  },
  {
    screen_name: "ETH_Daily",
    name: "Ethereum Daily",
    id_str: 1390323555569524738,
  },
  {
    screen_name: "CryptoHitmann",
    name: "Nick Hellmann",
    id_str: 788832211768188928,
  },
  { screen_name: "RaoulGMI", name: "Raoul Pal", id_str: 2453385626 },
  {
    screen_name: "Crypto_Cobra_",
    name: "Crypto Cobra",
    id_str: 1431838589839568902,
  },
  {
    screen_name: "PrimeTrading_",
    name: "Alex Desjardins \ud83d\udee1\ufe0f",
    id_str: 248885697,
  },
  {
    screen_name: "Sheldon_Sniper",
    name: "Sheldon The Sniper",
    id_str: 1374629644884971521,
  },
  {
    screen_name: "RealADAWhale",
    name: "$realadawhale",
    id_str: 1503530518172037122,
  },
  {
    screen_name: "KaizenCrypto",
    name: "K\u20b3izen Crypto",
    id_str: 1150788729172312065,
  },
  { screen_name: "RobinhoodApp", name: "Robinhood", id_str: 1265037073 },
  {
    screen_name: "Jacktheoiler",
    name: "Jack The Oiler \ud83d\udca5",
    id_str: 1338026130452803584,
  },
  {
    screen_name: "EMPOWR_us",
    name: "Empower Oversight",
    id_str: 1384515038443155456,
  },
  {
    screen_name: "BCBacker",
    name: "Blockchain Backer",
    id_str: 1191416292454096896,
  },
  {
    screen_name: "Bitcoinhabebe",
    name: "BitcoinHabebe",
    id_str: 1354723908730179585,
  },
  { screen_name: "trustfiorg", name: "TrustFi", id_str: 1344633325609054208 },
  { screen_name: "Bitstamp", name: "Bitstamp", id_str: 352518189 },
  {
    screen_name: "CRYPTOFIED1",
    name: "CRYPTOFIED1",
    id_str: 1003400207357366272,
  },
  {
    screen_name: "Crypto_CBas",
    name: "CBas \ud83c\udf92",
    id_str: 1089418138104614912,
  },
  { screen_name: "SHIBGems", name: "SHIB Gems", id_str: 1202495979708145664 },
  {
    screen_name: "DannyCrypt",
    name: "DANNYCRYPT \ud83e\uddf8\u2694\ufe0f",
    id_str: 1379490605043216384,
  },
  { screen_name: "WClementeIII", name: "Will", id_str: 1270906326823186432 },
  { screen_name: "mammontrading", name: "Mammon", id_str: 961646148997058561 },
  { screen_name: "Route2FI", name: "Route 2 FI", id_str: 1084397942319054850 },
  { screen_name: "bigpeyYT", name: "big pey", id_str: 1238839241448263680 },
  {
    screen_name: "Bifrost",
    name: "Bifrost - Omnichain LST",
    id_str: 1154348832903200768,
  },
  { screen_name: "sof1azara03", name: "SOFIA CRYPTO", id_str: 4920946291 },
  {
    screen_name: "jobsincrypto",
    name: "Cryptocurrency Jobs",
    id_str: 919314949909958658,
  },
  {
    screen_name: "DaSheriffCrypto",
    name: "\ud835\udde6\ud835\udddb\ud835\uddd8\ud835\udde5\ud835\udddc\ud835\uddd9\ud835\uddd9 \ud83d\udcb5",
    id_str: 1390171411474436096,
  },
  {
    screen_name: "247fnpNFT",
    name: "\u269c\ufe0fBig Breaux\u269c\ufe0f",
    id_str: 1491409626608324610,
  },
  {
    screen_name: "__kingcrypto__",
    name: "King Crypto",
    id_str: 1488460070111178754,
  },
  {
    screen_name: "crypto_news13",
    name: "Crypto signals",
    id_str: 1480168635398115331,
  },
  {
    screen_name: "cryptosharkiee",
    name: "crypto_sharkiee",
    id_str: 1665925607035924480,
  },
  {
    screen_name: "crypto_nuclear",
    name: "Crypto Nuclear",
    id_str: 1587360984787722240,
  },
  {
    screen_name: "frankdegods",
    name: "Frank (degod mode)",
    id_str: 1441835930889818113,
  },
  {
    screen_name: "ColdBloodShill",
    name: "Cold Blooded Shiller",
    id_str: 987343085251317760,
  },
  {
    screen_name: "Crypto_Hawkk",
    name: "Crypto Hawk \ud83d\udc8e",
    id_str: 851102799899181056,
  },
  {
    screen_name: "Cryptomartinyy",
    name: "crypto_martinyy",
    id_str: 1514857335465775105,
  },
  { screen_name: "zackvoell", name: "Zack Voell", id_str: 2373682811 },
  {
    screen_name: "CryptoAceBull",
    name: "Crypto\ud835\udd38ce",
    id_str: 1338421511875567618,
  },
  {
    screen_name: "PelosiTracker_",
    name: "Nancy Pelosi Stock Tracker \u265f",
    id_str: 1540038673810350080,
  },
  { screen_name: "cryptrus", name: "Cryptrus", id_str: 1361230994762854403 },
  {
    screen_name: "lIllIlllIIIll",
    name: "Nebuchadnezzar",
    id_str: 1046326953815339009,
  },
  {
    screen_name: "sassal0x",
    name: "sassal.eth/acc \ud83e\udd87\ud83d\udd0a",
    id_str: 313724502,
  },
  {
    screen_name: "DogecoinRise",
    name: "Dogecoin Rise \ud83c\udf0e\ud83d\ude80\ud83c\udf15",
    id_str: 1345576584204353543,
  },
  {
    screen_name: "thewolfofdefi",
    name: "The Wolf of Defi \u26a1\ufe0f",
    id_str: 1347670633736507393,
  },
  {
    screen_name: "PoolShamrock",
    name: "P\u20b3DDY",
    id_str: 1335968311104966659,
  },
  {
    screen_name: "Shigeo808",
    name: "Ivan Shigeo\ud83c\udf16 \u2b55\ufe0f",
    id_str: 1242894196245008384,
  },
  {
    screen_name: "Next100XGEMS",
    name: "Next 100X GEMS",
    id_str: 1377575199692914691,
  },
  { screen_name: "ApeBond", name: "ApeBond", id_str: 1358495802386243585 },
  {
    screen_name: "VoltInuOfficial",
    name: "Volt Inu \u26a1\ufe0f",
    id_str: 1472580752080580613,
  },
  {
    screen_name: "cryptodungeonma",
    name: "Crypto Dungeon \u269b\ufe0f",
    id_str: 1539658081746960385,
  },
  {
    screen_name: "Cryptobilbuwoo0",
    name: "(X)=chi (R)esurrected (P)=rho",
    id_str: 952541688072843264,
  },
  {
    screen_name: "LetsGoCryptoooo",
    name: "Shaun.cro \ud83d\udc26\u200d\u2b1b\ud83d\udd2a",
    id_str: 480768104,
  },
  { screen_name: "cryptosile", name: "sile.sol", id_str: 1397114705353875457 },
  {
    screen_name: "LittleBaoNFT",
    name: "LittleBao | only 1 $POL",
    id_str: 1624995897888350209,
  },
  {
    screen_name: "PolkadotInsider",
    name: "Polkadot Insider",
    id_str: 1532020492320010241,
  },
  {
    screen_name: "ICPSwap",
    name: "ICPSwap #DFINITY",
    id_str: 1311374758039220224,
  },
  { screen_name: "haloETH", name: "halo", id_str: 3184661497 },
  { screen_name: "Bcubeai", name: "B-cube.ai", id_str: 1188039074961072128 },
  {
    screen_name: "LeatherBTC",
    name: "Leather \u2014 Bitcoin wallet for the rest of us",
    id_str: 1591032279710797825,
  },
  {
    screen_name: "coinglass_com",
    name: "CoinGlass",
    id_str: 1169095068164050945,
  },
  { screen_name: "noBScrypto", name: "Kyren", id_str: 1365055010531381251 },
  {
    screen_name: "XparrotsNFT",
    name: "XParrots / XRPL \ud83e\udd9c",
    id_str: 1471147506927034374,
  },
  { screen_name: "westcoastbill", name: "bill lee", id_str: 692023 },
  {
    screen_name: "CryptoAs_TW",
    name: "CRYPTO ASTRONAUT",
    id_str: 1514276588804001793,
  },
  {
    screen_name: "BitcoinSapiens",
    name: "BitcoinSapiens \u26a1\ufe0f",
    id_str: 1333823429041074181,
  },
  {
    screen_name: "StepFinance_",
    name: "Step Finance | Solana's Portfolio Dashboard",
    id_str: 1373225316177620993,
  },
  {
    screen_name: "ALEXLabBTC",
    name: "ALEX \ud83d\udfe7 THE Finance Layer on Bitcoin",
    id_str: 1403698101060313090,
  },
  {
    screen_name: "Ripple_Effect11",
    name: "Following_Smart_Money",
    id_str: 1709334673921601536,
  },
  {
    screen_name: "cryptowizardBTC",
    name: "Cryptowizard \u20bf \u26a1",
    id_str: 1188722859713138688,
  },
  {
    screen_name: "CryptoSavy1",
    name: "Crypto Savy \ud83d\udd25Craig \ud83d\udd25 Market Analysis NFA",
    id_str: 3428149301,
  },
  {
    screen_name: "zebucus24",
    name: "Zebucus Mooner \u20bf",
    id_str: 1431188911666118659,
  },
  {
    screen_name: "MoonLamboio",
    name: "Moon Lambo",
    id_str: 947673769694416896,
  },
  {
    screen_name: "CryptoChihiro",
    name: "Chihiro \u2640 Cryptoclass\u201817",
    id_str: 107637333,
  },
  {
    screen_name: "perryjp",
    name: "Perry - John Parker | Official",
    id_str: 160887563,
  },
  {
    screen_name: "ChainlinkP",
    name: "Chainlink Red Pill",
    id_str: 1079427478915969024,
  },
  {
    screen_name: "MindFlowWealth",
    name: "Mind Flow Wealth",
    id_str: 1548655813971025925,
  },
  {
    screen_name: "RoseXrp",
    name: "Rose\ud83c\udf39",
    id_str: 1104163376832397312,
  },
  {
    screen_name: "coinmerge",
    name: "CoinMerge.io",
    id_str: 1381704361441644547,
  },
  { screen_name: "etscrivner", name: "Eric Scrivner", id_str: 15996614 },
  {
    screen_name: "Crypto_Lailaa",
    name: "Laila \ud83e\udd84",
    id_str: 1575844229662003201,
  },
  { screen_name: "CryptoKing4Ever", name: "Crypto King", id_str: 384116520 },
  { screen_name: "Filecoin", name: "Filecoin", id_str: 2653394250 },
  {
    screen_name: "luigidemeo",
    name: "Luigi9000 \ud83d\udd3a",
    id_str: 131207665,
  },
  {
    screen_name: "arbitrum",
    name: "Arbitrum (\ud83d\udc99,\ud83e\udde1)",
    id_str: 1332033418088099843,
  },
  { screen_name: "0xMantle", name: "Mantle", id_str: 1592468786489212928 },
  {
    screen_name: "MattWallace888",
    name: "Matt Wallace",
    id_str: 805532293951606785,
  },
  {
    screen_name: "buitengebieden",
    name: "Buitengebieden",
    id_str: 1130021685351079938,
  },
  { screen_name: "RealFlokiInu", name: "FLOKI", id_str: 1408806420233916416 },
  {
    screen_name: "InternetH0F",
    name: "internet hall of fame",
    id_str: 1486473049146904576,
  },
  { screen_name: "solana", name: "Solana", id_str: 951329744804392960 },
  {
    screen_name: "PythonCryptoLTD",
    name: "Python - Your Crypto Plug",
    id_str: 1488927940687577090,
  },
  { screen_name: "cb_doge", name: "DogeDesigner", id_str: 1389913567671975937 },
  { screen_name: "BTCBruce1", name: "Bruce J", id_str: 1676484342821040129 },
  {
    screen_name: "TeslaModelDoge",
    name: "Tesla Model \u00d0oge",
    id_str: 1392162470035345409,
  },
  { screen_name: "Polkadot", name: "Polkadot", id_str: 1595615893 },
  {
    screen_name: "BabyDogeCoin",
    name: "Baby Doge",
    id_str: 1354781680943431681,
  },
  { screen_name: "ONUSFinance", name: "ONUS", id_str: 1159287904213983232 },
  {
    screen_name: "coinmarketprime",
    name: "CoinMarketPrime \ud83d\udef8",
    id_str: 1674554807078510599,
  },
  { screen_name: "Optimism", name: "Optimism", id_str: 1044836083530452992 },
  { screen_name: "Candao_io", name: "Candao.io", id_str: 1425402607867211779 },
  { screen_name: "BNBCHAIN", name: "BNB Chain", id_str: 1052454006537314306 },
  {
    screen_name: "MHiesboeck",
    name: "Dr Martin Hiesboeck",
    id_str: 4711717992,
  },
  { screen_name: "Aptos", name: "Aptos", id_str: 1491526425580474368 },
  { screen_name: "mynt_josh", name: "Josh", id_str: 263017181 },
  {
    screen_name: "Cryptolorian3",
    name: "Cryptolorian",
    id_str: 1300053959206412289,
  },
  {
    screen_name: "bnbHodlerX",
    name: "BNB Hodler",
    id_str: 1663542054486691841,
  },
  {
    screen_name: "SOLBigBrain",
    name: "S\u25ceL Big Brain",
    id_str: 1424905944857722887,
  },
  { screen_name: "b0bcrypto", name: "Crypto Bob", id_str: 1421119412191563777 },
  {
    screen_name: "NEARProtocol",
    name: "NEAR Protocol",
    id_str: 1031949518609121280,
  },
  { screen_name: "sherlock_hodles", name: "rwlk", id_str: 953465278561734656 },
  {
    screen_name: "GTEM",
    name: "GTEM Optimized Quant Pooling Advisory",
    id_str: 40482230,
  },
  { screen_name: "Cardano_CF", name: "Cardano Foundation", id_str: 4135644558 },
  { screen_name: "apecoin", name: "ApeCoin", id_str: 1489018530511175681 },
  { screen_name: "litecoin", name: "Litecoin", id_str: 385562752 },
  {
    screen_name: "Bami_crypto",
    name: "$Bami_gems \u2694\ufe0f\u2728",
    id_str: 1221015625528741890,
  },
  {
    screen_name: "CryptoArch1tect",
    name: "Crypto Architect\u3010Giveaway\u3011",
    id_str: 955190844,
  },
  {
    screen_name: "stablecoinada",
    name: "Stablecoin - $STABLE \ud83d\udc0e\ud83d\udca8",
    id_str: 1653402025168601095,
  },
  { screen_name: "phantom", name: "Phantom", id_str: 1379053041995890695 },
  {
    screen_name: "Cardano",
    name: "Cardano Community",
    id_str: 825920479055671296,
  },
  {
    screen_name: "jon_charb",
    name: "Jon Charbonneau",
    id_str: 1484537340412452868,
  },
  {
    screen_name: "JacoryCrypto",
    name: "Jacory (Binance.US Angel)",
    id_str: 162426673,
  },
  {
    screen_name: "WietseWind",
    name: "WietseWind (\ud83e\ude9d\ud83d\udee0 Xaman @XRPLLabs)",
    id_str: 184574549,
  },
  { screen_name: "sofizamolo", name: "Sofia Zamolo", id_str: 153243904 },
  {
    screen_name: "NakedTrader3",
    name: "NakedTrader",
    id_str: 1576211183287287813,
  },
  { screen_name: "NFTwap", name: "niftywap.eth", id_str: 1380097546987560965 },
  {
    screen_name: "avax",
    name: "Avalanche \ud83d\udd3a",
    id_str: 1055894724245155841,
  },
  { screen_name: "HGEABC", name: "HGE", id_str: 1507052909355221006 },
  {
    screen_name: "CurveFinance",
    name: "Curve Finance",
    id_str: 1214050967986946051,
  },
  { screen_name: "THORChain", name: "THORChain", id_str: 1012021170244161536 },
  {
    screen_name: "germa_shepherd",
    name: "German Shepherd",
    id_str: 1255561704345407489,
  },
  { screen_name: "bricurrently", name: "Bri", id_str: 79836596 },
  {
    screen_name: "AlephSuper",
    name: "\ud835\ude3e\ud835\ude5d\ud835\ude67\ud835\ude5e\ud835\ude68 AKA Super",
    id_str: 1405014243544600582,
  },
  { screen_name: "GevCrypto", name: "Gev Crypto", id_str: 1208177379346010112 },
  {
    screen_name: "elephant_crypto",
    name: "Crypto elephant",
    id_str: 1171463465925398530,
  },
  {
    screen_name: "VandelayBTC",
    name: "Vandelay \u20bfTC Industries \u26a1",
    id_str: 175960015,
  },
  {
    screen_name: "RuneCrypto_",
    name: "Rune \ud83d\udd2e",
    id_str: 1556788003779010560,
  },
  { screen_name: "BSVyouniverse", name: "BSV Universe", id_str: 29279893 },
  {
    screen_name: "SchwabToken",
    name: "Schwab Token",
    id_str: 1693699751764480000,
  },
  {
    screen_name: "LovePowerCoin",
    name: "LovePowerCoin",
    id_str: 1496574338321072131,
  },
  {
    screen_name: "tmv_crypto",
    name: "Tmv \ud83d\udcb0",
    id_str: 899937865584459776,
  },
  {
    screen_name: "theshibdream",
    name: "Shib Dream * Shib Army News * Shib Army Social \ud83d\udc8e",
    id_str: 1449246435472551936,
  },
  {
    screen_name: "MarkYusko",
    name: "Mark W. Yusko #TwoPointOneQuadrillion",
    id_str: 254280603,
  },
  {
    screen_name: "CardanoHumpback",
    name: "Cardano Hieronymus",
    id_str: 1359271575732826112,
  },
  { screen_name: "Jennycitalinda", name: "Jenny Brito", id_str: 26070638 },
  {
    screen_name: "jpgstoreNFT",
    name: "jpg.store",
    id_str: 1454574462473363459,
  },
  { screen_name: "trondao", name: "TRON DAO", id_str: 894231710065446912 },
  {
    screen_name: "PerpTrading",
    name: "Perpetual Trading",
    id_str: 733065915311509504,
  },
  { screen_name: "kingfud", name: "FUD", id_str: 556601889 },
  { screen_name: "BloopXNFT", name: "BLOOPX NFT", id_str: 1567359427820359680 },
  {
    screen_name: "BitcoinBerlinSV",
    name: "Bitcoin Berl\u00edn SV \ud83c\uddf8\ud83c\uddfb",
    id_str: 1666986266360008705,
  },
  { screen_name: "SongonSolana", name: "$SONG", id_str: 1394739837459177476 },
  {
    screen_name: "TA4CryptoBot",
    name: "Technical Analysis 4 Crypto",
    id_str: 1504417682048925702,
  },
  {
    screen_name: "RealShibaDoge",
    name: "Shiba Doge",
    id_str: 1474178850305110017,
  },
  {
    screen_name: "coin_trades",
    name: "Coin Trades",
    id_str: 1291649306953310209,
  },
  { screen_name: "DuncanGray", name: "Duncan Gray", id_str: 28096877 },
  { screen_name: "MoneyTaura", name: "MartaVerse", id_str: 15110581 },
  { screen_name: "TheCryptoAL", name: "AL", id_str: 1477440271147225089 },
  { screen_name: "FurionCrypto", name: "Furion - Crypto", id_str: 4898053448 },
  {
    screen_name: "cryptocantoncat",
    name: "Cantonese Cat \ud83d\udc31- Hacked - Follow @evilcantoncat",
    id_str: 1476005540564189184,
  },
  {
    screen_name: "coinpaprika",
    name: "CoinPaprika - Crypto Market Data",
    id_str: 953203003749814272,
  },
  {
    screen_name: "FelipeCrypto",
    name: "ChainLinkChad.eth",
    id_str: 991820929054461965,
  },
  {
    screen_name: "CryptoMatt87",
    name: "Crypto Matt",
    id_str: 1309415495054118917,
  },
  {
    screen_name: "CryptoGParty",
    name: "CryptoGParty \u26ab\ufe0f\ud83d\udcb3",
    id_str: 1016215831083192320,
  },
  { screen_name: "trader_marky", name: "marky", id_str: 4212072621 },
  {
    screen_name: "SweetPeaCrypto",
    name: "MoonWalkerCrypto\ud83c\udf12",
    id_str: 1387801941699403780,
  },
  {
    screen_name: "evai_io",
    name: "Evai Crypto Ratings",
    id_str: 1207560493671469057,
  },
  { screen_name: "DeBankDeFi", name: "DeBank", id_str: 1156037602488639490 },
  {
    screen_name: "CryptoDeus2",
    name: "CryptoDeus",
    id_str: 1311320930040393730,
  },
  {
    screen_name: "CryptoKindie",
    name: "Crypto Kindie",
    id_str: 1372148698872877058,
  },
  {
    screen_name: "jack4crypto",
    name: "Crypto Jack",
    id_str: 1636300955162497024,
  },
  { screen_name: "thetrocro", name: "Troy Cross", id_str: 3010775305 },
  { screen_name: "CryptoDonAlt", name: "DonAlt", id_str: 878219545785372673 },
  {
    screen_name: "realjameswo",
    name: "James Wo \ud83d\udc8e\ud83d\udc8e\ud83d\udc8e",
    id_str: 3724412003,
  },
  {
    screen_name: "ShibInformer",
    name: "SHIB INFORMER",
    id_str: 1362892883494322176,
  },
  {
    screen_name: "ShibaInuHodler",
    name: "SHIB HODLER",
    id_str: 1411587179986489346,
  },
  {
    screen_name: "crypto_bearr",
    name: "Crypto\ud83d\ude80Rocket",
    id_str: 7173232,
  },
  {
    screen_name: "crypto_hanuman",
    name: "Crypto Hanuman",
    id_str: 1373647114350129156,
  },
  {
    screen_name: "crypto_oligarch",
    name: "Oligarch",
    id_str: 1232644224341819392,
  },
  {
    screen_name: "CoinsgridLens",
    name: "@coingrid.lens",
    id_str: 1143744127365865472,
  },
  { screen_name: "belufrancese", name: "Belen Francese", id_str: 146710910 },
  { screen_name: "AaymanNft", name: "Aayman.SOL", id_str: 1493485764055035904 },
  {
    screen_name: "AirdropDet",
    name: "AirdropDetective",
    id_str: 1012436626776174592,
  },
  {
    screen_name: "DaCryptoGems",
    name: "Crypto Gems",
    id_str: 1399363210441117700,
  },
  { screen_name: "ShibInfo", name: "SHIB WOOF!", id_str: 201368350 },
  {
    screen_name: "airdropinspect",
    name: "Airdrop Inspector",
    id_str: 1151153768165076992,
  },
  {
    screen_name: "patrickklepek",
    name: "Patrick Klepek \ud83c\udf83",
    id_str: 13461662,
  },
  {
    screen_name: "greenwav_es",
    name: "GreenWAVES",
    id_str: 1422232991305248768,
  },
  {
    screen_name: "BraverCrypto",
    name: "Braver Crypto",
    id_str: 1464821991513407491,
  },
  {
    screen_name: "FutureIsFantom",
    name: "Defi Maximalist \ud83d\udca5",
    id_str: 2225917730,
  },
  {
    screen_name: "ArbitrumDailyTK",
    name: "Arbitrum Daily (\ud83d\udc99,\ud83e\udde1)",
    id_str: 1437995145371086848,
  },
  {
    screen_name: "JRNYcrypto",
    name: "JRNY Crypto",
    id_str: 952087880918228992,
  },
  { screen_name: "AlmiraCrypto", name: "Almira Crypto", id_str: 3818789873 },
  {
    screen_name: "AtomicWallet",
    name: "Atomic - Crypto Wallet",
    id_str: 960930668246167553,
  },
  {
    screen_name: "alex_valaitis",
    name: "Alex Valaitis",
    id_str: 984606240876544001,
  },
  {
    screen_name: "thinkgrowcrypto",
    name: "Jami Safari | $thinkgrowcrypto",
    id_str: 905493911107502080,
  },
  {
    screen_name: "Jkylebass",
    name: "\ud83c\uddfa\ud83c\uddf8 Kyle Bass \ud83c\uddf9\ud83c\uddfc",
    id_str: 3005733012,
  },
  {
    screen_name: "WorldMobileTeam",
    name: "World Mobile",
    id_str: 1082320694548066305,
  },
  { screen_name: "Cris__U", name: "Cristian", id_str: 223962952 },
  {
    screen_name: "silvinaescudero",
    name: "Silvina Escudero",
    id_str: 157149632,
  },
  { screen_name: "RoboHero_io", name: "RoboHero", id_str: 1435157833654415362 },
  { screen_name: "1984_is_today", name: "TCB", id_str: 1361833358456676355 },
  { screen_name: "Shibtoken", name: "Shib", id_str: 1356248804899565569 },
  {
    screen_name: "kuro_9696_9696",
    name: "KURO\ud83d\udc3eSHIBARMY JPN\ud83c\uddef\ud83c\uddf5",
    id_str: 1341601046276132864,
  },
  {
    screen_name: "CynthiaMLummis",
    name: "Cynthia Lummis \ud83e\uddac",
    id_str: 1136711154435207168,
  },
  {
    screen_name: "paoloardoino",
    name: "Paolo Ardoino \ud83e\udd16\ud83c\udf50",
    id_str: 109404961,
  },
  {
    screen_name: "RENDERMAMI",
    name: "$RENDER Bear Mami \u2b55\ufe0f",
    id_str: 1050584517948465152,
  },
  {
    screen_name: "Quantfidential",
    name: "Knowledge Up Only \ud83d\udcc8",
    id_str: 1300181314906263559,
  },
  {
    screen_name: "iyagirL2016",
    name: "\ud835\udc22\ud835\udc18\ud835\udc00",
    id_str: 1185726032558489601,
  },
  { screen_name: "AlgodTrading", name: "Algod", id_str: 1178640283652767745 },
  {
    screen_name: "CryptoRedempti1",
    name: "It\u2019s coming\u2026",
    id_str: 1404451612815507461,
  },
  { screen_name: "resdegen", name: "Res", id_str: 1347335187823292416 },
  {
    screen_name: "XRPVampire",
    name: "XRP Vampire \ud83e\udd87",
    id_str: 1535430750,
  },
  { screen_name: "panosmek", name: "Panos \ud83d\udd3c{X}", id_str: 157933825 },
  {
    screen_name: "thebearablebull",
    name: "The Bearable Bull",
    id_str: 1102057144965316611,
  },
  {
    screen_name: "CryptoMagnified",
    name: "\ud835\uddd6\ud835\uddff\ud835\ude06\ud835\uddfd\ud835\ude01\ud835\uddfc \ud835\udde0\ud835\uddee\ud835\uddf4\ud835\uddfb\ud835\uddf6\ud835\uddf3\ud835\uddf6\ud835\uddf2\ud835\uddf1",
    id_str: 972535768660021248,
  },
  { screen_name: "_10delta_", name: "10\u0394", id_str: 299633340 },
  {
    screen_name: "Cryptoking",
    name: "Crypto King",
    id_str: 943378092059496448,
  },
  {
    screen_name: "MyroSOL",
    name: "Myro | Solana Chain \ud83d\udc15\u200d\ud83e\uddba",
    id_str: 1642713836393312260,
  },
  {
    screen_name: "TitanXBT",
    name: "Titan \ud83c\udfe6",
    id_str: 1386889706152026113,
  },
  {
    screen_name: "cryptoemperortr",
    name: "Crypto Emperor\ud83d\udc51\u2694\ufe0f",
    id_str: 1384477040246669314,
  },
  {
    screen_name: "CardanoChefPool",
    name: "\u0398eodore M. \ud83c\uddec\ud83c\uddf7\ud83c\udde8\ud83c\udde6",
    id_str: 1643247727,
  },
  {
    screen_name: "crypto202WIN",
    name: "FutureMan",
    id_str: 1262597008725905409,
  },
  { screen_name: "TechDev_52", name: "TechDev", id_str: 443073989 },
  {
    screen_name: "MocaverseNFT",
    name: "Mocaverse\ud83d\udcbc\ud83e\ude90",
    id_str: 1586321159745720321,
  },
  { screen_name: "AlanTradingYT", name: "Alan", id_str: 1360641323804614663 },
  { screen_name: "ParabolicHBAR", name: "SEVO", id_str: 1355598312376774656 },
  {
    screen_name: "CryptoBOSS___",
    name: "CryptoBOSS",
    id_str: 1425868864534032384,
  },
  {
    screen_name: "traderpow",
    name: "pow\ud83e\uddf2",
    id_str: 1325739682752204800,
  },
  {
    screen_name: "VentureCoinist",
    name: "Luke Martin",
    id_str: 877728873340956672,
  },
  { screen_name: "BONTESA_", name: "BONTESA", id_str: 1019285175145058306 },
  {
    screen_name: "bitcoin_brown",
    name: "\u24dfolk\ud835\ude3cd\u2b21t \u20bf\ud83c\udd61own",
    id_str: 1107742918952083462,
  },
  { screen_name: "stride_zone", name: "Stride", id_str: 1519990048497754113 },
  {
    screen_name: "EuCardano",
    name: "European Cardano Community",
    id_str: 1544975432981823489,
  },
  {
    screen_name: "Kevin_Cage_",
    name: "Kevin Cage",
    id_str: 999798685537062914,
  },
  {
    screen_name: "iCryptoSam",
    name: "CryptoSam\u26a1\ufe0f",
    id_str: 1084260679786344458,
  },
  {
    screen_name: "yuta_cryptox",
    name: "YUTA-Cardano/\u7a0e\u7406\u58eb\u516c\u8a8d\u4f1a\u8a08\u58eb",
    id_str: 1161623831061991424,
  },
  {
    screen_name: "Ra66itRunner",
    name: "HunterX.ai",
    id_str: 1390291277699297282,
  },
  {
    screen_name: "JagoeUnchained",
    name: "J//G0\u039e",
    id_str: 1252627966724648960,
  },
  {
    screen_name: "goldponycrypto",
    name: "Gold Pony Crypto",
    id_str: 1183736787975495685,
  },
  {
    screen_name: "danny_cryptofay",
    name: "Danny \ud83d\udc23",
    id_str: 44847919,
  },
  {
    screen_name: "WashoInvesting",
    name: "\ud83c\udf27\u20a9\ud835\udd52\ud835\udd64\u210d\ud835\udd60\ud83c\udf99",
    id_str: 1311464752942583808,
  },
  {
    screen_name: "cryptoslave12",
    name: "Cryptoslave12.link",
    id_str: 1393870952514129922,
  },
  { screen_name: "JinglesBTC", name: "Jingles", id_str: 449700905 },
  { screen_name: "tsncrypto", name: "tsn", id_str: 1564994547654037505 },
  { screen_name: "sgleahy", name: "SGL", id_str: 1130022078 },
  {
    screen_name: "CryptoTwist1",
    name: "Crypto Twist \ud83d\udea2(\ud83d\udc99,\ud83e\udde1)",
    id_str: 1417183047737397258,
  },
  {
    screen_name: "CoinTribuneFR",
    name: "Cointribune",
    id_str: 1126034004711440384,
  },
  {
    screen_name: "Oncryptobreak",
    name: "CR - CryptoMultiMillonaire",
    id_str: 949664837654609921,
  },
  { screen_name: "CryptoSeq", name: "Seq", id_str: 1071365059681665024 },
  { screen_name: "eCashOfficial", name: "eCash", id_str: 1327343239800582144 },
  {
    screen_name: "XRPNews_",
    name: "Ripple Van Winkle | Crypto Researcher \ud83d\ude80\ud83d\udea8",
    id_str: 39090194,
  },
  {
    screen_name: "CryptoFNewsDE",
    name: "REAL REEZY",
    id_str: 1421809268005777408,
  },
  {
    screen_name: "Cryptoalpharian",
    name: "CryptoAlpha\u26a1(\ud83d\udc99,\ud83e\udde1) \ud83c\udf44",
    id_str: 1435842469971771393,
  },
  {
    screen_name: "CryptoNash",
    name: "\ud83c\udd7d\ud83c\udd70\ud83c\udd82\ud83c\udd77 \u2664",
    id_str: 981812877094014976,
  },
  { screen_name: "blockchainchick", name: "Heidi", id_str: 738792704302161920 },
  { screen_name: "pepecoineth", name: "Pepe", id_str: 1643286556730351616 },
  {
    screen_name: "LilEthereum",
    name: "Lil ETH \ud83d\udcaf",
    id_str: 1587412598412255234,
  },
  {
    screen_name: "XrpSnakie",
    name: "XRP Snakie.XRP \ud83d\udc0d",
    id_str: 1379572669167771650,
  },
  {
    screen_name: "PlutusCrypto",
    name: "Lotus Mystic.BULA",
    id_str: 992016233192132608,
  },
  {
    screen_name: "holdtightyeah",
    name: "Kuji Keanu \ud83c\ude50 \ud83d\udc0b",
    id_str: 2663827945,
  },
  {
    screen_name: "BlumiesNFT",
    name: "Blumies CRO",
    id_str: 1551921122861613057,
  },
  {
    screen_name: "NadiaCNFTModel",
    name: "Nadia.ada - SQRL Pool \ud83d\udc3f\ufe0f",
    id_str: 1557746252871528449,
  },
  {
    screen_name: "JaromirTesar",
    name: "Cardano YOD\u20b3",
    id_str: 921325529730514944,
  },
  {
    screen_name: "CronosCallisto",
    name: "Callisto.cro \ud83c\udf40",
    id_str: 1405275385802199041,
  },
  {
    screen_name: "OX_DAO",
    name: "Coach Bruce \ud83d\udc02",
    id_str: 1471279207095406595,
  },
  {
    screen_name: "Sentti_official",
    name: "Sentti",
    id_str: 912399859050835968,
  },
  {
    screen_name: "Crypto_BitC",
    name: "\u2b21 LINK Collector \u2b21",
    id_str: 763417648059195393,
  },
  {
    screen_name: "ImCryptoProf",
    name: "PROFESSO\ud83c\udd61 \ud83d\udcca",
    id_str: 1265598537443602432,
  },
  {
    screen_name: "LifebyDeb",
    name: "Deb \ud83d\udda4",
    id_str: 1383276405501227014,
  },
  {
    screen_name: "bubbaiscrypto",
    name: "\u20bfubba Crypto",
    id_str: 2993721210,
  },
  { screen_name: "TheCryptoZombie", name: "Crypto Zombie", id_str: 106250623 },
  { screen_name: "Paul_VCS", name: "Paul", id_str: 1460262488692428811 },
  {
    screen_name: "RodniCypher",
    name: "\ud83d\udfe3 Rowdi Rodni Cypher",
    id_str: 952699204324884481,
  },
  { screen_name: "CoinCorner", name: "CoinCorner", id_str: 2561073732 },
  {
    screen_name: "terra_money",
    name: "Terra \ud83c\udf0d Powered by LUNA \ud83c\udf15",
    id_str: 1022028994772910086,
  },
  {
    screen_name: "RocketETC",
    name: "Donald McIntyre",
    id_str: 1514265020204519434,
  },
  {
    screen_name: "tac_collective",
    name: "The Art Collective",
    id_str: 979110931438370816,
  },
  {
    screen_name: "OCryptoTroll",
    name: "Crypto Troll (NOT!)",
    id_str: 3012832301,
  },
  { screen_name: "BoDoggosNFT", name: "BoDoggos", id_str: 1563915868672753665 },
  {
    screen_name: "WalkersCNFT",
    name: "WALKERS NFT",
    id_str: 1563529395683598338,
  },
  { screen_name: "TheMoonMidas", name: "Midas \u970a", id_str: 535662615 },
  {
    screen_name: "pepesolana_pepe",
    name: "PEPESOLANA - $PEPE \ud83d\udc38",
    id_str: 203142022,
  },
  {
    screen_name: "damskotrades",
    name: "CryptoAmsterdam",
    id_str: 1004687124141150208,
  },
  {
    screen_name: "DreadBong0",
    name: "\uff24\uff32\uff25\uff21\uff24 \uff22\uff2f\uff2e\uff27\uff2f",
    id_str: 1056304242812743680,
  },
  { screen_name: "AltcoinPsycho", name: "Psycho", id_str: 942999039192186882 },
  {
    screen_name: "NEOSTELLAR_",
    name: "neo \ud83c\udf20 COMMS OPEN",
    id_str: 1336539239983419400,
  },
  {
    screen_name: "TokenVC_",
    name: "Michael Pinto",
    id_str: 1403402439001260036,
  },
  {
    screen_name: "Chiliz_Swiss",
    name: "Chiliz Switzerland \ud83c\udde8\ud83c\udded | FanTokenBoard.com",
    id_str: 1212466684868456449,
  },
  {
    screen_name: "FLS_News_1",
    name: "\ud83c\udf3e \ud835\udc05\ud835\udc0b\ud835\udc12 \ud835\udc0d\ud835\udc04\ud835\udc16\ud835\udc12 \ud83c\udf3e",
    id_str: 1373182932815282181,
  },
  {
    screen_name: "Thememecoinking",
    name: "The Memecoins king \u2654",
    id_str: 1527424944296333312,
  },
  { screen_name: "MDXcrypto", name: "MDX", id_str: 760483079421095937 },
  { screen_name: "RevomonVR", name: "Revomon", id_str: 1351612104508366850 },
  { screen_name: "ShmooNFT", name: "Shmoo", id_str: 1431840544037163011 },
  {
    screen_name: "SuiGlobal",
    name: "SuiGlobal.sui",
    id_str: 1557374675583475712,
  },
  {
    screen_name: "Community_Sui",
    name: "Sui Community\ud83d\udca7",
    id_str: 1553609044127911937,
  },
  {
    screen_name: "SuiInsights",
    name: "SuiInsights.sui \ud83d\udca7",
    id_str: 1531113662538457088,
  },
  {
    screen_name: "NeoVikingsADA",
    name: "Neo Vikings $NV",
    id_str: 1600871963236376576,
  },
  {
    screen_name: "CryptoDanvans",
    name: "CryptoDanny",
    id_str: 874177873971744768,
  },
  {
    screen_name: "Alex_CryptoKing",
    name: "Alex BigEcosystem",
    id_str: 1338127170749784065,
  },
  {
    screen_name: "cosmos",
    name: "Cosmos - The Interchain \u269b\ufe0f",
    id_str: 15223775,
  },
  {
    screen_name: "aptos_ind",
    name: "Aptos India",
    id_str: 1464641755446489093,
  },
  {
    screen_name: "CryptoMatrix2",
    name: "CryptoMatrix",
    id_str: 1108493361986576384,
  },
  { screen_name: "CoinLegs", name: "CoinLegs", id_str: 945653261431910401 },
  {
    screen_name: "LTCFoundation",
    name: "Litecoin Foundation \u26a1\ufe0f",
    id_str: 1656328279,
  },
  { screen_name: "XTZNews", name: "XTZ News", id_str: 1329376729660592129 },
  {
    screen_name: "SteveCryptoGems",
    name: "SteveCryptoReviews",
    id_str: 1501683101277331466,
  },
  {
    screen_name: "CryptoNoan",
    name: "Prof.Noan.Ai \ud80c\udcf5",
    id_str: 1407925261249568775,
  },
  { screen_name: "Oly245", name: "Oliver Bell", id_str: 96583499 },
  {
    screen_name: "EverytngCrypto",
    name: "Everything Crypto",
    id_str: 2201243706,
  },
  {
    screen_name: "Crypto_Guy360",
    name: "Crypto Guy",
    id_str: 1537559106302754820,
  },
  { screen_name: "Excellion", name: "Samson Mow", id_str: 14654494 },
  {
    screen_name: "Mathews_CRO",
    name: "\ud83d\udc6e\u200d\u2642\ufe0fMathews Cosmonaut \u269b\ufe0f",
    id_str: 1478273779096698880,
  },
  { screen_name: "_ZAYN_N", name: "ZAYN CRYPTO", id_str: 1499167386104172547 },
  {
    screen_name: "Mel_Senechal",
    name: "XRP Meesku\u2764\ufe0f",
    id_str: 359748104,
  },
  {
    screen_name: "MoheganBTC",
    name: "Mohegan \u20bfTC \ud83c\udfb2",
    id_str: 1484023583781396486,
  },
  {
    screen_name: "BlazzordNFT",
    name: "Blazzord (MultiversX)",
    id_str: 1218614834910179334,
  },
  {
    screen_name: "liqwidfinance",
    name: "Liqwid Labs",
    id_str: 1302408526925631493,
  },
  {
    screen_name: "Nzero_official",
    name: "NETZERO",
    id_str: 1449919113040568326,
  },
  { screen_name: "ContraPoints", name: "Natalie Wynn", id_str: 27938182 },
  { screen_name: "FabriLemus7", name: "FABRI CRYPTO", id_str: 4804114077 },
  {
    screen_name: "CryptoPanicCom",
    name: "CryptoPanic",
    id_str: 869333058876559361,
  },
  {
    screen_name: "LecointreGrego1",
    name: "cryptogreg \ud83d\udcb8\ud83c\udfa7\u2764\ufe0f\ud83d\udc3b\ud83d\udc4c\ud83c\udde7\ud83c\uddea",
    id_str: 2494366160,
  },
  {
    screen_name: "VechainThorCom",
    name: "VeChain Community Hub",
    id_str: 945998026392424448,
  },
  { screen_name: "CryptoCon_", name: "CryptoCon", id_str: 1046541593912664064 },
  {
    screen_name: "HTXFutures",
    name: "HTX Futures",
    id_str: 1110434173162917888,
  },
  { screen_name: "Bullbarcoin", name: "BullBar", id_str: 1678785598566330369 },
  {
    screen_name: "ButchCryptoland",
    name: "\ud83c\uddec\ud83c\udde7Butchcryptolander \ud83c\uddec\ud83c\udde7 $LINGO",
    id_str: 1476347995025195008,
  },
  {
    screen_name: "Historic_Crypto",
    name: "historic-crypto.eth",
    id_str: 1471137375225065481,
  },
  {
    screen_name: "EvilKongsNFTs",
    name: "Evil Kongs",
    id_str: 1583175998828384271,
  },
  {
    screen_name: "AptosMonkeys",
    name: "Aptos Monkeys | ACT II",
    id_str: 1552619129764560896,
  },
  {
    screen_name: "Courtyard_NFT",
    name: "Courtyard.io",
    id_str: 1434959530304045058,
  },
  {
    screen_name: "pitcoin_",
    name: "Pitcoin \ud83c\udde7\ud83c\uddf7",
    id_str: 1411652846571999233,
  },
  { screen_name: "CJAYCRO", name: "CJAY.CRO Lets.CRO", id_str: 47183375 },
  {
    screen_name: "cryptobull3232",
    name: "Baller \ud83d\udc51",
    id_str: 1374847223427567617,
  },
  {
    screen_name: "THETA_ECOSYSTEM",
    name: "\ud835\ude4f\ud835\ude43\ud835\ude40\ud835\ude4f\ud835\ude3c \ud835\ude40\ud835\ude3e\ud835\ude4a\ud835\ude4e\ud835\ude54\ud835\ude4e\ud835\ude4f\ud835\ude40\ud835\ude48",
    id_str: 2596214584,
  },
  { screen_name: "y00tsNFT", name: "y00ts", id_str: 1538085876445499393 },
  {
    screen_name: "Cryptoahmeet",
    name: "Crypto \ud83d\udd36 Ahmet \ud83d\udd38\u20bf\ud83d\udd38",
    id_str: 3865986993,
  },
  {
    screen_name: "Real_Altcoin2",
    name: "The Real Altcoin",
    id_str: 927019661614600192,
  },
  {
    screen_name: "anetaBTC",
    name: "AnetaBTC \ud83e\udd77 cBTC on Cardano",
    id_str: 1458797692759461890,
  },
  { screen_name: "ascrypto13", name: "Dogi", id_str: 1489272555311706114 },
  { screen_name: "AptosLabs", name: "Aptos Labs", id_str: 1582127706560376832 },
  {
    screen_name: "NeoPunksSaga",
    name: "\ud835\udc0d\ud835\udc1e\ua74a\ud835\udc0f\ud835\udc2e\ud835\udc27\ud835\udc24\ud835\udc2c\ud835\udc12\ud835\udc1a\ud835\udc20\ud835\udc1a | NeoPunksSaga.eth",
    id_str: 1647603084569243657,
  },
  {
    screen_name: "JRichmondstyle",
    name: "John Richmond",
    id_str: 1485620615973330945,
  },
  { screen_name: "Pauly0x", name: "PAULY", id_str: 764250609402777600 },
  {
    screen_name: "CardanoGuru",
    name: "Guru FM \ud83d\udfe3",
    id_str: 1178732067607240705,
  },
  {
    screen_name: "revolution_coin",
    name: "Coin Revolution",
    id_str: 1374281217105928197,
  },
  { screen_name: "FrameItNFT", name: "Frame It", id_str: 1458386210389364738 },
  {
    screen_name: "monad_alexander",
    name: "monad_alexander",
    id_str: 1339409778934599680,
  },
  {
    screen_name: "CryptoBeastGems",
    name: "CryptoBeast Gems",
    id_str: 1514305485897412615,
  },
  {
    screen_name: "cryptowisdom7",
    name: "CRYPTO WISDOM with NEEL",
    id_str: 3329066050,
  },
  { screen_name: "TraderHyperion", name: "Hyperion", id_str: 3291517408 },
  { screen_name: "BwareLabs", name: "Bware Labs", id_str: 1359892451075518466 },
  { screen_name: "SuiDailyTK", name: "Sui Daily", id_str: 1358640864482889729 },
  {
    screen_name: "Bitcoin_World20",
    name: "Bitcoin Analyst",
    id_str: 930749335972671488,
  },
  { screen_name: "xyzvitaly", name: "Vitaly", id_str: 1012655099875856385 },
  {
    screen_name: "BTCEcosytem",
    name: "Bitcoin Ecosystem",
    id_str: 1361160790561067010,
  },
  { screen_name: "MikeStillBTC", name: "Mike Still", id_str: 2330844882 },
  {
    screen_name: "ShadowTrader__",
    name: "Shadow Trader",
    id_str: 1329903945825873921,
  },
  {
    screen_name: "CryptoTintin8",
    name: "\u20bfit Tintin",
    id_str: 944165549851987969,
  },
  {
    screen_name: "HumbertCrypto",
    name: "Charizard69.Theta",
    id_str: 952602148499410944,
  },
  { screen_name: "altcoinsavvyuk", name: "Altcoin $avvy", id_str: 47591664 },
  {
    screen_name: "CryptoEnact",
    name: "Crypto \ud83d\udd36 Enact",
    id_str: 2544574166,
  },
  {
    screen_name: "_XRP_QUEEN",
    name: "\ud835\udcd0\ud835\udcdd\ud835\udcd6 \u2764\ufe0e\ufe0e",
    id_str: 33998301,
  },
  { screen_name: "nicucrypto", name: "nicu", id_str: 2824023279 },
  {
    screen_name: "CardanoCube",
    name: "Cardano Cube",
    id_str: 1404502220859707396,
  },
  {
    screen_name: "CryptoMoodGems",
    name: "\ud835\ude3e\ud835\ude48",
    id_str: 1531138043154948121,
  },
  { screen_name: "DablClub", name: "Dabl Club", id_str: 1450088354180046853 },
  {
    screen_name: "TradedogCrypto",
    name: "TradeDog | Crypto Market Research",
    id_str: 1186658033079730176,
  },
  { screen_name: "KingsFantom", name: "Roosh", id_str: 1351739423273181186 },
  {
    screen_name: "RunwithBitcoin",
    name: "Paco de la India",
    id_str: 1398889283570659336,
  },
  { screen_name: "Cryptokidshaz", name: "shaz", id_str: 1456797769444724740 },
  {
    screen_name: "SOLgregs",
    name: "S\u25ceLgreg",
    id_str: 1396074644436832257,
  },
  { screen_name: "cryptonary", name: "Cryptonary", id_str: 905018401957011457 },
  {
    screen_name: "Cryptonnaber",
    name: "Na\u20bfer",
    id_str: 1316222761078009857,
  },
  {
    screen_name: "FantomTalk",
    name: "Fantom Talk",
    id_str: 1524430615420223488,
  },
  {
    screen_name: "ETCAlpha",
    name: "\ud83d\udc8eETC ALPHA\ud83d\udc8e",
    id_str: 1488358539886223360,
  },
  { screen_name: "PLMKR10", name: "Playmaker", id_str: 1395683111405563906 },
  { screen_name: "EverRise", name: "EverRise", id_str: 1403078477541171201 },
  { screen_name: "BranBTC", name: "Brandon", id_str: 739758704 },
  {
    screen_name: "CoinMarketCal",
    name: "CoinMarketCal",
    id_str: 909500372720418817,
  },
  {
    screen_name: "FantomFDN",
    name: "Fantom Foundation",
    id_str: 977020204071792641,
  },
  {
    screen_name: "SniperTraderCry",
    name: "Mister Whale Crypto",
    id_str: 909843786821447680,
  },
  { screen_name: "pepa_inu", name: "Pepa Inu", id_str: 1629960256083173379 },
  {
    screen_name: "TheCryptoBull77",
    name: "The CryptoBull",
    id_str: 1197614016807493639,
  },
  { screen_name: "0x_render", name: "render", id_str: 979600082084552705 },
  { screen_name: "JasmyPromoter", name: "279", id_str: 2822878502 },
  {
    screen_name: "CryptoSolutionG",
    name: "Crypto Solution",
    id_str: 1441332979620450314,
  },
  { screen_name: "_SwingTrader_", name: "Swing Trader", id_str: 1449830136 },
  {
    screen_name: "wmtoken",
    name: "World Mobile Token",
    id_str: 1384821991971201028,
  },
  { screen_name: "BitcoinWazir", name: "Rafi", id_str: 1246549847798353920 },
  {
    screen_name: "cardanowarriors",
    name: "\ud83d\udd30 Cardano Warriors",
    id_str: 1379607294372110337,
  },
  {
    screen_name: "Brett_FLR",
    name: "Brett FLR \u2600\ufe0f",
    id_str: 1643839802784247809,
  },
  { screen_name: "ki_young_ju", name: "Ki Young Ju", id_str: 302505363 },
  {
    screen_name: "Dogeh8er",
    name: "X-Art \u2600\ufe0f",
    id_str: 1443173296628015111,
  },
  {
    screen_name: "Coin360News",
    name: "Coin360 News",
    id_str: 1441101210786807833,
  },
  {
    screen_name: "cryptostreetoff",
    name: "Yury Kitano ( aka cryptostreet \ud83c\udde8\ud83c\udded\ud83c\udde6\ud83c\uddea )",
    id_str: 845906647348396032,
  },
  {
    screen_name: "AscendEX_India",
    name: "AscendEX India \ud83c\uddee\ud83c\uddf3",
    id_str: 1419577844326223872,
  },
  { screen_name: "RACA_3", name: "RACA.bab", id_str: 1393227081169870853 },
  {
    screen_name: "BSCNgaming",
    name: "BSCN Gaming",
    id_str: 1373389818193981447,
  },
  {
    screen_name: "AirdropStario",
    name: "AirdropStar",
    id_str: 1173425402523652096,
  },
  {
    screen_name: "Cremation_Coin",
    name: "Cremation Coin \ud83d\udd25Weekly LUNC Burns\ud83d\udd25",
    id_str: 1602144325365911552,
  },
  {
    screen_name: "CryptoHarry_",
    name: "Harry \ud83c\ude50\ufe0f",
    id_str: 1344661849472520192,
  },
  { screen_name: "tedtalksmacro", name: "ted", id_str: 1309703843949682694 },
  {
    screen_name: "_CLINTWESTWOOD_",
    name: "CLINT WESTWOOD \u24c2\ufe0f\ud83d\udd78",
    id_str: 2858239057,
  },
  { screen_name: "Sicarious_", name: "Sicarious", id_str: 2305628744 },
  {
    screen_name: "ElonMansur_STC",
    name: "ElonMansur",
    id_str: 1379246412437856256,
  },
  {
    screen_name: "dexioprotocol",
    name: "Dexioprotocol",
    id_str: 1389311462758404096,
  },
  { screen_name: "Thorpeedo", name: "MT", id_str: 12376402 },
  {
    screen_name: "MCDoge_1",
    name: "\u1d0d.\u1d04.\ud83c\uddfa\ud83c\uddf8\u0110o\u0262\u1d07",
    id_str: 251427634,
  },
  { screen_name: "Cryptoextension", name: "CUE", id_str: 1024114610 },
  {
    screen_name: "Crypto_Jury",
    name: "Crypto_Jury \ud83d\udc68\u200d\u2696\ufe0f",
    id_str: 1436853590044266497,
  },
  {
    screen_name: "spacebudznft",
    name: "SpaceBudz",
    id_str: 1372512716515127297,
  },
  {
    screen_name: "RealRigormortis",
    name: "Rigormortis",
    id_str: 1411549341844750338,
  },
  { screen_name: "AlphadogETH", name: "AlphaDog", id_str: 817523504203657216 },
  { screen_name: "coolwithcrypto", name: "Jordo", id_str: 1355959618837610506 },
  {
    screen_name: "ChainlinkWoman",
    name: "\ud83c\udf38 Reform Girl \ud83c\udf38\uea00\ud83c\uddec\ud83c\udde7",
    id_str: 1426203280909156362,
  },
  { screen_name: "SabreEthereum", name: "Sabre", id_str: 1400577822167556098 },
  { screen_name: "apenftorg", name: "APENFT", id_str: 1392094167522742272 },
  { screen_name: "CBarraford", name: "Chad Barraford", id_str: 14196108 },
  {
    screen_name: "staderlabs",
    name: "Stader Labs",
    id_str: 1387924807480664067,
  },
  { screen_name: "nftbstoken", name: "NFTBOOKS", id_str: 1414067689094475781 },
  {
    screen_name: "SundaeSwap",
    name: "Sundae Labs \ud83c\udf68",
    id_str: 1378490063588257792,
  },
  {
    screen_name: "DeCallsNFT",
    name: "DeCalls - Price Predictions",
    id_str: 1451279680573693954,
  },
  {
    screen_name: "ArthurPrestidge",
    name: "\u20b3rthur Prestidge \u20b3\ud83c\udf10\u20b3",
    id_str: 1165168460172840960,
  },
  { screen_name: "cryptolaw_joe", name: "Joe C", id_str: 1364456883642380289 },
  { screen_name: "EGLDsavvy", name: "Markus Allen", id_str: 4813292361 },
  { screen_name: "JCRYPTO_YT", name: "Jspeak", id_str: 1388878717984940033 },
  {
    screen_name: "UtilityTheory",
    name: "Citizen of the Future - A Citizen Journalist\ud83d\udcdd\ud83c\udf0e",
    id_str: 1440370719989268489,
  },
  { screen_name: "MadLads", name: "Mad Lads", id_str: 1574506227144482823 },
  { screen_name: "Hobbes_ETH", name: "Hobbes", id_str: 1637625353379237890 },
  {
    screen_name: "OdysseyHeart",
    name: "OdysseyOfTheHeart \ud83c\udff3\ufe0f\u200d\ud83c\udf08\ud83e\udddc\u200d\u2642\ufe0f",
    id_str: 1373544765157965826,
  },
  {
    screen_name: "RobynCryptonian",
    name: "Robyn #STC $STC",
    id_str: 1214491026133569536,
  },
  {
    screen_name: "SOLbuckets",
    name: "buckets | sandbar.io \ud83c\udfdd\ufe0f",
    id_str: 1425966540843896832,
  },
  {
    screen_name: "BitcoinReviewHQ",
    name: "Bitcoin.Review Pod\ud83c\udf99",
    id_str: 1461045832757690373,
  },
  {
    screen_name: "realDogecoinOG",
    name: "Dogecoin OG",
    id_str: 1361760524162113538,
  },
  {
    screen_name: "BankXRP",
    name: "\ud835\uddd5\ud835\uddee\ud835\uddfb\ud835\uddf8XRP",
    id_str: 937342499093471232,
  },
  { screen_name: "CoinQTS", name: "CoinQTS", id_str: 1127099130642173954 },
  { screen_name: "CryptoGorillaYT", name: "Gorilla", id_str: 94261044 },
  {
    screen_name: "SoSoValueCrypto",
    name: "SoSoValue",
    id_str: 1515307799672410115,
  },
  {
    screen_name: "AltcoinRadarYT",
    name: "Altcoin Radar",
    id_str: 1364690947007795204,
  },
  {
    screen_name: "GalaXxY03a",
    name: "\ud83e\ude75\ud83c\udf08Galazy\ud83c\udf49",
    id_str: 1006284813400268802,
  },
  { screen_name: "Ninjascalp", name: "Ninja", id_str: 943958987556507653 },
  {
    screen_name: "darkmatterr__",
    name: "Dark Matter \u269b\ufe0f",
    id_str: 1544583052705386498,
  },
  {
    screen_name: "HugoPhilion",
    name: "Hugo Philion \u2600\ufe0f",
    id_str: 1103764629602422785,
  },
  {
    screen_name: "Gameverse_",
    name: "Gameverse \ud83c\udfae",
    id_str: 1458203910041001988,
  },
  {
    screen_name: "getgreencrypto",
    name: "GreenCrypto",
    id_str: 1040800006934945792,
  },
  {
    screen_name: "Cryptocom_Insto",
    name: "Crypto.com Institutional",
    id_str: 1458080226311815174,
  },
  {
    screen_name: "axelarnetwork",
    name: "Axelar Network",
    id_str: 1321982248401457154,
  },
  {
    screen_name: "Cryptocito",
    name: "Cryptocito | Cosmos \u269b\ufe0f",
    id_str: 931179333607215104,
  },
  {
    screen_name: "DistilledCrypto",
    name: "Crypto, Distilled",
    id_str: 1462571622297063431,
  },
  {
    screen_name: "OptimusAI_Token",
    name: "The Optimus Project \ud83e\udd16 | $OPTI",
    id_str: 1618303057896345636,
  },
  {
    screen_name: "xrpcafe",
    name: "xrp.cafe \u2615",
    id_str: 1547652020319817728,
  },
  { screen_name: "DeansEpoch", name: "Dean", id_str: 1381571176619720707 },
  {
    screen_name: "AlexCryptorat",
    name: "Alex Rat \ud83e\udd77",
    id_str: 1246046627191836677,
  },
  { screen_name: "SoorajKSaju", name: "Sooraj", id_str: 842288538238484480 },
  { screen_name: "Legendary_NFT", name: "Legendary", id_str: 574598857 },
  { screen_name: "CryptOrca", name: "CryptOrca", id_str: 30077085 },
  {
    screen_name: "DylanLeClair_",
    name: "Dylan LeClair \ud83d\udfe0",
    id_str: 1649128015,
  },
  {
    screen_name: "CryptoMerlinn",
    name: "Crypto Merlin \ud83e\uddd9\u200d\u2642\ufe0f \u00a9",
    id_str: 1379075809663664128,
  },
  {
    screen_name: "CosmosDefi",
    name: "Joe \ud83c\udf99\ufe0f",
    id_str: 1481284852880183301,
  },
  {
    screen_name: "Ninacrypto_",
    name: "Crypto Nina",
    id_str: 1408892681426817044,
  },
  { screen_name: "ICPSquad", name: "ICPSquad", id_str: 983821973171171328 },
  { screen_name: "woonomic", name: "Willy Woo", id_str: 13418472 },
  {
    screen_name: "CryptoDiscipleX",
    name: "CryptoDisciple/MR.REDPILL",
    id_str: 1470352073090871297,
  },
  {
    screen_name: "Dr_shwetaPHD",
    name: "Dr. Shweta \ud83e\udd85",
    id_str: 892456671682887681,
  },
  {
    screen_name: "THORSwap",
    name: "THORSwap \u26a1 #BetterThanCEX",
    id_str: 1371793093247258626,
  },
  {
    screen_name: "OlimpioCrypto",
    name: "olimpio",
    id_str: 1451989485890248705,
  },
  {
    screen_name: "AVAXDaily",
    name: "AVAX Daily \ud83d\udd3a",
    id_str: 1390321458140778499,
  },
  { screen_name: "jreentertain", name: "JRE", id_str: 441255649 },
  {
    screen_name: "SolanaSail",
    name: "SolanaSail (\u26f5\ufe0f,\u26f5\ufe0f)",
    id_str: 1394686026044608519,
  },
  { screen_name: "cryptoalle", name: "alle", id_str: 1712587561 },
  { screen_name: "balajis", name: "Balaji", id_str: 2178012643 },
  {
    screen_name: "JetvoyOfficial",
    name: "Jetvoy.com",
    id_str: 1398145645932912641,
  },
  { screen_name: "Miacrypto_", name: "Mia Paez", id_str: 275563220 },
  {
    screen_name: "CardanoPapi",
    name: "CardanoPapi\ud83c\udf7e",
    id_str: 1430294430729134080,
  },
  { screen_name: "WhatBitcoinDid", name: "What Bitcoin Did", id_str: 14101605 },
  {
    screen_name: "stader_bnb",
    name: "Stader.BNB",
    id_str: 1526565369649025024,
  },
  {
    screen_name: "stader_polygon",
    name: "Stader Polygon",
    id_str: 1490640197348106242,
  },
  {
    screen_name: "Blue_CollarBTC",
    name: "Blue Collar Bitcoin",
    id_str: 1394456118018166784,
  },
  {
    screen_name: "Pentosh1",
    name: "Pentoshi \ud83d\udc27 euroPeng \ud83c\uddea\ud83c\uddfa",
    id_str: 1138993163706753029,
  },
  {
    screen_name: "LaCryptoMonkey",
    name: "Crypto Monkey",
    id_str: 1465658570100203523,
  },
  {
    screen_name: "LucieSHIB",
    name: "\ud835\udc0b\ud835\udc14\ud835\udc02\ud835\udc08\ud835\udc04 | SHIB.IO",
    id_str: 1417101905583607817,
  },
  { screen_name: "demirelo", name: ":omer", id_str: 30968048 },
  {
    screen_name: "CryptoChris007",
    name: "Asym_Spec",
    id_str: 1238050745687183365,
  },
  {
    screen_name: "coinexindia",
    name: "CoinEx India Official \ud83c\uddee\ud83c\uddf3",
    id_str: 1550024098436517888,
  },
  { screen_name: "cryptosfo", name: "cryptosfo", id_str: 1251996592862900224 },
  {
    screen_name: "Shibacryptonews",
    name: "Shiba News",
    id_str: 1482666049304936454,
  },
  {
    screen_name: "CryptoMasterCom",
    name: "Crypto Masters",
    id_str: 1150831008414961664,
  },
  {
    screen_name: "WestieCapital",
    name: "Westie \ud83d\udfea",
    id_str: 1358927095255232515,
  },
  { screen_name: "BSC_Newspaper", name: "BSC Newspaper", id_str: 1290792062 },
  {
    screen_name: "CryptoBestMood",
    name: "Crypto\ud83d\udd36\ufe0fBest_Mood\ud83d\udd36\ufe0f",
    id_str: 1527288315535273984,
  },
  {
    screen_name: "Dogememegirl",
    name: "Dogememegirl \ud83d\udc3e",
    id_str: 1418967492832153601,
  },
  {
    screen_name: "Bitcoin_Buddah",
    name: "Bitcoin Buddha",
    id_str: 1477250585791393792,
  },
  {
    screen_name: "CarPoolCrypto",
    name: "CarPool | \u20b3 | Stake Pool (VROOM)",
    id_str: 1390180915075239936,
  },
  { screen_name: "ShibalnuNews", name: "Shiba Inu News", id_str: 284845544 },
  { screen_name: "VEMPStudios", name: "VEMP", id_str: 1401267050950148098 },
  { screen_name: "Cov_duk", name: "Covduk", id_str: 1356925149581242370 },
  {
    screen_name: "TheReaperCoin",
    name: "Reaper Financial",
    id_str: 1464371647436230666,
  },
  {
    screen_name: "cryptocomnft",
    name: "Crypto.com NFT",
    id_str: 1364774803555581952,
  },
  { screen_name: "daomaker", name: "DAO Maker", id_str: 1143108958326054912 },
  {
    screen_name: "wearecultdao",
    name: "Cult.DAO",
    id_str: 1482787383418499075,
  },
  {
    screen_name: "LeJournalDuCoin",
    name: "Journal du Coin",
    id_str: 862443172089253893,
  },
  {
    screen_name: "FedorovMykhailo",
    name: "Mykhailo Fedorov",
    id_str: 1331528215899344896,
  },
  {
    screen_name: "MuseDash_EN",
    name: "Muse Dash",
    id_str: 1459048586503921665,
  },
  {
    screen_name: "HeyItsMeTheDev",
    name: "The Dev",
    id_str: 1576698902065053696,
  },
  {
    screen_name: "StepApp_",
    name: "Step App | The Future of Fitness",
    id_str: 1509634807587917829,
  },
  { screen_name: "FatManTerra", name: "FatMan", id_str: 1525464684190638082 },
  {
    screen_name: "Metanevolution",
    name: "TONSWAP.FINANCE",
    id_str: 1468867370860965890,
  },
  { screen_name: "801_XRP", name: "801_XRP", id_str: 1395529522414383104 },
  {
    screen_name: "BLSWhale",
    name: "BlueSparrow Whale BULLISH\uea00",
    id_str: 1298138521778237440,
  },
  { screen_name: "Bitcoin", name: "Bitcoin", id_str: 357312062 },
  {
    screen_name: "zk_Race",
    name: "zkRace (formerly DeRace, $DERC)",
    id_str: 1386276828759855109,
  },
  {
    screen_name: "houstonstellar",
    name: "Houston Stellar VB",
    id_str: 578571319,
  },
  { screen_name: "ShibBPP", name: "$SHIB BPP", id_str: 844385234770636800 },
  {
    screen_name: "Altcoincall",
    name: "Altcoin Calls & SHILL \u2122\ud83d\udd25",
    id_str: 1444144992130199552,
  },
  {
    screen_name: "swapleonicorn",
    name: "LEONICORN | Swap - NFT - GameFi",
    id_str: 1275810109671661573,
  },
  {
    screen_name: "regent_coin",
    name: "REGENT COIN",
    id_str: 1589688686203265024,
  },
  {
    screen_name: "chessinslums",
    name: "CHESS IN SLUMS AFRICA",
    id_str: 1033078117693304834,
  },
  {
    screen_name: "YMagnetgold",
    name: "Your MagnetGold",
    id_str: 1429786024113152005,
  },
  { screen_name: "Thyborg_", name: "Thyborg", id_str: 1463289807400964100 },
  {
    screen_name: "CryptoTheBeast_",
    name: "Crypto \u20bfeast",
    id_str: 1361064698557263873,
  },
  {
    screen_name: "Cosmos_allday",
    name: "Cosmos allday \u269b\ufe0f",
    id_str: 1607952854811283456,
  },
  {
    screen_name: "jasthecryptoman",
    name: "Jaz.AI",
    id_str: 799140339621597185,
  },
  {
    screen_name: "Crypto_PB",
    name: "P\u20bf \u26a1",
    id_str: 1360827101503512576,
  },
  {
    screen_name: "TimelessBitcoin",
    name: "The \u20bfitcoin Times",
    id_str: 1101691612676087808,
  },
  {
    screen_name: "TCryptoCurrency",
    name: "CRYPTO",
    id_str: 880715346373742594,
  },
  {
    screen_name: "xrpstik",
    name: "xSTIK \ud83d\udc8e XRPL NFT",
    id_str: 1465240861881643009,
  },
  {
    screen_name: "Indigo_protocol",
    name: "Indigo | Synthetic Assets on Cardano",
    id_str: 1377800316561850369,
  },
  {
    screen_name: "xdefi_wallet",
    name: "XDEFI Wallet",
    id_str: 1300069028975779841,
  },
  {
    screen_name: "InjectiveLord",
    name: "Injective Lord",
    id_str: 1625163498538893312,
  },
  { screen_name: "jsky_crypto", name: "JSKY", id_str: 1369306111250202643 },
  {
    screen_name: "Memeland",
    name: "Memeland \u2764\ufe0f Memecoin",
    id_str: 1491285218422300673,
  },
  {
    screen_name: "CryptoTitans1",
    name: "CryptoTiTans (\u26ab,\u26aa)",
    id_str: 1312166188697489408,
  },
  {
    screen_name: "BscPitbull",
    name: "Pitbull Token",
    id_str: 1372251751638822914,
  },
  {
    screen_name: "Crypto_Rush0",
    name: "Crypto Rush | AMA + Space",
    id_str: 1376761856749006848,
  },
  {
    screen_name: "Crypto_Twittier",
    name: "Anonymous | Crypto Predictions",
    id_str: 897550887257878528,
  },
  { screen_name: "CryptoGenzo", name: "Genzo", id_str: 1345718276819738624 },
  {
    screen_name: "ChainlinkToday",
    name: "Chainlink Today",
    id_str: 1368743841122115587,
  },
  {
    screen_name: "JamesPelton18",
    name: "James Pelton",
    id_str: 1455697511193067525,
  },
  {
    screen_name: "zilliqaportal",
    name: "Zilliqa Portal",
    id_str: 1555530309537505281,
  },
  {
    screen_name: "Ukraine",
    name: "Ukraine / \u0423\u043a\u0440\u0430\u0457\u043d\u0430",
    id_str: 732521058507620356,
  },
  { screen_name: "galazryy", name: "gala", id_str: 1297954777972654080 },
  {
    screen_name: "NeofiOfficial",
    name: "NeoFi - Crypto Investments Done Right",
    id_str: 1295983992554971137,
  },
  {
    screen_name: "crypto__mercy",
    name: "Crypto Mercy",
    id_str: 1185600404756795392,
  },
  {
    screen_name: "KeyOfLife_fi",
    name: "KeyOfLife - Multichain Store Of Value KOL",
    id_str: 1485483338211405825,
  },
  { screen_name: "WoofSolana", name: "Woof", id_str: 1392201953086558210 },
  {
    screen_name: "crypto_anita",
    name: "Crypto Anita | Wagmi.com",
    id_str: 1222611451405393921,
  },
  {
    screen_name: "CryptoMYsia",
    name: "CryptoMalaysia",
    id_str: 1433145092827582472,
  },
  {
    screen_name: "VinceNCrypto",
    name: "VinceNCrypto \u26a1\ud83d\udc33",
    id_str: 1395779698857238529,
  },
  {
    screen_name: "UpOnlyOfficial",
    name: "UpOnly!",
    id_str: 1402974306309599232,
  },
  {
    screen_name: "stoic_traders",
    name: "Stoic Crypto Trader",
    id_str: 1354027537245073408,
  },
  {
    screen_name: "MonsterGalaxy21",
    name: "Monster Galaxy P2E\ud83d\udc32\ud83d\udd0a Play and Earn!",
    id_str: 1457955424628477953,
  },
  { screen_name: "kris", name: "Kris | Crypto.com", id_str: 84850888 },
  {
    screen_name: "KyleLDavies",
    name: "Kyle \ud83d\udc02",
    id_str: 1479436486957322244,
  },
  { screen_name: "axotrade", name: "Axo", id_str: 1384492289964212225 },
  { screen_name: "axotrade", name: "Axo", id_str: 1384492289964212225 },
  {
    screen_name: "Green_Beli",
    name: "Green Beli",
    id_str: 1239772605659275270,
  },
  {
    screen_name: "PolkadotNow",
    name: "Polkadot Now",
    id_str: 1564226739164696576,
  },
  {
    screen_name: "UNCX_token",
    name: "UNCX Network",
    id_str: 1275709288787345408,
  },
  {
    screen_name: "Zone_BSC",
    name: "BSC Zone \u2605",
    id_str: 1479720353106907144,
  },
  {
    screen_name: "OptimismHub",
    name: "Optimism Hub (\u2728\ud83d\udd34_\ud83d\udd34\u2728)",
    id_str: 1380389898348154880,
  },
  { screen_name: "CryptoWinds1", name: "CryptoWinds", id_str: 2324778674 },
  { screen_name: "iotamorons", name: "MORONS", id_str: 938435930951766016 },
  { screen_name: "jerrybrito", name: "Jerry Brito", id_str: 794532 },
  {
    screen_name: "XDCFoundation",
    name: "XDC Foundation",
    id_str: 1458842463691546629,
  },
  {
    screen_name: "EfloudTheSurfer",
    name: "Efloud",
    id_str: 1063939505827323904,
  },
  { screen_name: "fuckyoumidgy", name: "Ran \ud83e\udebd", id_str: 493061376 },
  {
    screen_name: "KennethDredd",
    name: "Kenneth Dredd",
    id_str: 1103241617496915971,
  },
  { screen_name: "Coinbaas_NL", name: "Coinboss", id_str: 946630358895878144 },
  { screen_name: "CryptoFutureCF", name: "CryptoFutureCF", id_str: 3310816522 },
  { screen_name: "smallcappick", name: "Crypto Love", id_str: 3304958859 },
  { screen_name: "TiggersCrypto", name: "Tiggah", id_str: 1146413843616325632 },
  {
    screen_name: "cryptopolisgame",
    name: "Cryptopolis",
    id_str: 1428376185940099078,
  },
  {
    screen_name: "coinhall_org",
    name: "Coinhall \ud83d\udcc8 \ud83d\udd04",
    id_str: 1425610654900133891,
  },
  {
    screen_name: "ShibThomas",
    name: "$\ud835\udd16\ud835\udd25\ud835\udd26\ud835\udd1f \ud835\udd17\ud835\udd25\ud835\udd2c\ud835\udd2a\ud835\udd1e\ud835\udd30 \ud83c\uddfa\ud83c\udde6\ud83d\ude4f\ud83c\udffb",
    id_str: 2584610619,
  },
  {
    screen_name: "NotumDefi",
    name: "Notum | DeFi Investments",
    id_str: 1357270392948277253,
  },
  {
    screen_name: "BullishDumpling",
    name: "Bullish Dumpling",
    id_str: 1361417279418859520,
  },
  {
    screen_name: "therationalroot",
    name: "Root \ud83e\udd55",
    id_str: 859759392681545728,
  },
  { screen_name: "XRP_Productions", name: "PP", id_str: 1131352246573195264 },
  { screen_name: "Tether_to", name: "Tether", id_str: 2893133450 },
  {
    screen_name: "bitcoin__apex",
    name: "Bitcoin_apex",
    id_str: 1019668241910812672,
  },
  {
    screen_name: "asiansmallcoin",
    name: "asiansmallballs",
    id_str: 1547644446581202944,
  },
  {
    screen_name: "BinanceUS",
    name: "Binance.US \ud83c\uddfa\ud83c\uddf8",
    id_str: 1115465940831891457,
  },
  {
    screen_name: "BlockchainAssn",
    name: "Blockchain Association",
    id_str: 1029797947540746242,
  },
  {
    screen_name: "_CryptoThunder_",
    name: "Crypto\u26a1\ufe0fThunder",
    id_str: 1364245299255009282,
  },
  {
    screen_name: "Genius_Crypto_",
    name: "G\u00ea\u0148\u00ed\u00fb\u00df\ud83d\udd38Crypto",
    id_str: 2549855215,
  },
  { screen_name: "HsakaTrades", name: "Hsaka", id_str: 944686196331966464 },
  { screen_name: "limewire", name: "LimeWire", id_str: 247358809 },
  { screen_name: "tier10k", name: "db", id_str: 2361601055 },
  { screen_name: "PendulumFlow", name: "Pendulum Flow", id_str: 51686561 },
  { screen_name: "ArowNFT", name: "Arow", id_str: 637406742 },
  {
    screen_name: "Zeus_Crypto0",
    name: 'Zeus Crypto\u26a1\ufe0f"\u2764\ufe0f "',
    id_str: 1424264629618757639,
  },
  { screen_name: "CosmonautJay", name: "Cosmonaut Jay", id_str: 9532092 },
  {
    screen_name: "nikhileshde",
    name: "@nikhileshde@journa.host",
    id_str: 69614360,
  },
  {
    screen_name: "pumpcoinbets",
    name: "Pumpcoinbets - Crypto & NFT",
    id_str: 1023405063253807109,
  },
  { screen_name: "xMoney_com", name: "xMoney.com", id_str: 880470278408724481 },
  {
    screen_name: "lyu_johnny",
    name: "Johnny_KuCoin",
    id_str: 1025361817457975298,
  },
  { screen_name: "Ripple", name: "Ripple", id_str: 1051053836 },
  {
    screen_name: "CryptocapoOO",
    name: "cryptocapo",
    id_str: 1363250266821246977,
  },
  {
    screen_name: "CryptoDuck_TH",
    name: "CryptoDuck",
    id_str: 1043050580191502336,
  },
  {
    screen_name: "CryptoIdleMiner",
    name: "Crypto Idle Miner",
    id_str: 996167205946568704,
  },
  { screen_name: "LindaPJones", name: "Linda P. Jones", id_str: 15427096 },
  { screen_name: "ZakaWaqar", name: "Waqar Zaka", id_str: 1144885988 },
  {
    screen_name: "BioFiGlobal",
    name: "Biometric Financial",
    id_str: 1145764421840429057,
  },
  { screen_name: "LomahCrypto", name: "Loma", id_str: 995500158417711104 },
  {
    screen_name: "girlgone_crypto",
    name: "Girl Gone Crypto",
    id_str: 1150790822813560833,
  },
  {
    screen_name: "TodayInPolygon",
    name: "Today In Polygon",
    id_str: 1433947960551804936,
  },
  {
    screen_name: "tokenizedkiwi",
    name: "Tokenized Kiwi",
    id_str: 1436347271709351938,
  },
  {
    screen_name: "CryptoBoy55555",
    name: "Crypto Boy",
    id_str: 905928660825374721,
  },
  { screen_name: "0xCha0s", name: "0xChaos", id_str: 924134005 },
  {
    screen_name: "solananfter",
    name: "solananfter",
    id_str: 1437981489271017481,
  },
  { screen_name: "TokenCeres", name: "Ceres", id_str: 1343661021232304135 },
  {
    screen_name: "crypt0_me",
    name: "CRYPT0 ME \u2728",
    id_str: 1369290136911970305,
  },
  {
    screen_name: "STXLDN",
    name: "STX:LDN (Stacks London)",
    id_str: 1522696787936616448,
  },
  {
    screen_name: "ZeusInCrypto",
    name: "Zeus \ud83d\udd36 Hamad",
    id_str: 1578089796588675072,
  },
  {
    screen_name: "BscRetoken",
    name: "Renewable Energy Token",
    id_str: 1467082354032353283,
  },
  {
    screen_name: "bilpcoinbpc",
    name: "Bilpcoinbpc",
    id_str: 1354044773707997184,
  },
  {
    screen_name: "crypto___baby",
    name: "0xCryptoBaby\ud83d\udc30\u2601\ufe0f",
    id_str: 2273110974,
  },
  {
    screen_name: "NFTFeedOfficial",
    name: "NFTFeed \ud83d\udee1\ufe0f| Mainnet Live",
    id_str: 1552929446373773312,
  },
  {
    screen_name: "DogechainFamily",
    name: "Dogechain\ud83d\udc9c (Giving away a Tesla)",
    id_str: 1513260898332278785,
  },
  { screen_name: "MineBNB_", name: "Mine-BNB", id_str: 1632981234019123202 },
  {
    screen_name: "PolkadotSpace",
    name: "\ud83d\udea8 \ud835\udc0f\ud835\udc28\ud835\udc25\ud835\udc24\ud835\udc1a\ud835\udc1d\ud835\udc28\ud835\udc2d \ud835\udc12\ud835\udc29\ud835\udc1a\ud835\udc1c\ud835\udc1e \ud83d\udea8",
    id_str: 1402475985182760961,
  },
  {
    screen_name: "AMBERBTC888",
    name: "AM\u20bfER.\u20bfTC",
    id_str: 1396617588688363524,
  },
  {
    screen_name: "CryptosLaowai",
    name: "Jimmy \u7c73\u54e5 \ud83d\ude80\ud83d\ude80\ud83d\ude80",
    id_str: 1401887084076429324,
  },
  {
    screen_name: "LadyTraderRa",
    name: "LadyTraderRa.eth",
    id_str: 1401716923545448452,
  },
  { screen_name: "JRNYCLUB", name: "JRNY CLUB", id_str: 1455314963937980420 },
  {
    screen_name: "ECrypto0",
    name: "Ed \ud83c\udf39",
    id_str: 1453104014867025921,
  },
  { screen_name: "VelarBTC", name: "Velar", id_str: 1637762374433112064 },
  {
    screen_name: "DiarioBitcoin",
    name: "Diario\u0e3fitcoin\uea00",
    id_str: 2300299530,
  },
  {
    screen_name: "ALTCOIN4LIFE123",
    name: "CRYPTO 4 LIFE",
    id_str: 1487812169173377028,
  },
  {
    screen_name: "Cryptogemsguru",
    name: "Crypto Gems Guru \ud83d\udc8e",
    id_str: 1393914785209724928,
  },
  {
    screen_name: "Cryptoembassyy",
    name: "Crypto Embassy",
    id_str: 732283942405988353,
  },
  {
    screen_name: "Debest_Crypto",
    name: "Debest \ud83d\udcca\ud83d\ude80\ud83e\uddec",
    id_str: 1528711453141778434,
  },
  {
    screen_name: "CwalletOfficial",
    name: "Cwallet",
    id_str: 1209048414387765248,
  },
  {
    screen_name: "cryptofishx",
    name: "fish \ud83d\udc1f",
    id_str: 1370080218153680905,
  },
  {
    screen_name: "TheCryptoBlade",
    name: "The Crypto Blade",
    id_str: 1394665045867499523,
  },
  {
    screen_name: "BitMaker_",
    name: "BitMaker \u26a1",
    id_str: 1513466616994045952,
  },
  { screen_name: "lawmaster", name: "Larry Cermak", id_str: 16709601 },
  {
    screen_name: "PlayLoveMonster",
    name: "Love Monster",
    id_str: 1448086259105157126,
  },
  {
    screen_name: "0xPolygonFdn",
    name: "Polygon Foundation",
    id_str: 1290205144815108099,
  },
  { screen_name: "AXE", name: "AXE", id_str: 20205854 },
  {
    screen_name: "CointelegraphZN",
    name: "Cointelegraph Magazine",
    id_str: 1232353941871947776,
  },
  { screen_name: "MakerDAO", name: "Maker", id_str: 3190865591 },
  { screen_name: "SPYJared", name: "jaredblikre.ai", id_str: 360019454 },
  { screen_name: "BitTorrent", name: "BitTorrent", id_str: 36208197 },
  {
    screen_name: "crypto_logie",
    name: "crypto_logy",
    id_str: 1385772951274852355,
  },
  {
    screen_name: "DjedStablecoin",
    name: "\ud80c\udebd DJED \ud80c\udebd",
    id_str: 1442410897150918660,
  },
  {
    screen_name: "CoinTRpro",
    name: "CoinTR | PRO",
    id_str: 1294355334744678406,
  },
  {
    screen_name: "polygonflow",
    name: "Polygonflow",
    id_str: 1277396828682584065,
  },
  { screen_name: "wellyfriends", name: "welly", id_str: 1445150769129345024 },
  { screen_name: "vladtenev", name: "Vlad Tenev", id_str: 605700792 },
  {
    screen_name: "takegreenpill",
    name: "Green Pill \ud83d\udfe2\ud83e\udd76\ud83c\udf3d\ud83c\udf3e",
    id_str: 1278730153275920385,
  },
  {
    screen_name: "Picoin__Pi",
    name: "Picoin\u26a1\ufe0f\u03c0",
    id_str: 1228026476928405507,
  },
  {
    screen_name: "Trolltoshi",
    name: "Trolltoshi",
    id_str: 1587445097632866304,
  },
  {
    screen_name: "CryptoSapiens_",
    name: "cryptosapiens.eth",
    id_str: 1440441674392506390,
  },
  {
    screen_name: "XummWallet",
    name: "Xaman (formerly Xumm) Wallet \ud83e\ude9d",
    id_str: 1280799361744744448,
  },
  { screen_name: "ChainGab", name: "Chain Gab", id_str: 3084613643 },
  {
    screen_name: "CryptoPredattor",
    name: "Predator",
    id_str: 1649015505641144324,
  },
  {
    screen_name: "Degen_Everyday",
    name: "Degen Loot",
    id_str: 1678213705211977728,
  },
  {
    screen_name: "UltiverseDAO",
    name: "Ultiverse",
    id_str: 1499022668913328133,
  },
  {
    screen_name: "bnb_central",
    name: "BNB CENTRAL",
    id_str: 1502298149691400194,
  },
  { screen_name: "cryptojeodezi", name: "Jeodezi", id_str: 950134213965176833 },
  {
    screen_name: "AureliusBTC",
    name: "AureliusBTC",
    id_str: 998302896620953600,
  },
  { screen_name: "CoinCodex", name: "CoinCodex", id_str: 874564226848886784 },
  {
    screen_name: "IAM_X_IDENTITY",
    name: "IAMX - Own Your Identity",
    id_str: 1420663296408031234,
  },
  {
    screen_name: "BryanLegendCEO",
    name: "Bryan Legend",
    id_str: 1337709637945106432,
  },
  {
    screen_name: "CryptoPicsou",
    name: "Crypto Picsou",
    id_str: 1359826971694407686,
  },
  { screen_name: "TimStackss", name: "Tim Stacks", id_str: 2941795631 },
  {
    screen_name: "zcash",
    name: "Zcash \ud83d\udee1\ufe0f",
    id_str: 936138944080678912,
  },
  {
    screen_name: "CryptoAcid",
    name: "CryptoAcid (\ud83d\udc99,\ud83e\udde1)\ud83e\udd87\ud83d\udd0a",
    id_str: 4036376352,
  },
  {
    screen_name: "inCryptojack",
    name: "CryptoJack \ud83d\ude80",
    id_str: 1124644777,
  },
  {
    screen_name: "CryptoTownEU",
    name: "CryptoTown",
    id_str: 1034478294677250048,
  },
  { screen_name: "Ripple1026", name: "Ian Ripple", id_str: 79294846 },
  {
    screen_name: "Chico_Crypto_",
    name: "Chico Crypto (OFFICIAL CHICO CRYPTO)",
    id_str: 1051097917614944257,
  },
  { screen_name: "MoneroKon", name: "MoneroKon", id_str: 1115323814034862080 },
  {
    screen_name: "Block_Flash",
    name: "Bull Flash \ud835\udd4f",
    id_str: 63429948,
  },
  {
    screen_name: "myetherwallet",
    name: "MyEtherWallet | MEW",
    id_str: 4831010888,
  },
  {
    screen_name: "TheRyanLion",
    name: "RyanLion \ud83c\udf12",
    id_str: 1326366156173824000,
  },
  {
    screen_name: "TylerSCrypto",
    name: "Tyler Strejilevich",
    id_str: 952748996392554501,
  },
  {
    screen_name: "Cryptomireris",
    name: "\u00dcmit.eth",
    id_str: 1328599849554636801,
  },
  {
    screen_name: "JumpDashRoll",
    name: "Jump Dash Roll",
    id_str: 864887876517068800,
  },
  {
    screen_name: "Hotbit_news",
    name: "HotbitDEX \ud83c\udf40",
    id_str: 1085357358186881025,
  },
  { screen_name: "jeffjohnroberts", name: "Jeff Roberts", id_str: 17925409 },
  {
    screen_name: "PositiveCrypto",
    name: "Philip Swift",
    id_str: 874629802422145025,
  },
  { screen_name: "KicksPad_io", name: "KicksPad", id_str: 1519408166592819200 },
  {
    screen_name: "casub_crypto",
    name: "casub_crypt\u2b55(\u03c4,\u03c4)(\ud83d\udc99,\ud83e\udde1)\u269b\ufe0f\ud83d\udc10\ud83d\udef8",
    id_str: 1467829165164539908,
  },
  {
    screen_name: "MinaDevelopers",
    name: "Mina Developers \ud83e\udeb6",
    id_str: 1552763207789543437,
  },
  {
    screen_name: "PulsarFinance",
    name: "Pulsar Finance",
    id_str: 1408040900345933825,
  },
  {
    screen_name: "MoonriverNW",
    name: "Moonriver Network",
    id_str: 1400186867258204161,
  },
  {
    screen_name: "OP_allday",
    name: "Optimism allday \ud83d\udd34",
    id_str: 1629454415584464902,
  },
  {
    screen_name: "PresentsAirdrop",
    name: "Airdrop Presents \ud83d\uddfd",
    id_str: 1506970403805462529,
  },
  {
    screen_name: "eCashtab",
    name: "eCashtab \ud83d\udd3a\ud83c\udf0b",
    id_str: 1472467533592629249,
  },
  {
    screen_name: "redphonecrypto",
    name: "redphone \u260e\ufe0f\ud83e\uddd9\ud83c\udffb\u200d\u2642\ufe0f",
    id_str: 1257128739886051330,
  },
  {
    screen_name: "cryptomigss",
    name: "Crypto Migs",
    id_str: 1346601334980681728,
  },
  {
    screen_name: "CryptoWalletcom",
    name: "CryptoWallet.com",
    id_str: 1251445322494205953,
  },
  {
    screen_name: "SumoKuji",
    name: "Sumo \ud83c\ude50",
    id_str: 1435723345304096768,
  },
  {
    screen_name: "paddi_hansen",
    name: "Patrick Hansen",
    id_str: 1222139996951457794,
  },
  {
    screen_name: "_CryptoDiamond",
    name: "CRyptO Diamond \ud83d\udc8e",
    id_str: 1435623763605921799,
  },
  {
    screen_name: "AstarterDefiHub",
    name: "Astarter",
    id_str: 1341189064679108609,
  },
  { screen_name: "id_iota", name: "ID.iota", id_str: 1433726033136660496 },
  { screen_name: "Panterra0x", name: "panterra", id_str: 1353509326489530369 },
  {
    screen_name: "traderomercan",
    name: "Trader \u00d6mer \ud83e\udd81",
    id_str: 1207695539388735496,
  },
  { screen_name: "ETH_Army_", name: "ETH ARMY", id_str: 1687754910 },
  { screen_name: "syscoin", name: "Syscoin", id_str: 119060937 },
  {
    screen_name: "wearetalisman",
    name: "Talisman",
    id_str: 1412977944629182472,
  },
  {
    screen_name: "mindandtrading",
    name: "Mind Trader",
    id_str: 763412134495522820,
  },
  {
    screen_name: "SPCryptoAnalyst",
    name: "SP Crypto \ud835\udd4f",
    id_str: 1470628458682269696,
  },
  {
    screen_name: "BitDotCountry",
    name: "Bit.Country",
    id_str: 1299932702557138946,
  },
  {
    screen_name: "WSGToken",
    name: "Wall Street Games Token",
    id_str: 1345868806158229506,
  },
  {
    screen_name: "OpenOceanGlobal",
    name: "OpenOcean - A leading DeFi Aggregator",
    id_str: 1295973574725926913,
  },
  {
    screen_name: "GeneralCrypto_",
    name: "The General",
    id_str: 1474892689959268356,
  },
  {
    screen_name: "TokensoftInc",
    name: "Tokensoft",
    id_str: 918180298327711744,
  },
  {
    screen_name: "PolygonDaily",
    name: "Polygon Daily \ud83d\udc9c",
    id_str: 1398219687079538689,
  },
  {
    screen_name: "Coinstages",
    name: "Coinstages",
    id_str: 1265346267300786176,
  },
  {
    screen_name: "NFTradeOfficial",
    name: "NFTrade",
    id_str: 1378716588489199617,
  },
  {
    screen_name: "NFTCulture",
    name: "NFT CULTURE",
    id_str: 1353416238152691712,
  },
  {
    screen_name: "jackfriks2",
    name: "jack friks too",
    id_str: 1171434377009598464,
  },
  {
    screen_name: "CryptoMitch4",
    name: "CryptoMitch",
    id_str: 1195828001067458560,
  },
  {
    screen_name: "CelerNetwork",
    name: "CelerNetwork",
    id_str: 969093664348631040,
  },
  { screen_name: "aleixcrypto", name: "Alexei", id_str: 966243872555847680 },
  {
    screen_name: "ETHIndiaco",
    name: "ETHIndia \ud83e\udd87\ud83d\udd0a",
    id_str: 964919649837056000,
  },
  {
    screen_name: "BinanceLabs",
    name: "Binance Labs Fund",
    id_str: 960036444885565442,
  },
  {
    screen_name: "bifrost_finance",
    name: "doni darussalam $jup",
    id_str: 958315927543492609,
  },
  { screen_name: "cryptoes_ta", name: "Cryptoes", id_str: 911641891497742337 },
  {
    screen_name: "MinaFoundation",
    name: "Mina Foundation (httpz) \ud83e\udeb6",
    id_str: 1310635665470550018,
  },
  {
    screen_name: "JayBlessed901",
    name: "XRP-JayB\ud83d\udc1d",
    id_str: 911585169060003844,
  },
  { screen_name: "POODLCHAIN", name: "POODL", id_str: 1370707605702729732 },
  {
    screen_name: "TuongvyLe12",
    name: "TuongVy Le \ud83c\udf4e\ud83d\uddfd\ud83d\ude95",
    id_str: 1374457006791127051,
  },
  { screen_name: "OniiEth", name: "Onii.eth", id_str: 1378496716983447552 },
  {
    screen_name: "TheDoggyCoin",
    name: "Crypto Doggies",
    id_str: 1392186306873094146,
  },
  { screen_name: "RealKiwiCrypto", name: "KiwiCrypto", id_str: 44554759 },
  { screen_name: "ArjunKharpal", name: "Arjun Kharpal", id_str: 285051289 },
  {
    screen_name: "TheThirdFloor",
    name: "THE THIRD FLOOR, INC.",
    id_str: 27792817,
  },
  {
    screen_name: "CryptoCowb0i",
    name: "Cowb0i.sol \ud83d\udfe8",
    id_str: 1405108662369230858,
  },
  {
    screen_name: "shibarium_",
    name: "shibarium1",
    id_str: 1411910201758957569,
  },
  {
    screen_name: "Chiefofthecoins",
    name: "COIN CHIEF \u2660\ufe0f",
    id_str: 1703317136825851904,
  },
  {
    screen_name: "Vince_Van_Dough",
    name: "VincentVanDough",
    id_str: 1415760166667145227,
  },
  {
    screen_name: "CardanoThor",
    name: "CardanoThor",
    id_str: 1432795349001846790,
  },
  {
    screen_name: "Ryuzaki_SOL",
    name: "Ryuzaki S\u25ceL",
    id_str: 1437334357484589061,
  },
  {
    screen_name: "FlowX_finance",
    name: "FlowX Finance",
    id_str: 1605461197687468033,
  },
  {
    screen_name: "0xMantleEco",
    name: "Mantle Ecosystem",
    id_str: 1597261212596973568,
  },
  {
    screen_name: "ShepNFTs",
    name: "Shep || MEEP \ud83d\udc11",
    id_str: 1532488780803985419,
  },
  {
    screen_name: "CryptoDragon001",
    name: "CRYPTO DRAGON",
    id_str: 1528376531973816321,
  },
  {
    screen_name: "jestertrading33",
    name: "JesterTrading",
    id_str: 1446097113729884161,
  },
  {
    screen_name: "PollenDeFi",
    name: "Pollen DeFi \ud83d\udd3a",
    id_str: 1246458876456767488,
  },
  {
    screen_name: "TheCryptoMewtwo",
    name: "TheCryptoMewtwo.eth",
    id_str: 1279979212162523136,
  },
  { screen_name: "XRPXLMVERSE", name: "XRPDAILY", id_str: 1281582993610543104 },
  {
    screen_name: "DynamicAirdrops",
    name: "Dynamic Airdrops",
    id_str: 1288293005846142978,
  },
  { screen_name: "Pavia_io", name: "Pavia", id_str: 1293818320387538944 },
  {
    screen_name: "CryptoRgreen",
    name: "CryptoRGreen \u26a1",
    id_str: 1382414974732091393,
  },
  {
    screen_name: "VegasInuToken",
    name: "VegasInuToken",
    id_str: 1707557490043617280,
  },
  {
    screen_name: "ThorHartvigsen",
    name: "Thor Hartvigsen",
    id_str: 3039445715,
  },
  {
    screen_name: "luffyinutoken",
    name: "Luffy Token Official",
    id_str: 565908265,
  },
  {
    screen_name: "ZcashFoundation",
    name: "Zcash Foundation \ud83d\udee1\ufe0f",
    id_str: 836606419965202438,
  },
  { screen_name: "BonkzNFT_", name: "BONKz", id_str: 1613632201446522880 },
  { screen_name: "bilalgorand", name: "bilal.algo", id_str: 64669085 },
  {
    screen_name: "KyberNetwork",
    name: "Kyber Network",
    id_str: 865963965649571840,
  },
  { screen_name: "OPLabsPBC", name: "OP Labs", id_str: 1516836136517312527 },
  {
    screen_name: "el33th4xor",
    name: "Emin G\u00fcn Sirer\ud83d\udd3a",
    id_str: 399412477,
  },
  {
    screen_name: "cosmoshub",
    name: "Cosmos Hub \u269b\ufe0f",
    id_str: 1019126440233615361,
  },
  {
    screen_name: "Debi_CryptoNFTs",
    name: "debicrypto",
    id_str: 1691935011648507904,
  },
  {
    screen_name: "BitcoinMemeHub",
    name: "\u20bfitcoin Meme Hub \ud83d\udd1e",
    id_str: 494704739,
  },
  { screen_name: "polkadex", name: "Polkadex", id_str: 1328946367742533632 },
  {
    screen_name: "_SmartLiquidity",
    name: "SMART LIQUIDITY",
    id_str: 1421228670170148869,
  },
  {
    screen_name: "TurkishGiveaway",
    name: "TGW Crypto",
    id_str: 1036943512502259712,
  },
  {
    screen_name: "Meta_labz",
    name: "MetaLabz.gg",
    id_str: 1464427292458098688,
  },
  {
    screen_name: "a16zcrypto",
    name: "a16z crypto",
    id_str: 1539681011696603137,
  },
  { screen_name: "VitalikButerin", name: "vitalik.eth", id_str: 295218901 },
  {
    screen_name: "CryptoPoulpe",
    name: "Poulpe \ud83d\udc19",
    id_str: 1077509392478732289,
  },
  {
    screen_name: "usssks",
    name: "Steven \u26a1\ufe0f $DOGE",
    id_str: 1355895074702372865,
  },
  { screen_name: "wabdoteth", name: "wab.eth", id_str: 1417876510413885444 },
  {
    screen_name: "MoneroTalk",
    name: "MoneroTalk",
    id_str: 1014222620127096834,
  },
  {
    screen_name: "gmx_intern",
    name: "GMX Intern \ud83e\uded0\ud83c\udff0",
    id_str: 1428266588734693382,
  },
  { screen_name: "aave", name: "Aave Labs", id_str: 867100084248469505 },
  {
    screen_name: "CryptoChrys17",
    name: "Crypto Chrys",
    id_str: 1620852129916125203,
  },
  { screen_name: "dingalingts", name: "dingaling", id_str: 936048782206058496 },
  {
    screen_name: "KrownCryptoCave",
    name: "Eric Crown",
    id_str: 807022837777203201,
  },
  {
    screen_name: "INotoriousxrp",
    name: "\u019d\u01a0\u01ac\u01a0\u01a6\u0196\u01a0\u01b2\u01a7\u04b2\u01a6\u01a4 \ud83e\udd8b",
    id_str: 1059974447166304256,
  },
  {
    screen_name: "gametheorizing",
    name: "Jordi Alexander",
    id_str: 1081354216843632640,
  },
  {
    screen_name: "cryptoismyjam",
    name: "Jade \ud83e\udde9",
    id_str: 410380902,
  },
  {
    screen_name: "beincrypto_es",
    name: "BeInCrypto Espa\u00f1ol \ud83d\uddde",
    id_str: 1141636196776448000,
  },
  {
    screen_name: "Smart_NFT_News",
    name: "\ud83c\udf86 Smart_NFT_News \ud83c\udf86",
    id_str: 1321681599755268096,
  },
  {
    screen_name: "IbaxNetwork",
    name: "IBAX Network",
    id_str: 1323549139905380353,
  },
  { screen_name: "InuKishu", name: "Kishu Inu", id_str: 1333281524930781192 },
  {
    screen_name: "CryptoTrends",
    name: "Crypto Trends",
    id_str: 1333497225696129025,
  },
  {
    screen_name: "Hyena_Coin",
    name: "Sahara.social \ud83c\udfdc\ufe0f",
    id_str: 1619773105479581699,
  },
  { screen_name: "baconesq", name: "BaconEsq", id_str: 1360363582408577026 },
  {
    screen_name: "subsquid",
    name: "Subsquid \ud83e\udd91",
    id_str: 1417081246904197121,
  },
  {
    screen_name: "ApertureFinance",
    name: "Aperture Finance",
    id_str: 1422298025603436568,
  },
  {
    screen_name: "MemeCoinHeaven",
    name: "HeavenSent.eth",
    id_str: 1422671911797104640,
  },
  {
    screen_name: "Neo_Tokyo_News",
    name: "Neo Tokyo News",
    id_str: 1503643067366055936,
  },
  {
    screen_name: "CryptoPrestito",
    name: "PrestitoCrypto",
    id_str: 1373978436872466436,
  },
  {
    screen_name: "TradeStrikeBVI",
    name: "StrikeX",
    id_str: 1373345277868933121,
  },
  { screen_name: "initializable", name: "Bravo", id_str: 2289806010 },
  {
    screen_name: "coinacademy_fr",
    name: "Coin Academy",
    id_str: 1218201754153750533,
  },
  {
    screen_name: "EVNFTreal",
    name: "E.V Cyborgs",
    id_str: 1578105137817653260,
  },
  { screen_name: "OmniBTC", name: "OmniBTC", id_str: 1485889072774086657 },
  {
    screen_name: "kado_money",
    name: "Kado \ud83e\udd51",
    id_str: 1348866857672069123,
  },
  {
    screen_name: "XANANFTmarket",
    name: "XANA NFT",
    id_str: 1412321315911049216,
  },
  {
    screen_name: "tfm_com",
    name: "TFM \ud83e\ude90 Modular Liquidity Layer for Cosmos Chains",
    id_str: 143228545,
  },
  { screen_name: "pxlzNFT", name: "DEADPXLZ", id_str: 1369640533858091010 },
  {
    screen_name: "MEonPolygon",
    name: "Magic Eden on Polygon \ud83d\udc9c",
    id_str: 1581808108820914176,
  },
  {
    screen_name: "CronosChainNews",
    name: "CronosChainNews.Com",
    id_str: 1467925715085664267,
  },
  {
    screen_name: "PhotosDash",
    name: "Rainbow Dash Photos",
    id_str: 1188775355709968385,
  },
  {
    screen_name: "DrCrypto01",
    name: "Dr. Crypto",
    id_str: 1402990040498667526,
  },
  { screen_name: "QuantMeta", name: "Metaquant", id_str: 1582601037550325760 },
  { screen_name: "cryptomaneitaly", name: "Cryptomane", id_str: 729030936 },
  {
    screen_name: "the_ape_society",
    name: "The Ape Society",
    id_str: 1447734876552286211,
  },
  {
    screen_name: "MinaMyouiBrasil",
    name: "Mina Myoui Brasil",
    id_str: 1286741906194681856,
  },
  { screen_name: "ibunscripted", name: "brandon", id_str: 368647656 },
  { screen_name: "askthedr", name: "Dr. Crypto \u00ae", id_str: 35689265 },
  {
    screen_name: "ttcoinofficial",
    name: "TTcoin Network | $TC",
    id_str: 1380848514410942466,
  },
  {
    screen_name: "bachkhoabnb",
    name: "Th\u00e0nh Phan",
    id_str: 1448996542770077698,
  },
  {
    screen_name: "TheGariNetwork",
    name: "GARI - World's Biggest Web3 Social Community\ud83d\udd25",
    id_str: 1444002359516925954,
  },
  {
    screen_name: "EllenQueenNFT",
    name: "\u039eLL\u039eN \u1d3a\u1da0\u1d40",
    id_str: 144929836,
  },
  {
    screen_name: "CrogeCoin",
    name: "Crogecoin \ud83d\udc0a",
    id_str: 1502764534267846659,
  },
  {
    screen_name: "ONTOWallet",
    name: "ONTO Wallet - Web3 Gateway",
    id_str: 1279581944867459072,
  },
  {
    screen_name: "CryptoCoinator",
    name: "Akira \ud83d\ude08",
    id_str: 1381049328467243009,
  },
  { screen_name: "thecleotoken", name: "The $CLEO Token", id_str: 3049201359 },
  { screen_name: "tg_cryptos", name: "TGG", id_str: 819432341047218179 },
  {
    screen_name: "Casper_popular",
    name: "Casper Community I CSPR",
    id_str: 2966505803,
  },
  { screen_name: "under30bnb", name: "under30", id_str: 2600062736 },
  {
    screen_name: "iCryptoTesla",
    name: "TESLA.SHIB",
    id_str: 1417441083961790464,
  },
  {
    screen_name: "rphmeier",
    name: "asynchronous rob",
    id_str: 774217808628645891,
  },
  {
    screen_name: "thecryptocactus",
    name: "Cactus",
    id_str: 1003316046655381504,
  },
  { screen_name: "UberFacts", name: "UberFacts", id_str: 95023423 },
  {
    screen_name: "CosmostationVD",
    name: "Cosmostation",
    id_str: 1051710088098996224,
  },
  { screen_name: "Investments_CEO", name: "CEO", id_str: 1362987750312599552 },
  { screen_name: "iamdagtw", name: "nft girl", id_str: 1432483126899527685 },
  {
    screen_name: "RipplePandaXRP",
    name: "\ud83d\udc3cPandaRippleXRP\ud83d\udc3c",
    id_str: 967644039464931330,
  },
  {
    screen_name: "mycryptoguy69",
    name: "crypto-on-the-beat",
    id_str: 1457939437900337153,
  },
  {
    screen_name: "MuesliSwapTeam",
    name: "MuesliSwap Team\ud83e\udd5b",
    id_str: 1438517225917325313,
  },
  {
    screen_name: "CryptosHeaven1",
    name: "BNB MAXI \ud83d\udd36",
    id_str: 1392338231790694401,
  },
  { screen_name: "CryptoCred", name: "Cred", id_str: 899558268795842561 },
  {
    screen_name: "realProEGLD",
    name: "ProEGLD\ud835\udd4f SuperRareBears \ud835\udd4f",
    id_str: 1214985328705986560,
  },
  {
    screen_name: "NatasToken",
    name: "PixelDeMNs.xyz",
    id_str: 1436686239286104064,
  },
  {
    screen_name: "Dash_Community",
    name: "Digital Cash\u26a1\ud83d\udcb5",
    id_str: 1393347695524339713,
  },
  { screen_name: "iota404", name: "iota \u263e", id_str: 1270128460489527302 },
  {
    screen_name: "TheCryptoBelle",
    name: "CryptoBelle\ud83c\udf39",
    id_str: 3039554268,
  },
  {
    screen_name: "0x_eLCrypto",
    name: "Donald J. Pump",
    id_str: 1442874195109576710,
  },
  {
    screen_name: "xKingNFTx",
    name: "xKingNFTx \ud83d\udc19\ud83d\udc51",
    id_str: 1612517192989302791,
  },
  {
    screen_name: "TONiSwissTRON",
    name: "TONiSwiss TR\ud83c\udd7e\ufe0fN",
    id_str: 948630610737852416,
  },
  { screen_name: "cy_coleman", name: "Coleman", id_str: 943211139512532994 },
  { screen_name: "FuddlesNFT", name: "Fuddles", id_str: 1658346675356815361 },
  {
    screen_name: "SHIBCraftX",
    name: "SHIB Craft X",
    id_str: 1620431410480947201,
  },
  {
    screen_name: "selenaroyf",
    name: "Selena \ud83c\udde8\ud83c\udde6\ud83d\udc8e",
    id_str: 1331971465604960258,
  },
  { screen_name: "bitcoin_scoop", name: "Bitcoin Scoop", id_str: 2806482575 },
  {
    screen_name: "ETHTrendToday",
    name: "ETH Trending Today",
    id_str: 1387224270405701633,
  },
  { screen_name: "Oonabot", name: "Oonabot", id_str: 19688471 },
  { screen_name: "Atitty_", name: "Atitty", id_str: 840042680 },
  { screen_name: "HomeX_NFT", name: "Home X", id_str: 1572179799996596224 },
  {
    screen_name: "Casper_Labs",
    name: "Casper Labs",
    id_str: 1441063953388482561,
  },
  {
    screen_name: "TopCryptoKungfu",
    name: "TopCryptoKungfu",
    id_str: 1651260596954841092,
  },
  { screen_name: "benbybit", name: "Ben Zhou", id_str: 1100932506193260544 },
  { screen_name: "Crypto_Kaijii", name: "Kaiji", id_str: 3345502151 },
  {
    screen_name: "CryptoSafuGems",
    name: "CryptoSafu Gems",
    id_str: 1051496881,
  },
  {
    screen_name: "BTCZOfficial",
    name: "BITCOINZ",
    id_str: 1041350373187809281,
  },
  { screen_name: "risshuuu", name: "HOMELANDER", id_str: 1105434740230782976 },
  { screen_name: "montymetzger", name: "Monty Metzger", id_str: 10866802 },
  {
    screen_name: "TurtlesNFT",
    name: "Turtles \ud83d\udd3c\ud83d\udc22",
    id_str: 936146549947731969,
  },
  { screen_name: "DonnieBigBags", name: "Donnie", id_str: 773676379992883200 },
  {
    screen_name: "SebastienGllmt",
    name: "Sebastien Guillemot",
    id_str: 266454414,
  },
  {
    screen_name: "0xPolygonDeFi",
    name: "Polygon DeFi",
    id_str: 1321398370104004608,
  },
  {
    screen_name: "SolanaMummy",
    name: "Mummy | Laura \ud83d\udc9c",
    id_str: 1361349175431094272,
  },
  {
    screen_name: "SolanaMummy",
    name: "Mummy | Laura \ud83d\udc9c",
    id_str: 1361349175431094272,
  },
  {
    screen_name: "MultiVAC_Global",
    name: "MultiVAC",
    id_str: 1002781713603256320,
  },
  {
    screen_name: "0xMarketMaker",
    name: "Market Maker \ud83d\udc09",
    id_str: 1470475012268302342,
  },
  {
    screen_name: "ZcashRabbi",
    name: "\ud835\udc19\ud835\udc1c\ud835\udc1a\ud835\udc2c\ud835\udc21 \ud835\udc11\ud835\udc1a\ud835\udc1b\ud835\udc1b\ud835\udc22 \ud83d\udee1\ufe0f",
    id_str: 1299609944749010945,
  },
  {
    screen_name: "CoinexThailand",
    name: "CoinEx Thailand",
    id_str: 1466245851181838337,
  },
  { screen_name: "CroKingToken", name: "CroKing", id_str: 1505189222675890176 },
  { screen_name: "ericinjective", name: "Eric Chen", id_str: 2220273336 },
  {
    screen_name: "ChadThoreau",
    name: "Chad THOReau",
    id_str: 1395485033155031040,
  },
  {
    screen_name: "ol_smoothie",
    name: "Smoothie (Rog IRL)",
    id_str: 1311851026773762049,
  },
  { screen_name: "RealyOfficial", name: "Realy", id_str: 1340865915198423040 },
  {
    screen_name: "Flare_NFT",
    name: "NFTs on XRPL & Flare \u2600\ufe0f",
    id_str: 1471137902419709961,
  },
  {
    screen_name: "mannythehitman",
    name: "Mkay SaitaChain \u26d3\ufe0f",
    id_str: 1395073326947655682,
  },
  { screen_name: "bnbindex", name: "BNB Index", id_str: 1703606880298569728 },
  {
    screen_name: "MikeBurgersburg",
    name: "DIRTY BUBBLE MEDIA: GOOD LUCK, GOOD BYE.",
    id_str: 1399535047808622601,
  },
  { screen_name: "DeFiGav", name: "Gav", id_str: 1409093804536311816 },
  { screen_name: "cryptoneo17", name: "Matrix Guy", id_str: 141472845 },
  {
    screen_name: "PONGONSOLANA",
    name: "PONG { |\u2022| } | \ud83d\udfe1Listing Incoming |",
    id_str: 1430219557751468039,
  },
  {
    screen_name: "Maxi_maximalist",
    name: "DGRS Maxi",
    id_str: 1433188801665437703,
  },
  {
    screen_name: "cardano_daily",
    name: "Cardano Daily",
    id_str: 1393007027698298881,
  },
  {
    screen_name: "ConvertCryptoID",
    name: "OPEN CV $ Rate Di BIO",
    id_str: 1626613630635499527,
  },
  {
    screen_name: "crow_with_knife",
    name: "crow with knife",
    id_str: 1453599787476000770,
  },
  {
    screen_name: "Cryptorment",
    name: "Chris | Affinity CEO\ud83d\udd3a",
    id_str: 749790629727727616,
  },
  {
    screen_name: "EdenLuucas1",
    name: "Zeus\u26a1\ufe0f",
    id_str: 1312441104831328256,
  },
  {
    screen_name: "PlebSignalBTC",
    name: "-=FiatHasNoChance=-",
    id_str: 1157980350,
  },
  {
    screen_name: "aldo_trader",
    name: "Aldo Trader",
    id_str: 1345755759158689792,
  },
  {
    screen_name: "iamcryptocastle",
    name: "Crypto Castle",
    id_str: 1367263502906388487,
  },
  { screen_name: "babangARIE_", name: "ibo", id_str: 1908451524 },
  { screen_name: "iii_OIO_iii", name: "OIO", id_str: 1276528724318588929 },
  { screen_name: "CryptoGemsCom", name: "The Crypto GEMs", id_str: 352619090 },
  { screen_name: "bloodgoodBTC", name: "Bloodgood", id_str: 3183456849 },
  { screen_name: "ravanave", name: "Jarek Hirniak", id_str: 3026523395 },
  {
    screen_name: "fantom_insider",
    name: "Fantom Insider",
    id_str: 1476844114360430592,
  },
  {
    screen_name: "OpenLeverage",
    name: "OpenLeverage",
    id_str: 1381887284497145864,
  },
  { screen_name: "maxwellstrachan", name: "maxwell", id_str: 53113691 },
  {
    screen_name: "Sunny41404245",
    name: "B L A C K M O O N \ud83c\udf4a",
    id_str: 1357255751207706625,
  },
  { screen_name: "FluidToken", name: "Fluid", id_str: 1692704374714466304 },
  { screen_name: "iotabee", name: "Iotabee", id_str: 1516706133029040129 },
  {
    screen_name: "CryptoBull2020",
    name: "CryptoBull",
    id_str: 1033761461699571712,
  },
  { screen_name: "needazar", name: "Ali Needazar", id_str: 47700443 },
  {
    screen_name: "Neo_Blockchain",
    name: "Neo Smart Economy",
    id_str: 2592325530,
  },
  {
    screen_name: "gamr_onplyr",
    name: "ZOO ECOSYSTEM - GAMR",
    id_str: 1363048611790086145,
  },
  {
    screen_name: "coinsbee",
    name: "coinsbee.com \ud83d\udc1d",
    id_str: 1144266748771930112,
  },
  {
    screen_name: "KuCoinFutures",
    name: "KuCoin Futures \u26a1\ufe0f",
    id_str: 1131747226470871040,
  },
  { screen_name: "Livercoin", name: "Livercoin", id_str: 1077844224408723456 },
  { screen_name: "CryptoChille", name: "\u24d1", id_str: 1026449134901977089 },
  {
    screen_name: "0xPolygonApe",
    name: "Polygon Ape: The Evolution",
    id_str: 1540345958223040512,
  },
  {
    screen_name: "moonflowlabs",
    name: "MoonfloW ($MOON)",
    id_str: 1530867190001455104,
  },
  {
    screen_name: "SecretEscapeNFT",
    name: "SecretEscapeNFT \ud835\udd4d",
    id_str: 1469804324163366912,
  },
  {
    screen_name: "CryptoTechDAO",
    name: "Crypto Tech DAO",
    id_str: 1409112292470538243,
  },
  {
    screen_name: "generativAI",
    name: "Generative AI",
    id_str: 1466599829186887682,
  },
  {
    screen_name: "Ademiici",
    name: "Ademi\ud83e\udd11",
    id_str: 1207536985243627520,
  },
  {
    screen_name: "FirstNFTTower",
    name: "NFT & CNFT Promotions NFT & CNFT GiveAway DropNFT",
    id_str: 372199748,
  },
  { screen_name: "expansioncom", name: "expansioncom", id_str: 14942445 },
  { screen_name: "mishaboar", name: "Mishaboar", id_str: 1385271926852104193 },
  {
    screen_name: "DashCamOwnersAu",
    name: "Dash Cam Owners Aus",
    id_str: 3078452209,
  },
  {
    screen_name: "CronosChimpClub",
    name: "Cronos Chimp Club (Official)",
    id_str: 1447632077394907137,
  },
  {
    screen_name: "CryptoWaveWeb3",
    name: "Liu Huang",
    id_str: 1676810372492460032,
  },
  {
    screen_name: "SmartCryptoFlow",
    name: "Smart Crypto Flow (funny)",
    id_str: 1334333789242150915,
  },
  {
    screen_name: "coinspace_",
    name: "Mr.\u20bfitcoin",
    id_str: 1336617462138241024,
  },
  {
    screen_name: "traderdogs69",
    name: "Trader Dogs",
    id_str: 1423325444812656641,
  },
  {
    screen_name: "BitcoinFrogs",
    name: "Bitcoin Frogs",
    id_str: 1627276070922887170,
  },
  {
    screen_name: "CalorieToken",
    name: "CalorieToken\ud83c\udf54\ud83c\udf79\ud83e\udd66\ud83c\udf7a\ud83e\udd50",
    id_str: 1438652197101842433,
  },
  { screen_name: "ChadOnSolana", name: "$CHAD", id_str: 1702608982139949056 },
  {
    screen_name: "TheQuantKing",
    name: "\u2122\ufe0f\ud83c\udf10Everything Crypto\ud83c\udf10\u2122\ufe0f",
    id_str: 1489314297658363919,
  },
  {
    screen_name: "Tree_of_Alpha",
    name: "Tree (\ud83c\udf32,\ud83c\udf32)",
    id_str: 1456327895866314753,
  },
  {
    screen_name: "PolkadotDevs",
    name: "Polkadot Devs",
    id_str: 1214142523943186432,
  },
  {
    screen_name: "subwalletapp",
    name: "SubWallet",
    id_str: 1457964520282853377,
  },
  {
    screen_name: "KaBoSuTokens",
    name: "KaBoSu coin",
    id_str: 1714881032296763392,
  },
  {
    screen_name: "lexaMoon_crypto",
    name: "Lexa Moon Crypto",
    id_str: 1457778371690065926,
  },
  {
    screen_name: "CryptoLibrarys",
    name: "Crypto Library's",
    id_str: 1280893228821016576,
  },
  { screen_name: "AltaisNFT", name: "Altais", id_str: 3050025099 },
  {
    screen_name: "F_Gregaard",
    name: "Frederik Gregaard",
    id_str: 796694432720519169,
  },
  {
    screen_name: "Raticoin1",
    name: "\u2756RATICOIN\ud83d\udcc8 #BNB 1000 USD",
    id_str: 942966319229566977,
  },
  {
    screen_name: "JustinBarlow",
    name: "Justin Barlow",
    id_str: 866517108905193478,
  },
  { screen_name: "level39", name: "Level39", id_str: 26136365 },
  {
    screen_name: "BombMoneyBSC",
    name: "BOMB Money",
    id_str: 1462385414891782146,
  },
  {
    screen_name: "xPEPENFT",
    name: "xPEPE\ud83d\udc38",
    id_str: 1460473618916331520,
  },
  {
    screen_name: "Abod_crypto",
    name: "\u0639\u0628\u0648\u062f \u0643\u0631\u064a\u0628\u062a\u0648",
    id_str: 1092226029178052608,
  },
  { screen_name: "AmandaCerny", name: "Amanda Cerny", id_str: 104856942 },
  { screen_name: "QuadJacksXRP", name: "Jack", id_str: 1037931781532667904 },
  { screen_name: "CEOAdam", name: "Adam Aron", id_str: 399216451 },
  { screen_name: "KLAYswap", name: "KLAYswap", id_str: 1323147449003700226 },
  {
    screen_name: "TheAmerCrypt",
    name: "The American Crypto \ud83c\uddfa\ud83c\uddf8",
    id_str: 1407417595058393092,
  },
  {
    screen_name: "DevelopGems",
    name: "DevelopGems",
    id_str: 1353637182184050689,
  },
  {
    screen_name: "darktrader780",
    name: "Millionaire NYAN",
    id_str: 1479173120644681731,
  },
  {
    screen_name: "Eikonikos_HQ",
    name: "EIKONIKOS",
    id_str: 1494370978087903232,
  },
  {
    screen_name: "BitcoinShooter",
    name: "Shooter \ud83e\udeb6\ud83d\udc0d",
    id_str: 775232235297382401,
  },
  {
    screen_name: "KillmongerNFT",
    name: "XAMMIE\ud83d\udc8e\ud83d\udc41\ufe0f33.3",
    id_str: 1252365131431972864,
  },
  { screen_name: "dogecoin", name: "Dogecoin", id_str: 2235729541 },
  {
    screen_name: "blockblendIO",
    name: "BlockBlend",
    id_str: 1494989934826033157,
  },
  {
    screen_name: "CryptoGanster",
    name: "Crypto Ganster",
    id_str: 1384625504125325319,
  },
  {
    screen_name: "CryptoDrafter",
    name: "Danny\ud83d\udc68\u200d\ud83d\udcbb",
    id_str: 2154209746,
  },
  { screen_name: "_CryptoWaller", name: "Samantha Waller", id_str: 1063642404 },
  { screen_name: "jespow", name: "Jesse Powell", id_str: 66950686 },
  { screen_name: "Minepicoins", name: "PiCoin X", id_str: 1540362703973122048 },
  {
    screen_name: "Crypto_Viking11",
    name: "Nick $WADZ\ud83c\uddfa\ud83c\uddf8\ud83d\udc09",
    id_str: 1463743406711066629,
  },
  { screen_name: "ArnoldPoernomo", name: "Arnold.P", id_str: 268718494 },
  {
    screen_name: "PlanetParody_",
    name: "PlanetParody",
    id_str: 1527651290171555843,
  },
  {
    screen_name: "CrypFennecs_NFT",
    name: "CrypFennecs",
    id_str: 1439870469981839364,
  },
  {
    screen_name: "Spotcoins_",
    name: "\ud83d\udca5\ud835\udc7a\ud835\udc91\ud835\udc90\ud835\udc95\ud835\udc84\ud835\udc90\ud835\udc8a\ud835\udc8f\ud835\udc94",
    id_str: 1453242781594435599,
  },
  {
    screen_name: "ShytoshiKusama",
    name: "Shytoshi Kusama\u2122",
    id_str: 1349928660136910851,
  },
  {
    screen_name: "TheCoffeeBlock",
    name: "Coffee On The Blockchain",
    id_str: 1397624693109182473,
  },
  { screen_name: "champmedici", name: "Champ Medici", id_str: 1214941477 },
  {
    screen_name: "InterchainZones",
    name: "Mod DGr\u039eat",
    id_str: 1395592077925183499,
  },
  {
    screen_name: "future_isCrypto",
    name: "a m b r i s h \u26a1Crypto \u26a1",
    id_str: 1389125201477046279,
  },
  {
    screen_name: "DCJonaCrypto",
    name: "\ud83d\udc8e \ud83c\udd73\ud83c\udd72\ud83c\udd79\u1d0f\u0274\u1d00\ud83d\udc8e\ud83e\udd77",
    id_str: 1646771958971760640,
  },
  {
    screen_name: "LitecoinMag",
    name: "Litecoin Magazine \u0141\u24c2\ufe0f\ud83d\udd78",
    id_str: 1443155476808675330,
  },
  {
    screen_name: "UndeadBlocks",
    name: "Undead Blocks",
    id_str: 1449340418894729217,
  },
  { screen_name: "therabbit_io", name: "Rabbit", id_str: 1389858678279270400 },
  {
    screen_name: "ChilizX",
    name: "ChilizX - Powered by $CHZ \ud83c\udf36\ufe0f",
    id_str: 1566735545270665216,
  },
  {
    screen_name: "DefiSamurai",
    name: "Defi Samurai | Crypto",
    id_str: 2353363314,
  },
  {
    screen_name: "HCGemAlerts",
    name: "HC Gem Alerts",
    id_str: 1521120199340142592,
  },
  {
    screen_name: "dainguyenGTA",
    name: "Dai Nguyen GTA",
    id_str: 1365313264746258433,
  },
  {
    screen_name: "nexo_chris",
    name: "Chris \uea00 \ud835\udd4f",
    id_str: 50356346,
  },
  {
    screen_name: "cryptonick_foun",
    name: "AmberWhale (CryptoNick)",
    id_str: 1392457788165656580,
  },
  {
    screen_name: "ShibRumour",
    name: "SHIBRumour",
    id_str: 1506300547280048131,
  },
  {
    screen_name: "Zeroloss_defi",
    name: "Zeroloss",
    id_str: 1394836302521110531,
  },
  {
    screen_name: "BeeBookComic",
    name: "BitCoin News",
    id_str: 1470246696529514498,
  },
  {
    screen_name: "Fungible_Chris",
    name: "Fungible Chris",
    id_str: 1373915509322829827,
  },
  {
    screen_name: "wallet_guard",
    name: "Wallet Guard",
    id_str: 1484393423981449218,
  },
  {
    screen_name: "cryptoclub520",
    name: "Gems Crypto Club \u5b9d\u77f3\u4ff1\u4e50\u90e8",
    id_str: 1397813293197283328,
  },
  { screen_name: "Bitso", name: "Bitso", id_str: 2241683586 },
  {
    screen_name: "cryptocrushes",
    name: "Crypto Crushes",
    id_str: 1634608929522044928,
  },
  { screen_name: "jack", name: "jack", id_str: 12 },
  {
    screen_name: "CryptoShinn",
    name: "\u01d1eth\u01d1 (\u220e, \u2206)",
    id_str: 1264000809294913536,
  },
  { screen_name: "cryptogemci", name: "Gemci", id_str: 1040984826 },
  { screen_name: "NFT_NYC", name: "NFT.NYC", id_str: 1057660949161684992 },
  {
    screen_name: "TheBreadmakerr",
    name: "TheBreadmaker",
    id_str: 871055326795120640,
  },
  { screen_name: "holoride", name: "holoride", id_str: 1044847047176794112 },
  {
    screen_name: "AvalancheSweden",
    name: "Avalanche Studios Group",
    id_str: 25060765,
  },
  {
    screen_name: "HugotoCrypto",
    name: "Hugotogo",
    id_str: 1137808631129346049,
  },
  { screen_name: "BarrySilbert", name: "Barry Silbert", id_str: 396045469 },
  {
    screen_name: "robaeprice",
    name: "Rob Price \ud83c\udfd4",
    id_str: 47726356,
  },
  {
    screen_name: "crypto_guru_1",
    name: "Signal guy!",
    id_str: 1022148009994403840,
  },
  {
    screen_name: "BSV_Assn",
    name: "BSV Association",
    id_str: 1074694505532715008,
  },
  { screen_name: "HaloCrypto", name: "MrChief", id_str: 1002893498079764480 },
  {
    screen_name: "Solanaguy23",
    name: "SolanaGuy \ud835\udc17+",
    id_str: 1495595455967031299,
  },
  {
    screen_name: "TagadoBTC",
    name: "Tagado Bitcoin & Crypto",
    id_str: 1268232795853381642,
  },
  {
    screen_name: "thecryptocoinex",
    name: "THE CRYPTO COINER \ud83d\ude80",
    id_str: 1400888242619174914,
  },
  { screen_name: "MaxCrypto888", name: "Max Toshi", id_str: 91571664 },
  {
    screen_name: "MoonInuCNFT",
    name: "Moon Inu CNFT \ud83d\udc15",
    id_str: 1599464781664382976,
  },
  { screen_name: "MrT_Promotes", name: "Mr. T", id_str: 1486580766234578946 },
  {
    screen_name: "cosmoscoffeeDFI",
    name: "The Coffeehouse \u2615\ufe0f",
    id_str: 1491046672679555072,
  },
  { screen_name: "otteroooo", name: "otteroooo", id_str: 1499023927766626309 },
  {
    screen_name: "OptimismDailyTK",
    name: "Optimism Daily",
    id_str: 1443413638241415174,
  },
  {
    screen_name: "BlackFrog_xyz",
    name: "BlackFrog",
    id_str: 1496200242416562180,
  },
  {
    screen_name: "StevenAitchison",
    name: "Steven Aitchison \ud83c\udff4\udb40\udc67\udb40\udc62\udb40\udc73\udb40\udc63\udb40\udc74\udb40\udc7f",
    id_str: 6690362,
  },
  {
    screen_name: "Dagora_INJ",
    name: "Dagora on Injective",
    id_str: 1711235056432455680,
  },
  {
    screen_name: "SerDubaiCrypto",
    name: "The Dubai Crypto",
    id_str: 1450870537736138754,
  },
  {
    screen_name: "fantomnews",
    name: "Fantom News",
    id_str: 1394274355077554178,
  },
  {
    screen_name: "healthy_pockets",
    name: "Healthy Pockets",
    id_str: 1169015262504529921,
  },
  { screen_name: "berry_ales", name: "Ales", id_str: 2347513054 },
  {
    screen_name: "cryptobri_",
    name: "Crypto Bri",
    id_str: 1417477410841366538,
  },
  { screen_name: "WhaleWire", name: "WhaleWire", id_str: 234638601 },
  {
    screen_name: "tnolin",
    name: "Tyler.NYAN\ud83d\udd2b\ud83d\ude3c",
    id_str: 3246593611,
  },
  { screen_name: "Songonsolana", name: "SONG", id_str: 1461070150380277767 },
  {
    screen_name: "KINGTONMEME",
    name: "KingTonMEME",
    id_str: 1458814310726004738,
  },
  {
    screen_name: "coin_commit",
    name: "coincommit.ai",
    id_str: 1478755386052292608,
  },
  {
    screen_name: "ShibReports",
    name: "Shib Reports",
    id_str: 1457797431710523392,
  },
  { screen_name: "Pico_0x", name: "Pico", id_str: 1395293111476277248 },
  {
    screen_name: "Luciano_Trades",
    name: "Lucky Luciano",
    id_str: 1269707414489706496,
  },
  { screen_name: "jackfriks", name: "jack friks", id_str: 1699591741043527680 },
  {
    screen_name: "gm_dev_wif_hat",
    name: "gm devs. - wif hat",
    id_str: 1455328368409391113,
  },
  {
    screen_name: "bitcoincashclub",
    name: "BCH Club \ud83d\udfe9",
    id_str: 1406808294748983296,
  },
  {
    screen_name: "CelsiusNetwork",
    name: "Celsius",
    id_str: 900437084321832960,
  },
  {
    screen_name: "TurtleConNFT",
    name: "Turtle Connection",
    id_str: 1505295971084820488,
  },
  {
    screen_name: "Marywithpositiv",
    name: "Mary With Positive",
    id_str: 1473659197489352710,
  },
  { screen_name: "PSYCH0SEC", name: "X", id_str: 1161383053609930754 },
  {
    screen_name: "Shibaflokitoken",
    name: "Shiba Floki Inu",
    id_str: 1408390004246786051,
  },
  {
    screen_name: "Ben_000777",
    name: "Ben\uea00\ud835\udd4f",
    id_str: 748231698102517760,
  },
  { screen_name: "dehub_official", name: "DeHub", id_str: 1543258531419357185 },
  {
    screen_name: "cryptoseiwhale",
    name: "Crypto Sei Whale",
    id_str: 1109419862,
  },
  {
    screen_name: "NestXNFT",
    name: "NestX | NFT Marketplace",
    id_str: 1654478924473069568,
  },
  {
    screen_name: "CryptoIntellig6",
    name: "TJ - Tokyo Japan Ocean Sailor",
    id_str: 1427208498270511104,
  },
  {
    screen_name: "BTCRegister",
    name: "BitReg | BAYC #6973 | $well",
    id_str: 938916963815796736,
  },
  { screen_name: "cryptoPothu", name: "Pothu", id_str: 1363569088241434627 },
  { screen_name: "cryptodosee", name: "Crypto Dose", id_str: 570336969 },
  { screen_name: "Coinlists_net", name: "Coinlists.net", id_str: 83369396 },
  { screen_name: "QNTMAMI", name: "HBARMami", id_str: 1565578907545653248 },
  {
    screen_name: "SpaceRunnersNFT",
    name: "Space Runners",
    id_str: 1448171819035398144,
  },
  {
    screen_name: "Klaytn_KR",
    name: "Klaytn Korea\ud83c\uddf0\ud83c\uddf7",
    id_str: 1526116555615924224,
  },
  { screen_name: "etcpow", name: "ETCPOW", id_str: 1728559779881127936 },
  {
    screen_name: "CryptoNeoo",
    name: "Crypto Neo",
    id_str: 1406236696975450122,
  },
  { screen_name: "CryptoastMedia", name: "Cryptoast", id_str: 482477197 },
  { screen_name: "BubbaCrypto23", name: "Bubba", id_str: 1459766609896263680 },
  {
    screen_name: "ParallelFi",
    name: "Parallel Network",
    id_str: 1547243161239658497,
  },
  {
    screen_name: "blockfrost_io",
    name: "blockfrost.io",
    id_str: 1301531193184342026,
  },
  {
    screen_name: "Earn_Crypto24h",
    name: "Earn_Crypto",
    id_str: 1711135067861594112,
  },
  {
    screen_name: "BlackberryXRP",
    name: "Mr. Intuitive \ud83c\udff4\u200d\u2620\ufe0f",
    id_str: 1349749655316418561,
  },
  { screen_name: "CZanyk", name: "Zanyk", id_str: 563354186 },
  {
    screen_name: "LucretiusNet",
    name: "Lucretius",
    id_str: 1481317225483337729,
  },
  {
    screen_name: "combfinancial",
    name: "COMB Financial",
    id_str: 1469364653591433218,
  },
  { screen_name: "O3_Labs", name: "O3 Labs", id_str: 917505648828465153 },
  {
    screen_name: "Discordels_NFT",
    name: "Discordels NFT",
    id_str: 1592920517320871937,
  },
  {
    screen_name: "XRPLLabs",
    name: "XRPL Labs \ud83e\ude9d",
    id_str: 1060857438772043776,
  },
  {
    screen_name: "holder_casper",
    name: "The Hodler",
    id_str: 854681172151902209,
  },
  {
    screen_name: "myDopamineApp",
    name: "Dopamine: Ultimate Wealth Manager/Trader App. AI\ud83d\ude80",
    id_str: 876252481038274561,
  },
  {
    screen_name: "Cryptochunshui",
    name: "\u6625\u6c34\u5148\u751f-e/acc",
    id_str: 1467510584794959873,
  },
  { screen_name: "eternlwallet", name: "Eternl", id_str: 1411286394253754370 },
  {
    screen_name: "hectorlocutor",
    name: "H\u00e9ctor Rossi \ud83d\udd25\ud83d\udc95\ud83c\udf08",
    id_str: 119048187,
  },
  {
    screen_name: "bitcoin_lupin",
    name: "CoinLupin \ucf54\uc778\ub8e8\ud321",
    id_str: 1292012875330883584,
  },
  {
    screen_name: "PunksOnSolana",
    name: "SolPunks",
    id_str: 1381601992313819137,
  },
  {
    screen_name: "ZaraGiveaway",
    name: "Zara Crypto \u2600\ufe0f",
    id_str: 1401406260000460800,
  },
  {
    screen_name: "Crypto__Goku",
    name: "Goku \ud83d\uddde",
    id_str: 1395846093389316096,
  },
  {
    screen_name: "BCHdotgames",
    name: "BCH.games",
    id_str: 1265196082755182592,
  },
  {
    screen_name: "stedas",
    name: "XRP_Cro \ud83d\udd25 AI / Gaming / DePIN",
    id_str: 33457664,
  },
  { screen_name: "TrustPad", name: "TrustPad", id_str: 1377353173275443204 },
  {
    screen_name: "CryptoHunter_ch",
    name: "Crypto \ud83d\udd36 Hunter \uea00 \ud835\udd4f",
    id_str: 1413524467582742537,
  },
  { screen_name: "SadafJadran", name: "Sadaf Jadran", id_str: 230588721 },
  { screen_name: "hulkordie", name: "Hulk or Die", id_str: 1388485520 },
  {
    screen_name: "TeslaPromoter",
    name: "Tesla - Promotion\ud83e\udd47",
    id_str: 1467518903404437507,
  },
  {
    screen_name: "ChicoCrypto",
    name: 'Chico Crypto-"The Most Trustless Name in News"',
    id_str: 2218173114,
  },
  {
    screen_name: "CryptoSegments",
    name: "CryptoSegments",
    id_str: 1685483495626608640,
  },
  {
    screen_name: "avaxholic",
    name: "Avaxholic \ud83d\udd3a",
    id_str: 1392040970431074309,
  },
  {
    screen_name: "cryptos_updates",
    name: "CU101",
    id_str: 1438222843947409411,
  },
  {
    screen_name: "TelcoinDaily",
    name: "Telcoin Daily Info \u2121",
    id_str: 1085112425706373121,
  },
  {
    screen_name: "BeefSupreme1776",
    name: "Beef Supreme",
    id_str: 1326227518966263808,
  },
  {
    screen_name: "pythadoge",
    name: "Pytha \u2764\ufe0f\ud83d\ude9c",
    id_str: 1388639562029846529,
  },
  {
    screen_name: "WildOnesNFTs",
    name: "Wild Ones || What's Next?\ud83e\udd8e",
    id_str: 1599861099834314785,
  },
  {
    screen_name: "rafal_zaorski",
    name: "Rafa\u0142 Zaorski",
    id_str: 747476326135791616,
  },
  {
    screen_name: "ScottSummersICP",
    name: "ICP Whale News \u221e",
    id_str: 1600275581387644928,
  },
  {
    screen_name: "cz_binance",
    name: "CZ \ud83d\udd36 BNB",
    id_str: 902926941413453824,
  },
  { screen_name: "CryptoCorleon", name: "Crypto Corleone", id_str: 2810985970 },
  {
    screen_name: "InterlayHQ",
    name: "Interlay | Bitcoin DeFi",
    id_str: 1227956362023505921,
  },
  {
    screen_name: "BTClovera",
    name: "Lovera \u26cf \u20bf \u0271",
    id_str: 2283489266,
  },
  {
    screen_name: "SeGives",
    name: "\ud835\udc94\ud835\udc86",
    id_str: 1434834066822500355,
  },
  {
    screen_name: "PepeArmyToken",
    name: "PEPEARMY Token",
    id_str: 1665093020499230722,
  },
  {
    screen_name: "CryptoBruixot",
    name: "El Bruixot D'Or \ud83c\uddf5\ud83c\uddf9",
    id_str: 1370820686084706305,
  },
  {
    screen_name: "Cryptosignal000",
    name: "Crypto signals",
    id_str: 1492786060748746752,
  },
  {
    screen_name: "Hero_NFT_",
    name: "HERO NFT \u2694\ufe0f",
    id_str: 1297398237179940864,
  },
  {
    screen_name: "HashBitorg",
    name: "HashBit AI",
    id_str: 1370763938883846152,
  },
  {
    screen_name: "norugpls",
    name: "Gabriela Mazano",
    id_str: 1393089621257555969,
  },
  { screen_name: "SolanaCATO", name: "$CATO", id_str: 1393614520048779264 },
  {
    screen_name: "ICOAnnouncement",
    name: "ICO Announcement",
    id_str: 1007618472732512256,
  },
  {
    screen_name: "cryptokahin",
    name: "KAH\u0130N \ud83e\udd89\ud83d\udd2e",
    id_str: 808754956937347072,
  },
  { screen_name: "Crypto_Lucy1", name: "Crypto Lucy", id_str: 1095768248 },
  { screen_name: "Rocky_Bitcoin", name: "Rocky", id_str: 1003908754813935621 },
  {
    screen_name: "MinaMNFanunion",
    name: "Mina Multinational Fanunion",
    id_str: 1278146425495289856,
  },
  {
    screen_name: "SolMintDaily",
    name: "SolMints \ud83d\udfe0",
    id_str: 1444050325258842122,
  },
  {
    screen_name: "CryptoEmre2020",
    name: "Crypto Emre",
    id_str: 1245933293134020609,
  },
  {
    screen_name: "ImagineBitcoin",
    name: "Imagine Bitcoin",
    id_str: 1545044432323375107,
  },
  {
    screen_name: "EmillNFT",
    name: "Emiliano \uea00",
    id_str: 1474825281798090752,
  },
  {
    screen_name: "ChineseCoinex",
    name: "CoinEx\u83ef\u8a9e\u983b\u9053",
    id_str: 1485864461357711360,
  },
  {
    screen_name: "DcentralCon",
    name: "DCENTRAL Conference",
    id_str: 1433258956818980864,
  },
  {
    screen_name: "ADAGurl4Eva",
    name: "ADAGurl- Bullish Bear Market \ud83c\uddfa\ud83c\uddf8 | WAGMI",
    id_str: 701447612637904898,
  },
  {
    screen_name: "SNX_Thai",
    name: "Synthetix Thai Spartan \u2694\ufe0f \ud83c\uddf9\ud83c\udded",
    id_str: 1520063712232566789,
  },
  {
    screen_name: "0xshowmaker",
    name: "ShowMaker",
    id_str: 1564267464346697729,
  },
  {
    screen_name: "nftsonsolana",
    name: "Alpha S\u25ceL \ud83d\udc8e",
    id_str: 1448738190920146954,
  },
  {
    screen_name: "BitcoinCouteau",
    name: "Mon Bitcoin Et Mon Couteau",
    id_str: 1455509825136336896,
  },
  { screen_name: "scott_lew_is", name: "scottlewis.canto", id_str: 3226266525 },
  {
    screen_name: "CoinexArabic",
    name: "CoinEx Arabic",
    id_str: 1324241258710097920,
  },
  { screen_name: "_Urkann", name: "Urkann", id_str: 1061233599209463809 },
  { screen_name: "Bybit_NFT", name: "Bybit NFT", id_str: 1481845102368952320 },
  {
    screen_name: "AnthonyCardano",
    name: "Anthony",
    id_str: 1292177395978579980,
  },
  {
    screen_name: "jasmy_BNB",
    name: "jasmy(J\u5148\u751f)",
    id_str: 1512812495467020288,
  },
  { screen_name: "StackingUSD", name: "Stacks", id_str: 431243238 },
  {
    screen_name: "degenx_trader",
    name: "Degen Trader",
    id_str: 1396852216028348422,
  },
  {
    screen_name: "Coin_Theory_",
    name: "Coin Theory \ud83d\udcad",
    id_str: 1490668902191443968,
  },
  { screen_name: "XDogecoin_ERC", name: "XDoge", id_str: 1684528494372429826 },
  {
    screen_name: "VeChainJP",
    name: "VeChain Japan Community\ud83c\uddef\ud83c\uddf5",
    id_str: 1343508639269625857,
  },
  { screen_name: "CryptoDevinL", name: "CryptoD", id_str: 1371263177288130561 },
  { screen_name: "crypto_themis", name: "Themis", id_str: 2242085544 },
  {
    screen_name: "CoinRaise_xyz",
    name: "CoinRaise",
    id_str: 1522215823351316481,
  },
  {
    screen_name: "Cipher1guru",
    name: "ANTI-FRAUD AGENT \ud83c\udf96\ufe0f",
    id_str: 1584575017064472576,
  },
  { screen_name: "michilumin", name: "Michi Lumin", id_str: 35618737 },
  { screen_name: "jimtalbot", name: "jim", id_str: 309749985 },
  {
    screen_name: "cardano_farmer",
    name: "Cardano Farmer",
    id_str: 1509947523204628483,
  },
  {
    screen_name: "TheRealNeoThe",
    name: "\ud835\udc39\ud835\udcc7\ud835\udc52\ud835\udcb6\ud835\udcc0\ud835\udcce Neo The \ud83c\udde6\ud83c\uddf7 (ROAD TO\u2026 THE TOILET)",
    id_str: 1298772973432827904,
  },
  { screen_name: "spectrocoin", name: "SpectroCoin", id_str: 1710828710 },
  {
    screen_name: "Bitcoin68753720",
    name: "Z\u00dc\u011e\u00dcRT",
    id_str: 1486692046500995081,
  },
  {
    screen_name: "CryptoXLARG",
    name: "\ud835\udc02\ud835\udc2b\ud835\udc32\ud835\udc29\ud835\udc2d\ud835\udc28\ud835\udc17\ud835\udc0b\ud835\udc00\ud835\udc11\ud835\udc06\ud835\udc04 \ud83c\uddf9\ud83c\uddf7",
    id_str: 1602209694575202306,
  },
  { screen_name: "BTCzcv", name: "Kimi", id_str: 320866105 },
  {
    screen_name: "traderyusufnoon",
    name: "Yusuf | Noon",
    id_str: 1405233865900298246,
  },
  {
    screen_name: "crypto_raiders",
    name: "Crypto Raiders",
    id_str: 1410499770372722691,
  },
  { screen_name: "TimeTradingX", name: "Rifai", id_str: 1373179184927830020 },
  {
    screen_name: "solananftsnews",
    name: "Solana NFTS Notification",
    id_str: 1457895688893726724,
  },
  {
    screen_name: "MadDogCarClub",
    name: "Mad Dog",
    id_str: 1489133621873033223,
  },
  { screen_name: "evolution", name: "v", id_str: 1383137438323912706 },
  { screen_name: "solamicoin", name: "$SOLAMI", id_str: 1519548249626996736 },
  {
    screen_name: "sol_giveaways",
    name: "Sol Giveaways",
    id_str: 1461170249726836737,
  },
  {
    screen_name: "MrAltcoinsAllen",
    name: "MrAltcoin",
    id_str: 1403140054579482628,
  },
  {
    screen_name: "money_zg",
    name: "James | MoneyZG",
    id_str: 1344973047003013120,
  },
  {
    screen_name: "Onchain_Trader",
    name: "On-chain Trader",
    id_str: 1528692453632802816,
  },
  {
    screen_name: "cryptojpeg",
    name: "jpeg joker (JJ)",
    id_str: 731505418489356288,
  },
  {
    screen_name: "Cronosphere_",
    name: "Space Pirates Finance",
    id_str: 1488085497733947392,
  },
  {
    screen_name: "IzNoGooDNFT_",
    name: "IzNoGooD \uea00",
    id_str: 1524883470404706313,
  },
  {
    screen_name: "XRPL3DAPES",
    name: "3DAPES\u2122",
    id_str: 1477739515557171201,
  },
  { screen_name: "OliviaaWeb3", name: "Olivia", id_str: 803738553964462081 },
  { screen_name: "tommychong", name: "Tommy Chong", id_str: 64857767 },
  {
    screen_name: "KBSCryptoKorea",
    name: "KBS-\ud55c\uad6d\ubc29",
    id_str: 1171102157250027520,
  },
  { screen_name: "mortycryptox", name: "Morty", id_str: 1446228705785925634 },
  {
    screen_name: "ethpadofficial",
    name: "ETHPad",
    id_str: 1410504490961104897,
  },
  {
    screen_name: "DogecoinFdn",
    name: "Dogecoin Foundation",
    id_str: 2586748687,
  },
  {
    screen_name: "cryptodino_io",
    name: "CryptoDino | P&E GAME IS LIVE",
    id_str: 1422953504755302406,
  },
  {
    screen_name: "skullcrypto1",
    name: "\u039e Lord Skull Crypto \u039e \ud83e\udd87\ud83d\udd0a",
    id_str: 1356427656812630016,
  },
  {
    screen_name: "Raphitttfarm",
    name: "Raphittt 2.0 \u2692\ufe0f",
    id_str: 1613372845383421956,
  },
  { screen_name: "SecretsOfCrypto", name: "Secrets", id_str: 2856228978 },
  {
    screen_name: "SoraLabsADA",
    name: "Sora Labs",
    id_str: 1529847713264717826,
  },
  {
    screen_name: "Cryptobabushka",
    name: "CryptoBabushka",
    id_str: 946866003761299457,
  },
  { screen_name: "conraddit", name: "$conrad", id_str: 1390818654435041281 },
  {
    screen_name: "squatch_crypto",
    name: "\ud835\udde6\ud835\uddfe\ud835\ude02\ud835\uddee\ud835\ude01\ud835\uddf0\ud835\uddf5\ud83e\udd77",
    id_str: 1009829949606449152,
  },
  {
    screen_name: "TheDefiJedi",
    name: "The Defi Jedi",
    id_str: 884568713982164992,
  },
  {
    screen_name: "guardarian_com",
    name: "Guardarian",
    id_str: 1291407266328055810,
  },
  {
    screen_name: "Theresia_BDC",
    name: "Theresia\ud83c\udf38Lady of Memes\ud83d\udc51",
    id_str: 1435514039463931909,
  },
  {
    screen_name: "HuobiGlobal",
    name: "\u706b\u5e01HTX",
    id_str: 1546055871955800065,
  },
  {
    screen_name: "DocumentingBTC",
    name: "Documenting \u20bfitcoin \ud83d\udcc4",
    id_str: 1337780902680809474,
  },
  {
    screen_name: "CryptoKeith_",
    name: "CRYPTO K\u039eITH \ud83d\ude80",
    id_str: 221595752,
  },
  {
    screen_name: "algorandblog",
    name: "Algorand Blog",
    id_str: 1507998866893053953,
  },
  {
    screen_name: "OPNX_Official",
    name: "OPNX (LAMB) \ud83d\udc11",
    id_str: 1619200156926181376,
  },
  {
    screen_name: "BubbleCoinBase",
    name: "$Bubble On Base",
    id_str: 1679176093012721665,
  },
  {
    screen_name: "crypto_hiroshi",
    name: "Hiroshi Crypto \ud83d\udcb8",
    id_str: 4854217089,
  },
  {
    screen_name: "CryptoMkts",
    name: "CryptoMarkets",
    id_str: 1684655359804219392,
  },
  {
    screen_name: "kaptencrypto707",
    name: "Kapten Saham Crypto 707",
    id_str: 1105345042493906944,
  },
  {
    screen_name: "CryptoBethany",
    name: "Crypto \u20bfethany",
    id_str: 1275404514778951680,
  },
  {
    screen_name: "MarceBanios",
    name: "Marce Ba\u00f1os \u264f\ufe0f",
    id_str: 343091757,
  },
  {
    screen_name: "Crypto_Arshaq",
    name: "\ud835\udc02\ud835\udc2b\ud835\udc32\ud835\udc29\ud835\udc2d\ud835\udc28 \ud835\udc00\ud835\udc2b\ud835\udc2c\ud835\udc21\ud835\udc1a\ud835\udc2a",
    id_str: 1602964713444352000,
  },
  {
    screen_name: "FreddieRaynolds",
    name: "FreddieRaynolds",
    id_str: 1009165539304865794,
  },
  {
    screen_name: "Crypto_Trades_",
    name: "Crypto Trades",
    id_str: 877460560761192449,
  },
  {
    screen_name: "OneCommunityAda",
    name: "One Community ADA (1COMM)",
    id_str: 1288294480198750209,
  },
  { screen_name: "PolkaCity", name: "Polka City", id_str: 1358170946486558720 },
  { screen_name: "metatimecom", name: "Metatime", id_str: 1506184125715255296 },
  { screen_name: "jwang815", name: "Jason Wang", id_str: 28043183 },
  {
    screen_name: "realcryptohippy",
    name: "Crypto Culture",
    id_str: 1181581081,
  },
  { screen_name: "WingSwapFTM", name: "WingSwap", id_str: 1441949346719682568 },
  {
    screen_name: "cpl_crypto",
    name: "Rezolut (previously CPL)",
    id_str: 1321132472193540096,
  },
  { screen_name: "kevinrose", name: "Kevin Rose", id_str: 657863 },
  {
    screen_name: "itsALLrisky",
    name: "\ud83d\udcb8\ud83d\udcb8\ud83d\udcb8",
    id_str: 1061349266545885185,
  },
  { screen_name: "EcoCoin1", name: "EcoCoin", id_str: 1148902257284517890 },
  { screen_name: "BlazeXCoin", name: "BlazeX Coin", id_str: 3226422677 },
  {
    screen_name: "TheRuneRanger",
    name: "$RUNE Ranger \ud83d\udd28",
    id_str: 1268949779314290689,
  },
  {
    screen_name: "Cryptotrader_22",
    name: "Trader \ud83e\udd88",
    id_str: 1505498237318410241,
  },
  {
    screen_name: "gemxtrading",
    name: "GemX Trading",
    id_str: 1704415520962048000,
  },
  { screen_name: "Treboltoken", name: "Treboltoken", id_str: 14594392 },
  {
    screen_name: "AirdropAchiever",
    name: "Airdrop Achiever | Web3.0 | Crypto",
    id_str: 1418997462488928257,
  },
  { screen_name: "bitforexcom", name: "BitForex", id_str: 957221394009354245 },
  {
    screen_name: "ShidenNetwork",
    name: "Shiden Network",
    id_str: 1354271932116393987,
  },
  {
    screen_name: "AdamLiu22599247",
    name: "OX Lucky",
    id_str: 1463569800601296900,
  },
  { screen_name: "MantleHunter", name: "Mantle Hunter", id_str: 2475650167 },
  {
    screen_name: "bunny_polygon",
    name: "\ud835\udcd1\ud835\udce4\ud835\udcdd\ud835\udcdd\ud835\udce8 \ud835\udcdf\ud835\udcf8\ud835\udcf5\ud835\udd02\ud835\udcf0\ud835\udcf8\ud835\udcf7 \ud835\udcdd\ud835\udcd5\ud835\udce3'\ud835\udcfc.\ud83c\udf3c",
    id_str: 1631005813803286550,
  },
  { screen_name: "Cryptohaull", name: "HAULNFT\u2122\ufe0f", id_str: 20835692 },
  { screen_name: "CRYPT0TRZ", name: "CRYPTO TRZ", id_str: 1226086596073664513 },
  {
    screen_name: "0x8899CryptoUS",
    name: "0x8899 | CryptoUS \u26a1\ufe0f",
    id_str: 1371515919248154628,
  },
  {
    screen_name: "flexstackAI",
    name: "FlexStack",
    id_str: 1393395686117318663,
  },
  { screen_name: "BNBhare_BSC", name: "BNBhare", id_str: 1480333794175557633 },
  { screen_name: "tradingview", name: "TradingView", id_str: 373457750 },
  {
    screen_name: "CryptoTroia",
    name: "Crypto Troia",
    id_str: 1357300605878996992,
  },
  { screen_name: "cryptodins", name: "Din \u0b13", id_str: 2974685646 },
  {
    screen_name: "CryptoWuzard",
    name: "Crypto Wizard \u2728",
    id_str: 1323413108,
  },
  { screen_name: "NootMemecoin", name: "$NOOT", id_str: 1648771666653093888 },
  {
    screen_name: "crypto2025S",
    name: "B\u00e9 Kem S2",
    id_str: 1307171709888335872,
  },
  {
    screen_name: "Jetfuelfinance",
    name: "Jetfuel Finance Multi Chain Ecosystem \ud83d\ude80",
    id_str: 1332792291640152067,
  },
  {
    screen_name: "TraderYuso",
    name: "Trader Yuso",
    id_str: 1151475778204438528,
  },
  {
    screen_name: "gianpromotes",
    name: "Gian Crypto",
    id_str: 1387657259287662592,
  },
  { screen_name: "ShibOfficiaI", name: "PEPE", id_str: 1452693012786548736 },
  { screen_name: "CryptoFuryZK", name: "CryptoFury", id_str: 2887785533 },
  {
    screen_name: "zorotokenio",
    name: "Zororium $ZRT \u2b50\ufe0f",
    id_str: 1656520217231790080,
  },
  {
    screen_name: "xAdam_NFT",
    name: "xAdam#UseEGLD",
    id_str: 1091234534295855104,
  },
  { screen_name: "smdiehl", name: "Stephen Diehl", id_str: 151535146 },
  {
    screen_name: "TheGreatestGALA",
    name: "Derin GALA \ud83c\udde7\ud83c\uddf9",
    id_str: 1682244861318033416,
  },
  {
    screen_name: "CryptoSouldier",
    name: "Crypto Souldier",
    id_str: 1249090869615038466,
  },
  {
    screen_name: "TheBoostCoin",
    name: "DiamondCoin",
    id_str: 1417155152637677573,
  },
  {
    screen_name: "playtoearngames",
    name: "Ronin $RON Holder",
    id_str: 1463430587960594432,
  },
  {
    screen_name: "Chloe_Le_Pen",
    name: "Chlo\u00e9 XRP \ud83c\uddec\ud83c\udde7",
    id_str: 1362150829898219524,
  },
  { screen_name: "blow_coin", name: "Blow Token", id_str: 1656328295237967877 },
  {
    screen_name: "OPOEthereum",
    name: "Only Possible On Ethereum",
    id_str: 1708076547830083584,
  },
  { screen_name: "NFTGANGBIZ", name: "NUGGIES", id_str: 1455672343469170694 },
  {
    screen_name: "MetaListNFT",
    name: "Meta List",
    id_str: 1510028664792047623,
  },
  {
    screen_name: "Bull_Sander",
    name: "Bull Sander",
    id_str: 966617065246380032,
  },
  { screen_name: "Beamswapio", name: "Beamswap", id_str: 1446776764475355138 },
  {
    screen_name: "EOSFoundationCN",
    name: "EOS\u7f51\u7edc\u57fa\u91d1\u4f1a",
    id_str: 1448918526803148808,
  },
  {
    screen_name: "jiamihui",
    name: "Crypto Hui\ud83d\udcc8\ud83d\ude80",
    id_str: 1639536485128114176,
  },
  {
    screen_name: "HederaInform",
    name: "HederaInform ($HBAR)",
    id_str: 1579543424906166287,
  },
  {
    screen_name: "chicrypto_eth",
    name: "chicypto_eth",
    id_str: 1636165452937216000,
  },
  { screen_name: "HT_DAO", name: "HT DAO", id_str: 1605946779341578246 },
  {
    screen_name: "PeterTheTerran",
    name: "Peter \ud83d\udc41\ud83d\udc3d\ud83d\udc41 \u2694\ufe0f Orivium",
    id_str: 166801998,
  },
  {
    screen_name: "ChilizESP",
    name: "Chiliz ESPA\u00d1A \ud83c\uddea\ud83c\uddf8 ($CHZ)",
    id_str: 1392032359856447488,
  },
  { screen_name: "StartFinance", name: "StartFi", id_str: 1284535450963763202 },
  { screen_name: "CoinTigerEX", name: "CoinTiger", id_str: 940933824191406080 },
  {
    screen_name: "DeFiatoOfficial",
    name: "DeFiato",
    id_str: 1335869551620210689,
  },
  {
    screen_name: "Avax_News",
    name: "Avax News \ud83d\udd3a",
    id_str: 1419074643277885444,
  },
  {
    screen_name: "CryptoChef_M",
    name: "Crypto Chef M",
    id_str: 1011452090948911104,
  },
  {
    screen_name: "BitcoinIsComing",
    name: "Bitcoin Is Coming",
    id_str: 835908554284544006,
  },
  {
    screen_name: "BoredApeINJClub",
    name: "Bored Ape INJ Club",
    id_str: 1707770567900971008,
  },
  {
    screen_name: "JapaneseCrypto",
    name: "\u65e5\u672c\u306e\u30b3\u30df\u30e5\u30cb\u30c6\u30a3 | JAPANESE CRYPTO\ud83c\uddef\ud83c\uddf5",
    id_str: 1199482051482677249,
  },
  {
    screen_name: "shartoken",
    name: "SharPei \ud83d\udc36 | for the Crypto Community & Culture",
    id_str: 1358528780550221825,
  },
  {
    screen_name: "CHARTBTC1",
    name: "\ud83c\uddf2\ud83c\udde6 CRYPTO\ud83c\uddf2\ud83c\udde6BLACK\ud83c\uddf2\ud83c\udde6TIGER\ud83c\uddf2\ud83c\udde6",
    id_str: 1475552840986808332,
  },
  {
    screen_name: "Optimism_Radar",
    name: "Optimism Radar (\ud83d\udd34_\ud83d\udd34)",
    id_str: 1629840472863821824,
  },
  {
    screen_name: "PeelCoin",
    name: "Bananas\ud83c\udf4c| Trading Live",
    id_str: 1562479956231262212,
  },
  {
    screen_name: "CoinWZH",
    name: "CoinW\u5e01\u8d62\u4e2d\u6587",
    id_str: 1465633874210279425,
  },
  {
    screen_name: "shopping_io",
    name: "Shopping.io",
    id_str: 1301587672230756352,
  },
  { screen_name: "Coinmatik1", name: "Paramatik", id_str: 1309740525470265344 },
  {
    screen_name: "ShibaInuHgl",
    name: "ShibArmy $1.0 \ud83c\udf10",
    id_str: 1455041270263402498,
  },
  {
    screen_name: "cryptoheisnberg",
    name: "Heisnberg",
    id_str: 1444794150528946178,
  },
  {
    screen_name: "LuckyFactoryNFT",
    name: "Lucky Factory \ud83c\udf40",
    id_str: 1655792751395622912,
  },
  {
    screen_name: "GlowTokenLLC",
    name: "GlowToken LLC",
    id_str: 1458151508466352131,
  },
  {
    screen_name: "LegendaoNFT",
    name: "Legendao\u26a1\ufe0f",
    id_str: 1450037667471691776,
  },
  {
    screen_name: "ArbitrumFocus",
    name: "Arbitrum Focus (\ud83d\udc99,\ud83e\udde1)",
    id_str: 1560175946795479040,
  },
  {
    screen_name: "GMersPolygon",
    name: "GMers || Mint Live",
    id_str: 1638219958164267012,
  },
  {
    screen_name: "CoinTradeindia",
    name: "CoinTrade",
    id_str: 1415902283536113664,
  },
  {
    screen_name: "BreakingADA",
    name: "BREAK pool \ud83e\udd73\ud83c\uddf9\ud83c\udded",
    id_str: 1370919548216430592,
  },
  { screen_name: "Metamall_io", name: "Metamall", id_str: 1472894029033582598 },
  {
    screen_name: "0harekrishna0",
    name: "Shekhar Banerjee",
    id_str: 951357277008728066,
  },
  { screen_name: "StellarProgram", name: "$SRB", id_str: 705812207 },
  { screen_name: "rekktguy", name: "Rektguy", id_str: 1273806383423471618 },
  {
    screen_name: "BTC1144",
    name: "\u56db\u54e5\u3010\u5411\u9633\u5546\u5b66\u9662\u3011",
    id_str: 1525008326227922946,
  },
  {
    screen_name: "BTChainOfficial",
    name: "BTChain",
    id_str: 1681081372830736384,
  },
  {
    screen_name: "ArbitrumEcoMM",
    name: "Arbitrum Ecosystem (\ud83d\udc99,\ud83e\udde1)",
    id_str: 1648291126091341825,
  },
  { screen_name: "Trobriand15", name: "Halaster", id_str: 950093192061505536 },
  {
    screen_name: "coindoktorunuz",
    name: "\ud835\udc03\ud835\udc0e\ud835\udc0a\ud835\udc13\ud835\udc0e\ud835\udc11",
    id_str: 4854945729,
  },
  {
    screen_name: "MichaelEHayden",
    name: "Michael Edison Hayden",
    id_str: 487942988,
  },
  {
    screen_name: "edoryuCrypto",
    name: "\u30a8\u30c9\u30ef\u30fc\u30c9/Bitcoin",
    id_str: 1588458255881494533,
  },
  {
    screen_name: "cryptolewlew",
    name: "Lewis Jackson",
    id_str: 1237297677236416514,
  },
  {
    screen_name: "ytflzyyds",
    name: "\u4ee5\u592a\u574a\u8001\u5f20.eth",
    id_str: 1437049754882494466,
  },
  {
    screen_name: "hnt_guy",
    name: "Passive Income Coach",
    id_str: 1466467890383753221,
  },
  {
    screen_name: "czdaomaker",
    name: "Chris DAO Maker",
    id_str: 1259032968707952640,
  },
  {
    screen_name: "ShibaArchives",
    name: "Shiba Archives",
    id_str: 1396074770811195394,
  },
  {
    screen_name: "HeroArena_Hera",
    name: "Hero Arena",
    id_str: 1431082309420150787,
  },
  {
    screen_name: "kriptokrali_",
    name: "Kripto Kral\u0131",
    id_str: 1372214373607936002,
  },
  {
    screen_name: "criptodaisy",
    name: "\ud835\uddd6\ud835\uddff\ud835\uddf6\ud835\uddfd\ud835\ude01\ud835\uddfc\ud835\uddd7\ud835\uddee\ud835\uddf6\ud835\ude00\ud835\ude06",
    id_str: 1454467367027814402,
  },
  {
    screen_name: "barbosacoin",
    name: "Barbaros Ali Kavakl\u0131o\u011flu",
    id_str: 1355060625634975745,
  },
  { screen_name: "criptotemplo", name: "CriptoTemplo", id_str: 2734856300 },
  { screen_name: "GokhanGark", name: "Gokhan Gark", id_str: 89994880 },
  {
    screen_name: "CoinexK",
    name: "\ucf54\uc778\uc5d1\uc2a4 CoinEx Korea",
    id_str: 1393126620735541252,
  },
  {
    screen_name: "Cryptoman12Man",
    name: "CRYPTO-MAN",
    id_str: 1490716267375906816,
  },
  {
    screen_name: "CryptoForSA",
    name: "\u0645\u062c\u062a\u0645\u0639 \u0627\u0644\u0639\u0645\u0644\u0627\u062a \u0627\u0644\u0631\u0642\u0645\u064a\u0629",
    id_str: 1357812416038453251,
  },
  { screen_name: "greeneconomyy", name: "Green", id_str: 512980770 },
  {
    screen_name: "Crypto_BabyBoss",
    name: "Crypto Baby",
    id_str: 996971667095093249,
  },
  {
    screen_name: "JpCointelegraph",
    name: "\u30b3\u30a4\u30f3\u30c6\u30ec\u30b0\u30e9\u30d5\u30b8\u30e3\u30d1\u30f3 - Cointelegraph Japan",
    id_str: 931507973821816834,
  },
  {
    screen_name: "fatihcrypto1",
    name: "fatihcrypto",
    id_str: 1355783497089212416,
  },
  {
    screen_name: "Tiga_Six_TONES",
    name: "\ud835\udce3\ud835\udcea\ud835\udcf2\ud835\udcf0\ud835\udcea.\ud835\udcda",
    id_str: 1079766060511842305,
  },
  {
    screen_name: "avalanche_tr",
    name: "Avalanche TR \ud83d\udd3a",
    id_str: 1132226795137318912,
  },
  { screen_name: "Selcoin", name: "Selcoin", id_str: 185640900 },
  {
    screen_name: "CryptoFuegoYT",
    name: "CryptoFuego \ud83d\udd25",
    id_str: 1520074037996789761,
  },
  {
    screen_name: "ZCryptoOfficial",
    name: "Z crypto - \u0632\u064a \u0643\u0631\u064a\u0628\u062a\u0648 \u26a1",
    id_str: 408778162,
  },
  {
    screen_name: "CryptooSeneR",
    name: "Kripto$eneR",
    id_str: 1107729191280300032,
  },
  {
    screen_name: "Coinistt",
    name: "\ud835\udc36\ud835\udc5c\ud835\udc56\ud835\udc5b\ud835\udc56\ud835\udc60\ud835\udc61",
    id_str: 1591032976334372865,
  },
  {
    screen_name: "AltcoinEvreni",
    name: "Altcoin Evreni",
    id_str: 1479316868955619330,
  },
  {
    screen_name: "KingData_CN",
    name: "KingData | \u4e2d\u6587",
    id_str: 1475736787587067911,
  },
  {
    screen_name: "CoinToEarn",
    name: "\u5e01\u8d5a/CoinToEarn(\u6bcf\u65e5\u7a7a\u6295\uff09",
    id_str: 1501830478688567298,
  },
  {
    screen_name: "mrkenbitcoin",
    name: "23\u6b73\u5104\u30c8\u30ec\u30fc\u30c0\u30fcMr.Ken / Tenset CMO",
    id_str: 1287373615260790784,
  },
  { screen_name: "coinagend", name: "Coin Agend", id_str: 1644144309627760640 },
  { screen_name: "CoinexR", name: "CoinEx CIS", id_str: 1406903231029014534 },
  {
    screen_name: "DrXRP1",
    name: "\u30c9\u30af\u3061\u3083\u3093\ud83d\udc30",
    id_str: 1194606103268511744,
  },
  { screen_name: "ZakionBitcoin", name: "Zaki on Bitcoin", id_str: 4602196643 },
  {
    screen_name: "flowsaaaan",
    name: "\u3010\u516c\u5f0f\u3011\u6728\u6751\u62d3\u54c9 Flow supported by Spotify",
    id_str: 1155702361295208448,
  },
  {
    screen_name: "BitcoinTech5",
    name: "Foxx\ud83e\udd8a\u30d3\u30c3\u30c8\u30b3\u30a4\u30f3\u6295\u8cc7\u5bb6",
    id_str: 887656591436136448,
  },
  {
    screen_name: "BlackRockFin",
    name: "BlackrockFund | Treasury-Backed, Debase-Driven",
    id_str: 2486910224,
  },
  {
    screen_name: "ClownonSolana",
    name: "CLOWN \ud83e\udd21",
    id_str: 1709265343829467136,
  },
  {
    screen_name: "CryptoKursu",
    name: "Crypto Kursu",
    id_str: 1378781646644731912,
  },
  {
    screen_name: "MoneroTime",
    name: "Monero Time",
    id_str: 1587779015460487170,
  },
  { screen_name: "zeosprotocol", name: "ZEOS", id_str: 1479208640921939969 },
  {
    screen_name: "coininsightsvnn",
    name: "Coin Insights Vi\u1ec7t Nam.",
    id_str: 1339959260072046593,
  },
  {
    screen_name: "MuseDash_KR",
    name: "Muse Dash\uacf5\uc2dd",
    id_str: 1473494173357535233,
  },
  {
    screen_name: "INJcreatures",
    name: "Injective Creatures",
    id_str: 1682403441631711232,
  },
  {
    screen_name: "TheShibLord1",
    name: "\ud83d\udcb8TheLordOfTheDawgs\ud83d\udcb8",
    id_str: 1410784833467523078,
  },
  {
    screen_name: "LuaSwap",
    name: "LuaSwap | $LUA",
    id_str: 1305792873674153984,
  },
  {
    screen_name: "MugenCNFT",
    name: "Project: MUGEN \u26e9\ufe0f | MINTING NOW!",
    id_str: 1661689880299339776,
  },
  {
    screen_name: "Cryptobaofu",
    name: "\u66b4\u5bcc\u65e5\u8bb0\u2728Crypto Baofu\ud83d\udcb0",
    id_str: 1609495552949125121,
  },
  {
    screen_name: "Caviar_NFTs",
    name: "xCaviar NFTs - MultiversX \u26a1",
    id_str: 1514060882367721472,
  },
  {
    screen_name: "HPOP8I",
    name: "HarryPotterObamaPacMan8Inu",
    id_str: 1688111350549979136,
  },
  {
    screen_name: "DayNewsTrader",
    name: "Mention_Caps",
    id_str: 1635019908277837824,
  },
  {
    screen_name: "Tyrant_Trading",
    name: "TTG_Tyrant",
    id_str: 1432392359417991170,
  },
  {
    screen_name: "tetsuwan_DASH",
    name: "\u30b6\uff01\u9244\u8155\uff01DASH!! \u516c\u5f0f",
    id_str: 1593408722797461504,
  },
  {
    screen_name: "actioncoin",
    name: "Action Coin",
    id_str: 879338994365906945,
  },
  { screen_name: "LucasNoLimit", name: "Lucas", id_str: 1537544288032239616 },
  { screen_name: "KabosuInuETH", name: "Kabosu", id_str: 1608656999381880833 },
  { screen_name: "Minadex_gil", name: "MinaDex", id_str: 1615906121449107456 },
  {
    screen_name: "CryptoGemTR",
    name: "CryptoGemTR\ud83c\uddf9\ud83c\uddf7",
    id_str: 1397138361031217156,
  },
  {
    screen_name: "CikanCoin",
    name: "\u1d04\u026a\u1d0b\u1d00\u0274\u1d04\u1d0f\u026a\u0274",
    id_str: 1397362012456378370,
  },
  {
    screen_name: "Oliver_LaRosa",
    name: "Oliver La Rosa",
    id_str: 976207459563032578,
  },
  {
    screen_name: "BittrexGlobal",
    name: "Bittrex Global",
    id_str: 1027584103825137664,
  },
  { screen_name: "deeban_r", name: "Dr Deeban", id_str: 617032595 },
  { screen_name: "dojoprotocol", name: "DOJO", id_str: 1526541369870786561 },
  {
    screen_name: "INJ_Dojo",
    name: "INJ Dojo - nINJas",
    id_str: 1482030862237581317,
  },
  {
    screen_name: "CryptoWhalesCW",
    name: "Crypto Whales",
    id_str: 1595030503433928704,
  },
  {
    screen_name: "Saitamaguru1",
    name: "Russell Armand",
    id_str: 1411098927068233735,
  },
  {
    screen_name: "CardanoKarts",
    name: "Cardano Karts \ud83c\udfc6 MINTING LIVE",
    id_str: 1686411312945922048,
  },
  { screen_name: "beanNFT", name: "bean.algo", id_str: 1450950999972134914 },
  {
    screen_name: "ParallelLabs_",
    name: "Parallel Labs",
    id_str: 1372630788844453894,
  },
  {
    screen_name: "ZGroveCoin",
    name: "\ud835\ude89 | GroveCoin",
    id_str: 1580172106221948930,
  },
  {
    screen_name: "minaeth68",
    name: "MINA.ETH.LENS",
    id_str: 1431065281170792451,
  },
  {
    screen_name: "cryptofatguy2",
    name: "Crypto Fat Guy\u2122\ufe0f",
    id_str: 1467071746436177922,
  },
  {
    screen_name: "frmncapital",
    name: "FrmnCapital",
    id_str: 1500511382961520641,
  },
  {
    screen_name: "SonicOnSolana",
    name: "$SONIC \u26a1Meme Powered EduFi on Solana",
    id_str: 1644553961540833282,
  },
  {
    screen_name: "CaptainsCryptos",
    name: "Captain Crypto Reviews \u2b50",
    id_str: 1417935420072108036,
  },
  {
    screen_name: "BitkeepOS",
    name: "Bitget Wallet",
    id_str: 1664260933336846337,
  },
  {
    screen_name: "DegenSpartan",
    name: "\ucc0c G \u8dfb \u3058 MBA, CFA, FRM, CFP, NGMI, HFSP, HENTAI \ud83d\udee1\ufe0f",
    id_str: 1978953986,
  },
  {
    screen_name: "dogedillionaire",
    name: "Doge Dillionaire",
    id_str: 1719943572198481920,
  },
  {
    screen_name: "GenxAnalytics",
    name: "GenX Analytics\u2122 \ud83c\udf1f",
    id_str: 1344747827214237697,
  },
  { screen_name: "LBRYcom", name: "LBRY \ud83d\ude80", id_str: 2863324024 },
  {
    screen_name: "MihaiDanielBMK",
    name: "Mihai Daniel Marius",
    id_str: 1354221582806347780,
  },
  {
    screen_name: "CoingraphNews",
    name: "Coingraph | News",
    id_str: 2972586573,
  },
  {
    screen_name: "hellosunshine",
    name: "Hello Sunshine",
    id_str: 740608243941179393,
  },
  {
    screen_name: "axelarcore",
    name: "Formerly Axelar, now at @axelarnetwork",
    id_str: 1276508169154564096,
  },
  {
    screen_name: "CryptoLinns",
    name: "Crypto_Moon",
    id_str: 1397073609739235331,
  },
  {
    screen_name: "Krypto_Kyle",
    name: "Kyle Tardif",
    id_str: 1603012370481250308,
  },
  {
    screen_name: "CryptoGirlNFT_",
    name: "CRYPTO GIRL",
    id_str: 1450962881999097859,
  },
  { screen_name: "xrp_owl", name: "xrp_owl", id_str: 1603928966959489024 },
  { screen_name: "itscomplexz", name: "Aizen", id_str: 1623777842201862144 },
  {
    screen_name: "lunaterraluna",
    name: "Lun\u00e1ticos",
    id_str: 1439050947880267779,
  },
  {
    screen_name: "garitoken",
    name: "Aarthi Reddy",
    id_str: 1628612655119233028,
  },
  {
    screen_name: "1CryptoQuest",
    name: "CryptoQuest",
    id_str: 1438151677018886150,
  },
  {
    screen_name: "RiRi_gonewild",
    name: "RiRi (Beware of fake RiRi)",
    id_str: 1437965776225398784,
  },
  { screen_name: "TelefyConnect", name: "Telefy", id_str: 1437956312114024456 },
  {
    screen_name: "bold_point",
    name: "Bold Point \ud83d\udd3a",
    id_str: 1437790938588405764,
  },
  {
    screen_name: "TelegramTycoon",
    name: "Telegram Tycoon Marketing",
    id_str: 1435518886531661831,
  },
  {
    screen_name: "WhaleStatsBSC",
    name: "WhaleStats - the top 1000 BSC richlist",
    id_str: 1435159158253187078,
  },
  {
    screen_name: "Metabusdcoin",
    name: "metabusdcoin",
    id_str: 1638718998542942214,
  },
  {
    screen_name: "gigiialc",
    name: "GIGI Alcaraz\ud83c\udf99\ud83e\udddd\ud83c\udffc\u200d\u2640\ufe0f",
    id_str: 1434633459792764930,
  },
  {
    screen_name: "UTrust",
    name: "Ushagram Trust",
    id_str: 1665281650685509633,
  },
  {
    screen_name: "Prism_Protocol",
    name: "Non Doxxed",
    id_str: 1666681159973994496,
  },
  {
    screen_name: "HelenaFinancial",
    name: "Helena Financial",
    id_str: 1504036096941957125,
  },
  {
    screen_name: "ShibaPredator1",
    name: "QOM - Shiba Predator",
    id_str: 1504592695804952576,
  },
  {
    screen_name: "312Xuefeng",
    name: "\u96ea\u5cf0",
    id_str: 1492115620938145802,
  },
  {
    screen_name: "LFG_org",
    name: "LFG | Luna Foundation Guard",
    id_str: 1491939578323906564,
  },
  {
    screen_name: "SolandCity",
    name: "SolandCity",
    id_str: 1505462661823307779,
  },
  {
    screen_name: "arc9de",
    name: "Arcade - Promoter",
    id_str: 1491897083888025601,
  },
  {
    screen_name: "baktat_official",
    name: "Baktat.io",
    id_str: 1491740039147048964,
  },
  {
    screen_name: "NXD_OCM",
    name: "NXD Discord \ud83c\udf0f",
    id_str: 1506640618873049089,
  },
  {
    screen_name: "CryptoApeSkull",
    name: "Crypto Ape Skull Club",
    id_str: 1491704359704739844,
  },
  {
    screen_name: "royalpantheremp",
    name: "Royal Panther Empire",
    id_str: 1491402623764865027,
  },
  {
    screen_name: "real_moneylisa",
    name: "Money Lisa",
    id_str: 1508439416976515076,
  },
  {
    screen_name: "Athena_Finance_",
    name: "Athena Finance",
    id_str: 1509350030926356484,
  },
  { screen_name: "nft_war", name: "StopWarNFT", id_str: 1512000046199451655 },
  {
    screen_name: "cardalonia",
    name: "Cardalonia",
    id_str: 1512367046348136456,
  },
  {
    screen_name: "MeetWawa",
    name: "MeetWawa \ud83d\udc36",
    id_str: 1490201150301552641,
  },
  {
    screen_name: "EchelonFDN",
    name: "Echelon Blockchain\u26a1",
    id_str: 1513043622211424261,
  },
  { screen_name: "proleafty", name: "Proleafty", id_str: 1489901048026251265 },
  {
    screen_name: "BillionSkull_L0",
    name: "Billionaire Skull Club\uff5cTiny Skulls\uff5cCC0OmnichainNFT",
    id_str: 1513348276925648898,
  },
  {
    screen_name: "Web3GamesBSC",
    name: "Web3 Games",
    id_str: 1513353149956112384,
  },
  {
    screen_name: "EtherstonesFi",
    name: "Etherstones \ud83d\udc8e",
    id_str: 1489790047276597251,
  },
  {
    screen_name: "Cryptoconflict_",
    name: "Gunship Battle: Crypto Conflict",
    id_str: 1489118466682474499,
  },
  {
    screen_name: "GossipShib",
    name: "Gossip Shib is now @LucieSHIB",
    id_str: 1523199337303613442,
  },
  {
    screen_name: "remi_tetot",
    name: "Remi Tetot",
    id_str: 1488092644005842944,
  },
  {
    screen_name: "LunaTheDuck001",
    name: "Luna The Duck",
    id_str: 1487379553462984705,
  },
  { screen_name: "DDROPgg", name: "DDROPgg", id_str: 1525887125887016961 },
  {
    screen_name: "gp6299",
    name: "GP6299 \ud83c\udf16",
    id_str: 1486735842496372739,
  },
  {
    screen_name: "DegenGuild",
    name: "DegenGuild.eth",
    id_str: 1486637198162247680,
  },
  { screen_name: "stablekoi", name: "StableKoi", id_str: 1486338852180701186 },
  {
    screen_name: "MeshnetCapital",
    name: "Meshnet Capital",
    id_str: 1486175195391877128,
  },
  { screen_name: "basho_swap", name: "Bashoswap", id_str: 1485012456502136835 },
  {
    screen_name: "otoprotocol",
    name: "otoprotocol",
    id_str: 1483747085703053314,
  },
  {
    screen_name: "CryptoDrizzyy",
    name: "drizzy {313} {DD} \ud83c\udf27",
    id_str: 1483610624357806081,
  },
  { screen_name: "HGESOL", name: "NFTOTCDESK", id_str: 1530279263043198976 },
  {
    screen_name: "shibaqueen3",
    name: "shiba queen",
    id_str: 1474782190433181699,
  },
  {
    screen_name: "grape_finance",
    name: "Grape Finance",
    id_str: 1474382452147822594,
  },
  { screen_name: "news_of_bsc", name: "bsc", id_str: 1532342701609328641 },
  {
    screen_name: "fishinglifeNFT",
    name: "Fishing Life",
    id_str: 1473906954728734720,
  },
  {
    screen_name: "ApeCoin_Airdrop",
    name: "MetaUFO",
    id_str: 1532703887051849728,
  },
  {
    screen_name: "LionPrideClub",
    name: "Lion Pride Club",
    id_str: 1473642067347791874,
  },
  {
    screen_name: "CoinBetterCom",
    name: "CoinBetter",
    id_str: 1473536713116897280,
  },
  {
    screen_name: "GoCryptoMeCoin",
    name: "gocryptome",
    id_str: 1551349353473871875,
  },
  {
    screen_name: "Healthland_io",
    name: "Healthland",
    id_str: 1472325962591350787,
  },
  { screen_name: "0x_fxnction", name: "x", id_str: 1554484190762000384 },
  { screen_name: "Vulcano_Game", name: "Vulcano", id_str: 1471576823523954690 },
  {
    screen_name: "SoulDogsNFT",
    name: "Soul Dogs Sales Bot",
    id_str: 1471425925749166081,
  },
  {
    screen_name: "nOFTEN_NFT",
    name: "NFT marketplace overview",
    id_str: 1557391569975758849,
  },
  {
    screen_name: "CryptoWhale",
    name: "CryptoWhale",
    id_str: 1561707668007526401,
  },
  {
    screen_name: "elonmansur",
    name: "SaitaLogistics",
    id_str: 1561899163931009027,
  },
  {
    screen_name: "GorillaDiamondT",
    name: "zena.io",
    id_str: 1575102114682093568,
  },
  {
    screen_name: "CardanoStiftung",
    name: "Cardano Foundation",
    id_str: 1576281018482544645,
  },
  {
    screen_name: "The100xGems",
    name: "The 100x Gems \ud83d\udcb9\ud83d\ude80",
    id_str: 1468243350797131784,
  },
  { screen_name: "earn_usdt", name: "earn USDT", id_str: 1576832059888353281 },
  { screen_name: "AECOChain", name: "AECO", id_str: 1457898983511052288 },
  { screen_name: "SpeakNFT", name: "Speak", id_str: 1580221492129943553 },
  {
    screen_name: "NFTBunnyReal",
    name: "NFT Bunny",
    id_str: 1455454445727272965,
  },
  {
    screen_name: "RadioCacaNFT",
    name: "Follow @RACA_3",
    id_str: 1584322942397075456,
  },
  {
    screen_name: "MetaInsurgent",
    name: "Meta Insurgent",
    id_str: 1454383412580470788,
  },
  {
    screen_name: "ShibPrincess",
    name: "SHIB Princess \ud83d\udc51",
    id_str: 1454103880195665925,
  },
  { screen_name: "MTV_Art", name: "MTV.Art", id_str: 1454099631806222344 },
  {
    screen_name: "GymNet_Official",
    name: "GYM NETWORK",
    id_str: 1453091654903140356,
  },
  {
    screen_name: "cryptoartist78",
    name: "Crypto Artist / NFT Promoter",
    id_str: 1452582388362620931,
  },
  { screen_name: "Shibthemv", name: "Shib MV", id_str: 1685066941512269824 },
  { screen_name: "lunatichisoka", name: "Hisoka", id_str: 1413575681649295364 },
  {
    screen_name: "NetworkSandwich",
    name: "Sandwich Network",
    id_str: 1412016502656671748,
  },
  {
    screen_name: "All_in_MCDOGE_1",
    name: "Italian Stallion",
    id_str: 1410396708031320064,
  },
  {
    screen_name: "Next1000XGems",
    name: "\ud83e\udd34King Shanaz",
    id_str: 1410112337483231232,
  },
  {
    screen_name: "asparagoid",
    name: "a humble man",
    id_str: 1409176187121946628,
  },
  {
    screen_name: "babydoge_value",
    name: "Baby Doge Coin Value",
    id_str: 1408912771563933700,
  },
  {
    screen_name: "safe_rick",
    name: "\u2694\ufe0fRick. The Noble One",
    id_str: 1406055055946563585,
  },
  {
    screen_name: "MemeLordzRPG",
    name: "MemeLordz",
    id_str: 1405764092246204423,
  },
  { screen_name: "muzible", name: "Muzible.com", id_str: 1404113070293667845 },
  {
    screen_name: "FanfuryOfficial",
    name: "Fanfury \ud83c\udf16",
    id_str: 1403416781830516739,
  },
  {
    screen_name: "SHIBAExpert",
    name: "\u0627\u0628\u0640\u0648 \u0645\u0640\u0636\u0640\u0640\u0627\u0648\u064a",
    id_str: 2243786063,
  },
  {
    screen_name: "hotspotrf",
    name: "HotspotRF \ud83c\udf88",
    id_str: 1402636143418560515,
  },
  {
    screen_name: "astral_money",
    name: "Astral Money",
    id_str: 1402555490568073220,
  },
  {
    screen_name: "wowsuchcurrency",
    name: "Pixel Rick",
    id_str: 1399992201610936325,
  },
  {
    screen_name: "MansionAirdrop",
    name: "AirdropMansion",
    id_str: 1399664379956764677,
  },
  { screen_name: "d3protocol", name: "D3", id_str: 1399622124243927041 },
  {
    screen_name: "jpegheat",
    name: "$shib lover here",
    id_str: 1398420455082631168,
  },
  {
    screen_name: "deficonnect",
    name: "deficonnect",
    id_str: 1397886003566845956,
  },
  {
    screen_name: "TheCronicleNews",
    name: "TheCronicle.com - #CronosNews #Cronos #CRO #DeFi",
    id_str: 1397671945634713608,
  },
  { screen_name: "Gojo_Crypto", name: "Gojo", id_str: 1396564126734848000 },
  {
    screen_name: "Steam_Exchange",
    name: "Steam Exchange",
    id_str: 1396516168828080128,
  },
  { screen_name: "chitaglorya__", name: "Cayang", id_str: 1396365532849336323 },
  {
    screen_name: "CoinersTakingLs",
    name: "Crypto Bros Taking Ls",
    id_str: 1394891305755373568,
  },
  {
    screen_name: "bbntoken",
    name: "Big Brother Nations Token | Offical Channel",
    id_str: 1393685283632205826,
  },
  {
    screen_name: "StakeNerd",
    name: "Stake with Nerd and Nerd2",
    id_str: 44199761,
  },
  { screen_name: "VibraAfrica", name: "VIBRA", id_str: 1392813230905380868 },
  {
    screen_name: "doge1moon_CEO",
    name: "Doge-1 \ud83c\udf65",
    id_str: 1392145687194767366,
  },
  {
    screen_name: "topcryptostats",
    name: "Topcrypostats",
    id_str: 726437948527398912,
  },
  {
    screen_name: "Crosschainfarm",
    name: "Cross Chain Farming {3.3.3.3}",
    id_str: 1391739942116679683,
  },
  {
    screen_name: "PolkadotBoss",
    name: "Polkadot Boss",
    id_str: 1391074891521216512,
  },
  {
    screen_name: "moonpotdotcom",
    name: "Moonpot",
    id_str: 1376966167735234565,
  },
  {
    screen_name: "Farshad84478821",
    name: "farshad fan of caw \ud83e\udd85 \ud83c\udf39\ud83c\udf39\ud83c\udf39",
    id_str: 1373354010791575555,
  },
  { screen_name: "ksicrypto", name: "KSICRYPTO", id_str: 1373240119189241857 },
  {
    screen_name: "shibainuart",
    name: "MILKSHAKE",
    id_str: 1370977627989630976,
  },
  {
    screen_name: "mrtotalsuccess",
    name: "brian\ud83c\udf16",
    id_str: 758145260031619072,
  },
  {
    screen_name: "ListADA420",
    name: "HasGeminiDecidedToListADA",
    id_str: 1366813112653996034,
  },
  { screen_name: "coinviewing", name: "Capo.SOL", id_str: 1355144715579121665 },
  { screen_name: "Cypher3301", name: "Cypher", id_str: 1347717723464331264 },
  {
    screen_name: "Dogecoinxx",
    name: "Dogecoin\ud83e\udd47",
    id_str: 1345898128042569728,
  },
  {
    screen_name: "enriquesouza_",
    name: "Enrique Souza",
    id_str: 909802765391712257,
  },
  {
    screen_name: "Crypto__Diva",
    name: "Kristina Keller",
    id_str: 1309548880078548992,
  },
  {
    screen_name: "drseangallagher",
    name: "Sean R. Gallagher",
    id_str: 130405946,
  },
  { screen_name: "shibafame", name: "SHIBA FAME", id_str: 1298482527728877570 },
  {
    screen_name: "Bitboy_Crypto",
    name: "Ben Armstrong",
    id_str: 954005112174862336,
  },
  { screen_name: "Soheil_SBabaei", name: "S O H E i L", id_str: 957187663 },
  { screen_name: "BullishMas", name: "m4son.eth", id_str: 1259510265906900992 },
  {
    screen_name: "shibaburned",
    name: "Shitoshy BURNsama\u2122",
    id_str: 1251575010768158721,
  },
  {
    screen_name: "MoonMoon_IX",
    name: "MoonMoon_IX {313}",
    id_str: 1249683499071819776,
  },
  {
    screen_name: "ShibaAustralia",
    name: "\ud83d\udc8eShiba Inu Army/Community\ud83d\udc8e",
    id_str: 1218421413503094785,
  },
  { screen_name: "Magnumaggressor", name: "R.R", id_str: 985007921930027009 },
  {
    screen_name: "CryptoModulus",
    name: "CryptoModulus",
    id_str: 968583993317879808,
  },
  { screen_name: "ItsAlwaysZonny", name: "zon", id_str: 97081043 },
  {
    screen_name: "Phil_4_Freedom_",
    name: "Fed up Phil",
    id_str: 1207409404984868864,
  },
  {
    screen_name: "zo_pixle",
    name: "ZO.eth \ud83c\udfad",
    id_str: 1196205688172748801,
  },
  {
    screen_name: "vEmpireDDAO",
    name: "vEmpire DDAO is now @VEMPStudios",
    id_str: 1503808601676296200,
  },
  {
    screen_name: "BUSDBuffet",
    name: "BUSD Buffet",
    id_str: 1479463058602868742,
  },
  {
    screen_name: "WeRACAInsights",
    name: "WeRACA Insights",
    id_str: 1480388770637647874,
  },
  {
    screen_name: "ADuocrypto",
    name: "DuoCrypto.eth/Trad. Painting/Photography/Mixed Art",
    id_str: 1387877296539906052,
  },
  {
    screen_name: "bifrost_finance",
    name: "Darshan Prajapati",
    id_str: 1387438403621298180,
  },
  {
    screen_name: "dogefatherDEFI",
    name: "DogeFather",
    id_str: 1493434273981878274,
  },
  {
    screen_name: "DeFinder_E",
    name: "PandaDAO Alert",
    id_str: 1493484101378076677,
  },
  {
    screen_name: "FrogFrens",
    name: "Frog Frens\ud83d\udd3a| SOLD OUT",
    id_str: 1479225288542371848,
  },
  { screen_name: "AneoIo", name: "ANEO (\u26a1)", id_str: 1495860312540651522 },
  {
    screen_name: "ufi69j",
    name: "bubblegum \ud83e\udee7",
    id_str: 1496438341281234948,
  },
  { screen_name: "MattyG_Trades", name: "MattyG", id_str: 1497884187994599424 },
  {
    screen_name: "MetaApeGang",
    name: "Meta Ape Gang \ud83d\udd2b\ud83e\udd43 | SOLD OUT",
    id_str: 1498021409695711238,
  },
  {
    screen_name: "FinanceHexagon",
    name: "Hexagon Finance \ud83d\udd3a",
    id_str: 1498532947212705796,
  },
  {
    screen_name: "ringfiprotocol",
    name: "RingFi",
    id_str: 1498994145246990336,
  },
  {
    screen_name: "greenapp_io",
    name: "green-app.io \ud83c\udf31",
    id_str: 1478710191193567237,
  },
  { screen_name: "eth_shiba", name: "ETH SHIBA", id_str: 1499673394941669377 },
  { screen_name: "RogerFN6", name: "Roger FN", id_str: 1385073891391918086 },
  {
    screen_name: "airdrop_cetesi",
    name: "Airdrop \u00c7etesi",
    id_str: 1502603590950703112,
  },
  {
    screen_name: "tech_trusted",
    name: "Trusted Tech",
    id_str: 1562714311357005826,
  },
  {
    screen_name: "DefiAvalanche",
    name: "Avalanche Defi Listings \ud83d\udd3a",
    id_str: 1383781399430275081,
  },
  { screen_name: "clytie_io", name: "Clytie", id_str: 1383158298782875654 },
  { screen_name: "luart_io", name: "H", id_str: 1565449890850906119 },
  { screen_name: "_acardex", name: "Acardex", id_str: 1477969775796101124 },
  { screen_name: "bnbpad", name: "Tobby Tobby", id_str: 1569444406654291968 },
  { screen_name: "netvrk1", name: "netvrk1", id_str: 1569719908967272450 },
  {
    screen_name: "_Lady_Crypto_",
    name: "\ud835\udc0b\ud835\udd86\ud835\udd89\ud835\udd9e \ud83d\udd25 \ud835\udc02\ud835\udd97\ud835\udd9e\ud835\udd95\ud835\udd99\ud835\udd94",
    id_str: 1570982561530384388,
  },
  {
    screen_name: "crypt0mummy",
    name: "Crypt0mummy",
    id_str: 1571035260099465218,
  },
  {
    screen_name: "HectorDAO_HEC",
    name: "new account @hector_network",
    id_str: 15737388,
  },
  {
    screen_name: "CryptoListy",
    name: "Crypto Listy",
    id_str: 1382867077321527299,
  },
  { screen_name: "jonastyle_", name: "Jonas", id_str: 1382825708381159424 },
  {
    screen_name: "Loremaster0x1",
    name: "Loremaster",
    id_str: 1389028653326082052,
  },
  {
    screen_name: "SafemoonWarrior",
    name: "\ud83d\udd25SafeMoonWarrior",
    id_str: 1381346458843160577,
  },
  {
    screen_name: "ArcanumVentures",
    name: "Arcanum Ventures",
    id_str: 1476468844507983873,
  },
  {
    screen_name: "DeathNote_Token",
    name: "Death Note Token ($KIRA)",
    id_str: 1476351047144808458,
  },
  { screen_name: "VSTMEX", name: "VSTMEX", id_str: 1295211526467411968 },
  { screen_name: "STYLLARNFT", name: "STYLLAR", id_str: 1651197554 },
  { screen_name: "GemsByKans", name: "Kans", id_str: 1675125885693722626 },
  {
    screen_name: "PayBoltOfficial",
    name: "John21Crypto",
    id_str: 1678225027089707008,
  },
  { screen_name: "NFT_Enterprise", name: "Chris", id_str: 1705354477220274176 },
  { screen_name: "CBCAlerts", name: "CBC News Alerts", id_str: 19038934 },
  { screen_name: "GiganticRebirth", name: "GCR", id_str: 1289071298556170240 },
  { screen_name: "WOLF_of_DEFI", name: "Wolf of Defi", id_str: 301616369 },
  {
    screen_name: "ShibArmyBsc",
    name: "ShibArmy Token",
    id_str: 1446417881332338690,
  },
  { screen_name: "ArdanaProject", name: "Ardana Official", id_str: 308212434 },
  {
    screen_name: "MuchWowDog",
    name: "Much Wow \ud83d\udc3e Dog \ud83d\udc15",
    id_str: 1445709016240246793,
  },
  { screen_name: "defi_daria", name: "DefiDaria", id_str: 34850283 },
  {
    screen_name: "anchor_protocol",
    name: "Anchor Protocol",
    id_str: 1279333728032976896,
  },
  {
    screen_name: "stablekwon",
    name: "Do Kwon \ud83c\udf15",
    id_str: 357317524,
  },
  {
    screen_name: "crypto_mystery",
    name: "The Mysterious Mystic",
    id_str: 1444358536289562628,
  },
  {
    screen_name: "LaLaLand_305",
    name: "Sarah.B \ud83c\udf11",
    id_str: 1441266190345588745,
  },
  {
    screen_name: "abandonedameric",
    name: "Abandoned America",
    id_str: 536777262,
  },
  {
    screen_name: "pronewchaos",
    name: "Saitoshi Nanomoto \ud83c\udf11\u269b\ufe0f\ud83d\udfe5",
    id_str: 575008003,
  },
  {
    screen_name: "im_dimneo",
    name: "Dimitris Neocleous \u24cb VeChain",
    id_str: 589428477,
  },
  { screen_name: "FuturesCash", name: "FuturesCash", id_str: 811818840 },
  {
    screen_name: "BitradeKeysCo",
    name: "BitradeKeys",
    id_str: 820889684821868546,
  },
  {
    screen_name: "GordoCryptos",
    name: "GORDO | CRYPTO & NFT",
    id_str: 822168675679510528,
  },
  { screen_name: "samuel_clack", name: "Dr Sam", id_str: 833400652143357952 },
  {
    screen_name: "BDTalk0",
    name: "Shiba Billionaires \ud83d\udcb0\ud83d\udcb8",
    id_str: 1442174546077884427,
  },
  {
    screen_name: "terraLUNAdaily",
    name: "Terra Daily",
    id_str: 1443711772410593283,
  },
  {
    screen_name: "WR_Travel_Tech",
    name: "WR Travel Tech",
    id_str: 1388474423754121216,
  },
  { screen_name: "dougnewell2", name: "Cyclebas", id_str: 2590163252 },
  {
    screen_name: "game_wonderlab",
    name: "Game Wonderlab",
    id_str: 1428623753689243652,
  },
  {
    screen_name: "AlgoVestAVS",
    name: "AlgoVest | Earn 60% APR on USDC",
    id_str: 1330392918314643456,
  },
  {
    screen_name: "MathewPatenaud2",
    name: "Mat Patenaude \ud83c\udde8\ud83c\udde6",
    id_str: 1331368741410463746,
  },
  {
    screen_name: "Elikacrypto",
    name: "Elika.sol\ud83d\udc8e",
    id_str: 232257686,
  },
  {
    screen_name: "autofarmnetwork",
    name: "autofarm.network",
    id_str: 1336297862867501062,
  },
  { screen_name: "eeyernlee", name: "Ian", id_str: 1548702587389710337 },
  { screen_name: "ApexNodes", name: "Apex Nodes", id_str: 1547674556239716352 },
  { screen_name: "SHolt1993", name: "Sarah Holt", id_str: 1362269390473953280 },
  {
    screen_name: "HwanniePromotes",
    name: "Hwan \u2726",
    id_str: 1363765131172220933,
  },
  { screen_name: "juancitopaya", name: "Juan Payares", id_str: 488114005 },
  {
    screen_name: "Cryptonaut73",
    name: "Cryptonaut \ud83c\udde8\ud83c\udde6",
    id_str: 502225740,
  },
  { screen_name: "gnarhost", name: "HONEY", id_str: 1544373880902524933 },
  { screen_name: "FynnToTheMoon", name: "Fynn", id_str: 1366420336263245829 },
  {
    screen_name: "USTmarketcap",
    name: "UST MarketCap",
    id_str: 1461683235788890113,
  },
  {
    screen_name: "realcryptowire",
    name: "BLH RCW",
    id_str: 1540217869929046016,
  },
  {
    screen_name: "CardanoDan",
    name: "Card\u20b3no Dan \ud83d\udc02 ADA",
    id_str: 1080231212084535296,
  },
  {
    screen_name: "LunaBulls",
    name: "Mohammed Israil",
    id_str: 1538455276420222976,
  },
  {
    screen_name: "BabyMuskCoin",
    name: "Baby Musk Coin",
    id_str: 1077954315937996800,
  },
  {
    screen_name: "adaswapapp",
    name: "AdaSwap - The next-gen Cardano DEX!",
    id_str: 1417475153374715905,
  },
  {
    screen_name: "CardanoMaladex",
    name: "Maladex",
    id_str: 1521744871933222913,
  },
  { screen_name: "XDogeapp", name: "X Doge", id_str: 1519957191393890305 },
  { screen_name: "deuxpad", name: "DeuxPad", id_str: 1423608467571544064 },
  {
    screen_name: "lambofinance1",
    name: "LAMBO FINANCE",
    id_str: 1518466139436638209,
  },
  {
    screen_name: "ecosystemsleep",
    name: "Sleep Ecosystem",
    id_str: 1518065230361858048,
  },
  { screen_name: "FilialFamily", name: "Filial and Family", id_str: 80648281 },
  { screen_name: "maga_financial", name: "Mm", id_str: 1517756570049802240 },
  {
    screen_name: "NotLasers",
    name: "Jay Edidin \ud83e\udded",
    id_str: 100301510,
  },
  {
    screen_name: "PowerPixelNFTs",
    name: "PowerPixel",
    id_str: 1517123481057517569,
  },
  {
    screen_name: "DcntrlBank",
    name: "Decentral Bank",
    id_str: 1515740625378938881,
  },
  {
    screen_name: "frostyweb3",
    name: "Frosty\ud83d\udd3a\u2744\ufe0f",
    id_str: 1515718703408627717,
  },
  {
    screen_name: "YodaResearch",
    name: "Yoda Research",
    id_str: 1428376779568394244,
  },
  { screen_name: "fazel12376", name: "fazel", id_str: 2429135615 },
  {
    screen_name: "josh_qualley",
    name: "KOAcain!NOT!HYPE",
    id_str: 1429825993414434819,
  },
  { screen_name: "FinanceFroge", name: "FrogeX", id_str: 1514573362172936194 },
  {
    screen_name: "SafeWages",
    name: "SafeWages \u272a",
    id_str: 1465723941473992710,
  },
  { screen_name: "dylanleclair", name: "Dylan Rose LeClair", id_str: 93944385 },
  {
    screen_name: "CurrencyCom",
    name: "Currency.com",
    id_str: 1025311907786514433,
  },
  { screen_name: "StartFinance", name: "StartFi", id_str: 940713420512464896 },
  { screen_name: "GRIPDefi", name: "GRIPFinance", id_str: 1465565628635492352 },
  { screen_name: "BurnBNB", name: "BurnBNB", id_str: 1465169313669816320 },
  {
    screen_name: "battleshiptoken",
    name: "Battleship Token",
    id_str: 1464913372374261760,
  },
  {
    screen_name: "leontas1983",
    name: "Leogemio \ud83d\udc8e",
    id_str: 950883946073640960,
  },
  {
    screen_name: "CryptoTribune_",
    name: "Crypto Tribune",
    id_str: 1019040802020847616,
  },
  { screen_name: "flux_army", name: "FluxArmy", id_str: 1459662030164615174 },
  {
    screen_name: "CryptoCapo_",
    name: "il Capo Of Crypto",
    id_str: 988796804446769153,
  },
  {
    screen_name: "KarlaCrypto1",
    name: "\ud83d\udc8eKarla Crypto\ud83d\udc8e",
    id_str: 1464792312073052168,
  },
  {
    screen_name: "YieldHuntAVAX",
    name: "Yield Hunt - Gen 0 SOLD OUT\ud83d\udd3a",
    id_str: 1464461045959999490,
  },
  { screen_name: "AceDCoin", name: "AceD Coin", id_str: 991164637558140933 },
  {
    screen_name: "MulVerseCapital",
    name: "Multiverse Capital - $MVC",
    id_str: 1463763996306149380,
  },
  {
    screen_name: "carolintaling",
    name: "Carolin Taling",
    id_str: 991716278103691264,
  },
  {
    screen_name: "Polkadot_Hub",
    name: "Polkadot Hub",
    id_str: 1619360182018121728,
  },
  { screen_name: "Ms_NicoleMurray", name: "xoxo, Nicole", id_str: 2951361943 },
  {
    screen_name: "ShibainuCoin",
    name: "Shiba Inu",
    id_str: 1355918864509509637,
  },
  {
    screen_name: "ApexAlpha_",
    name: "Apex Alpha ( \ud83d\ude80,\ud83c\udf37)",
    id_str: 1615111371745632263,
  },
  { screen_name: "JohnWickArgo", name: ".", id_str: 1144525983816527872 },
  { screen_name: "alice_finance", name: "Alice", id_str: 1358674352808415232 },
  {
    screen_name: "imposs_reader",
    name: "Maria the Crypto Girl",
    id_str: 327028681,
  },
  {
    screen_name: "TheDaoMaker",
    name: "Bullish By DAO",
    id_str: 1359439646640529415,
  },
  {
    screen_name: "the_claymates",
    name: "CLAY Stake Pool",
    id_str: 1359462534101745667,
  },
  {
    screen_name: "BellaCryptoLA",
    name: "\u2728Bella | GEM FINDER \ud83d\ude80\u2728",
    id_str: 1359493130823827457,
  },
  {
    screen_name: "StraightUpXRP",
    name: "$\ud835\udd99\ud835\udd97\ud835\udd86\ud835\udd8e\ud835\udd8c\ud835\udd8d\ud835\udd99 \ud835\udd9a\ud835\udd95\u2600\ufe0f\u24e7\u24e1\u24df\ud83d\ude80\ud83c\udf16",
    id_str: 3387817821,
  },
  { screen_name: "cstross", name: "Charlie Stross", id_str: 390039185 },
  { screen_name: "OutletFinance", name: "Outlet", id_str: 1126213538475847680 },
  { screen_name: "PayasoSinGracia", name: "Paya.eth", id_str: 418205370 },
  {
    screen_name: "POKKETOfficial",
    name: "POKKET",
    id_str: 1121321245763485696,
  },
  { screen_name: "GabeGable", name: "Gabe Gable", id_str: 4216732692 },
  { screen_name: "Hey_ImTheNewGuy", name: "HeyImTheNewGuy", id_str: 423059537 },
  {
    screen_name: "aurenfts",
    name: "AureNFTs \uea00.eth",
    id_str: 1591871074861547524,
  },
  {
    screen_name: "BEconomy_HQ",
    name: "Blockchain Economy",
    id_str: 1047161598681518080,
  },
  {
    screen_name: "MultichainOrg",
    name: "Multichain (Previously Anyswap)",
    id_str: 1274693174930190341,
  },
  {
    screen_name: "fcfpay",
    name: "FCF PAY - Blockchain Payment System",
    id_str: 1478031071350566912,
  },
  {
    screen_name: "PompPodcast",
    name: "Pomp Podcast",
    id_str: 1448743621310754816,
  },
  {
    screen_name: "OKCNetwork",
    name: "OKT Chain \u269b\ud83d\udd78",
    id_str: 1316686343905316864,
  },
  { screen_name: "Tundra_Explorer", name: "Tundra", id_str: 573129730 },
  {
    screen_name: "lensofcinema",
    name: "Cinematic Lens",
    id_str: 1483160376821051392,
  },
  {
    screen_name: "dogesuppporteam",
    name: "Khal\u0110oge79\ud83d\ude80",
    id_str: 1311271048650862592,
  },
  {
    screen_name: "EDNewsChina",
    name: "Economic Daily, China",
    id_str: 1211950858746785793,
  },
  {
    screen_name: "MaryGiveaway",
    name: "Mary Giveaway",
    id_str: 1483504470084526084,
  },
  {
    screen_name: "CRYPTOxADDICT",
    name: "Crypto Addict",
    id_str: 1384523962667909121,
  },
  { screen_name: "astrnews", name: "AstarNews", id_str: 1319725042041839616 },
  {
    screen_name: "XRP_Moonshots",
    name: "XRP Moon \ud83d\ude80\ud83c\udf15",
    id_str: 1156074409767178240,
  },
  { screen_name: "DenevaAni", name: "Ani Crypto", id_str: 1451897869649289224 },
  {
    screen_name: "rapics721",
    name: "Ra \u263c \u039e",
    id_str: 1460215707761840135,
  },
  { screen_name: "SHIBAExpert", name: "SHIB Expert", id_str: 861097190 },
  {
    screen_name: "CryptoTanjiros",
    name: "CryptoTanjiro",
    id_str: 1540420868769210368,
  },
  {
    screen_name: "SmartBSCNews",
    name: "\ud83d\udcab Smart BSC News\ud83d\udcab",
    id_str: 1362599985582252037,
  },
  {
    screen_name: "angelzofficial",
    name: "ANGELZ",
    id_str: 1520993767045574656,
  },
  {
    screen_name: "ArdanaProject",
    name: "Ardana - DeFi Hub of Cardano",
    id_str: 1393604451370512394,
  },
  { screen_name: "hernanlafalce", name: "Hernan Lafalce", id_str: 91261017 },
  {
    screen_name: "MarketsMickle",
    name: "Mickle\u2600\ufe0f",
    id_str: 1633933058406506496,
  },
  {
    screen_name: "WagglesCG",
    name: "Waggles Crypto Goods",
    id_str: 1485018401642717186,
  },
  {
    screen_name: "PayBoltOfficial",
    name: "PayBolt",
    id_str: 1468905677993111554,
  },
  {
    screen_name: "PassivePandaNC",
    name: "Passive Panda Node Club\ud83d\udd3a\ud83d\udc3c",
    id_str: 1484537629773291521,
  },
  {
    screen_name: "GalaxyHeroesGHC",
    name: "Galaxy Heroes",
    id_str: 1431355642804121604,
  },
  {
    screen_name: "mexc_global",
    name: "MEXC Global",
    id_str: 1666439560551694340,
  },
  {
    screen_name: "mexc_global",
    name: "mexc global",
    id_str: 1614895502721536003,
  },
  {
    screen_name: "shaggy_waggyy",
    name: "Shaggy waggy",
    id_str: 1517778977913331715,
  },
  {
    screen_name: "tradervaus",
    name: "vaus \u2755",
    id_str: 1462509205043224578,
  },
  {
    screen_name: "sgivs_",
    name: "\ud835\udc94\ud835\udc86",
    id_str: 1444431205261189120,
  },
  {
    screen_name: "bg_rusa",
    name: "Big Rusa ~IFBP 100%",
    id_str: 1581419103138504704,
  },
  { screen_name: "CoooinCrypto", name: "MIU MIU", id_str: 1655198183708938242 },
  {
    screen_name: "AWLDAO",
    name: "AWL\u2665\ufe0fDAO",
    id_str: 1519673001867984897,
  },
  {
    screen_name: "minifootballeth",
    name: "Mini Football",
    id_str: 1423471667674767360,
  },
  {
    screen_name: "Vinay_Web3",
    name: "Vinay Kumar",
    id_str: 1616980843053957122,
  },
  {
    screen_name: "popop_world",
    name: "Popop World| Stake Popman now!",
    id_str: 1429657423078256640,
  },
  {
    screen_name: "ANGELZOFFICIAL",
    name: "ANGELZ \u24d6\ud83d\udd79\ufe0f",
    id_str: 1337060846,
  },
  {
    screen_name: "asparagoid",
    name: "\ud83d\udc2d",
    id_str: 1398290390374027271,
  },
  {
    screen_name: "AztecNodes",
    name: "Aztec Nodes - WE ARE LIVE\ud83c\udf1e",
    id_str: 1502830629297213445,
  },
  { screen_name: "SmorcSol", name: "SMORC", id_str: 1445371917351931907 },
  {
    screen_name: "RizenNft",
    name: "Rizen \ud83c\udf4c-\ud83c\udf4cnft",
    id_str: 1590650047175303175,
  },
  { screen_name: "DonYakka", name: "Don Y", id_str: 1633456108927950849 },
  {
    screen_name: "ShibQueenie",
    name: "Fake Liar",
    id_str: 1620617570666614784,
  },
  { screen_name: "CalvinsBrew", name: "0141", id_str: 126134969 },
  { screen_name: "Astrnews", name: "Astar News", id_str: 1279805726404337666 },
  {
    screen_name: "CARDANOVILLAGE",
    name: "Cardano Village",
    id_str: 1459545014741368832,
  },
  {
    screen_name: "ImpactXPToken",
    name: "ImpactXP Official",
    id_str: 1458772070377172995,
  },
  {
    screen_name: "basedcryptoking",
    name: "Basedcryptoking",
    id_str: 1415959959188217857,
  },
  {
    screen_name: "ALL_IN_MCDOGE_1",
    name: "\u026a \u029f\u1d0f\u1d20\u1d07 \u029c\u1d0f\u1d1b \u0274\u1d00\u1d0b\u1d07\u1d05 \u1d0d\u1d07\u0274 \u2764\ufe0f\ud83c\uddfa\ud83c\uddf8",
    id_str: 1568887973999157250,
  },
  { screen_name: "itsdappsy", name: "Dappsy.io", id_str: 1422528609219190787 },
  {
    screen_name: "PandasAwakening",
    name: "The Panda's Awakening (Minting \ud83d\udd1c)",
    id_str: 1616632538780377092,
  },
  {
    screen_name: "GariPandas",
    name: "Gari Intern",
    id_str: 1620624301396299777,
  },
  { screen_name: "Web3Queenie", name: "Queenie", id_str: 881157706190512130 },
  {
    screen_name: "ShibReports",
    name: "RyoshiMade",
    id_str: 1558920321432002561,
  },
  {
    screen_name: "Katie_ShibaHoly",
    name: "Katie - Shiba Holy",
    id_str: 1447720676845133824,
  },
  { screen_name: "RyotaDubai", name: "Ryota", id_str: 1344463133327282177 },
  { screen_name: "dehub_official", name: "DeHub", id_str: 1415660208987181059 },
  {
    screen_name: "flozinbtc",
    name: "FLOZIN \ud83e\udd8a",
    id_str: 1473447051832840198,
  },
  { screen_name: "TrechNex", name: "Bobby Moss", id_str: 1551762505101344769 },
  { screen_name: "bellacnft", name: "bella", id_str: 1443955570134769666 },
  {
    screen_name: "startup_space1",
    name: "\ud83d\ude80 \ud835\ude4e\ud835\ude69\ud835\ude56\ud835\ude67\ud835\ude69\ud835\ude6a\ud835\ude65 \ud835\ude4e\ud835\ude65\ud835\ude56\ud835\ude58\ud835\ude5a \ud83d\ude80",
    id_str: 1425013529271230484,
  },
  {
    screen_name: "EduardoSurita",
    name: "Eduardo Jan Harry Skoldberg Surita",
    id_str: 1592219277922373632,
  },
  {
    screen_name: "PaulLar79181510",
    name: "Paul Larry",
    id_str: 1372082784269971456,
  },
  {
    screen_name: "nftcollector001",
    name: "NFT Collector",
    id_str: 1592184059262013441,
  },
  {
    screen_name: "lif_e_Tm",
    name: "S\u029c\u026a\u0299\u1d00W\u1d00\u0280\u0280\u026a\u1d0f\u0280",
    id_str: 1213152860675829760,
  },
  {
    screen_name: "GyroCommunity",
    name: "Blockchain || Crypto || NFT || Metaverse",
    id_str: 1257642549851766786,
  },
  {
    screen_name: "jjcollinsworth",
    name: "Josh Collinsworth",
    id_str: 21283188,
  },
  {
    screen_name: "TikiTuka_",
    name: "TikiTuka \uea00",
    id_str: 1426318224979804160,
  },
  { screen_name: "ShibInform", name: "SHIBA INU", id_str: 1416642324 },
  { screen_name: "astar_hub", name: "Astar Hub", id_str: 1500736474588848128 },
  {
    screen_name: "polkadot_hub",
    name: "Polkadot Hub $DOT",
    id_str: 1503714342969171978,
  },
  { screen_name: "scoinaldo", name: "Simon Dedic", id_str: 902126235463147520 },
  { screen_name: "shibaburnerc20", name: "TBC", id_str: 1476530191132409857 },
  {
    screen_name: "missufe",
    name: "jihan \ud83e\udd84",
    id_str: 1461324154381103112,
  },
  {
    screen_name: "ladycryptogem",
    name: "LadyCryptoGem \ud83d\udc8e",
    id_str: 1165658491765149696,
  },
  {
    screen_name: "ShibArmy_4Life",
    name: "\ud835\udc0b\ud835\udd86\ud835\udd89\ud835\udd9e \ud83d\udd25 \ud835\udc02\ud835\udd97\ud835\udd9e\ud835\udd95\ud835\udd99\ud835\udd94",
    id_str: 1389585274573709313,
  },
  { screen_name: "remi_tetot", name: "Remi Tetot", id_str: 1953841016 },
  {
    screen_name: "YieldZilla_",
    name: "YieldZilla",
    id_str: 1504399127429455874,
  },
  { screen_name: "0x1559_eth", name: "Bob0x1559", id_str: 1324045046904139777 },
  {
    screen_name: "0x_LilWhale",
    name: "Lilwhale.sol.eth \ud83d\udc0b",
    id_str: 1512105416347553794,
  },
  { screen_name: "ShibGerman", name: "Shib", id_str: 1419593718202568708 },
  {
    screen_name: "react_dao",
    name: "Rebase Aggregator Capital",
    id_str: 1508717751967563780,
  },
  {
    screen_name: "BattleKnightICO",
    name: "BattleKnight.Network",
    id_str: 1474764872139390976,
  },
  {
    screen_name: "TheDegenMidas",
    name: "The Degen Midas \ud83d\udc51",
    id_str: 1572677682885795848,
  },
  {
    screen_name: "FlyDropCourse",
    name: "Fly Drop Course",
    id_str: 768904089728065536,
  },
  {
    screen_name: "VinumTech",
    name: "Vinum Technology",
    id_str: 1412426733106651139,
  },
  {
    screen_name: "BinemonNft",
    name: "Binemon Apple",
    id_str: 1395999986484011009,
  },
  {
    screen_name: "BeckMartin_",
    name: "Beck Martin \ud83d\udc8e",
    id_str: 1464094796776185858,
  },
  { screen_name: "tan_twtt", name: "Tan\u2600", id_str: 22448465 },
  { screen_name: "JUMPNofficial", name: "JUMPN", id_str: 533027578 },
  {
    screen_name: "MoonwithADAM",
    name: "Adam King \ud83e\udea9",
    id_str: 1080576397100363795,
  },
  {
    screen_name: "Raidensoll",
    name: "ChibiRaiden",
    id_str: 1508415724598923264,
  },
  {
    screen_name: "Beantown_shinja",
    name: "Conor McShinja",
    id_str: 1455992104304345091,
  },
  {
    screen_name: "NFTPandania",
    name: "Pandania \ud83d\udc3c",
    id_str: 1513623127279943686,
  },
  { screen_name: "MJ25Hoops", name: "MarMar", id_str: 946920805 },
  {
    screen_name: "BonaDeaCrypto",
    name: "\u20bfona D\u20aca",
    id_str: 1427648858981605391,
  },
  { screen_name: "w3nerked", name: "w3nark", id_str: 1396994471829594114 },
  {
    screen_name: "Krypto_Kyle",
    name: "Krypto Kyle \u270c\ud83c\udffc",
    id_str: 1293039780104724482,
  },
  { screen_name: "Andrew92SG", name: "Andrew", id_str: 1247931138699476992 },
  {
    screen_name: "Jessiicaaniston",
    name: "Jessica Aniston\uea00",
    id_str: 1410144692856004608,
  },
  { screen_name: "LunaBulls", name: "LunaBulls", id_str: 1549299896041209856 },
  {
    screen_name: "sweeplord",
    name: "Lil Swag \ud83e\uddd9\ud83c\udfff\u200d\u2642\ufe0f",
    id_str: 1443628984504229893,
  },
  { screen_name: "zenacorp", name: "Zena", id_str: 1378926997636849664 },
  {
    screen_name: "degengamblesol",
    name: "Degen Gamble \ud83c\udfb2",
    id_str: 1362500532896600071,
  },
  {
    screen_name: "nexus_dubai",
    name: "Nexus Dubai Official",
    id_str: 1508233649132560384,
  },
  { screen_name: "CryptoKing1st", name: "Crypto King", id_str: 3191032983 },
  {
    screen_name: "attitudeapenft",
    name: "ATTITUDE APE NFT",
    id_str: 1512861632372047874,
  },
  { screen_name: "DogeorTesla", name: "CryptoLV", id_str: 1481643599871676424 },
  { screen_name: "LVNFTs", name: "LVNFTS", id_str: 1501566869005811715 },
  {
    screen_name: "luart_io",
    name: "Luart.io \ud83d\udc69\u200d\ud83d\ude80",
    id_str: 1440293769375485957,
  },
  {
    screen_name: "MultimediaNFT",
    name: "State of NFT",
    id_str: 1364595094700101632,
  },
  {
    screen_name: "Nexus_Dubai",
    name: "\ud83c\udde6\ud83c\uddeaNXD",
    id_str: 17506089,
  },
  {
    screen_name: "Kaisen_Inu",
    name: "Kaisen Inu",
    id_str: 1508084997504204802,
  },
  {
    screen_name: "RematicEGC",
    name: "RematicEGC",
    id_str: 1461389679337558023,
  },
  {
    screen_name: "Blueboytweets_",
    name: "Blue \ud83c\udf0d",
    id_str: 1247597912135262210,
  },
  { screen_name: "jayythedope", name: "Shola", id_str: 1448745376324915203 },
  { screen_name: "Abes0x", name: "Abes", id_str: 1488148944932716551 },
  {
    screen_name: "GruugCrypto",
    name: "Crypto | Grug \ud83d\uddff\ud83e\udea8",
    id_str: 983910380178034688,
  },
  {
    screen_name: "MazeWandaX",
    name: "MazeWanda | CRYPTO",
    id_str: 984860246634934272,
  },
  { screen_name: "ETH_Spark", name: "Spark.eth PROMOTIONS", id_str: 440550430 },
  { screen_name: "MarissaCrypto", name: "Marissa Crypto", id_str: 426907429 },
  {
    screen_name: "AlexWillCrypto",
    name: "Alex Wilder \ud83c\udf0e",
    id_str: 3179399429,
  },
  {
    screen_name: "saraharrisons",
    name: "Sarah\ud83d\udc8e",
    id_str: 110713860,
  },
  {
    screen_name: "eqnetwork_io",
    name: "EQ Network",
    id_str: 1404782526598500358,
  },
  {
    screen_name: "SHIBA__Token",
    name: "ShibToKen",
    id_str: 1508845211048620032,
  },
  {
    screen_name: "VaporNodes",
    name: "VaporNodes",
    id_str: 1557089360314617859,
  },
  {
    screen_name: "VortexProtocol",
    name: "Vortex Protocol",
    id_str: 1482047070999506944,
  },
  {
    screen_name: "Theophite",
    name: "torment nexus ethics engineer",
    id_str: 268052347,
  },
  {
    screen_name: "GiuPagnotta",
    name: "Giuseppe CRYPTO\ud83d\udc51",
    id_str: 1316888158374682625,
  },
  {
    screen_name: "ouagostinho",
    name: "Agostinho Carrara",
    id_str: 1399532406881853443,
  },
  { screen_name: "peterhuntcrypto", name: "Peter Hunt", id_str: 495249214 },
  { screen_name: "sDanieleGalipo", name: "SalvatoreCrypto", id_str: 63176175 },
  { screen_name: "imbagsy", name: "Retired", id_str: 1543911954519101440 },
  {
    screen_name: "GoCryptoMeApp",
    name: "GoCryptoMe",
    id_str: 1490346666720964609,
  },
  { screen_name: "0xAilla", name: "0xAilla", id_str: 1513143265188581380 },
  { screen_name: "coinexnews", name: "CoinEx News", id_str: 1432209108 },
  {
    screen_name: "GoldenToken_0x",
    name: "Golden Inu - The Golden Horde \u2694\ufe0f",
    id_str: 1508965242021236737,
  },
  {
    screen_name: "Jswap_Finance",
    name: "Jswap_Finance DAO",
    id_str: 1367785210817880065,
  },
  {
    screen_name: "GossipShib",
    name: "Lucie Sasinkova",
    id_str: 1547137250357657601,
  },
  {
    screen_name: "AlexKir62743918",
    name: "Alex Kira",
    id_str: 1471817430410223620,
  },
  {
    screen_name: "Hector_Network",
    name: "Hector Network",
    id_str: 1452731103467511809,
  },
  { screen_name: "Firmiti", name: "Firmiti", id_str: 1441418984348270598 },
];
