import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { RiEyeLine, RiEyeOffFill } from "react-icons/ri";
import { InputProps } from "./interfaces";

const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  variant = "standard",
  formik,
  name,
  helpText,
  hasInlineLabel = true,
  hasInnerLabel = false,
  disabled = false,
  icon,
  initialValues,
  setInitialValues,
  labelColor,
  inputTextColor,
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (setInitialValues) {
      setInitialValues({
        ...initialValues,
        [name]: formik.getFieldProps(name).value,
      });
    }
  }, [formik.getFieldProps(name).value]);

  return (
    <Grid container>
      {hasInlineLabel ? (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: { xs: ".4rem !important" },
          }}
        >
          {icon ? icon : ""}
          <Typography
            variant="body2"
            sx={{
              color: labelColor ? labelColor : theme.palette.text.primary,
              fontWeight: 100,
            }}
          >
            {label}
          </Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid item md={12} xs={12}>
        <TextField
          fullWidth
          label={hasInnerLabel ? label : ""}
          variant={variant}
          type={show ? "text" : type}
          {...formik.getFieldProps(name)}
          name={name}
          size="small"
          disabled={disabled}
          sx={{
            fontWeight: 100,
            "& .MuiFormLabel-root": {
              fontSize: "13px",
              color: inputTextColor
                ? inputTextColor
                : theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              fontWeight: 100,
              fontSize: "14px",
              color: inputTextColor
                ? inputTextColor
                : theme.palette.text.primary,
            },
          }}
          InputProps={
            type === "password"
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      {show ? (
                        <RiEyeLine
                          onClick={() => setShow(!show)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <RiEyeOffFill
                          onClick={() => setShow(!show)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }
              : icon
                ? {
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        color={theme.palette.common.white}
                      >
                        {icon}
                      </InputAdornment>
                    ),
                  }
                : null
          }
        />
        {helpText && (
          <Typography
            variant="caption"
            sx={{
              fontWeight: 100,
              marginTop: "7px",
              display: "block",
              //@ts-ignore
              color: theme.palette.common.mute,
            }}
          >
            {helpText}
          </Typography>
        )}
        {formik.touched[name] && formik.errors[name] && (
          <Typography className="formik-error" color={theme.palette.error.main}>
            {formik.errors[name]}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Input;
