import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GrShieldSecurity } from "react-icons/gr";
import {
  MdOutlineAnalytics,
  MdOutlineChevronRight,
  MdOutlineDonutLarge,
  MdOutlineKeyboardArrowDown,
  MdOutlinePriceCheck,
} from "react-icons/md";
import { CgMenuLeft } from "react-icons/cg";
import { TbBrandProducthunt } from "react-icons/tb";
import { RiNotification2Fill } from "react-icons/ri";
import { GoCommentDiscussion } from "react-icons/go";
import { HiOutlineChatAlt2, HiOutlineUserGroup } from "react-icons/hi";
import { FaMoon, FaRegCreditCard, FaSun, FaUserCircle } from "react-icons/fa";
import { HiHome } from "react-icons/hi2";
import Logo from "../../common/logo";
import { themeSwitcher } from "../../../features/app/appSlice";
import { toggleAgentDrawer } from "../../../features/agent/agentSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { HeaderProps } from "./interface";
import "./style.scss";
import { BiLoaderCircle } from "react-icons/bi";
import Avatar from "react-avatar";

// @ts-ignore
const Header: React.FC<HeaderProps> = ({}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const mode = theme.palette.mode;
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const productsRef = useRef<HTMLLIElement>(null);
  const dispatch: AppDispatch = useAppDispatch();
  const account: any = useSelector((state: RootState) => state.account);
  const alertsState: any = useSelector((state: RootState) => state.alerts);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const UserAvatar = Avatar;

  const [productsMenu, setProductsMenu] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("/");
  const [notificationMenu, setNotificationMenu] = useState<null | HTMLElement>(
    null
  );
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem("UserData"));
  const notificationMenuState = Boolean(notificationMenu);
  const productsMenuState = Boolean(productsMenu);

  const handleClickProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProductsMenu(event.currentTarget);
  };
  const handleCloseProducts = () => {
    setProductsMenu(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationMenu(event.currentTarget);
    // setOpenDrawer((prev: boolean) => !prev)
  };

  const handleClose = () => {
    setNotificationMenu(null);
  };

  const handleDocumentClick = (event: any) => {
    // Close menus only if the click is outside of the header or its children
    if (!productsRef.current?.contains(event.target) && !notificationMenu) {
      handleCloseProducts();
    }
    if (notificationMenu && !notificationMenu.contains(event.target)) {
      handleClose();
    }
  };

  useLayoutEffect(() => {
    const handleClickOutsideBtn = (e: any) => {
      if (productsRef.current && !productsRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideBtn);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideBtn);
  }, [productsRef]);

  useEffect(() => {
    setActiveTab(window.location.pathname);
  }, [window.location.pathname]);

  const switchColor: string =
    //@ts-ignore
    mode === "dark" ? theme.palette.text.primary : theme.palette.common.green;

  const setColorBasedOnLocation = (loc: string) => {
    return loc == location.pathname
      ? theme.palette.primary.main
      : theme.palette.text.primary;
  };

  const handleProfilePic = (userData: any): string => {
    if (userData?.profile_picture && userData?.profile_picture_google) {
      return userData?.profile_picture;
    } else if (userData?.profile_picture) {
      return userData?.profile_picture;
    } else {
      return userData?.profile_picture_google;
    }
  };

  return (
    <Box sx={{ maxWidth: "2000px", mx: "auto" }}>
      <header
        style={{
          position: "relative",
          zIndex: 1000,
          marginLeft: downLg
            ? 0
            : location.pathname.includes("/agents")
              ? "270px"
              : 0,
        }}
      >
        <Box className="logo">
          {downLg && location.pathname.includes("/agents") && (
            <Box className="menu-icon" id="derawer-menu-button">
              <IconButton
                onClick={() => {
                  location.pathname.includes("/agents") &&
                    dispatch(toggleAgentDrawer());
                }}
                sx={{
                  color: (theme: any) =>
                    mode === "dark"
                      ? theme.palette.text.primary
                      : theme.palette.common.green,
                }}
              >
                <CgMenuLeft size={23} />
              </IconButton>
            </Box>
          )}
          <Link
            to="/"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Logo isDashboard />
            <h6
              style={{
                marginTop: "0.3rem",
                color: "#00AEEF",
                fontSize: "20px",
              }}
            >
              Coinfident
            </h6>
          </Link>
          <IconButton
            className="notification-icon"
            id="notification-button"
            aria-controls={open ? "notification-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              color: (theme: any) =>
                mode === "dark"
                  ? theme.palette.text.primary
                  : theme.palette.common.green,
            }}
          >
            <RiNotification2Fill size={20} />
          </IconButton>
          <Menu
            id="notification-menu"
            anchorEl={notificationMenu}
            open={notificationMenuState}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "notification-button",
            }}
            sx={{
              ul: {
                padding: "0 !important",
              },
            }}
          >
            <Box
              sx={{
                padding: "1rem",
                background: (theme: any) => theme.palette.common.solidCard,
              }}
            >
              <Typography
                variant="body1"
                align="center"
                mb={2}
                sx={{ color: theme.palette.common.white }}
              >
                Alerts
              </Typography>

              {alertsState.engagementAlerts?.count > 0 &&
                alertsState.engagementAlerts.results?.map((alert: any) => (
                  <Link
                    to="/alerts"
                    key={alert.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #eee",
                      minWidth: "200px",
                      padding: ".5rem 0 ",
                    }}
                  >
                    <GoCommentDiscussion
                      color={theme.palette.common.white}
                      style={{ marginRight: "10px" }}
                    />
                    <Typography variant="caption" align="left" sx={{ flex: 1 }}>
                      {
                        cryptosState.availableCoins?.find(
                          (crypto: any) => crypto.id == alert.coin
                        )?.name
                      }
                    </Typography>
                    <Typography variant="caption" sx={{ flex: 1 }}>
                      {alert.target_engagement_percentage_change}%
                    </Typography>
                    <MdOutlineChevronRight
                      size={20}
                      color={theme.palette.common.white}
                    />
                  </Link>
                ))}
              {alertsState.priceAlerts?.count > 0 &&
                alertsState.priceAlerts.results?.map((alert: any) => (
                  <Link
                    to="/alerts"
                    key={alert.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      minWidth: "200px",
                      padding: ".5rem 0 ",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    <MdOutlinePriceCheck
                      color={theme.palette.common.white}
                      style={{ marginRight: "10px" }}
                    />
                    <Typography variant="caption" sx={{ flex: 1 }}>
                      {
                        cryptosState.availableCoins?.find(
                          (crypto: any) => crypto.id == alert.coin
                        )?.name
                      }
                    </Typography>
                    <Typography variant="caption" sx={{ flex: 1 }}>
                      ${alert.target_price}
                    </Typography>
                    <MdOutlineChevronRight
                      size={20}
                      color={theme.palette.common.white}
                    />
                  </Link>
                ))}

              {(!alertsState.priceAlerts ||
                !alertsState.engagementAlerts ||
                alertsState.priceAlerts?.count == 0 ||
                alertsState.engagementAlerts?.count == 0) && (
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.primary }}
                >
                  {account.isAuthenticated
                    ? "There are no new notifications"
                    : "Login to your account so you can set alert"}
                </Typography>
              )}

              {account.isAuthenticated && (
                <Button
                  variant="contained"
                  fullWidth
                  size="small"
                  sx={{
                    mt: 2,
                    padding: "0 !important",
                    "&:hover": {
                      background: theme.palette.secondary.main,
                    },
                  }}
                >
                  <Link
                    to="/alerts"
                    style={{
                      width: "100%",
                      padding: "4px 14px",
                      //@ts-ignore
                      color: theme.palette.common.darkPrimary,
                    }}
                  >
                    View all
                  </Link>
                </Button>
              )}
            </Box>
          </Menu>

          <label htmlFor="toggleTheme" className="switch">
            <input
              type="checkbox"
              id="toggleTheme"
              checked={theme.palette.mode === "dark" ? true : false}
              onChange={() =>
                dispatch(
                  themeSwitcher(
                    theme.palette.mode === "dark" ? "light" : "dark"
                  )
                )
              }
            />
            <Box className="switch__container">
              <Box
                className="switch__anchor"
                sx={{
                  left: mode === "dark" ? "0.125rem" : "unset",
                  right: mode === "dark" ? "unset" : "0.125rem",
                  color: (theme: any) =>
                    mode === "dark"
                      ? theme.palette.text.primary
                      : theme.palette.common.green,
                }}
              >
                {theme.palette.mode === "dark" ? (
                  <FaMoon size={15} style={{ color: "inherit" }} />
                ) : (
                  <FaSun size={15} style={{ color: "inherit" }} />
                )}
              </Box>
            </Box>
          </label>
        </Box>

        <Box className="navigation">
          {!downLg && (
            <nav>
              <List>
                <ListItem>
                  <Link to="/">
                    <HiHome
                      size={19}
                      style={{
                        color: setColorBasedOnLocation("/"),
                      }}
                    />
                    <Typography
                      variant="body2"
                      ml={0.5}
                      sx={{
                        color: setColorBasedOnLocation("/"),
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Home
                    </Typography>
                  </Link>
                </ListItem>

                <ListItem>
                  <Link to="/security">
                    <GrShieldSecurity
                      size={19}
                      style={{
                        color: setColorBasedOnLocation("/security"),
                      }}
                    />
                    <Typography
                      variant="body2"
                      ml={0.5}
                      sx={{
                        color: setColorBasedOnLocation("/security"),
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Security
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/subscription">
                    <FaRegCreditCard
                      size={17}
                      style={{
                        color: setColorBasedOnLocation("/subscription"),
                      }}
                    />
                    <Typography
                      variant="body2"
                      ml={0.5}
                      sx={{
                        color: setColorBasedOnLocation("/subscription"),
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      Pricing
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/influencers">
                    <HiOutlineUserGroup
                      size={19}
                      color={switchColor}
                      style={{
                        color: setColorBasedOnLocation("/influencers"),
                      }}
                    />
                    <Typography
                      sx={{
                        color: setColorBasedOnLocation("/influencers"),
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                      variant="body2"
                      ml={0.5}
                      color={switchColor}
                    >
                      Influencers
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link to="/BackTest">
                    <BiLoaderCircle
                      size={19}
                      style={{
                        color: setColorBasedOnLocation("/BackTest"),
                      }}
                    />
                    <Typography
                      variant="body2"
                      ml={0.5}
                      sx={{
                        color: setColorBasedOnLocation("/BackTest"),
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                      }}
                    >
                      BackTest
                    </Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Button
                    id="products-button"
                    aria-haspopup="true"
                    aria-expanded={productsMenuState ? "true" : undefined}
                    onClick={handleClickProducts}
                    aria-controls={
                      productsMenuState ? "products-menu" : undefined
                    }
                    sx={{
                      whiteSpace: "nowrap",
                      color:
                        location.pathname.includes("/agents") ||
                        location.pathname == "/" ||
                        location.pathname == "/asset-tracking"
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      "&:hover": {
                        background: "transparent",
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    {location.pathname.includes("/agents") ? (
                      <HiOutlineChatAlt2
                        size={19}
                        color={setColorBasedOnLocation("/agents")}
                        style={{ marginRight: ".2rem" }}
                      />
                    ) : location.pathname == "/" ? (
                      <MdOutlineAnalytics
                        size={19}
                        color={setColorBasedOnLocation("/")}
                        style={{ marginRight: ".2rem" }}
                      />
                    ) : location.pathname == "/asset-tracking" ? (
                      <MdOutlineDonutLarge
                        size={19}
                        color={setColorBasedOnLocation("/asset-tracking")}
                        style={{ marginRight: ".2rem" }}
                      />
                    ) : (
                      <TbBrandProducthunt
                        size={19}
                        color={switchColor}
                        style={{ marginRight: ".2rem" }}
                      />
                    )}

                    {location.pathname.includes("/agents")
                      ? "Agents"
                      : location.pathname == "/"
                        ? "Analytics"
                        : location.pathname == "/assets-tracking"
                          ? "Asset Tracking"
                          : "Products"}
                    <MdOutlineKeyboardArrowDown
                      size={18}
                      style={{ marginLeft: "3px" }}
                    />
                  </Button>
                  <Menu
                    id="products-menu"
                    anchorEl={productsMenu}
                    open={productsMenuState}
                    onClose={handleCloseProducts}
                    MenuListProps={{
                      "aria-labelledby": "products-button",
                    }}
                    sx={{
                      ".MuiList-root": {
                        background: (theme: any) => theme.palette.primary.main,
                      },
                    }}
                  >
                    <MenuItem onClick={handleCloseProducts}>
                      <Link to="/">
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: ".85rem",
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <MdOutlineAnalytics
                            size={19}
                            color={theme.palette.common.black}
                            style={{ marginRight: ".4rem" }}
                          />
                          Analytics
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProducts}
                      sx={{ margin: ".6rem 0" }}
                    >
                      <Link to="/agents">
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: ".85rem",
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <HiOutlineChatAlt2
                            size={19}
                            color={theme.palette.common.black}
                            style={{ marginRight: ".4rem" }}
                          />
                          Agents
                        </Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseProducts}>
                      <Link to="/asset-tracking">
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: ".85rem",
                            display: "flex",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          <MdOutlineDonutLarge
                            size={19}
                            color={theme.palette.common.black}
                            style={{ marginRight: ".4rem" }}
                          />
                          Assets&nbsp;Tracking
                        </Typography>
                      </Link>
                    </MenuItem>
                  </Menu>
                </ListItem>
              </List>
            </nav>
          )}

          <Box className="register">
            {account.isAuthenticated ? (
              <IconButton
                aria-label="Menu"
                size="small"
                onClick={() => navigate("/account")}
              >
                {userData?.profile_picture ||
                userData?.profile_picture_google ? (
                  <UserAvatar
                    src={handleProfilePic(userData)}
                    size="40"
                    round={true}
                    style={{
                      objectFit: "cover",
                      width: "40px",
                      height: "40px",
                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.primary.main
                          : theme.palette.grey["700"],
                    }}
                  />
                ) : (
                  <FaUserCircle
                    size="40"
                    style={{
                      width: "100%",
                      height: "100%",

                      color:
                        theme.palette.mode === "light"
                          ? theme.palette.primary.main
                          : theme.palette.grey["700"],
                    }}
                  />
                )}
              </IconButton>
            ) : (
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  navigate(
                    `/login${
                      location.pathname === "/"
                        ? ""
                        : `?redirect=${location.pathname.slice(1)}`
                    }`
                  )
                }
                sx={{ "&:hover": { background: theme.palette.secondary.main } }}
              >
                Login
              </Button>
            )}
          </Box>
        </Box>
      </header>
    </Box>
  );
};

export default Header;
