import { useEffect, useState } from "react";
import "./styles.css";
import { NodeImageProgram } from "@sigma/node-image";
import "@react-sigma/core/lib/react-sigma.min.css";
import CustomGraph from "./CustomGraph";
import drawLabel from "./Canvas-Utils.js";
import SearchField from "./SearchField";
import { useSelector } from "react-redux";
import EdgeCurveProgram from "@sigma/edge-curve";
import {
  SigmaContainer,
  ZoomControl,
  FullScreenControl,
  ControlsContainer,
} from "@react-sigma/core";

const initialSettings = {
  allowInvalidContainer: true,
  nodeProgramClasses: { image: NodeImageProgram },
  edgeProgramClasses: { curved: EdgeCurveProgram },
  renderEdgeLabels: true,
  defaultNodeType: "image",
  defaultEdgeType: "curve",
  labelDensity: 0.07,
  labelGridCellSize: 60,
  labelRenderedSizeThreshold: 50,
  labelRenderer: drawLabel,
  labelFont: "Poppins",
  zIndex: true,
};

const Graph = ({ data }) => {
  const { final_nodes, final_edges } = data;

  const cryptosState = useSelector((state) => state.cryptos);
  const coinList = cryptosState.availableCoins;

  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  return (
    <SigmaContainer
      className="sigmaContainer"
      graphOptions={{ type: "undirected" }}
      settings={initialSettings}
    >
      <CustomGraph
        dataset={[final_nodes, final_edges]}
        setLoading={setLoading}
        coinList={coinList}
      >
        <ControlsContainer position="bottom-left">
          <FullScreenControl />
          <ZoomControl />
          {/* <LayoutForceAtlas2Control /> */}
        </ControlsContainer>
        <SearchField selected={selected} setSelected={setSelected} />
      </CustomGraph>
    </SigmaContainer>
  );
};

export default Graph;
