import http from "../services/http";

export const pinCryptoHandler = async (coin: number) => {
  const url = "/crypto/pinned-coin-create/";
  const requestBody = { coin };

  return await http.post(url, requestBody);
};

export const unpinCryptoHandler = async (coin: number) => {
  const url = "/crypto/pinned-coin-destroy/";
  const config = {
    data: { coin },
  };

  return await http.delete(url, config);
};
