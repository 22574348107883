import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material";
import styles from "./charts.module.scss";

interface NetWorthAreaChartProps {
  chartData: any;
}

const NetWorthAreaChart: React.FC<NetWorthAreaChartProps> = ({ chartData }) => {
  const theme = useTheme();

  const [data, setData] = useState<any>({
    series: [],
    options: {},
  });

  useEffect(() => {
    if (chartData.length > 0)
      setData({
        series: [
          {
            name: "Assets Balance",
            data: chartData.map(
              (chartItem: any) => chartItem.total_wallets_balance,
            ),
          },
        ],
        options: {
          chart: {
            height: 250,
            width: "100%",
            parentHeightOffset: 0,
            fontFamily: "Poppins",
            type: "area",
            offsetY: 30,
            toolbar: { show: false },
            zoom: { enabled: false },
            animations: {
              easing: "easeout",
            },
          },
          tooltip: {
            //@ts-ignore
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return `<div class='${styles.linechart_tooltip} ${
                styles.networth_linechart_tooltip
              }' style="background: ${
                //@ts-ignore
                theme.palette.common.solidCard
              }">
              <h6 style="color: ${theme.palette.common.white} ">${
                w.globals.categoryLabels[dataPointIndex]
              }</h6>
              <div>
                <h4 style="color: ${theme.palette.common.white} ">${
                  w.globals.seriesNames[0]
                }:</h4>
                <h5 style="color: ${theme.palette.common.white}">$${series[
                  seriesIndex
                ][dataPointIndex].toFixed(2)}</h5>
              </div>
              </div>`;
            },
          },
          labels: chartData.map((chartItem: any) => chartItem.date),
          colors: [theme.palette.primary.main],
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          yaxis: {
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false },
          },
          xaxis: {
            labels: { show: false },
            axisBorder: { show: false },
            axisTicks: { show: false },
          },
          legend: { show: false },
          fill: {
            type: "gradient",
            gradient: {
              shade: "light",
              type: "vertical",
              shadeIntensity: 0,
              opacityFrom: 0.7,
              opacityTo: 0.2,
              stops: [0],
            },
          },
        },
      });
  }, [chartData]);

  return (
    <div id="chart">
      <ReactApexChart
        type="area"
        //@ts-ignore
        options={data.options}
        series={data.series}
        height={250}
      />
    </div>
  );
};

export default NetWorthAreaChart;
