import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { SignalState } from "./interfaces";

export const fetchInfluencerSignal = createAsyncThunk<
  { data: null },
  { coin: number; page: number; category: string },
  { rejectValue: any }
>("influencer signal", async (requestProps, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-signal/?page=${requestProps.page}${
        requestProps.category ? `&category=${requestProps.category}` : ""
      }`,
      {
        coin_id: requestProps.coin,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCryptoPageInfluencerSignal = createAsyncThunk<
  { data: null },
  { coin: number; page: number; category: string },
  { rejectValue: any }
>("crypto page influencer signal", async (requestProps, thunkAPI) => {
  try {
    const response = await http.post(
      `/crypto/get-influencer-signal/?page=${requestProps.page}${
        requestProps.category ? `&category=${requestProps.category}` : ""
      }`,
      {
        coin_id: requestProps.coin,
      }
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinSignalCount = createAsyncThunk<
  { data: null },
  string | undefined,
  { rejectValue: any }
>("sps histoory", async (coinId, thunkAPI) => {
  const endTime = new Date();

  // Calculate the date 30 days ago
  const daysToSubtract = 30;
  const pastDate = new Date(
    endTime.getTime() - daysToSubtract * 24 * 60 * 60 * 1000
  );

  // Extract the year, month, and day for both dates
  const yearNow = endTime.getFullYear();
  let monthNow = (endTime.getMonth() + 1).toString().padStart(2, "0");
  let dayNow = endTime.getDate().toString().padStart(2, "0");

  // Format current date
  const formattedCurrentDate = `${yearNow}-${monthNow}-${dayNow}`;

  // Extract year, month, and day for the past date
  const yearPast = pastDate.getFullYear();
  let monthPast = (pastDate.getMonth() + 1).toString().padStart(2, "0");
  let dayPast = pastDate.getDate().toString().padStart(2, "0");

  // Format 30 days ago date
  const startTime = `${yearPast}-${monthPast}-${dayPast}`;

  try {
    const url = `/crypto/get-sps-price-coin/${startTime}/${formattedCurrentDate}/${coinId}/`;
    const response = await http.get(url);
    //@ts-ignore
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
export const fetchCoinSignalCountFullChart = createAsyncThunk(
  "sps/fetchCoinSignalCountFullChart",
  async (
    {
      coinId,
      startDate,
      endDate,
    }: { coinId: any; startDate: string; endDate: string },
    thunkAPI
  ) => {
    try {
      // Construct the API endpoint
      const url = `/crypto/get-sps-price-coin/${startDate}/${endDate}/${coinId}/`;

      // Make the API call
      const response = await http.get(url);

      // Safely check if the response contains the required data
      if (response?.data?.response?.data) {
        return response.data.response.data;
      } else {
        // Handle case where data is missing or invalid
        throw new Error("Invalid response format from server");
      }
    } catch (error: any) {
      // Log error for debugging (optional)
      console.error("Error fetching coin signal count:", error);

      // Reject the thunk with the error message
      return thunkAPI.rejectWithValue(
        error.response?.data?.message ||
          error.message ||
          "An unknown error occurred"
      );
    }
  }
);

const initialState: SignalState = {
  newsPageData: null,
  influencerSignal: null,
  cyptoPageInfluencerSignal: null,
  cryptoSignalCount: null,
  influencerSignalLoading: false,
  cyptoPageInfluencerSignalLoading: false,
  cryptoSignalCountLoading: true,
  cryptoSignalCountFullChart: null,
  cryptoSignalCountFullChartLoading: true,
  cryptoSignalCountFullChartError: null,
  error: null,
};

export const signalSlice = createSlice({
  name: "signal",
  initialState,
  reducers: {
    setNewsPageData: (state, action) => {
      state.newsPageData = {
        count: action.payload.count,
        next: action.payload.next,
        results: action.payload.results,
        tab: action.payload.tab,
        coin: action.payload.coin,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInfluencerSignal.pending, (state) => {
        state.influencerSignalLoading = true;
        state.influencerSignal = null;
        state.error = null;
      })
      .addCase(fetchInfluencerSignal.fulfilled, (state, action) => {
        state.influencerSignalLoading = false;
        state.influencerSignal = action.payload;
      })
      .addCase(fetchInfluencerSignal.rejected, (state, action) => {
        state.influencerSignalLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchCryptoPageInfluencerSignal.pending, (state) => {
        state.cyptoPageInfluencerSignalLoading = true;
        state.error = null;
      })
      .addCase(fetchCryptoPageInfluencerSignal.fulfilled, (state, action) => {
        state.cyptoPageInfluencerSignalLoading = false;
        state.cyptoPageInfluencerSignal = action.payload;
      })
      .addCase(fetchCryptoPageInfluencerSignal.rejected, (state, action) => {
        state.cyptoPageInfluencerSignalLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchCoinSignalCount.pending, (state) => {
        state.cryptoSignalCountLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinSignalCount.fulfilled, (state, action) => {
        state.cryptoSignalCountLoading = false;
        state.cryptoSignalCount = action.payload;
      })
      .addCase(fetchCoinSignalCount.rejected, (state, action) => {
        state.cryptoSignalCountLoading = true;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinSignalCountFullChart.pending, (state) => {
        state.cryptoSignalCountFullChartLoading = true;
        state.cryptoSignalCountFullChartError = null;
      })
      .addCase(fetchCoinSignalCountFullChart.fulfilled, (state, action) => {
        state.cryptoSignalCountFullChartLoading = false;
        state.cryptoSignalCountFullChart = action.payload;
      })
      .addCase(fetchCoinSignalCountFullChart.rejected, (state, action) => {
        state.cryptoSignalCountFullChartLoading = true;
        state.cryptoSignalCountFullChartError =
          action.error.message || "Something went wrong";
      });
  },
});

export default signalSlice.reducer;

export const { setNewsPageData } = signalSlice.actions;
