import { Box, Typography, useTheme } from "@mui/material";
import { formatPrice, getCoinImage } from "../../../../utils/functions";
import Loading from "../../../common/loading";

const CoinInfo = ({
  cryptoId,
  cryptoData,
}: {
  cryptoId: any;
  cryptoData: any;
}) => {
  const theme = useTheme();
  const price = Number(cryptoData?.latest_price);

  return cryptoData?.title ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={getCoinImage(cryptoId)} width={50} height={50} />
        <Box sx={{}} ml={2}>
          <Typography
            variant="h6"
            color={theme.palette.text.primary}
            sx={{ lineHeight: 1.3 }}
          >
            {cryptoData?.title}
          </Typography>
          <Typography
            variant="body2"
            color={(theme: any) => theme.palette.common.mute}
          >
            {cryptoData?.label}
          </Typography>
        </Box>
      </Box>

      <Typography variant="h5" color={theme.palette.text.primary}>
        {price
          ? `$${
              //@ts-ignore
              formatPrice(price) > 1
                ? Number(formatPrice(price)).toLocaleString()
                : formatPrice(price)
            }`
          : ""}
      </Typography>
    </Box>
  ) : (
    <Box>
      <Loading />
    </Box>
  );
};

export default CoinInfo;
