import { Box, Typography, useTheme } from "@mui/material";
import { ImFileEmpty } from "react-icons/im";
import React from "react";
import { EmptyProps } from "./interface";

const Empty: React.FC<EmptyProps> = ({ text }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      py={2}
    >
      <ImFileEmpty size={20} color={theme.palette.text.primary} />
      <Typography variant="caption" py={1} color={theme.palette.text.primary}>
        {text}
      </Typography>
    </Box>
  );
};

export default Empty;
