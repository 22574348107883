import { Box, Skeleton, Stack, useMediaQuery, useTheme } from "@mui/material";

const SkeletonLoading = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack spacing={1} p={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Skeleton variant="circular" width={42} height={42} />
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: downLg ? 100 : 180 }}
          />
        </Box>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1rem", width: downLg ? 70 : 120 }}
        />
      </Box>

      <Skeleton variant="rounded" height={260} sx={{ width: "100%" }} />
    </Stack>
  );
};

export default SkeletonLoading;
