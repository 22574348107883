import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { MoversProps } from "../interfaces";
import styles from "../analytics.module.scss";

const MoversMarquee: React.FC<MoversProps> = ({ moversData, type }) => {
  const theme = useTheme();

  return (
    <Box className={styles.moversMarquee}>
      {moversData.data &&
      //@ts-ignore
      moversData.data[type].length > 1 ? (
        <Swiper
          modules={[Autoplay]}
          spaceBetween={10}
          slidesPerView={3}
          loop={true}
          speed={6000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          allowTouchMove={true}
          grabCursor={true}
          freeMode={true}
          breakpoints={{
            100: {
              slidesPerView: 2.4,
            },
            768: {
              slidesPerView: 3,
            },
          }}
        >
          {//@ts-ignore
          moversData.data[type]?.map((moverItem) => (
            <SwiperSlide key={moverItem.name}>
              <Box className={styles.coinContainer} key={moverItem.name}>
                <img src={moverItem.img} alt={moverItem.name} />
                <Box className={styles.coinInfo}>
                  <Typography
                    component="h2"
                    color={theme.palette.text.primary}
                    sx={{
                      whiteSpace: "nowrap",
                      maxWidth: "110px",
                      overflow: "hidden",
                    }}
                  >
                    {moverItem.name ?? ""}
                  </Typography>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="h6"
                      component="h4"
                      color={theme.palette.text.primary}
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {moverItem.price}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: "nowrap",
                        color:
                          type === "top_gainers"
                            ? (theme: any) => theme.palette.common.greenText
                            : "#FA637A",
                      }}
                    >
                      {type === "top_gainers" ? "+" : "-"}{" "}
                      {moverItem.percentage}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <Typography
          align="center"
          variant="body2"
          my={2}
          color={theme.palette.text.primary}
        >
          No data
        </Typography>
      )}
    </Box>
  );
};

export default MoversMarquee;
