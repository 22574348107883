import GaugeComponent from "react-gauge-component";

const AgentBotChart = (value: any) => {
  const minValue = value.value[0];
  const maxValue = value.value[1];

  return (
    <>
      {minValue !== null && maxValue !== null ? (
        <GaugeComponent
          type="semicircle"
          arc={{
            width: 0.2,
            // padding: 0.005,
            // cornerRadius: 1,
            // gradient: true,
            subArcs: [
              {
                limit: minValue,
                color: "gray",
                showTick: true,
              },

              {
                limit: maxValue,
                color: "#00AEEF",
                showTick: true,
              },
              {
                color: "gray",
              },
            ],
          }}
          pointer={{
            color: "transparent",
            length: 0.8,
            width: 15,
            elastic: true,
          }}
          labels={{
            valueLabel: {
              formatTextValue: () => `${minValue}% - ${maxValue}%`,
            },
            tickLabels: {
              type: "outer",
              defaultTickValueConfig: {
                style: { fontSize: 10 },
              },
            },
          }}
        />
      ) : (
        <GaugeComponent
          type="semicircle"
          arc={{
            width: 0.2,
            // padding: 0.005,
            // cornerRadius: 1,
            // gradient: true,
            subArcs: [
              {
                color: "gray",
              },
            ],
          }}
          pointer={{
            color: "transparent",
            length: 0.8,
            width: 15,
            elastic: true,
          }}
          labels={{
            valueLabel: {
              formatTextValue: () => `${minValue}% - ${maxValue}%`,
            },
            tickLabels: {
              type: "outer",
              defaultTickValueConfig: {
                style: { fontSize: 10 },
              },
            },
          }}
        />
      )}
    </>
  );
};

export default AgentBotChart;
