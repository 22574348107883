import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Container, useTheme } from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineRssFeed } from "react-icons/md";
import DashboardLayout from "../../layout/dashboardLayout";
import CardTitle from "../../components/common/title/CardTitle";
import RssNews from "../../components/news/newsType/RssNews";
import TweetsNews from "../../components/news/newsType/TweetsNews";
import NewsHeader from "../../components/news/newsHeader";
import Loading from "../../components/common/loading";
import { selectedCoinsRegex } from "../../utils/functions";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchRssNewsList,
  fetchTweetsList,
  setIsFilterLoading,
  setNewsPageFilter,
  setNewsPageRssResult,
  setNewsPageTweetsResult,
} from "../../features/news/newsSlice";
import { newsFilterType } from "./interfaces";
import useAuth from "../../utils/useAuth";

const News = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();
  const isAuthValid = useAuth();
  const newsList: any = useSelector((state: RootState) => state.news);
  const coinsList = useSelector((state: RootState) => state.cryptos);

  const [newsType, setNewsType] = useState("Twitter");
  const [tweetsPage, setTweetsPage] = useState(1);
  const [rssPage, setRssPage] = useState(1);

  const fetchNewsPageTweets = (
    page: number,
    ordering: string,
    selectedCoins: [],
  ) => {
    const requestData = {
      page,
      ordering,
      selectedCoins: selectedCoinsRegex(selectedCoins),
    };
    dispatch(fetchTweetsList(requestData)).then((response: any) => {
      dispatch(
        setNewsPageTweetsResult({
          count: response.payload.count,
          next: response.payload.next,
          results: response.payload.results,
        }),
      );
      dispatch(setIsFilterLoading(false));
    });
  };

  const fetchNewsPageRss = (page: number, search: string) => {
    const requestData = {
      page,
      search,
    };
    dispatch(fetchRssNewsList(requestData)).then((response: any) => {
      dispatch(
        setNewsPageRssResult({
          count: response.payload.count,
          next: response.payload.next,
          results: response.payload.results,
        }),
      );
      dispatch(setIsFilterLoading(false));
    });
  };

  useEffect(() => {
    if (isAuthValid) {
      if (coinsList.availableCoins) {
        if (newsType === "Rss") {
          if (!newsList.rssDisplayResult) fetchNewsPageRss(rssPage, "");
        } else {
          if (!newsList.tweets && !newsList.tweetsLoading) {
            fetchNewsPageTweets(tweetsPage, "-total", []);
          }
        }
      }
    }
  }, [coinsList.availableCoins, newsType, isAuthValid]);

  const filterChangeHandler = (type: string, filter: newsFilterType) => {
    dispatch(setIsFilterLoading(true));
    dispatch(setNewsPageFilter({ type, filter }));
    if (type === "Twitter") {
      setTweetsPage(1);
      fetchNewsPageTweets(1, filter.ordering, filter.selectedCoins);
    } else {
      setRssPage(1);
      fetchNewsPageRss(1, filter.search);
    }
  };

  return (
    <DashboardLayout title="Coinfident | News">
      <Container>
        <Box mt={2} sx={{ minHeight: "130vh" }}>
          <CardTitle hasInfo>
            {newsType === "Twitter" ? (
              <FaXTwitter
                size={20}
                color={theme.palette.text.primary}
                style={{ marginRight: ".3rem" }}
              />
            ) : (
              <MdOutlineRssFeed
                size={20}
                color={theme.palette.text.primary}
                style={{ marginRight: ".3rem" }}
              />
            )}
            {newsType === "Twitter" ? "Twitter News" : "News websites"}
          </CardTitle>
          {newsList.isFilterLoading ? (
            <Loading />
          ) : (
            <>
              <NewsHeader
                newsType={newsType}
                setNewsType={setNewsType}
                filterChangeHandler={filterChangeHandler}
                newsList={newsList}
              />

              {newsType === "Rss" ? (
                <RssNews
                  setRssPage={setRssPage}
                  //@ts-ignore
                  newsList={newsList}
                />
              ) : (
                <TweetsNews
                  setTweetsPage={setTweetsPage}
                  //@ts-ignore
                  newsList={newsList}
                />
              )}
            </>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default News;
