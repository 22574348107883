import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  styled,
} from "@mui/material";
import { PiCurrencyBtcBold, PiSpeedometer } from "react-icons/pi";
import { RiPushpin2Fill, RiPushpin2Line } from "react-icons/ri";
import { LuInfo } from "react-icons/lu";
import {
  MdOutlineSentimentDissatisfied,
  MdOutlineSentimentSatisfiedAlt,
} from "react-icons/md";
import { FaXTwitter, FaArrowTrendUp, FaArrowTrendDown } from "react-icons/fa6";
import { InfluencersSortTablesProps } from "../interfaces";
import { getUserProfile } from "../../../utils/userPhoto";
import { pinInfluencer, removeInfluencerPin } from "../../../api/influencers";
import { AppDispatch, RootState } from "../../../features/store";
import { fetchPinnedInfluencers } from "../../../features/influencers/influencersSlice";
import AnalyticsTitle from "../../common/title/AnalyticsTitle";
import LoginAlertModal from "../../common/loginAlertModal";
import styles from "../influencers.module.scss";
import Loading from "../../common/loading";
import InfluencersTimeFrame from "../influencersTimeFrame";
import InfluencersSearch from "../influencersSearch";

const TableTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    enterTouchDelay={0}
    {...props}
    classes={{ popper: className }}
    placement="top"
    arrow
    onClick={(e) => e.stopPropagation()}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color:
      //@ts-ignore
      theme.palette.common.backdrop,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const InfluencersSortTables: React.FC<InfluencersSortTablesProps> = ({
  dependedCoinData,
  independedCoindata,
  pinnedList,
  setSelectedCoin,
  timePeriod,
  setTimePeriod,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const accountState = useSelector((state: RootState) => state.account);
  const influencersSlice = useSelector((state: RootState) => state.influencers);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [openLoginAlertModal, setLoginAlertModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const tableHeadData = [
    { title: "Influencer" },
    { title: "Count" },
    { title: "Follower" },
  ];

  const customTableHeader = (title: string) => {
    switch (title) {
      case "Tweet Volume":
        return "Crypto Related\nTweets Count";
      case "Tweets Volume with Buy SPS":
        return "Buy Signal\nTweets Count";
      case "Tweets Volume with Not-Buy SPS":
        return "Not Buy Signal\nTweets Count";
      case "Coin Overall Accuracy Rank":
        return "Overall Accuracy";
      case "Tweets Volume with Positive Emotional Sentiment":
        return "Positive Sentiment\nTweets Count";
      case "Tweets Volume with Negative Emotional Sentiment":
        return "Negative Sentiment\nTweets Count";
      case "Coin Tweet Volume":
        return "Related\nTweets Count";
      case "Coin Tweet Volume with Buy SPS":
        return "Buy Signal\nTweets Count";
      case "Coin Tweet Volume with Not-Buy SPS":
        return "Not Buy Signal\nTweets Count";
      default:
        return "Count";
    }
  };

  const timePeriodText = useMemo(() => {
    switch (timePeriod) {
      case 1:
        return "past day";
      case 3:
        return "past 3 days";
      case 7:
        return "past week";
      case 30:
        return "past month";
      default:
        return "past week";
    }
  }, [timePeriod]);

  const dataSchema = useMemo(() => {
    const dataTitles = [
      "Tweet Volume",
      "Tweets Volume with Buy SPS",
      "Tweets Volume with Not-Buy SPS",
      "Tweets Volume with Positive Emotional Sentiment",
      "Tweets Volume with Negative Emotional Sentiment",
    ];
    const dataTooltip = [
      `Displays the ranking of crypto influencers based on the number of tweets they have published in the ${timePeriodText}, from highest to lowest.`,
      `Ranks crypto influencers by the number of buy signal tweets they have published in the ${timePeriodText}, from highest to lowest. This helps identify bullish crypto influencers. `,
      `Ranks crypto influencers by the number of not-buy signal tweets they have published in the ${timePeriodText}, from highest to lowest. This helps identify bearish crypto influencers. `,
      `Ranks crypto influencers based on the number of tweets with positive emotional sentiment they published in the ${timePeriodText}, from highest to lowest.`,
      `Ranks crypto influencers based on the number of tweets with negative emotional sentiment they published in the ${timePeriodText}, from highest to lowest.`,
    ];
    const dataIcons = [
      // <FaArrowTrendUp size={24} color={theme.palette.common.white} />,
      // <FaArrowTrendDown size={24} color={theme.palette.common.white}/>,
      <FaXTwitter size={20} color={theme.palette.common.white} />,
      <PiSpeedometer size={24} color={theme.palette.common.white} />,
      <PiSpeedometer
        size={24}
        color={theme.palette.common.white}
        style={{ transform: "rotateY(180deg)" }}
      />,
      <MdOutlineSentimentSatisfiedAlt
        size={24}
        color={theme.palette.common.white}
      />,
      <MdOutlineSentimentDissatisfied
        size={24}
        color={theme.palette.common.white}
      />,
    ];
    const dataValues = Object.values(independedCoindata);
    const schema = dataValues.map((item: any) => ({
      id: item.id,
      title: dataTitles[item.id],
      tooltip: dataTooltip[item.id],
      icon: dataIcons[item.id],
      data: item.data,
    }));

    return schema.sort((a, b) => a.id - b.id);
  }, [independedCoindata]);

  const dependedCoinDataSchema = useMemo(() => {
    const dataTitles = [
      "Coin Tweet Volume",
      "Coin Tweet Volume with Buy SPS",
      "Coin Tweet Volume with Not-Buy SPS",
      "Coin Overall Accuracy Rank",
    ];

    const dataTooltip = [
      `Ranks crypto influencers by the number of related tweets published in the ${timePeriodText}, from highest to lowest.`,
      `Ranks crypto influencers by the number of tweets related to coins with a 'Buy SPS' signal published in the ${timePeriodText}, from highest to lowest.`,
      `Ranks crypto influencers by the number of tweets related to coins with a 'Not-Buy SPS' signal published in the ${timePeriodText}, from highest to lowest.`,
      "Indicates how reliable the influencer's signals are in predicting market movements based on their tweets and subsequent price changes.",
    ];

    const dataValues = Object.values(dependedCoinData);
    const schema = dataValues.map((item: any) => ({
      id: item.id,
      title: dataTitles[item.id],
      tooltip: dataTooltip[item.id],
      data: item.data,
    }));

    return schema.sort((a, b) => a.id - b.id);
  }, [dependedCoinData]);

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const pinnedListId = pinnedList?.map((influencer) => influencer.id_str);

  const hanldePinInfluencer = (
    e: React.MouseEvent<HTMLButtonElement>,
    id: string
  ) => {
    e.stopPropagation();
    const isPinned = pinnedListId?.includes(id);
    if (accountState.isAuthenticated) {
      if (isPinned) {
        setLoading(true);
        removeInfluencerPin(id)
          .then((response) => {
            if (response.status === 204) {
              dispatch(fetchPinnedInfluencers());
              setLoading(false);
              toast.success("Influencer has been successfully unpinned");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Something went wrong");
          });
      } else {
        setLoading(true);
        pinInfluencer(id)
          .then((response) => {
            if (response.status === 201) {
              dispatch(fetchPinnedInfluencers());
              setLoading(false);
              toast.success("Influencer pinned successfully");
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Something went wrong");
          });
      }
    } else {
      setLoginAlertModal(true);
    }
  };

  const coinsSelectorHandler = (selectedCoinItem: number) => {
    setSelectedCoin(selectedCoinItem);
  };

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={12}>
          <Box className={styles.influencersToolbar}>
            <InfluencersSearch />

            <InfluencersTimeFrame
              timePeriod={timePeriod}
              setTimePeriod={setTimePeriod}
            />
          </Box>
        </Grid>

        {dependedCoinDataSchema?.map((sortedData) => (
          <Grid key={sortedData.id} item xs={12} md={4}>
            <Box
              className={`analytics-card ${styles.sort_card_wrapper}`}
              sx={{
                background: theme.palette.primary.light,
                "&:after": {
                  background: (theme: any) => theme.palette.common.darkPrimary,
                },
              }}
            >
              <AnalyticsTitle
                title={
                  <Typography
                    variant="h2"
                    fontSize="0.75rem"
                    fontWeight={100}
                    lineHeight="18px"
                  >
                    Top Influencers by <br />
                    <strong
                      style={{
                        fontSize: downLg ? "0.85rem" : "0.95rem",
                        fontWeight: 500,
                      }}
                    >
                      {sortedData.title}
                    </strong>
                    <TableTooltip title={sortedData.tooltip}>
                      <IconButton size="small">
                        <LuInfo
                          size={13}
                          color={
                            theme.palette.mode === "light"
                              ? theme.palette.primary.main
                              : theme.palette.grey[500]
                          }
                        />
                      </IconButton>
                    </TableTooltip>
                  </Typography>
                }
                hasCryptoList
                coinsSelectorHandler={coinsSelectorHandler}
                isLarge
                minHeight={75}
              />
              {influencersSlice.sortedInfluencersSelectedCoinLoading.some(
                (i) => i === true
              ) ? (
                <Box sx={{ height: 520 }}>
                  <Loading />
                </Box>
              ) : (
                <TableContainer
                  className={`customScrollbar ${scrollbarClassName}`}
                  sx={{ maxHeight: 546 }}
                >
                  <Table stickyHeader>
                    <TableHead sx={{ zIndex: 3 }}>
                      <TableRow>
                        {tableHeadData.map((cell, idx) => (
                          <TableCell
                            component="th"
                            key={cell.title}
                            align={idx ? "center" : "left"}
                          >
                            <Typography
                              variant="body2"
                              color={theme.palette.text.primary}
                              sx={{ whiteSpace: "pre" }}
                            >
                              {idx === 1
                                ? customTableHeader(sortedData.title)
                                : cell.title}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData?.data?.map((influencer: any, idx: number) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            padding="none"
                            sx={{ cursor: "pointer", padding: ".6rem 0" }}
                            onClick={() =>
                              navigate(
                                `/influencers/${influencer?.screen_name}`,
                                { state: { id: influencer.id_str } }
                              )
                            }
                          >
                            <Box display="flex" alignItems="center">
                              <IconButton
                                sx={{ mr: 0.5 }}
                                disabled={loading}
                                onClick={(e) =>
                                  hanldePinInfluencer(e, influencer?.id_str)
                                }
                              >
                                {pinnedListId?.includes(influencer?.id_str) ? (
                                  <RiPushpin2Fill size={18} />
                                ) : (
                                  <RiPushpin2Line size={18} />
                                )}
                              </IconButton>
                              <Avatar
                                src={getUserProfile(influencer?.screen_name)}
                                sx={{ width: 38, height: 38, mr: 1 }}
                              />
                              <Stack py={0.7}>
                                <Typography
                                  variant="body1"
                                  color={theme.palette.text.primary}
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: ".82rem",
                                    lineHeight: 1.4,
                                  }}
                                >
                                  {influencer.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color={theme.palette.text.primary}
                                  sx={{
                                    fontSize: ".75rem",
                                    fontWeight: 100,
                                  }}
                                >
                                  @{influencer.screen_name}
                                </Typography>
                              </Stack>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              color={theme.palette.text.primary}
                              variant="body2"
                              sx={{
                                fontWeight: 100,
                              }}
                            >
                              {/* {selectRank(sortedData.id, influencer)} */}
                              {influencer?.tweet_count}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              color={theme.palette.text.primary}
                              variant="body2"
                              sx={{
                                fontWeight: 100,
                              }}
                            >
                              {Number(
                                influencer.followers_count
                              ).toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Grid>
        ))}

        {dataSchema?.map((sortedData) => (
          <Grid key={sortedData.id} item xs={12} md={4}>
            <Box
              className={`analytics-card ${styles.sort_card_wrapper}`}
              sx={{
                background: theme.palette.primary.light,
                "&:after": {
                  background: (theme: any) => theme.palette.common.darkPrimary,
                },
              }}
            >
              <AnalyticsTitle
                title={
                  <Typography
                    variant="h2"
                    fontSize="0.82rem"
                    fontWeight={100}
                    lineHeight="18px"
                  >
                    Top Influencers by <br />
                    <strong
                      style={{
                        fontSize: downLg ? "0.85rem" : "0.95rem",
                        fontWeight: 500,
                      }}
                    >
                      {sortedData.title}
                    </strong>
                    <TableTooltip title={sortedData.tooltip}>
                      <IconButton size="small">
                        <LuInfo
                          size={13}
                          color={
                            theme.palette.mode === "light"
                              ? theme.palette.primary.main
                              : theme.palette.grey[500]
                          }
                        />
                      </IconButton>
                    </TableTooltip>
                  </Typography>
                }
                icon={sortedData.icon}
                isLarge
                minHeight={75}
              />
              {influencersSlice.sortedInfluencersLoading[sortedData.id] ? (
                <Box sx={{ height: 520 }}>
                  <Loading />
                </Box>
              ) : (
                <TableContainer
                  className={`customScrollbar ${scrollbarClassName}`}
                  sx={{ maxHeight: 546 }}
                >
                  <Table stickyHeader>
                    <TableHead sx={{ zIndex: 3 }}>
                      <TableRow>
                        {tableHeadData.map((cell, idx) => (
                          <TableCell
                            component="th"
                            key={cell.title}
                            align={idx ? "center" : "left"}
                          >
                            <Typography
                              variant="body2"
                              color={theme.palette.text.primary}
                              sx={{ whiteSpace: "pre" }}
                            >
                              {idx === 1
                                ? customTableHeader(sortedData.title)
                                : cell.title}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedData?.data?.map((influencer: any, idx: number) => (
                        <TableRow
                          key={idx}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            padding="none"
                            sx={{ cursor: "pointer", padding: ".6rem 0" }}
                            onClick={() =>
                              navigate(
                                `/influencers/${influencer?.screen_name}`,
                                { state: { id: influencer.id_str } }
                              )
                            }
                          >
                            <Box display="flex" alignItems="center">
                              <IconButton
                                sx={{ mr: 0.5 }}
                                disabled={loading}
                                onClick={(e) =>
                                  hanldePinInfluencer(e, influencer?.id_str)
                                }
                              >
                                {pinnedListId?.includes(influencer?.id_str) ? (
                                  <RiPushpin2Fill size={18} />
                                ) : (
                                  <RiPushpin2Line size={18} />
                                )}
                              </IconButton>
                              <Avatar
                                src={getUserProfile(influencer?.screen_name)}
                                sx={{ width: 38, height: 38, mr: 1 }}
                              />
                              <Stack py={0.7}>
                                <Typography
                                  variant="body1"
                                  color={theme.palette.text.primary}
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: ".82rem",
                                    lineHeight: 1.4,
                                  }}
                                >
                                  {influencer.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color={theme.palette.text.primary}
                                  sx={{
                                    fontSize: ".75rem",
                                    fontWeight: 100,
                                  }}
                                >
                                  @{influencer.screen_name}
                                </Typography>
                              </Stack>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              color={theme.palette.text.primary}
                              variant="body2"
                              sx={{
                                fontWeight: 100,
                              }}
                            >
                              {/*{selectRank(sortedData.id, influencer)} */}
                              {influencer?.tweet_count}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              color={theme.palette.text.primary}
                              variant="body2"
                              sx={{
                                fontWeight: 100,
                              }}
                            >
                              {Number(
                                influencer.followers_count
                              ).toLocaleString()}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      {openLoginAlertModal && (
        <LoginAlertModal
          open={openLoginAlertModal}
          setOpen={setLoginAlertModal}
        />
      )}
    </>
  );
};

export default InfluencersSortTables;
