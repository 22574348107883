import { Box, useTheme } from "@mui/material";
import { BottomDrawerProps } from "../interfaces";
import styles from "./bottomDrawer.module.scss";

const BottomDrawer: React.FC<BottomDrawerProps> = ({
  open,
  setOpen,
  children,
  isSolid,
}) => {
  const theme = useTheme();

  return (
    <Box
      className={`top-zindex ${styles.bottomDrawerWrapper}`}
      sx={{
        background: isSolid
          ? //@ts-ignore
            theme.palette.common.bottomDrawer
          : //@ts-ignore
            theme.palette.common.bottomDrawer,
        bottom: open ? 0 : "-100%",
        opacity: open ? 1 : 0,
      }}
      onClick={() => setOpen(false)}
    >
      {children}
    </Box>
  );
};

export default BottomDrawer;
