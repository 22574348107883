import { useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import { Box, Button, Typography, useTheme } from "@mui/material";
import NewsRssCard from "./newsRssCard";
import Loading from "../../common/loading";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import {
  fetchRssNewsList,
  setNewsPageRssResult,
} from "../../../features/news/newsSlice";
import { RssItemType, RssNewsProps } from "../../news/interfaces";
import loginLogo from "../../../assets/images/icons/login_icon.svg";

const CryptoRssNews: React.FC<RssNewsProps> = ({
  setRssPage,
  newsList,
  isAuthenticated,
}) => {
  const theme = useTheme();
  const params = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const [newsLoading, setNewsLoading] = useState(false);

  const fetchNextPage = () => {
    const requestData = {
      page: Number(
        newsList.rssDisplayResult.next.split("page=")[1].split("&")[0],
      ),
      search: cryptosState.availableCoins
        .find((coin: any) => coin.id == params.crypto)
        ?.code?.toLowerCase(),
    };

    setNewsLoading(true);
    setRssPage(
      Number(newsList.rssDisplayResult.next.split("page=")[1].split("&")[0]),
    );
    dispatch(fetchRssNewsList(requestData)).then((response) => {
      dispatch(
        setNewsPageRssResult({
          count: response.payload.count,
          next: response.payload.next,
          results: [...newsList.rssDisplayResult.results].concat(
            response.payload.results,
          ),
        }),
      );
      setNewsLoading(false);
    });
  };

  return (
    <Box mt={3}>
      {!newsList.rssDisplayResult ? (
        <Loading />
      ) : (
        newsList.rssDisplayResult &&
        (newsList.rssDisplayResult.count > 0 ? (
          <>
            {newsList.rssDisplayResult.results.map((newsItem: RssItemType) => (
              <NewsRssCard
                key={`${newsItem.title}-${newsItem.published}`}
                url={newsItem.url ?? ""}
                thumbnail_url={newsItem.thumbnail_url}
                title={newsItem.title}
                base_link={newsItem.base_link}
                published={moment
                  .parseZone(newsItem.published)
                  .format("YYYY-MM-DD")}
                tags={newsItem.tags}
              />
            ))}
            {newsLoading ? (
              <Loading />
            ) : (
              newsList.rssDisplayResult.next && (
                <Box display="flex" justifyContent="center" mt={5}>
                  <Button variant="text" onClick={fetchNextPage}>
                    View More
                  </Button>
                </Box>
              )
            )}
          </>
        ) : (
          <Box
            className={`default-card-style `}
            sx={{ background: theme.palette.background.paper }}
          >
            <Typography align="center" py={3}>
              No data
            </Typography>
          </Box>
        ))
      )}
    </Box>
  );
};

export default CryptoRssNews;
