import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { IoIosAttach } from "react-icons/io";
import { MdClose } from "react-icons/md";
import ChatInput from "../chatInput";
import styles from "../agents.module.scss";

const AgentInput = ({
  file,
  setFile,
  selectedAgentItem,
  submitQueryHandler,
}: {
  file: any;
  setFile: any;
  selectedAgentItem: any;
  submitQueryHandler: any;
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box className={styles.agentInputWrapper}>
      {file && (
        <Fade triggerOnce>
          <Box
            className={styles.uploadedFile}
            sx={{
              background: (theme: any) => theme.palette.common.solidCard,
            }}
          >
            <IconButton
              size="small"
              sx={{ marginRight: "5px" }}
              onClick={() => setFile(null)}
            >
              <MdClose />
            </IconButton>
            <Box
              className={styles.uploadedFileIcon}
              sx={{
                background: (theme: any) => theme.palette.primary.main,
              }}
            >
              <IoIosAttach size={23} />
            </Box>
            <Box className={styles.uploadedFileData}>
              <Typography variant="h6">
                {downLg ? file.name.slice(0, 30) : file.name}
              </Typography>
              <Typography variant="caption">{file.type}</Typography>
            </Box>
          </Box>
        </Fade>
      )}
      <Box className={styles.agentInput}>
        <ChatInput
          file={file}
          setFile={setFile}
          isActive
          selectedAgent={selectedAgentItem?.agent_name}
          placeholder={selectedAgentItem?.placeholder}
          submitQueryHandler={submitQueryHandler}
        />
      </Box>
    </Box>
  );
};

export default AgentInput;
