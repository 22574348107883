import { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { PerformanceProps } from "../../analytics/interfaces";
import Loading from "../../common/loading";

const Performance: React.FC<PerformanceProps> = ({ data, size }) => {
  const theme = useTheme();
  const [performanceData, setPerformanceData] = useState<any[]>([]);

  useEffect(() => {
    if (data.coinPerformance)
      setPerformanceData([
        {
          name: "1D",
          dollar_change: data.coinPerformance?.one_day?.dollar_change,
          percentage_change: data.coinPerformance?.one_day?.percentage_change,
        },
        {
          name: "1W",
          dollar_change: data.coinPerformance?.one_week?.dollar_change,
          percentage_change: data.coinPerformance?.one_week?.percentage_change,
        },
        {
          name: "1M",
          dollar_change: data.coinPerformance?.one_month?.dollar_change,
          percentage_change: data.coinPerformance?.one_month?.percentage_change,
        },
        {
          name: "2M",
          dollar_change: data.coinPerformance?.two_month?.dollar_change,
          percentage_change: data.coinPerformance?.two_month?.percentage_change,
        },
        {
          name: "3M",
          dollar_change: data.coinPerformance?.three_month?.dollar_change,
          percentage_change:
            data.coinPerformance?.three_month?.percentage_change,
        },
        {
          name: "6M",
          dollar_change: data.coinPerformance?.six_month?.dollar_change,
          percentage_change: data.coinPerformance?.six_month?.percentage_change,
        },
        // {
        //   name: "1Y",
        //   dollar_change: data.coinPerformance?.one_year?.dollar_change,
        //   percentage_change: data.coinPerformance?.one_year?.percentage_change,
        // },
      ]);
  }, [data.coinPerformance]);

  return (
    <>
      {data.coinPerformanceLoading ? (
        <Loading />
      ) : performanceData.length > 0 ? (
        <Grid
          container
          sx={{
            py: size == "small" ? 0 : 2,
            mt: size == "small" ? 0 : 2,
          }}
        >
          {performanceData
            .filter(
              (item: any) =>
                item.percentage_change !== undefined &&
                item.dollar_change !== undefined,
            )
            .map((dataItem: any) => (
              <Grid
                item
                xs={size == "small" ? 6 : 12}
                lg={4}
                key={dataItem.name}
                sx={{ padding: ".2rem" }}
              >
                <Box
                  sx={{
                    background: theme.palette.background.paper,
                    borderRadius: "8px",
                    padding: ".8rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 900,
                    }}
                  >
                    {dataItem.name}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      color={
                        dataItem.percentage_change < 0
                          ? theme.palette.error.main
                          : theme.palette.success.main
                      }
                    >
                      {dataItem.percentage_change < 0 ? "-" : "+"}%
                      {Math.abs(dataItem.percentage_change).toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color={(theme: any) => theme.palette.common.mute}
                    >
                      {dataItem.dollar_change < 0 ? "-" : "+"}$
                      {Math.abs(dataItem.dollar_change).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      ) : (
        <Box
          className={`default-card-style `}
          sx={{ background: theme.palette.background.paper, width: "100%" }}
        >
          <Typography align="center" py={3}>
            No data
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Performance;
