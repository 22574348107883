import DaedalusImg from "../assets/images/wallets/Daedalus.png";
import MetaMaskImg from "../assets/images/wallets/MetaMask.png";
import PhantomImg from "../assets/images/wallets/Phantom.png";
import TrezorImg from "../assets/images/wallets/Trezor.png";
import LedgerImg from "../assets/images/wallets/Ledger.png";
import BraveWalletImg from "../assets/images/wallets/Brave-Wallet.png";
import KyberSwapImg from "../assets/images/wallets/KyberSwap.png";
import UniswapImg from "../assets/images/wallets/Uniswap.png";
import ElectrumImg from "../assets/images/wallets/Electrum.png";
import KeepKeyImg from "../assets/images/wallets/KeepKey.png";
import CompoundImg from "../assets/images/wallets/Compound.png";
import CoinbaseWalletImg from "../assets/images/wallets/Coinbase-Wallet.png";
import MyEtherWalletImg from "../assets/images/wallets/MyEtherWallet.png";
import MyCryptoImg from "../assets/images/wallets/MyCrypto.png";
import GameStopImg from "../assets/images/wallets/GameStop.jpg";
import RobinhoodWeb3WalletImg from "../assets/images/wallets/Robinhood-Web3-Wallet.png";
import JaxxImg from "../assets/images/wallets/Jaxx.png";
import eToroWalletImg from "../assets/images/wallets/eToro-Wallet.jpg";
import inchImg from "../assets/images/wallets/1inch.png";
import YoroiImg from "../assets/images/wallets/Yoroi.png";
import TrustImg from "../assets/images/wallets/Trust.png";
import PeraWalletImg from "../assets/images/wallets/Pera-Wallet.png";
import EthereumImg from "../assets/images/wallets/Ethereum.png";
import BitcoinImg from "../assets/images/wallets/Bitcoin.png";
import BinanceSmartChainImg from "../assets/images/wallets/Binance-Smart-Chain.png";
import CardanoImg from "../assets/images/wallets/Cardano.png";
import SolanaImg from "../assets/images/wallets/Solana.png";
import CosmosImg from "../assets/images/wallets/Cosmos.png";
import OptimismImg from "../assets/images/wallets/Optimism.png";
import ArbitrumOneImg from "../assets/images/wallets/Arbitrum-One.png";
import BaseImg from "../assets/images/wallets/Base.png";
import RippleImg from "../assets/images/wallets/Ripple.png";
import LitecoinImg from "../assets/images/wallets/Litecoin.png";
import StellarImg from "../assets/images/wallets/Stellar.png";
import DogecoinImg from "../assets/images/wallets/Dogecoin.png";
import PolygonImg from "../assets/images/wallets/Polygon.png";
import TronImg from "../assets/images/wallets/Tron.png";
import BitcoinCashImg from "../assets/images/wallets/Bitcoin-Cash.png";
import TezosImg from "../assets/images/wallets/Tezos.png";
import VechainImg from "../assets/images/wallets/Vechain.png";
import EthereumClassicImg from "../assets/images/wallets/Ethereum-Classic.png";
import AvalancheImg from "../assets/images/wallets/Avalanche.png";
import ZcashImg from "../assets/images/wallets/Zcash.png";
import NEOImg from "../assets/images/wallets/NEO.png";
import DashImg from "../assets/images/wallets/Dash.png";
import EOSImg from "../assets/images/wallets/EOS.png";
import BitcoinSatoshiVisionImg from "../assets/images/wallets/Bitcoin-Satoshi-Vision.png";
import QtumImg from "../assets/images/wallets/Qtum.png";
import NEMImg from "../assets/images/wallets/NEM.png";
import FantomImg from "../assets/images/wallets/Fantom.png";
import MoonriverImg from "../assets/images/wallets/Moonriver.png";
import TerraClassicImg from "../assets/images/wallets/Terra-Classic.png";
import MoonbeamImg from "../assets/images/wallets/Moonbeam.png";
import HuobiTokenImg from "../assets/images/wallets/Huobi-Token.png";
import AlgorandImg from "../assets/images/wallets/Algorand.png";
import WalletconnectImg from "../assets/images/wallets/walletconnect.png";

export const wallets = [
  {
    name: "WalletConnect",
    imageUrl: WalletconnectImg,
    category: "WalletConnect",
    websiteUrl: "https://walletconnect.com/",
    info: {
      cryptoNetwork: "",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  {
    name: "Bitcoin",
    imageUrl: BitcoinImg,
    category: "Blockchain",
    websiteUrl: "https://bitcoin.org",
    info: {
      cryptoNetwork: "bitcoin",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  {
    name: "Coinbase Wallet",
    imageUrl: CoinbaseWalletImg,
    category: "Wallet",
    websiteUrl: "https://coinbase.com/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "ethereum",
        "polygon",
        "bitcoin",
        "bitcoincash",
        "dogecoin",
        "litecoin",
        "stellar",
        "xrp",
        "solana",
        "avalanche",
        "fantom",
        "optimism",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "Trust",
    imageUrl: TrustImg,
    category: "Wallet",
    websiteUrl: "https://trustwallet.com/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        // 'cardano',
        "avalanche",
        "ethereum",
        "fantom",
        "polygon",
        "solana",
        "tezos",
        "stellar",
        "xrp",
        "bitcoin",
        "dogecoin",
        "litecoin",
        "optimism",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "MetaMask",
    imageUrl: MetaMaskImg,
    category: "Wallet",
    websiteUrl: "https://metamask.io/",
    info: {
      cryptoNetwork: "ethereum",
      supportedCryptoNetworks: [
        "ethereum",
        "fantom",
        "avalanche",
        "polygon",
        "optimism",
      ],
      isEvmChain: true,
    },
  },
  {
    name: "Ethereum",
    imageUrl: EthereumImg,
    category: "Blockchain",
    websiteUrl: "https://ethereum.org/",
    info: {
      cryptoNetwork: "ethereum",
      supportedCryptoNetworks: null,
      isEvmChain: true,
    },
  },
  {
    name: "Phantom",
    imageUrl: PhantomImg,
    category: "Wallet",
    websiteUrl: "https://phantom.app/",
    info: {
      cryptoNetwork: "solana",
      supportedCryptoNetworks: ["solana", "ethereum", "polygon", "bitcoin"],
      isEvmChain: false,
    },
  },
  {
    name: "Trezor",
    imageUrl: TrezorImg,
    category: "Wallet",
    websiteUrl: "https://trezor.io/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "avalanche",
        "bitcoin",
        "dogecoin",
        "ethereum",
        "fantom",
        "litecoin",
        "polygon",
        "xrp",
        "solana",
        "stellar",
        "tezos",
        "optimism",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "Ledger",
    imageUrl: LedgerImg,
    category: "Wallet",
    websiteUrl: "https://www.ledger.com/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "avalanche",
        "bitcoin",
        "dogecoin",
        "ethereum",
        "fantom",
        "litecoin",
        "polygon",
        "xrp",
        "solana",
        "stellar",
        "tezos",
        "optimism",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "Brave Wallet",
    imageUrl: BraveWalletImg,
    category: "Wallet",
    websiteUrl: "https://brave.com/wallet/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "ethereum",
        "avalanche",
        "polygon",
        "optimism",
        "fantom",
        "solana",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "KyberSwap",
    imageUrl: KyberSwapImg,
    category: "Wallet",
    websiteUrl: "https://kyberswap.com/swap",
    info: {
      cryptoNetwork: "ethereum",
      supportedCryptoNetworks: [
        "ethereum",
        "polygon",
        "avalanche",
        "fantom",
        "optimism",
      ],
      isEvmChain: true,
    },
  },
  {
    name: "Uniswap",
    imageUrl: UniswapImg,
    category: "Wallet",
    websiteUrl: "https://uniswap.exchange/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: ["ethereum", "polygon", "optimism"],
      isEvmChain: null,
    },
  },
  {
    name: "Electrum",
    imageUrl: ElectrumImg,
    category: "Wallet",
    websiteUrl: "https://electrum.org",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: ["bitcoin"],
      isEvmChain: null,
    },
  },
  {
    name: "KeepKey",
    imageUrl: KeepKeyImg,
    category: "Wallet",
    websiteUrl: "https://www.keepkey.com/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "bitcoin",
        "dogecoin",
        "litecoin",
        "ethereum",
        "xrp",
        "polygon",
        "fantom",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "Compound",
    imageUrl: CompoundImg,
    category: "Wallet",
    websiteUrl: "https://compound.finance",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: ["ethereum"],
      isEvmChain: null,
    },
  },
  {
    name: "MyEtherWallet",
    imageUrl: MyEtherWalletImg,
    category: "Wallet",
    websiteUrl: "https://www.myetherwallet.com/",
    info: {
      cryptoNetwork: "ethereum",
      supportedCryptoNetworks: ["ethereum", "polygon"],
      isEvmChain: true,
    },
  },
  {
    name: "MyCrypto",
    imageUrl: MyCryptoImg,
    category: "Wallet",
    websiteUrl: "https://www.mycrypto.com/",
    info: {
      cryptoNetwork: "ethereum",
      supportedCryptoNetworks: ["ethereum"],
      isEvmChain: true,
    },
  },
  {
    name: "GameStop",
    imageUrl: GameStopImg,
    category: "Wallet",
    websiteUrl: "https://wallet.gamestop.com/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: ["ethereum"],
      isEvmChain: null,
    },
  },
  {
    name: "Robinhood Web3 Wallet",
    imageUrl: RobinhoodWeb3WalletImg,
    category: "Wallet",
    websiteUrl: "https://robinhood.com/web3-wallet/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "avalanche",
        "bitcoin",
        "dogecoin",
        "ethereum",
        "litecoin",
        "polygon",
        "solana",
        "stellar",
        "tezos",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "Jaxx",
    imageUrl: JaxxImg,
    category: "Wallet",
    websiteUrl: "https://jaxx.io/",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: ["bitcoin", "dogecoin", "litecoin"],
      isEvmChain: null,
    },
  },
  {
    name: "eToro Wallet",
    imageUrl: eToroWalletImg,
    category: "Wallet",
    websiteUrl: "https://etoro.com",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "bitcoin",
        "ethereum",
        "xrp",
        "litecoin",
        "stellar",
      ],
      isEvmChain: null,
    },
  },
  {
    name: "1inch",
    imageUrl: inchImg,
    category: "Wallet",
    websiteUrl: "https://1inch.io",
    info: {
      cryptoNetwork: null,
      supportedCryptoNetworks: [
        "avalanche",
        "bitcoin",
        "dogecoin",
        "ethereum",
        "fantom",
        "litecoin",
        "polygon",
        "solana",
        "stellar",
        "xrp",
        "tezos",
      ],
      isEvmChain: null,
    },
  },
  // {
  //   name: 'Binance Smart Chain',
  //   imageUrl: BinanceSmartChainImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.bnbchain.org/en',
  //   info: {
  //     cryptoNetwork: 'binance-smart-chain',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  // {
  //   name: 'Cardano',
  //   imageUrl: CardanoImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://cardano.org/',
  //   info: {
  //     cryptoNetwork: 'cardano',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  {
    name: "Solana",
    imageUrl: SolanaImg,
    category: "Blockchain",
    websiteUrl: "https://solana.com/",
    info: {
      cryptoNetwork: "solana",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  // {
  //   name: 'Cosmos',
  //   imageUrl: CosmosImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://cosmos.network/',
  //   info: {
  //     cryptoNetwork: 'cosmos',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  {
    name: "Optimism",
    imageUrl: OptimismImg,
    category: "Blockchain",
    websiteUrl: "https://www.optimism.io/",
    info: {
      cryptoNetwork: "optimism",
      supportedCryptoNetworks: null,
      isEvmChain: true,
    },
  },
  // {
  //   name: 'Arbitrum One',
  //   imageUrl: ArbitrumOneImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://arbitrum.io/',
  //   info: {
  //     cryptoNetwork: 'arbitrum-one',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  // {
  //   name: 'Base',
  //   imageUrl: BaseImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://base.org/',
  //   info: {
  //     cryptoNetwork: 'base',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  {
    name: "XRP",
    imageUrl: RippleImg,
    category: "Blockchain",
    websiteUrl: "https://xrpl.org/",
    info: {
      cryptoNetwork: "xrp",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  {
    name: "Litecoin",
    imageUrl: LitecoinImg,
    category: "Blockchain",
    websiteUrl: "https://litecoin.org/",
    info: {
      cryptoNetwork: "litecoin",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  {
    name: "Stellar",
    imageUrl: StellarImg,
    category: "Blockchain",
    websiteUrl: "https://www.stellar.org/",
    info: {
      cryptoNetwork: "stellar",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  {
    name: "Dogecoin",
    imageUrl: DogecoinImg,
    category: "Blockchain",
    websiteUrl: "https://dogecoin.com/",
    info: {
      cryptoNetwork: "dogecoin",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  {
    name: "Polygon",
    imageUrl: PolygonImg,
    category: "Blockchain",
    websiteUrl: "https://polygon.technology/",
    info: {
      cryptoNetwork: "polygon",
      supportedCryptoNetworks: null,
      isEvmChain: true,
    },
  },
  // {
  //   name: 'Tron',
  //   imageUrl: TronImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://tron.network/',
  //   info: {
  //     cryptoNetwork: 'tron',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'Bitcoin Cash',
  //   imageUrl: BitcoinCashImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://bch.info/en/',
  //   info: {
  //     cryptoNetwork: 'bitcoin-cash',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  {
    name: "Tezos",
    imageUrl: TezosImg,
    category: "Blockchain",
    websiteUrl: "https://tezos.com/",
    info: {
      cryptoNetwork: "tezos",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
  // {
  //   name: 'Vechain',
  //   imageUrl: VechainImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.vechain.org/',
  //   info: {
  //     cryptoNetwork: 'vechain',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'Ethereum Classic',
  //   imageUrl: EthereumClassicImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://ethereumclassic.org/',
  //   info: {
  //     cryptoNetwork: 'ethereum-classic',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  {
    name: "Avalanche",
    imageUrl: AvalancheImg,
    category: "Blockchain",
    websiteUrl: "https://www.avax.network/",
    info: {
      cryptoNetwork: "avalanche",
      supportedCryptoNetworks: null,
      isEvmChain: true,
    },
  },
  // {
  //   name: 'Zcash',
  //   imageUrl: ZcashImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://z.cash/',
  //   info: {
  //     cryptoNetwork: 'zcash',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'NEO',
  //   imageUrl: NEOImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://neo.org/',
  //   info: {
  //     cryptoNetwork: 'neo',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'Dash',
  //   imageUrl: DashImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.dash.org/',
  //   info: {
  //     cryptoNetwork: 'dash',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'EOS',
  //   imageUrl: EOSImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://eosnetwork.com/',
  //   info: {
  //     cryptoNetwork: 'eos',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'Bitcoin Satoshi Vision',
  //   imageUrl: BitcoinSatoshiVisionImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.bitcoinsv.com/',
  //   info: {
  //     cryptoNetwork: 'bitcoin-satoshi-vision',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'Qtum',
  //   imageUrl: QtumImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.qtum.org/',
  //   info: {
  //     cryptoNetwork: 'qtum',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'NEM',
  //   imageUrl: NEMImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.nem.io/',
  //   info: {
  //     cryptoNetwork: 'nem',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  {
    name: "Fantom",
    imageUrl: FantomImg,
    category: "Blockchain",
    websiteUrl: "https://fantom.foundation/",
    info: {
      cryptoNetwork: "fantom",
      supportedCryptoNetworks: null,
      isEvmChain: true,
    },
  },
  // {
  //   name: 'Moonriver',
  //   imageUrl: MoonriverImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://moonbeam.network/networks/moonriver/',
  //   info: {
  //     cryptoNetwork: 'moonriver',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  // {
  //   name: 'Terra Classic',
  //   imageUrl: TerraClassicImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.terra.money/',
  //   info: {
  //     cryptoNetwork: 'terra-classic',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: false,
  //   },
  // },
  // {
  //   name: 'Moonbeam',
  //   imageUrl: MoonbeamImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://moonbeam.network/networks/moonbeam/',
  //   info: {
  //     cryptoNetwork: 'moonbeam',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  // {
  //   name: 'Huobi Token',
  //   imageUrl: HuobiTokenImg,
  //   category: 'Blockchain',
  //   websiteUrl: 'https://www.hecochain.com/',
  //   info: {
  //     cryptoNetwork: 'huobi-token',
  //     supportedCryptoNetworks: null,
  //     isEvmChain: true,
  //   },
  // },
  {
    name: "Algorand",
    imageUrl: AlgorandImg,
    category: "Blockchain",
    websiteUrl: "https://algorand.com/",
    info: {
      cryptoNetwork: "algorand",
      supportedCryptoNetworks: null,
      isEvmChain: false,
    },
  },
];
