import { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { HiRss } from "react-icons/hi2";
import { FaXTwitter } from "react-icons/fa6";
import { NewsAvatarProps } from "../interfaces";
import styles from "./newsAvatar.module.scss";

const NewsAvatar: React.FC<NewsAvatarProps> = ({
  type,
  imageUrl,
  width,
  height,
  fullWidth,
  isGallery,
}) => {
  const theme = useTheme();
  const [imageError, setImageError] = useState(false);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const handleImageError = () => {
    setImageError(true);
  };

  if (type === "rss") {
    return (
      <Box
        className={styles.newsRssImage}
        sx={{
          width: downLg ? "100%" : fullWidth ? "80%" : `${width}px`,
          maxWidth: "400px",
          height: downLg ? "190px" : fullWidth ? "auto" : `${height}px`,
          minHeight: fullWidth ? "140px" : 0,
          margin: "auto",
          marginTop: "auto",
        }}
      >
        {!imageError && imageUrl ? (
          <img src={imageUrl} onError={handleImageError} loading="lazy" />
        ) : (
          <Box sx={{ background: theme.palette.background.paper }}>
            <HiRss
              //@ts-ignore
              color={theme.palette.common.whiteToDarkGreen}
            />
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        className={styles.newsTweetImage}
        sx={{
          width: isGallery ? "100%" : fullWidth ? "80%" : `${width}px`,
        }}
      >
        {!imageError && imageUrl ? (
          <img src={imageUrl} onError={handleImageError} loading="lazy" />
        ) : (
          <Box sx={{ background: theme.palette.background.paper }}>
            <FaXTwitter
              //@ts-ignore
              color={theme.palette.common.whiteToDarkGreen}
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default NewsAvatar;
