import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { InitialProfileState } from "./interfaces";
import http from "../../services/http";

export const generateCode = createAsyncThunk<any, any, { rejectValue: any }>(
  "generate code",
  async (_, thunkAPI) => {
    try {
      const url = `/users/early-access-token/register/ `;
      const response = await http.post(url);
      return response.data.response.data.token;
    } catch (error: any) {
      if (error.response.status === 400) {
        const url = `/users/early-access-token/get/ `;
        const response = await http.get(url);
        return response.data.response.data.token;
      } else {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

export const getCode = createAsyncThunk<any, any, { rejectValue: any }>(
  "get code",
  async (_, thunkAPI) => {
    try {
      const url = `/users/early-access-token/get/ `;
      const response = await http.get(url);
      return response.data.response.data.token;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const uploadAvatar = createAsyncThunk<any, File, { rejectValue: any }>(
  "profile/uploadAvatar",
  async (file, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("profile_picture", file);
      const url = `/users/profile/`;
      const response = await http.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data.response.data.avatarUrl;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAvatar = createAsyncThunk<any, void, { rejectValue: any }>(
  "profile/deleteAvatar",
  async (_, thunkAPI) => {
    try {
      const url = `/users/profile/`;
      const response = await http.delete(url);
      return response.data.response.data.message; // Optional: message from server
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState: InitialProfileState = {
  connectedWallet: null,
  connectionWalletLoading: false,
  pinedCoins: null,
  isLimitAccess: false,
  limitAccessData: { message: "" },
  pinCoinsLoading: false,
  generatedCode: null,
  generatedCodeLoading: false,
  avatarUrl: null,
  avatarLoading: false,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    openLimitAccessModal: (state) => {
      state.isLimitAccess = true;
    },
    closeLimitAccessModal: (state) => {
      state.isLimitAccess = false;
    },
    setLimitAccessData: (state, action) => {
      state.limitAccessData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateCode.pending, (state) => {
        state.generatedCodeLoading = true;
      })
      .addCase(generateCode.fulfilled, (state, action) => {
        state.generatedCodeLoading = false;
        state.generatedCode = action.payload;
      })
      .addCase(generateCode.rejected, (state) => {
        state.generatedCodeLoading = false;
      })
      .addCase(uploadAvatar.pending, (state) => {
        state.avatarLoading = true;
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.avatarLoading = false;
        state.avatarUrl = action.payload;
      })
      .addCase(uploadAvatar.rejected, (state) => {
        state.avatarLoading = false;
      })
      .addCase(deleteAvatar.pending, (state) => {
        state.avatarLoading = true;
      })
      .addCase(deleteAvatar.fulfilled, (state) => {
        state.avatarLoading = false;
        state.avatarUrl = null;
      })
      .addCase(deleteAvatar.rejected, (state) => {
        state.avatarLoading = false;
      });
  },
});
export default profileSlice.reducer;

export const {
  closeLimitAccessModal,
  openLimitAccessModal,
  setLimitAccessData,
} = profileSlice.actions;
