import { Box, useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { TreeMapProps } from "./interfaces";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import styles from "./charts.module.scss";
import Empty from "../common/empty/Empty";

const TreeMap: React.FC<TreeMapProps> = ({ data }) => {
  const theme = useTheme();

  const filteredData = useMemo(() => {
    if (isEmpty(data)) return [];

    const values = data?.map((coin: any) => coin.y);
    const sumValues = values.reduce((acc, val) => (acc += val), 0);
    const averageValues = sumValues / data?.length;
    const threshold = 0.1 * averageValues;

    const filterData = data
      ?.filter((coin: any) => coin.y >= threshold)
      .sort((a, b) => b.y - a.y);

    return filterData;
  }, [data]);

  const options: ApexOptions = {
    legend: {
      show: false,
    },
    chart: {
      height: 350,
      type: "treemap",
      toolbar: {
        show: false,
      },
      offsetX: 0,
    },
    dataLabels: {
      enabled: true,
      offsetY: -5,
      // @ts-ignore
      formatter: function (text, op) {
        const blockSize =
          op.w.config.series[op.seriesIndex]?.data[op.dataPointIndex]?.size;

        if (blockSize <= 10) return text;
        return [
          text,
          `Tweet Count: ${op.value?.toLocaleString()}`,
          `Price Change: ${
            op.w.config.series[op.seriesIndex]?.data[op.dataPointIndex]
              ?.priceChange
          }%`,
        ];
      },
      style: {
        fontSize: "9px",
        colors: [theme.palette.grey[900]],
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return `
        <div class='${styles.linechart_tooltip}' style="background: ${
          //@ts-ignore
          theme.palette.common.solidCard
        }">
          <h6 style="color: ${theme.palette.common.white}">
            ${w.globals.categoryLabels[dataPointIndex]}
          </h6>
          <div>
            <h4 style="color: ${theme.palette.common.white}">Tweet Count:</h4>
            <h5 style="color: ${theme.palette.common.white}">
            ${series[seriesIndex][dataPointIndex]?.toLocaleString()}
            </h5>
          </div>
          <div>
            <h4 style="color: ${theme.palette.common.white}">price Chagne:</h4>
            <h5 style="color: ${theme.palette.common.white} ">
            ${w.config.series[seriesIndex].data[dataPointIndex].priceChange}%
            </h5>
          </div>
        </div>`;
      },
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.3,
        reverseNegativeShade: true,
        borderRadius: 0,
        distributed: true,
        dataLabels: {
          format: "scale",
        },
        colorScale: {
          ranges: [],
        },
      },
    },
  };

  return (
    <>
      {!isEmpty(filteredData) ? (
        <Box id="treemap-chart" whiteSpace={"pre"}>
          <ReactApexChart
            key={theme.palette.mode}
            options={options}
            series={[{ data: filteredData }]}
            type="treemap"
            height={350}
          />
        </Box>
      ) : (
        <Empty text="No Data" />
      )}
    </>
  );
};

export default TreeMap;
