import { useEffect, useState } from "react";
import FA2Layout from "graphology-layout-forceatlas2/worker";
import louvain from "graphology-communities-louvain";
import {
  useSigma,
  useRegisterEvents,
  useLoadGraph,
  useSetSettings,
} from "@react-sigma/core";
import drawLabel from "./Canvas-Utils";

const CustomGraph = ({ dataset, children, setLoading, coinList }) => {
  const [nodes, edges] = dataset;
  const sigma = useSigma();
  const registerEvents = useRegisterEvents();
  const loadGraph = useLoadGraph();
  const setSettings = useSetSettings();
  const graph = sigma.getGraph();

  // states
  const [draggedNode, setDraggedNode] = useState(null);
  const [hoveredNode, setHoveredNode] = useState(null);

  // groupBy function
  const groupBy = (arr, criteria) => {
    const newObj = arr.reduce(function (acc, currentValue) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);
      return acc;
    }, {});
    return newObj;
  };

  // ======= Feed graphology with the new dataset
  useEffect(() => {
    if (!graph || !nodes || !edges) return;
    /**
     * add node and edge to graph
     */
    nodes.forEach((node) =>
      graph.addNode(node.key, {
        x: Math.floor(Math.random() * 100 + 1),
        y: Math.floor(Math.random() * 100 + 1),
        size: node.attributes.size + 10,
        modularity_class: 0,
        label: coinList?.find((coin) => coin.id === node.attributes.label)
          ?.name,
        community_name: node.attributes.community_name ?? "",
        followers_count: node.attributes.followers_count ?? "",
        friends_count: node.attributes.friends_count ?? "",
        details: {},
        is_details: false,
        short_details: node.attributes.label,
        long_details: `${node.attributes.label}@`,
        type: "image",
        image: `https://pwa.coinfident.ai/i/api/rest/media/coin_imgs/${node.key}.png`,
      }),
    );
    edges.forEach((edge) =>
      graph.addEdge(edge.source, edge.target, {
        size: 0.5,
        type: "curved",
        curvature: 0.2,
      }),
    );

    // ======= apply force atlas to graph
    const layout = new FA2Layout(graph, {
      settings: { slowDown: 1000 },
    });

    layout.start();

    setTimeout(() => {
      setLoading(false);
      layout.stop();
    }, 5000);

    return () => graph.clear();
  }, [graph]);

  useEffect(() => {
    const communityData = {};

    // ======= assign communities to graph by louvian
    louvain.assign(graph);
    const communities = louvain.detailed(graph);

    const keys = [];
    graph.forEachNode((node) => {
      const label = graph.getNodeAttributes(node).label;
      const key_lebel = { key: label };
      keys.push(key_lebel);
    });
    const classify_keys = groupBy(keys, "key");

    const cluster_arr = [];
    for (const cluster in classify_keys) {
      const obj = {
        id: cluster,
        clusterName: cluster,
        clusterCount: classify_keys[cluster].length,
        community: cluster,
      };
      cluster_arr.push(obj);
    }

    graph.forEachNode((node) => {
      graph.setNodeAttribute(node, "color", "#353535");
    });

    graph.forEachEdge((edge) => {
      graph.setEdgeAttribute(edge, "color", "#7cccb7");
    });

    Object.entries(communities.communities).forEach(
      ([nodeName, communityId]) => {
        if (!(communityId in communityData)) {
          communityData[communityId] = {
            id: communityId,
            community: communityId,
            clusterName: communityId,
            nodes: [],
            clusterCount: 0,
          };
        }
        communityData[communityId].nodes.push(nodeName);
        communityData[communityId].clusterCount =
          communityData[communityId].nodes.length;
      },
    );
  }, [graph]);

  /**
   * When component mount or hovered node change
   * => Setting the sigma reducers
   */
  useEffect(() => {
    setSettings({
      nodeReducer: (node, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, highlighted: data.highlighted || false };

        if (hoveredNode) {
          if (
            node === hoveredNode ||
            graph.neighbors(hoveredNode).includes(node)
          ) {
            newData.highlighted = true;
          } else {
            newData.color = "#7cccb7";
            newData.highlighted = false;
          }
        }
        return newData;
      },
      edgeReducer: (edge, data) => {
        const graph = sigma.getGraph();
        const newData = { ...data, hidden: false };

        if (hoveredNode && !graph.extremities(edge).includes(hoveredNode)) {
          newData.hidden = true;
        }
        return newData;
      },
    });
  }, [hoveredNode, setSettings, sigma]);

  /**
   * Apply events for each node:
   */
  useEffect(() => {
    // Register the events
    registerEvents({
      clickNode: (e) => {
        const oldIsDetails = graph.getNodeAttributes(e.node).is_details;
        graph.setNodeAttribute(e.node, "is_details", !oldIsDetails);
        const newIsDetails = graph.getNodeAttributes(e.node).is_details;

        const moreDetails = {
          name: graph.getNodeAttributes(e.node).community_name,
          following: graph.getNodeAttributes(e.node).friends_count,
          follower: graph.getNodeAttributes(e.node).followers_count,
        };

        if (newIsDetails) {
          graph.setNodeAttribute(e.node, "details", moreDetails);
        } else {
          graph.setNodeAttribute(e.node, "details", {});
        }
      },
      enterNode: (e) => {
        setHoveredNode(e.node);
        sigma.setSetting("hoverRenderer", (context, data, settings) =>
          drawLabel(
            context,
            { ...sigma.getNodeDisplayData(data.key), ...data },
            settings,
          ),
        );
      },
      leaveNode: (e) => {
        setHoveredNode(null);
      },
      downNode: (e) => {
        setDraggedNode(e.node);
        graph.setNodeAttribute(e.node, "highlighted", true);
      },
      mouseup: (e) => {
        if (draggedNode) {
          setDraggedNode(null);
          graph.removeNodeAttribute(draggedNode, "highlighted");
        }
      },
      mousedown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      mousemove: (e) => {
        if (draggedNode) {
          // Get new position of node
          const pos = sigma.viewportToGraph(e);
          graph.setNodeAttribute(draggedNode, "x", pos.x);
          graph.setNodeAttribute(draggedNode, "y", pos.y);

          // Prevent sigma to move camera:
          e.preventSigmaDefault();
          e.original.preventDefault();
          e.original.stopPropagation();
        }
      },
      touchup: (e) => {
        if (draggedNode) {
          setDraggedNode(null);
          graph.removeNodeAttribute(draggedNode, "highlighted");
        }
      },
      touchdown: (e) => {
        // Disable the autoscale at the first down interaction
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      touchmove: (e) => {
        if (draggedNode) {
          // Get new position of node
          const pos = sigma.viewportToGraph(e);
          graph.setNodeAttribute(draggedNode, "x", pos.x);
          graph.setNodeAttribute(draggedNode, "y", pos.y);

          // Prevent sigma to move camera:
          e.preventSigmaDefault();
          e.original.preventDefault();
          e.original.stopPropagation();
        }
      },
    });
  }, [registerEvents, sigma, draggedNode]);

  return <>{children}</>;
};

export default CustomGraph;
