import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Slide } from "react-awesome-reveal";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { LuExternalLink } from "react-icons/lu";
import {
  setIsModalResponseComplete,
  setIsResponseComplete,
  setModalSelectedModel,
  updateAgentList,
  updateModalAgentList,
} from "../../../features/agent/agentSlice";
import ChatInput from "../chatInput";
import styles from "../agents.module.scss";

const AgentInfo = ({
  isModal,
  isLight,
  file,
  setFile,
  selectedAgent,
  selectedAgentItem,
  showCompleteInfo,
  setShowCompleteInfo,
}: {
  isModal?: boolean;
  isLight?: boolean;
  file: any;
  setFile: any;
  selectedAgent: any;
  selectedAgentItem: any;
  showCompleteInfo: any;
  setShowCompleteInfo: any;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const navigatePageHandler = (network: any, query: string) => {
    if (query !== "") {
      if (isModal) {
        dispatch(setIsModalResponseComplete(false));
        dispatch(setModalSelectedModel(selectedAgent));
        dispatch(
          updateModalAgentList([
            {
              question: query,
              answer: null,
            },
          ]),
        );
      } else {
        dispatch(setIsResponseComplete(false));
        dispatch(
          updateAgentList([
            {
              question: query,
              answer: null,
            },
          ]),
        );

        navigate(`/agents/new-question`, {
          state: {
            question: query,
            network: network ? network : "",
            agent: selectedAgent,
          },
        });
      }
    }
  };

  const submitQueryHandler = (network: any, query: any) => {
    navigatePageHandler(network, query);
  };

  return (
    <>
      <Slide triggerOnce direction="up">
        {!downLg && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={selectedAgentItem?.icon} width={55} height={55} />
            <Typography
              variant="h6"
              component="h2"
              ml={2}
              color={theme.palette.text.primary}
            >
              {selectedAgentItem?.title}
            </Typography>
          </Box>
        )}
        <Box
          onClick={() => setShowCompleteInfo(!showCompleteInfo)}
          className={downLg ? styles.agentInfo : ""}
          sx={{
            overflow: "hidden",
            maxHeight: downLg
              ? showCompleteInfo
                ? "2000px"
                : "293px"
              : "auto",
            transition: "all .4s",
          }}
        >
          <Box className={styles.agentInfoContent}>
            <Box flex={3}>
              <Box
                color={theme.palette.text.primary}
                className={styles.agentInfoText}
                pr={18}
                mb={2}
                dangerouslySetInnerHTML={{
                  __html: `${selectedAgentItem?.description}`,
                }}
              ></Box>
            </Box>
            {!downLg && (
              <img
                style={{ flex: 1, width: 100 }}
                src={selectedAgentItem?.image}
              />
            )}
          </Box>

          {downLg && (
            <Box
              className={styles.agentInfoMoreBtn}
              sx={{
                background: theme.palette.mode === "dark" ? "#222" : "#fff",
              }}
            >
              <MdOutlineKeyboardArrowDown
                style={{
                  transition: "all .4s",
                  transform: showCompleteInfo
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
                size={24}
                color={
                  //@ts-ignore
                  theme.palette.common.whiteToGreen
                }
              />
            </Box>
          )}
        </Box>
        <Box
          className={`${styles.agentSuggestions} ${styles.agentResponseSuggestions}`}
        >
          {selectedAgentItem?.type === "Free" &&
            //@ts-ignore
            selectedAgentItem?.suggestions?.map((suggest) => (
              <Box
                key={suggest}
                sx={{ maxWidth: "800px" }}
                onClick={() => navigatePageHandler("", suggest)}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 100 }}
                  color={theme.palette.text.primary}
                >
                  {suggest}
                </Typography>
                <LuExternalLink color={theme.palette.text.primary} />
              </Box>
            ))}
        </Box>
        <Box
          className={`${styles.agentInputWrapper} ${styles.agentLargeInputWrapper}`}
        >
          {/* {file && (
            <Fade triggerOnce>
              <Box
                className={styles.uploadedFile}
                sx={{
                  background: (theme: any) => theme.palette.common.solidCard,
                }}
              >
                <IconButton
                  size="small"
                  sx={{ marginRight: "5px" }}
                  onClick={() => setFile(null)}
                >
                  <MdClose />
                </IconButton>
                <Box
                  className={styles.uploadedFileIcon}
                  sx={{
                    background: (theme: any) => theme.palette.primary.main,
                  }}
                >
                  <IoIosAttach size={23} />
                </Box>
                <Box className={styles.uploadedFileData}>
                  <Typography variant="h6">
                    {downLg ? file.name.slice(0, 30) : file.name}
                  </Typography>
                  <Typography variant="caption">{file.type}</Typography>
                </Box>
              </Box>
            </Fade>
          )} */}
          <Box className={styles.agentInput} sx={{ maxWidth: "800px" }}>
            {selectedAgentItem?.type === "Free" && (
              <ChatInput
                isLight
                file={file}
                setFile={setFile}
                isActive
                //@ts-ignore
                selectedAgent={selectedAgentItem?.agent_name}
                placeholder={selectedAgentItem?.placeholder}
                submitQueryHandler={submitQueryHandler}
              />
            )}
          </Box>
        </Box>
      </Slide>
    </>
  );
};

export default AgentInfo;
