//@ts-nocheck
import { useState, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { AgentCustomLineProps } from "./interfaces";
import AnalyticsTitle from "../common/title/AnalyticsTitle";
import styles from "./charts.module.scss";

const AgentCustomLine: React.FC<AgentCustomLineProps> = ({
  data,
  labels,
  names,
}) => {
  const theme = useTheme();

  function convertTime(datetime: string) {
    const [date, time] = datetime.split(" ");
    return date;
  }

  const options = useMemo(() => {
    return data?.map((i, index) => ({
      chart: {
        id: `chart-${index}`,
        group: "agent",
        type: "line",
        fontWeight: 100,
        fontFamily: "Poppins",
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      colors: i?.map((item) => item.color),
      grid: { show: false },
      dataLabels: { enabled: false },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontWeight: 100,
        markers: {
          width: 4,
          height: 4,
        },
        labels: {
          colors: theme.palette.text.primary,
        },
      },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      yaxis: i?.map((j, idx) => ({
        opposite: idx === 1 ? true : false,
        title: {
          text: "",
          offsetX: 5,
          style: {
            fontWeight: 100,
            fontSize: "11px",
            color: theme.palette.text.primary,
          },
        },
        axisBorder: { show: true, offsetX: 0 },
        labels: {
          show: true,
          style: {
            fontSize: "10px",
            fontWeigh: 100,
            colors: theme.palette.text.primary,
          },
          formatter: (value) => {
            return Number(value.toFixed(0)).toLocaleString();
          },
        },
        axisTicks: { show: false },
        tickAmount: 5,
        min: 0,
      })),
      xaxis: {
        tickAmount: 2,
        tickPlacement: "on",
        categories: labels?.map((label: string) => convertTime(label)) || [],
        labels: {
          rotate: 0,
          style: {
            fontWeight: 100,
            fontSize: "10px",
            colors: theme.palette.text.primary,
          },
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          offsetY: 2,
        },
        axisTicks: { show: false },
      },
      tooltip: {
        shared: true,
        intersect: false,
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const totalPoints = w.globals.series[seriesIndex].length;
          const isSecondHalf = dataPointIndex >= totalPoints / 2;

          const position = isSecondHalf ? "topLeft" : "topRight";
          w.config.tooltip.fixed.position = position;

          return `<div class='${styles.linechart_tooltip} ${
            styles.agent_response_chart_tooltip
          }' style="background: ${theme.palette.common.solidCard}">
      <h6 style="color: ${theme.palette.common.white} ">${
        w?.globals?.categoryLabels[dataPointIndex] || ""
      }</h6>
      ${series
        ?.map(
          (item, idx) =>
            `<div>
              <h4 style="color: ${theme.palette.common.white} ">
                ${w.globals?.seriesNames[idx] || ""}:
              </h4>
              <h5 style="color: ${theme.palette.common.white}">
                ${
                  (series[idx][dataPointIndex] &&
                    series[idx][dataPointIndex]?.toLocaleString()) ||
                  ""
                }
              </h5>
            </div>`,
        )
        .join("")}
      </div>`;
        },
      },
    }));
  }, [data, theme]);

  return (
    <Box id="wrapper" display="flex" gap="1rem">
      {data?.map((item, index) => (
        <Box
          key={index}
          id={`chart-${index + 1}`}
          flex={1}
          sx={{ background: (theme: any) => theme.palette.common.popup }}
          my={4}
          borderRadius={"8px"}
          overflow={"hidden"}
        >
          <AnalyticsTitle title={names?.at(index)} isLarge />
          <ReactApexChart
            options={options?.at(index)}
            series={item}
            type="line"
            height="200px"
          />
        </Box>
      ))}
    </Box>
  );
};

export default AgentCustomLine;
