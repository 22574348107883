import { useState, useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import { FullPageBlurProps } from "../interfaces";
import styles from "./fullPageBlue.module.scss";

const FullPageBlur: React.FC<FullPageBlurProps> = ({
  isVisible,
  setIsVisible,
  closeFullBlurFunction,
}) => {
  const [zindex, setZindex] = useState(-100);
  const theme = useTheme();
  const fullPageBlurRef = useRef();

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => setZindex(2000), 100);
    } else {
      setTimeout(() => setZindex(-100), 700);
    }
  }, [isVisible]);

  return (
    <Box
      ref={fullPageBlurRef}
      onClick={() => {
        setIsVisible(false);
        closeFullBlurFunction && closeFullBlurFunction();
      }}
      className={styles.fullPageBlurContainer}
      sx={{
        opacity: isVisible ? 1 : 0,
        zIndex: zindex,
        //@ts-ignore
        background: theme.palette.common.backdrop,
      }}
    ></Box>
  );
};

export default FullPageBlur;
