import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialAccountState } from "./interfaces";

const initialState: InitialAccountState = {
  data: null,
  isAuthenticated: false,
  loading: true,
  error: null,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<string | {}>) => {
      localStorage.setItem(
        "access-token",
        //@ts-ignore
        action.payload.access,
      );
      localStorage.setItem(
        "UserData",
        //@ts-ignore
        JSON.stringify(action.payload.user)
      );
      localStorage.setItem(
        "refresh-token",
        //@ts-ignore
        action.payload.refresh,
      );
      //@ts-ignore
      state.data = action.payload;
    },
    setIsAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setUserData, setIsAuthenticated, setIsAuthLoading } =
  accountSlice.actions;
export default accountSlice.reducer;
