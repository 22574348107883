import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import http from "../../services/http";
import {
  CryptoResponseState,
  SingleAssetHistoryType,
  SingleAssetMarketsType,
} from "./interfaces";

export const fetchSingleAsset = createAsyncThunk<
  { data: null },
  string | undefined,
  { rejectValue: any }
>("asset", async (name, thunkAPI) => {
  try {
    const response = await axios.get(
      `https://api.coincap.io/v2/assets/${name}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSingleAssetHistory = createAsyncThunk<
  { data: null },
  SingleAssetHistoryType,
  { rejectValue: any }
>("asset-history", async (requestData, thunkAPI) => {
  try {
    const response = await axios.get(
      `https://api.coincap.io/v2/assets/${requestData.name}/history?interval=${requestData.interval}&start=${requestData.start}&end=${requestData.end}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSingleAssetMarkets = createAsyncThunk<
  { data: null },
  SingleAssetMarketsType,
  { rejectValue: any }
>("asset-markets", async (name, thunkAPI) => {
  try {
    const response = await axios.get(
      `https://api.coincap.io/v2/assets/${name}/markets`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSingleAssetPerformance = createAsyncThunk<
  { data: null },
  number,
  { rejectValue: any }
>("asset-performance", async (coin_id, thunkAPI) => {
  try {
    const response = await http.post(`/crypto/coin-performance-api/`, {
      coin_id,
    });
    const data = response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCryptoInfluencers = createAsyncThunk<
  any,
  number,
  { rejectValue: any }
>("crypto Influencers", async (coinId, thunkAPI) => {
  try {
    const url = `/crypto/get_top_influencers_coin/?coin_id=${coinId}`;
    return http.get(url).then((response) => {
      return response.data;
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptoResponseState = {
  analyticsSelectedCoin: 3,
  analyticsSelectedNewsTab: "",
  analyticsSelectedTopNewsTab: "social",
  coin: null,
  coinHistory: null,
  coinInfluencers: null,
  coinMarkets: null,
  coinPerformance: null,
  marketcapWeightedResult: null,
  tweetsCountResult: null,
  technicalSignalsResult: null,
  coinLoading: false,
  coinHistoryLoading: false,
  coinInfluencersLoading: false,
  coinMarketsLoading: false,
  coinPerformanceLoading: true,
  error: null,
};

export const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    setAnalyticsSelectedCoin: (state, action) => {
      state.analyticsSelectedCoin = action.payload;
    },
    setAnalyticsSelectedNewsTab: (state, action) => {
      state.analyticsSelectedNewsTab = action.payload;
    },
    setAnalyticsSelectedTopNewsTab: (state, action) => {
      state.analyticsSelectedTopNewsTab = action.payload;
    },
    setTweetsCountResult: (state, action) => {
      state.tweetsCountResult = action.payload;
    },
    setMarketcapWeightedResult: (state, action) => {
      state.marketcapWeightedResult = action.payload;
    },
    setTechnicalSignalsResult: (state, action) => {
      state.technicalSignalsResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleAsset.pending, (state) => {
        state.coinLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAsset.fulfilled, (state, action) => {
        state.coinLoading = false;
        state.coin = action.payload;
      })
      .addCase(fetchSingleAsset.rejected, (state, action) => {
        state.coinLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchSingleAssetHistory.pending, (state) => {
        state.coinHistoryLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAssetHistory.fulfilled, (state, action) => {
        state.coinHistoryLoading = false;
        state.coinHistory = action.payload;
      })
      .addCase(fetchSingleAssetHistory.rejected, (state, action) => {
        state.coinHistoryLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchSingleAssetMarkets.pending, (state) => {
        state.coinMarketsLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAssetMarkets.fulfilled, (state, action) => {
        state.coinMarketsLoading = false;
        state.coinMarkets = action.payload;
      })
      .addCase(fetchSingleAssetMarkets.rejected, (state, action) => {
        state.coinMarketsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchSingleAssetPerformance.pending, (state) => {
        state.coinPerformanceLoading = true;
        state.error = null;
      })
      .addCase(fetchSingleAssetPerformance.fulfilled, (state, action) => {
        state.coinPerformanceLoading = false;
        //@ts-ignore
        state.coinPerformance = action.payload.response.data;
      })
      .addCase(fetchSingleAssetPerformance.rejected, (state, action) => {
        state.coinPerformanceLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchCryptoInfluencers.pending, (state) => {
        state.coinInfluencersLoading = true;
        state.error = null;
      })
      .addCase(fetchCryptoInfluencers.fulfilled, (state, action) => {
        state.coinInfluencersLoading = false;
        //@ts-ignore
        state.coinInfluencers = action.payload;
      })
      .addCase(fetchCryptoInfluencers.rejected, (state, action) => {
        state.coinInfluencersLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default cryptoSlice.reducer;

export const {
  setTweetsCountResult,
  setMarketcapWeightedResult,
  setAnalyticsSelectedCoin,
  setAnalyticsSelectedNewsTab,
  setAnalyticsSelectedTopNewsTab,
  setTechnicalSignalsResult,
} = cryptoSlice.actions;
