import ApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

type ChartData = {
  x: string; // The x-axis value (e.g., "Jan")
  y: number | [number, number]; // A single number (for line) or a range (for rangeBar)
};

type Series = {
  name: string; // Name of the series
  type: "rangeBar" | "line"; // Type of the chart for this series
  data: ChartData[]; // Array of data points
};
type LineBarChartProps = {
  series: Series[];
};

export const LineBarChartAgent = ({ series }: LineBarChartProps) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const botDetial = useSelector((state: any) => state.BotSlice.BotDetial);
  const categories = botDetial?.map((item: any) => {
    const date = new Date(item.created_at);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");

    // Format the output as "YYYY-MM-DD HH:mm"
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  });
  const chartOption: ApexOptions = {
    colors: ["rgba(0,0,174,0)", "#00AEEF"],
    chart: {
      type: "rangeBar",
      toolbar: {
        tools: {
          zoom: true, // Enables zoom functionality
          zoomin: true,
          zoomout: true,
          reset: true, // Adds a reset zoom button
        },
      },
      zoom: {
        enabled: true,
        type: "xy", // Allows zooming on both x and y axes
        autoScaleYaxis: true, // Automatically adjusts y-axis while zooming
      },
      animations: {
        easing: "easeout",
      },
    },
    markers: {
      size: 4,
      colors: ["#fff"],
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        colors: {},
      },
    },
    fill: {
      colors: series.flatMap((item, seriesIndex) =>
        item.data?.map((dataPoint, dataPointIndex) => {
          if (item.type === "rangeBar" && Array.isArray(dataPoint.y)) {
            const profitOrLoss = dataPoint.y[0] - dataPoint.y[1];
            return profitOrLoss > 0 ? "#6aff9f" : "#e74b3b"; // Green for profit, Red for loss
          }
          // Default color
        })
      ),
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    xaxis: {
      type: "category",
      categories: categories || [],
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: "balance",
        },
      },
      {
        opposite: false,
        title: {
          text: "loss or profit",
        },
      },
    ],
  };

  return (
    <div>
      <ApexChart
        options={chartOption}
        series={series}
        type="rangeBar"
        height={300}
        width={downLg ? 350 : 750}
      />
    </div>
  );
};
