import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import styles from "./guage.module.scss";

const HalfGuage: React.FC<{
  value: number;
  isEmpty?: boolean;
  name?: string;
}> = ({ value, isEmpty, name }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box className={styles.halfGaugeWrapper}>
      <Typography
        className={styles.halfGaugeValue}
        sx={{
          color: theme.palette.text.primary,
          transform: name == "technical" ? "rotate(-50deg)" : "rotate(50deg)",
          left: name == "technical" ? "auto" : downLg ? "40px" : "90px",
          right: name == "technical" ? (downLg ? "16px" : "28px") : "auto",
          bottom: downLg ? "-10px" : "33px",
        }}
      >
        {isEmpty ? "N/A" : `${value}%`}
      </Typography>
      <GaugeComponent
        type="semicircle"
        value={value ?? 0}
        style={{
          width: downLg ? 170 : 260,
          height: downLg ? 110 : 200,
        }}
        arc={{
          colorArray: isEmpty
            ? [theme.palette.grey["700"]]
            : ["#FA637A", "#CDD931", "#259659"],
          padding: 0,
          width: 0.2,
          gradient: true,
        }}
        pointer={{
          animate: false,
          length: isEmpty ? 0 : 0.65,
          width: isEmpty ? 0 : 10,
          color: theme.palette.text.primary,
        }}
        labels={{
          valueLabel: {
            hide: true,
          },
          tickLabels: {
            hideMinMax: true,
          },
        }}
      />
    </Box>
  );
};

export default HalfGuage;
