import moment from "moment";

export const convertTransaction = (
  transactions: any,
  walletAddress: string,
) => {
  const result = [];

  if (transactions.length > 0) {
    for (const transaction of transactions) {
      const from = transaction.events.find(
        (event: any) =>
          event.type === "utxo_input" || event.type === "transfer",
      )?.source;
      const to = transaction.events.find(
        (event: any) =>
          event.type === "utxo_output" || event.type === "transfer",
      )?.destination;
      const date = moment.unix(transaction.date).format("DD MMM YYYY");
      const value =
        transaction.events.find(
          (event: any) =>
            event.type === "utxo_output" || event.type === "transfer",
        )?.amount /
        Math.pow(
          10,
          transaction.events.find(
            (event: any) =>
              event.type === "utxo_output" || event.type === "transfer",
          )?.decimals ?? 0,
        );
      const asset = transaction.events.find(
        (event: any) =>
          event.type === "utxo_output" || event.type === "transfer",
      )?.denomination;
      const type = walletAddress === to ? "Receive" : "Deposit";

      result.push({
        from,
        to,
        date,
        value,
        asset,
        type,
      });
    }

    return result.filter((result: any) => result.from !== undefined);
  } else {
    return [];
  }
};
