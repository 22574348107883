import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboardLayout";
import styles from "./success.module.scss";
import { Slide } from "react-awesome-reveal";
import { MdCheckCircle } from "react-icons/md";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../features/store";
import {
  confirmPayment,
  createSubscription,
} from "../../../features/membership/membershipSlice";
import toast from "react-hot-toast";

const Success: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { hash } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const subscriptionData = useSelector(
    (state: RootState) => state.membership.subscriptionData
  );

  useEffect(() => {
    if (hash) {
      dispatch(confirmPayment(hash));
    }

    dispatch(createSubscription(subscriptionData))
      .unwrap()
      .then((res) => {
        if (res.status === 201) {
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          "Sorry! We’re facing some issues. Please try again after some time"
        );
      });
  }, []);

  return (
    <DashboardLayout title="Coinfident | Success">
      <Box className={styles.successPaymentContainer}>
        <Container maxWidth="lg">
          <Slide duration={1500} direction="up" triggerOnce>
            <Box
              className={styles.successPayment}
              sx={{
                background: (theme: any) => theme.palette.common.darkPrimary,
                border: "3px solid transparent",
              }}
            >
              <Box>
                <Typography variant="h3" component="h2" mb={2}>
                  Subscription Successful!
                </Typography>
                <Typography variant="body2" mb={4}>
                  Thank you for subscribing! Your subscription is now active.
                  Head back to the home page to explore all the new features
                  we’ve unlocked for you.
                </Typography>
                <Box display={"flex"} gap={2}>
                  <Button variant="contained" onClick={() => navigate("/")}>
                    Go to Home
                  </Button>
                </Box>
              </Box>
              <Box>
                <MdCheckCircle color={theme.palette.success.main} size={100} />
              </Box>
            </Box>
          </Slide>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Success;
