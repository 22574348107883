import { Box, Typography, useTheme } from "@mui/material";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import { GoArrowRight } from "react-icons/go";

const RankingCard = ({
  title,
  rank,
  prev_rank,
}: {
  title: string;
  rank: number;
  prev_rank: number;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "1rem",
        borderRadius: "8px",
        padding: ".6rem .8rem",
        background: (theme: any) => theme.palette.common.agentBg,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Typography
          sx={{
            lineHeight: "1 !important",
            color: theme.palette.text.primary,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: 100,
              lineHeight: "2 !important",
              color: theme.palette.text.primary,
            }}
          >
            Rank In
          </Typography>
          <br />
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "2.5rem",
            fontWeight: 900,
            textShadow:
              theme.palette.mode === "dark"
                ? "3px 3px 10px rgba(0, 0, 0, .3)"
                : "3px 3px 10px rgba(255, 255, 255, .3)",
            color: (theme: any) =>
              prev_rank - rank == 0
                ? theme.palette.common.mute
                : prev_rank - rank < 0
                  ? theme.palette.error.main
                  : theme.palette.success.main,
          }}
        >
          <Typography
            variant="caption"
            sx={{
              display: "inline",
              fontSize: "1.2rem",
              color: (theme: any) =>
                prev_rank - rank == 0
                  ? theme.palette.common.mute
                  : prev_rank - rank < 0
                    ? theme.palette.error.main
                    : theme.palette.success.main,
            }}
          >
            #
          </Typography>
          {rank.toLocaleString()}
        </Typography>

        <Typography
          variant="caption"
          sx={{
            mt: 3,

            display: "flex",
            alignItems: "center",
            padding: ".05rem .7rem",
            borderRadius: "50px",
            background: (theme: any) =>
              prev_rank - rank === 0
                ? theme.palette.common.mute
                : prev_rank - rank < 0
                  ? theme.palette.error.main
                  : theme.palette.success.main,
          }}
        >
          {Math.abs(prev_rank - rank).toLocaleString()}
          {prev_rank - rank === 0 ? (
            <GoArrowRight size={14} style={{ marginLeft: "7px" }} />
          ) : prev_rank - rank < 0 ? (
            <FaArrowTrendDown size={14} style={{ marginLeft: "7px" }} />
          ) : (
            <FaArrowTrendUp size={14} style={{ marginLeft: "7px" }} />
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default RankingCard;
