import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CoinType } from "../interfaces";
import SignalComponent from "./SignalComponent";
import AnalyticsTitle from "../../common/title/AnalyticsTitle";
import InlineComingSoon from "../../common/comingSoon/InlineComingSoon";
import ViewMoreButton from "../../common/buttons/ViewMoreButton";
import Loading from "../../common/loading";

const BuyAndNotBuySignals = ({
  loading,
  data,
  title,
  type,
}: {
  loading: any;
  data: any;
  title: string;
  type: string;
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <AnalyticsTitle
        title={title}
        hasPriceAlert
        isLarge
        info="SPS = Social Prediction Signal"
      />
      {loading ? (
        <Box
          sx={{
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Grid container sx={{ minHeight: "83%" }}>
          <Grid item xs={12} lg={6}>
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                margin: "15px auto",
                display: "block",
              }}
              color={theme.palette.text.primary}
            >
              Social Media
            </Typography>
            {data?.filter(
              (coin: CoinType) => Object.keys(coin.signal).length > 0
            ).length > 0 ? (
              <SignalComponent data={data} type={type} name="social" />
            ) : (
              <InlineComingSoon />
            )}
          </Grid>
          {!downLg && (
            <Grid
              item
              xs={12}
              lg={6}
              sx={{ borderLeft: `1px solid ${theme.palette.grey[800]}` }}
            >
              <Typography
                variant="caption"
                sx={{
                  textAlign: "center",
                  margin: "15px auto",
                  display: "block",
                }}
                color={theme.palette.text.primary}
              >
                Technical
              </Typography>
              {data?.filter(
                (coin: CoinType) => coin.technical_signals !== undefined
              ).length > 0 ? (
                <SignalComponent data={data} type={type} name="technical" />
              ) : (
                <InlineComingSoon />
              )}
            </Grid>
          )}
        </Grid>
      )}
      <ViewMoreButton link="/prices" />
    </>
  );
};

export default BuyAndNotBuySignals;
