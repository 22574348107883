import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

interface CustomTooltipProps {
  children: React.ReactElement;
  title: React.ReactElement | string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ children, title }) => {
  const DefaultTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return <DefaultTooltip title={title}>{children}</DefaultTooltip>;
};

export default CustomTooltip;
