import { useState, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { SmallAreaChartProps } from "./interfaces";

const SmallAreaChart: React.FC<SmallAreaChartProps> = ({
  data,
  name,
  color,
}) => {
  const [series, setSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries | undefined
  >([]);

  const options = useMemo(
    () => ({
      chart: {
        type: "area",
        toolbar: { show: false },
        zoom: { enabled: false },
      },
      //@ts-ignore
      colors: [color],
      grid: { show: false },
      dataLabels: { enabled: false },
      legend: { show: false },
      stroke: {
        curve: "smooth",
        width: 1,
      },
      yaxis: {
        type: "numeric",
        labels: { show: false },
        axisBorder: { show: false },
        axisTicks: { show: false },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.7,
          opacityTo: 0.2,
          stops: [0],
        },
      },
      tooltip: { enabled: false },
    }),
    [],
  );

  useEffect(() => {
    setSeries([{ name, data }]);
  }, [data, name]);

  return (
    <ReactApexChart
      //@ts-ignore
      options={options}
      series={series}
      type="area"
      height="90px"
    />
  );
};

export default SmallAreaChart;
