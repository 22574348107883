import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fade } from "react-awesome-reveal";
import {
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineChevronLeft } from "react-icons/md";
import DashboardLayout from "../../../layout/dashboardLayout";
import Stars from "../../../components/common/svg/stars";
import Loading from "../../../components/common/loading";
import ContentContainer from "../../../components/common/contentContainer";
import WalletsList from "../../../components/assetsManagement/connectWallet/walletsList";
import AssetDrawer from "../../../components/assetsManagement/assetDrawer";
import ethIcon from "./../../../assets/images/icons/eth_icon.svg";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { closeAssetsDrawer } from "../../../features/assets/assetsSlice";
import { fetchWalletsList } from "../../../features/assetsManagement/assetsManagementSlice";
import styles from "../assetsManagement.module.scss";

const ConnectWallet = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const open: any = useSelector((state: RootState) => state.assets.openDrawer);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    if (!assetsManagementState.wallets) dispatch(fetchWalletsList());
  }, [assetsManagementState.wallets]);

  return (
    <DashboardLayout title="Coinfident | Asset Tracking">
      <Container
        maxWidth="lg"
        onClick={() => open && dispatch(closeAssetsDrawer())}
      >
        <Box className={styles.assets_management}>
          <Fade triggerOnce>
            <img src={ethIcon} alt="icon" className={styles.eth_logo} />
          </Fade>
          <Fade className={styles.stars}>
            <Stars width={320} height={320} />
          </Fade>
          <AssetDrawer />
          <ContentContainer open={open} type="assets">
            <Typography
              mt={3}
              mb={2}
              variant="h1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              <IconButton
                onClick={() => navigate("/asset-tracking")}
                sx={{
                  marginLeft: "-2rem",
                  marginRight: ".5rem",
                }}
              >
                <MdOutlineChevronLeft size={30} />
              </IconButton>
              Connect Wallet
            </Typography>
            {assetsManagementState.walletsLoading ? (
              <Box>
                <Loading />
              </Box>
            ) : (
              <>
                {assetsManagementState.wallets?.filter(
                  (walletItem: any) => walletItem.category !== "Cash"
                ).length > 0 && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="contained"
                      sx={{
                        minWidth: "350px",
                        padding: "0 !important",
                        "&:hover": {
                          background: theme.palette.secondary.dark,
                        },
                      }}
                    >
                      <Link
                        to="/asset-tracking/connected-wallets"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          padding: "8px 16px",
                        }}
                      >
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: theme.palette.text.primary,
                          }}
                        >
                          Connected Wallets
                          <FaArrowRightLong style={{ marginLeft: "1rem" }} />
                        </Typography>
                      </Link>
                    </Button>
                  </Box>
                )}
                <WalletsList />
              </>
            )}
          </ContentContainer>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default ConnectWallet;
