import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";
import {
  calculateDate,
  calculateUnixTime,
  getCoinId,
  getDatePeriod,
} from "../../utils/functions";
import { RootState } from "../../features/store";
import {
  fetchRssNewsList,
  setIsFilterLoading,
  setNewsPageRssResult,
} from "../../features/news/newsSlice";
import {
  fetchCryptoInfluencers,
  fetchSingleAsset,
  fetchSingleAssetHistory,
  fetchSingleAssetMarkets,
  fetchSingleAssetPerformance,
  setTechnicalSignalsResult,
} from "../../features/crypto/cryptoSlice";
import {
  fetchPriceActionPattern,
  fetchSupportAndResistance,
} from "../../features/crypto/cryptoIndicatorsSlice";
import {
  fetchCoinsStatistics,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import { fetchCryptoTweetCount } from "../../features/crypto/cryptoNews";
import { fetchCoinSignalCount } from "../../features/signal/signalSlice";
import { CryptoPageType } from "./interfaces";
import { fetchCoinOHLCData } from "../../api/cryptoIndicators";
import { tradingSignals } from "../../utils/tradingSignals";
import useAuth from "../../utils/useAuth";
import DesktopView from "./desktopView";
import MobileView from "./mobileView";

const CryptoPageNew = () => {
  const theme = useTheme();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthValid = useAuth();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const account: any = useSelector((state: RootState) => state.account);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const cryptoState: any = useSelector((state: RootState) => state.crypto);
  const newsList: any = useSelector((state: RootState) => state.news);
  const cryptoIndicatorsState: any = useSelector(
    (state: RootState) => state.cryptoIndicators
  );
  const cryptoNewsState: any = useSelector(
    (state: RootState) => state.cryptoNews
  );
  const fullCoins: any = useSelector(
    (state: RootState) => state.cryptos.availableCoins
  );

  const [rssPage, setRssPage] = useState(1);
  const [foundAsset, setFoundAsset] = useState(false);
  const [cryptoChartData, setCryptoChartData] = useState([]);
  const [assetAvailability, setAssetAvailability] = useState(false);
  const [technichalMethod, setTechnichalMethod] = useState<null | string>(null);
  const [showTechnichalMethod, setShowTechnichalMethod] = useState(false);
  const [loadingHistoricalChart, setLoadingHistoricalChart] = useState(false);
  const [cryptoFilter, setCryptoFilter] = useState({
    type: "1H",
    interval: "m1",
  });
  const [socialActivityTimeFrame, setSocialActivityTimeFrame] =
    useState<string>("1W");

  const fetchCoinData = async () => {
    //@ts-ignore
    const response = await dispatch(fetchSingleAsset(params.crypto));
    return response;
  };

  const getCoinData = async (cryptoName: string, currentPrice: string) => {
    const coinOHLC = await fetchCoinOHLCData(cryptoName);

    if (coinOHLC) {
      const open = coinOHLC.map((item: number[]) => Number(item[1]));
      const high = coinOHLC.map((item: number[]) => Number(item[2]));
      const low = coinOHLC.map((item: number[]) => Number(item[3]));
      const close = coinOHLC.map((item: number[]) => Number(item[4]));

      dispatch(
        setTechnicalSignalsResult(
          tradingSignals(open, high, low, close, Number(currentPrice))
        )
      );
    } else {
      dispatch(setTechnicalSignalsResult("No data for this coin"));
    }
  };

  const fetchCoinMarketsData = async () => {
    //@ts-ignore
    const response = await dispatch(fetchSingleAssetMarkets(params.crypto));
    return response;
  };

  const fetchNewsPageRss = (page: number, search: string | undefined) => {
    const requestData = {
      page,
      search,
    };

    //@ts-ignore
    dispatch(fetchRssNewsList(requestData)).then((response: any) => {
      dispatch(
        setNewsPageRssResult({
          count: response.payload.count,
          next: response.payload.next,
          results: response.payload.results,
        })
      );
      dispatch(setIsFilterLoading(false));
    });
  };

  const fetchCoinHistoryData = async () => {
    const requestData = {
      name: params.crypto,
      interval: cryptoFilter.interval,
      start: calculateUnixTime(cryptoFilter.type)?.start,
      end: calculateUnixTime(cryptoFilter.type)?.end,
    };

    //@ts-ignore
    const response = await dispatch(fetchSingleAssetHistory(requestData));
    return response;
  };

  useEffect(() => {
    if (isAuthValid)
      if (!cryptosState.coinsStatistics) {
        //@ts-ignore
        dispatch(fetchCoinsStatistics(1)).then((res) =>
          dispatch(
            setCoinStatistics({
              page: 1,
              result: cryptosState.coinStatisticsState.result.concat(
                res.payload
              ),
            })
          )
        );
      }
  }, [isAuthValid]);

  useEffect(() => {
    if (isAuthValid) {
      if (cryptosState.coinsStatistics) {
        fetchNewsPageRss(
          rssPage,
          cryptosState.coinsStatistics
            .find((coin: any) => coin.full_id == params.crypto)
            ?.label?.toLowerCase()
        );

        dispatch(
          //@ts-ignore
          fetchSingleAssetPerformance(
            cryptosState.coinsStatistics.find(
              (coin: any) => coin.full_id == params.crypto
            )?.coin_id
          )
        );

        dispatch(
          //@ts-ignore
          fetchCryptoInfluencers(
            cryptosState.coinsStatistics.find(
              (coin: any) => coin.full_id == params.crypto
            )?.coin_id
          )
        );
        fetchCoinData()
          .then((response) => {
            if (response.payload.data) {
              setFoundAsset(true);
              setTimeout(() => {
                getCoinData(
                  response.payload.data.id.toLowerCase(),
                  response.payload.data.priceUsd
                );
              }, 1);
            } else {
              setFoundAsset(false);
              // navigate("/404");
            }
          })
          .catch((err) => {});

        fetchCoinMarketsData();
      }
    }
  }, [cryptosState.coinsStatistics, isAuthValid, params.crypto]);

  useEffect(() => {
    if (cryptosState.coinsStatistics === null) return;
    const lastWeek = {
      duration: getDatePeriod("1W"),
      timeframe: "1W",
      coinId: getCoinId(params.crypto, cryptosState.coinsStatistics),
    };
    const last2Weeks = {
      duration: getDatePeriod("2W"),
      timeframe: "2W",
      coinId: getCoinId(params.crypto, cryptosState.coinsStatistics),
    };
    const lastMonth = {
      duration: getDatePeriod("1M"),
      timeframe: "1M",
      coinId: getCoinId(params.crypto, cryptosState.coinsStatistics),
    };
    const last3Months = {
      duration: getDatePeriod("3M"),
      timeframe: "3M",
      coinId: getCoinId(params.crypto, cryptosState.coinsStatistics),
    };

    if (isAuthValid) {
      // @ts-ignore
      dispatch(fetchCryptoTweetCount(lastWeek));
      // @ts-ignore
      dispatch(fetchCryptoTweetCount(last2Weeks));
      // @ts-ignore
      dispatch(fetchCryptoTweetCount(lastMonth));
      // @ts-ignore
      dispatch(fetchCryptoTweetCount(last3Months));
    }
  }, [isAuthValid, params.crypto, cryptosState.coinsStatistics]);

  useEffect(() => {
    if (cryptosState.availableCoins) {
      setLoadingHistoricalChart(true);
      fetchCoinHistoryData()
        .then((response) => {
          setAssetAvailability(true);
          setCryptoChartData(
            response.payload.data.map(
              (chartItem: { date: string; priceUsd: number }) => ({
                x:
                  cryptoFilter.interval === "m1" ||
                  cryptoFilter.interval === "h1"
                    ? moment(chartItem.date).format("HH:mm")
                    : moment(chartItem.date).format("YYYY/MM/DD"),
                y: Number(chartItem.priceUsd),
              })
            )
          );
          setLoadingHistoricalChart(false);
        })
        .catch((err) => {
          setAssetAvailability(false);
        });
    }
  }, [cryptoFilter, cryptosState.availableCoins, params.crypto]);

  useEffect(() => {
    if (isAuthValid) {
      if (cryptosState.coinsStatistics) {
        dispatch(
          //@ts-ignore
          fetchCoinSignalCount(
            cryptosState.coinsStatistics.find(
              (coin: any) => coin.full_id == params.crypto
            )?.coin_id
          )
        );
      }
    }
  }, [isAuthValid, cryptosState.coinsStatistics, params.crypto]);

  useEffect(() => {
    if (cryptosState.availableCoins && showTechnichalMethod) {
      if (
        cryptoFilter.type !== "1H" &&
        cryptoFilter.type !== "1D" &&
        technichalMethod
      ) {
        const requestData = {
          coin: cryptosState.availableCoins.find(
            (item: CryptoPageType) => item.id === params.crypto
          ).logo,
          start_date: calculateDate(cryptoFilter.type),
          end_date: moment().format("YYYY-MM-DD"),
        };

        if (technichalMethod === "sr")
          //@ts-ignore
          dispatch(fetchSupportAndResistance(requestData));

        if (technichalMethod === "pap")
          //@ts-ignore
          dispatch(fetchPriceActionPattern(requestData));
      }
    }
  }, [
    cryptoFilter,
    cryptosState.availableCoins,
    technichalMethod,
    params.crypto,
  ]);

  return (
    <DashboardLayout
      title={
        params.crypto
          ? `Coinfident | ${
              params.crypto[0].toUpperCase() + params.crypto.slice(1)
            }`
          : "Coinfident"
      }
    >
      <Box sx={{ maxWidth: "2000px", mx: "auto" }}>
        {downLg ? (
          <MobileView
            cryptosState={cryptosState}
            cryptoState={cryptoState}
            assetAvailability={assetAvailability}
            cryptoChartData={cryptoChartData}
            cryptoFilter={cryptoFilter}
            setCryptoFilter={setCryptoFilter}
            cryptoIndicatorsState={cryptoIndicatorsState}
            technichalMethod={technichalMethod}
            setTechnichalMethod={setTechnichalMethod}
            showTechnichalMethod={showTechnichalMethod}
            setShowTechnichalMethod={setShowTechnichalMethod}
            loadingHistoricalChart={loadingHistoricalChart}
            setRssPage={setRssPage}
            influencers={cryptoState.coinInfluencers}
            newsList={newsList}
            cryptoNewsState={cryptoNewsState}
            technical_signals={cryptoState.technicalSignalsResult}
            socialActivityTimeFrame={socialActivityTimeFrame}
            setSocialActivityTimeFrame={setSocialActivityTimeFrame}
          />
        ) : (
          <DesktopView
            cryptosState={cryptosState}
            cryptoState={cryptoState}
            assetAvailability={assetAvailability}
            cryptoChartData={cryptoChartData}
            cryptoFilter={cryptoFilter}
            setCryptoFilter={setCryptoFilter}
            cryptoIndicatorsState={cryptoIndicatorsState}
            technichalMethod={technichalMethod}
            setTechnichalMethod={setTechnichalMethod}
            showTechnichalMethod={showTechnichalMethod}
            setShowTechnichalMethod={setShowTechnichalMethod}
            loadingHistoricalChart={loadingHistoricalChart}
            setRssPage={setRssPage}
            influencers={cryptoState.coinInfluencers}
            newsList={newsList}
            cryptoNewsState={cryptoNewsState}
            technical_signals={cryptoState.technicalSignalsResult}
            socialActivityTimeFrame={socialActivityTimeFrame}
            setSocialActivityTimeFrame={setSocialActivityTimeFrame}
          />
        )}
      </Box>
    </DashboardLayout>
  );
};

export default CryptoPageNew;
