import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const AuthTitle: React.FC<{ children: string; margin?: string }> = ({
  children,
  margin,
}) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: 500,
          margin: margin ? margin : "65px 0 100px",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default AuthTitle;
