import { Button } from "@mui/material";
import { FaAngleUp } from "react-icons/fa";
import styles from "./scrollupBtn.module.scss";

interface ScrollupBtnProps {
  onClick: () => void;
}

const ScrollupBtn: React.FC<ScrollupBtnProps> = ({ onClick }) => {
  return (
    <Button
      variant={"contained"}
      onClick={onClick}
      className={styles["fab_button"]}
    >
      <FaAngleUp size={22} />
    </Button>
  );
};

export default ScrollupBtn;
