import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { NewsState, RssNewsListProps, TweetsListProps } from "./interfaces";

export const fetchRssNewsList = createAsyncThunk<
  { data: null },
  RssNewsListProps,
  { rejectValue: any }
>("RSS News", async (requestData, thunkAPI) => {
  try {
    const response = await http.get(
      `/news/news_content/?page=${requestData.page}${
        requestData.search ? `&search=['${requestData.search}']` : "&search="
      }`,
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTweetsList = createAsyncThunk<
  { data: null },
  TweetsListProps,
  { rejectValue: any }
>("tweets", async (requestData, thunkAPI) => {
  try {
    const response = await http.get(
      `/crypto/get-tweets-list/?page=${requestData.page}&ordering=${
        requestData.ordering
      }${requestData.selectedCoins ? `&${requestData.selectedCoins}` : ""}`,
    );
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTopTweets = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("top news", async (_, thunkAPI) => {
  try {
    const response = await http.get("/crypto/get-top-tweets/");
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchTopRssNews = createAsyncThunk<
  { data: null },
  void,
  { rejectValue: any }
>("Top RSS News", async (_, thunkAPI) => {
  try {
    const response = await http.get(`/news/news_content/?page=1`);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: NewsState = {
  newsPageFilter: null,
  tweetsDisplayResult: null,
  rssDisplayResult: null,
  tweets: null,
  rssNews: null,
  tweetsLoading: false,
  isFilterLoading: false,
  topTweets: null,
  topRssNews: null,
  rssNewsLoading: true,
  topTweetsLoading: true,
  topRssNewsLoading: true,
  error: null,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNewsPageTweetsResult: (state, action) => {
      state.tweetsDisplayResult = {
        count: action.payload.count,
        next: action.payload.next,
        results: action.payload.results,
      };
    },
    setNewsPageRssResult: (state, action) => {
      state.rssDisplayResult = {
        count: action.payload.count,
        next: action.payload.next,
        results: action.payload.results,
      };
    },
    setNewsPageFilter: (state, action) => {
      if (action.payload.type === "Twitter") {
        state.newsPageFilter = {
          ...state.newsPageFilter,
          twitterFilters: action.payload.filter,
        };
      } else {
        state.newsPageFilter = {
          ...state.newsPageFilter,
          rssFilters: action.payload.filter,
        };
      }
    },
    setIsFilterLoading: (state, action) => {
      state.isFilterLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTweetsList.pending, (state) => {
        state.tweetsLoading = true;
        state.error = null;
      })
      .addCase(fetchTweetsList.fulfilled, (state, action) => {
        state.tweetsLoading = false;
        //@ts-ignore
        state.tweets = action.payload;
      })
      .addCase(fetchTweetsList.rejected, (state, action) => {
        state.tweetsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchTopTweets.pending, (state) => {
        state.topTweetsLoading = true;
        state.error = null;
      })
      .addCase(fetchTopTweets.fulfilled, (state, action) => {
        state.topTweetsLoading = false;
        //@ts-ignore
        state.topTweets = action.payload;
      })
      .addCase(fetchTopTweets.rejected, (state, action) => {
        state.topTweetsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchRssNewsList.pending, (state) => {
        state.rssNewsLoading = true;
        state.error = null;
      })
      .addCase(fetchRssNewsList.fulfilled, (state, action) => {
        state.rssNewsLoading = false;
        //@ts-ignore
        state.rssNews = action.payload;
      })
      .addCase(fetchRssNewsList.rejected, (state, action) => {
        state.rssNewsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchTopRssNews.pending, (state) => {
        state.topRssNewsLoading = true;
        state.error = null;
      })
      .addCase(fetchTopRssNews.fulfilled, (state, action) => {
        state.topRssNewsLoading = false;
        //@ts-ignore
        state.topRssNews = action.payload;
      })
      .addCase(fetchTopRssNews.rejected, (state, action) => {
        state.topRssNewsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default newsSlice.reducer;

export const {
  setNewsPageTweetsResult,
  setNewsPageRssResult,
  setNewsPageFilter,
  setIsFilterLoading,
} = newsSlice.actions;
