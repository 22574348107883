import { Box, CircularProgress, useTheme } from "@mui/material";
import loading from "../../../assets/images/icons/loading.gif";
import styles from "./loading.module.scss";

const Loading: React.FC<{
  hasBackground?: boolean;
  size?: number | string;
}> = ({ hasBackground, size }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: hasBackground
          ? theme.palette.background.paper
          : "transparent",
      }}
      className={hasBackground ? "default-card-style" : ""}
    >
      <Box className={styles.loading}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Loading;
