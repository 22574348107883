//@ts-nocheck
import { useEffect, useRef } from "react";
import * as d3 from "d3";
import _, { isEmpty } from "lodash";
import { useMediaQuery, useTheme } from "@mui/material";

const CoOccurrence: React.FC<CoOccurenceProps> = ({ data }) => {
  const theme = useTheme();
  const graphRef = useRef();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const diameter = downLg ? 340 : 420;
  const radius = diameter / 2;
  const innerRadius = radius - 120;

  const cluster = d3.cluster().size([360, innerRadius]);

  const drawChart = () => {
    const line = d3
      .lineRadial()
      .curve(d3.curveBundle.beta(0.85))
      .radius((d) => d.y)
      .angle((d) => (d.x / 180) * Math.PI);

    const svg = d3
      .select(graphRef.current)
      .append("svg")
      .attr("width", diameter)
      .attr("height", diameter)
      .append("g")
      .attr("transform", `translate(${radius},${radius})`);

    let link = svg.append("g").selectAll(".link");
    let node = svg.append("g").selectAll(".node");

    const root = packageHierarchy(data).sum((d) => d.size);

    cluster(root);

    link = link
      .data(packageImports(root.leaves()))
      .enter()
      .append("path")
      .each((d) => {
        d.source = d[0];
        d.target = d[d.length - 1];
      })
      .attr("class", "link")
      .attr("d", line);

    node = node
      .data(root?.leaves())
      .enter()
      .append("text")
      .attr("class", "node")
      .attr("dy", "0.31em")
      .attr(
        "transform",
        (d) =>
          `rotate(${d.x - 90})translate(${d.y + 8},0)${
            d.x < 180 ? "" : "rotate(180)"
          }`,
      )
      .attr("text-anchor", (d) => (d.x < 180 ? "start" : "end"))
      .attr("fill", "#00D9A5")
      .text((d) => d.data.key)
      .on("mouseover", mouseOvered)
      .on("mouseout", mouseOuted);

    // function for handle mouse over event
    function mouseOvered(d) {
      node.each((n) => (n.target = n.source = false));

      link
        // .classed('link--target', (l) => {
        //   if (l.target.data.name === d.target.innerHTML) return (l.source.source = true);
        // })
        .classed("link--source", (l) => {
          if (l.source?.data?.name === d.target.innerHTML)
            return (l.target.target = true);
        })
        .filter(
          (l) =>
            l.target?.data?.name === d.target.innerHTML ||
            l.source?.data?.name === d.target.innerHTML,
        )
        .raise();

      node.classed("node--target", (n) => n.target);
      // .classed('node--source', (n) => n.source);
    }

    // function for handle mouse out event
    function mouseOuted(d) {
      link.classed("link--target", false).classed("link--source", false);
      node.classed("node--target", false).classed("node--source", false);
    }
  };

  // Lazily construct the package hierarchy from class names.
  function packageHierarchy(classes) {
    const map = {};

    const clonedClasses = _.cloneDeep(classes);

    function find(name, d3Data) {
      let node = map[name];
      let i;
      if (!node) {
        node = map[name] = d3Data || { name, children: [] };
        if (name?.length) {
          node.parent = find(name?.substring(0, (i = name?.lastIndexOf("."))));
          node?.parent?.children?.push(node);
          node.key = name?.substring(i + 1);
        }
      }
      return node;
    }

    clonedClasses?.forEach((d) => {
      find(d?.name, d);
    });

    return d3.hierarchy(map[""]);
  }

  // Return a list of imports for the given array of nodes.
  function packageImports(nodes) {
    const map = {};
    const imports = [];

    // Compute a map from name to node.
    nodes?.forEach((d) => {
      map[d?.data?.name] = d;
    });

    // For each import, construct a link from the source to target node.
    nodes.forEach((d) => {
      if (d.data.imports)
        d.data.imports.forEach((i) => {
          imports.push(map[d.data.name].path(map[i]));
        });
    });

    return imports;
  }

  useEffect(() => {
    if (!isEmpty(data)) {
      drawChart();
    }
  }, [data[Symbol]]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }} ref={graphRef} />
  );
};

export default CoOccurrence;
