import { createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import http from "../../services/http";
import { CryptoStaticsState } from "../../features/crypto/interfaces";
import { createSlice } from "@reduxjs/toolkit";

interface FetchCryptoStaticsArgs {
  coinId: number;
  startDate: string;
  endDate: string;
  timeFrame: string;
}

export const fetchCryptoStaticsData = createAsyncThunk<
  any,
  FetchCryptoStaticsArgs,
  { rejectValue: any }
>("crypto/fetchStatistics", async (requestData, thunkAPI) => {
  try {
    const url = `crypto/all-statistics/?start_date=${requestData.startDate}&end_date=${requestData.endDate}&coin_id=${requestData.coinId}`;
    const response = await http.get(url);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptoStaticsState = {
  coinStatisticsLoading: false,
  coinStatisticsState: {
    "1W": null,
    "2W": null,
    "1M": null,
    "3M": null,
  },
  error: null,
};

const cryptoStaticsSlice = createSlice({
  name: "cryptoStatics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoStaticsData.pending, (state) => {
        state.coinStatisticsLoading = true;
        state.error = null;
      })
      .addCase(fetchCryptoStaticsData.fulfilled, (state, action) => {
        const timeFrame = action?.meta.arg.timeFrame;
        state.coinStatisticsLoading = false;
        state.coinStatisticsState[timeFrame] = action.payload;
      })
      .addCase(
        fetchCryptoStaticsData.rejected,
        (state, action: PayloadAction<any>) => {
          state.coinStatisticsLoading = false;
          state.error = action.payload || "An error occurred";
        }
      );
  },
});

export const cryptoStaticsReducer = cryptoStaticsSlice.reducer;
