import { Box, Grid, useTheme } from "@mui/material";
import InfluencerTitle from "../../common/title/InfluencerTitle";
import RankingCard from "./rankingCard";
import { RankingCardProps } from "../interfaces";
import Loading from "../../common/loading";

const Ranking = ({ period, setPeriod, data, loading }: RankingCardProps) => {
  const theme = useTheme();

  const cards = [
    {
      title: "Buy SPS",
      rank: data?.latest_rank?.buy_signal_rank,
      prev_rank: data?.previous_rank?.buy_signal_rank,
    },
    {
      title: "Not-Buy SPS",
      rank: data?.latest_rank?.not_buy_signal_rank,
      prev_rank: data?.previous_rank?.not_buy_signal_rank,
    },
    {
      title: "Positive Sentiment",
      rank: data?.latest_rank?.positive_sentiment_rank,
      prev_rank: data?.previous_rank?.positive_sentiment_rank,
    },
    {
      title: "Negative Sentiment",
      rank: data?.latest_rank?.negative_sentiment_rank,
      prev_rank: data?.previous_rank?.negative_sentiment_rank,
    },
    {
      title: "BTC Tweets",
      rank: data?.latest_rank?.btc_tweets_rank,
      prev_rank: data?.previous_rank?.btc_tweets_rank,
    },
    {
      title: "Follower Count",
      rank: data?.latest_rank?.follower_count_rank,
      prev_rank: data?.previous_rank?.follower_count_rank,
    },
    {
      title: "Crypto-Related Tweet Count",
      rank: data?.latest_rank?.relevant_tweets_rank,
      prev_rank: data?.previous_rank?.relevant_tweets_rank,
    },
    {
      title: "Total Tweets",
      rank: data?.latest_rank?.total_tweets_rank,
      prev_rank: data?.previous_rank?.total_tweets_rank,
    },
  ];

  return (
    <Box
      sx={{
        borderRadius: "8px",
        overflow: "hidden",
        background: theme.palette.primary.light,
        border: (theme: any) => `1px solid ${theme.palette.common.darkPrimary}`,
      }}
    >
      <InfluencerTitle
        title="Influencer Ranks"
        selectedDate={period}
        setSelectedDate={setPeriod}
      />

      {loading ? (
        <Box minHeight={172}>
          <Loading />
        </Box>
      ) : (
        <Grid
          container
          gap={0.3}
          rowGap={1}
          justifyContent="space-between"
          p={2}
        >
          {cards.map((card: any, idx: number) => (
            <Grid item lg={2.93} xs={12} key={idx}>
              <RankingCard
                title={card.title}
                rank={card.rank}
                prev_rank={card.prev_rank}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Ranking;
