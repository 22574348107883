import { Box, Typography, useTheme } from "@mui/material";
import GaugeComponent from "react-gauge-component";
import styles from "./guage.module.scss";
import { useMemo } from "react";

const DefaultGauge: React.FC<{
  value: number;
  hasBackground?: boolean;
  isEmpty?: boolean;
  isBotChecker?: boolean;
  type?: string;
  name?: string;
  width?: string | number;
  height?: string | number;
}> = ({
  value,
  hasBackground,
  isEmpty,
  isBotChecker,
  type,
  name,
  width,
  height,
}) => {
  const theme = useTheme();

  const gaugeWidth = (): string | number => {
    if (width) {
      return width;
    } else if (type === "summaryTechnicalSignal") {
      return 300;
    } else if (type === "technicalSignal") {
      return 240;
    } else {
      return 200;
    }
  };

  const gaugeHeight = (): string | number => {
    if (height) {
      return height;
    } else if (type === "summaryTechnicalSignal") {
      return 140;
    } else {
      return 130;
    }
  };

  return (
    <Box className={styles.sentimentGaugeWrapper}>
      {isBotChecker ? (
        <>
          <Typography
            className={styles.sentimentBotText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
            }}
          >
            Bot
          </Typography>
          <Typography
            className={styles.sentimentNotBotText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
            }}
          >
            Not Bot
          </Typography>
        </>
      ) : (
        <>
          <Typography
            className={styles.sentimentNotBuyText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
              paddingLeft: name == "technical" ? "33px" : "23px",
              bottom:
                type === "summaryTechnicalSignal"
                  ? "-18px"
                  : type === "technicalSignal"
                    ? "-3px"
                    : "17px",
            }}
          >
            {name == "technical" ? "Sell" : "Not buy"}
          </Typography>
          <Typography
            className={styles.sentimentBuyText}
            sx={{
              color: hasBackground
                ? theme.palette.common.white
                : theme.palette.text.primary,
              bottom:
                type === "summaryTechnicalSignal"
                  ? "-18px"
                  : type === "technicalSignal"
                    ? "-3px"
                    : "17px",
            }}
          >
            Buy
          </Typography>
        </>
      )}
      <Typography
        className={styles.sentimentValue}
        sx={{
          color: hasBackground
            ? //@ts-ignore
              theme.palette.common.white
            : theme.palette.text.primary,
          bottom:
            type === "summaryTechnicalSignal"
              ? "-28px"
              : type === "technicalSignal"
                ? "-15px"
                : "10px",
        }}
      >
        {isEmpty ? "N/A" : `${value}%`}
      </Typography>
      <GaugeComponent
        type="semicircle"
        value={value ?? 0}
        style={{
          width: gaugeWidth(),
          height: gaugeHeight(),
        }}
        arc={{
          colorArray: isEmpty
            ? [theme.palette.grey["700"]]
            : isBotChecker
              ? ["#259659", "#CDD931", "#FA637A"]
              : ["#FA637A", "#CDD931", "#259659"],
          padding: 0,
          width: 0.2,
          gradient: true,
        }}
        pointer={{
          animate: false,
          length: isEmpty ? 0 : 0.65,
          width: isEmpty ? 0 : 10,
          //@ts-ignore
          color: hasBackground
            ? //@ts-ignore
              theme.palette.common.white
            : theme.palette.text.primary,
        }}
        labels={{
          valueLabel: {
            hide: true,
          },
          tickLabels: {
            hideMinMax: true,
          },
        }}
      />
    </Box>
  );
};

export default DefaultGauge;
