export const subscriptionFeaturesInfo: any = {
  "Influencer-Explorer":
    "Access tailored insights on crypto influencers directly from the Coinfident database.",
  Alert: "Set custom alerts for your favorite coins.",
  "Chain-Gaurdian":
    "Validate wallet addresses and avoid bots and wallet poisoning",
  CoinAlysis:
    "Get the most updated Price and Technical Indicators abouts coins",
  "Crypto-Q&A":
    "Find answers for all of your questions about Defi and Security in Crypto market",
  Trade: "Automate your trading",
  "Assets-Tracking":
    "Manage your assets by adding them manually or connecting your wallets",
  "Prediction-Agent":
    "Do market analysis in easy way and create custom charts based on technical, social and macroeconomic factors",
  "Trade-360": "Find all-in-one solution for mastering the crypto market",
  TruthLens: "Twitter fact-checking assistant",
  "Alert-limit": "Maximum number of alerts you can create.",
  "CAVA-limit": "Daily limit of requests you can send to our agents.",
  "Trade-Bot-Limit": "Maximum number of trade bots you can configure.",
};
