import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { convertCoins } from "../../utils/functions";
import { CryptosResponseState } from "./interfaces";

export const fetchAvailableCoins = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("available coins", async (_, thunkAPI) => {
  try {
    const url = "/crypto/get-coin-list/";
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

// export const fetchCoinsStatistics = createAsyncThunk<
//   any,
//   void,
//   { rejectValue: any }
// >("coins statistics", async (_, thunkAPI) => {
//   try {
//     const url = `/crypto/get-coins-statistics/?page_size=100`;
//     const response = await http.get(url);
//     const data = convertCoins(Object.values(response.data.response.data.coins));
//     return { data, overall: response.data.response.data["overall_signal"] };
//   } catch (error: any) {
//     if (error.response.status === 404) {
//       thunkAPI.dispatch(fetchCoinsStatistics());
//     }
//     return thunkAPI.rejectWithValue(error);
//   }
// });

export const fetchCoinsStatistics = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("coins statistics", async (_, thunkAPI) => {
  const url = `/crypto/get-coins-statistics/?page_size=100`;

  const fetchData = async (retries: number): Promise<any> => {
    try {
      const response = await http.get(url);
      const data = convertCoins(
        Object.values(response.data.response.data.coins)
      );
      return { data, overall: response.data.response.data["overall_signal"] };
    } catch (error: any) {
      if (error.response && error.response.status === 404 && retries > 0) {
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 * (4 - retries))
        );
        return fetchData(retries - 1);
      }
      throw error;
    }
  };

  try {
    return await fetchData(3);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchScreenersData = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("screeners", async (requestData, thunkAPI) => {
  try {
    const url = `/crypto/get-coins-statistics/`;
    const response = await http.get(url, {
      params: {
        page_size: 100,
        start_date: requestData.duration.startDate,
        end_date: requestData.duration.endDate,
      },
    });
    const data = convertCoins(Object.values(response.data.response.data.coins));
    return {
      [requestData.timeframe]: data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({
      error,
      timeframe: requestData.timeframe,
    });
  }
});

export const fetchTreemapData = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("treemap", async (requestData, thunkAPI) => {
  try {
    const url = `/crypto/price-tweet/?start_date=${requestData.duration.startDate}&end_date=${requestData.duration.endDate}`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return { [requestData.timeframe]: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchCoinsMarketcapWeighted = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("coins marketcap weighted", async (_, thunkAPI) => {
  try {
    const url = `/crypto/get-innovative-parameter/`;
    const response = await http.get(url);
    //@ts-ignore
    const data = response.data.response.data;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchPinnedCoins = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("pinned coins", async (_, thunkAPI) => {
  try {
    const url = `/crypto/pinned-coin-list/`;
    const response = await http.get(url);
    //@ts-ignore
    const data = response.data.response.data.results;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchSPSChanges = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("sps changes", async (_, thunkAPI) => {
  try {
    const url = `/crypto/get-sps-changes/`;
    const response = await http.get(url);
    const data = response.data?.coins_sps;
    return data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptosResponseState = {
  coinStatisticsState: {
    page: 1,
    result: [],
  },
  overallSignal: null,
  coinsStatistics: null,
  screeners: {},
  treemapData: {},
  availableCoins: null,
  marketcapWeighted: null,
  pinnedCoins: null,
  SPSHistory: null,
  spsChanges: null,
  marketcapWeightedLoading: true,
  coinsStatisticsLoading: true,
  screenersLoading: { "3H": true, "6H": true, "12H": true, "24H": true },
  treemapDataLoading: { "24H": true, "3D": true, "1W": true, "1M": true },
  availableCoinsLoading: false,
  pinnedCoinsLoading: true,
  SPSHistoryLoading: true,
  spsChangesLoading: true,
  error: null,
};

export const cryptosSlice = createSlice({
  name: "coins",
  initialState,
  reducers: {
    setCoinStatistics: (state, action) => {
      state.coinStatisticsState = {
        page: action.payload.page,
        result: action.payload.result,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailableCoins.pending, (state) => {
        state.availableCoinsLoading = true;
        state.error = null;
      })
      .addCase(fetchAvailableCoins.fulfilled, (state, action) => {
        state.availableCoinsLoading = false;
        state.availableCoins = action.payload;
      })
      .addCase(fetchAvailableCoins.rejected, (state, action) => {
        state.availableCoinsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinsStatistics.pending, (state) => {
        state.coinsStatisticsLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinsStatistics.fulfilled, (state, action) => {
        state.coinsStatisticsLoading = false;
        state.coinsStatistics = action.payload.data;
        state.overallSignal = action.payload.overall;
        state.coinStatisticsState = { page: 1, result: action.payload };
      })
      .addCase(fetchCoinsStatistics.rejected, (state, action) => {
        state.coinsStatisticsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchScreenersData.pending, (state, action) => {
        state.screenersLoading = {
          ...state.screenersLoading,
          [action.meta.arg.timeframe]: true,
        };
        state.error = null;
      })
      .addCase(fetchScreenersData.fulfilled, (state, action) => {
        state.screeners = { ...state.screeners, ...action.payload };
        state.screenersLoading = {
          ...state.screenersLoading,
          [action.meta.arg.timeframe]: false,
        };
      })
      .addCase(fetchScreenersData.rejected, (state, action) => {
        state.screenersLoading = {
          ...state.screenersLoading,
          [action.meta.arg.timeframe]: false,
        };
        state.screeners = {
          ...state.screeners,
          [action.meta.arg.timeframe]: [],
        };
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchTreemapData.pending, (state, action) => {
        state.treemapDataLoading = {
          ...state.treemapDataLoading,
          [action.meta.arg.timeframe]: true,
        };
        state.error = null;
      })
      .addCase(fetchTreemapData.fulfilled, (state, action) => {
        state.treemapData = { ...state.treemapData, ...action.payload };
        state.treemapDataLoading = {
          ...state.treemapDataLoading,
          [action.meta.arg.timeframe]: false,
        };
      })
      .addCase(fetchTreemapData.rejected, (state, action) => {
        state.treemapDataLoading = {
          ...state.treemapDataLoading,
          [action.meta.arg.timeframe]: false,
        };
        state.treemapData = {
          ...state.treemapData,
          [action.meta.arg.timeframe]: [],
        };
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchCoinsMarketcapWeighted.pending, (state) => {
        state.marketcapWeightedLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinsMarketcapWeighted.fulfilled, (state, action) => {
        state.marketcapWeightedLoading = false;
        state.marketcapWeighted = action.payload;
      })
      .addCase(fetchCoinsMarketcapWeighted.rejected, (state, action) => {
        state.marketcapWeightedLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPinnedCoins.pending, (state) => {
        state.pinnedCoinsLoading = true;
        state.error = null;
      })
      .addCase(fetchPinnedCoins.fulfilled, (state, action) => {
        state.pinnedCoinsLoading = false;
        state.pinnedCoins = action.payload;
      })
      .addCase(fetchPinnedCoins.rejected, (state, action) => {
        state.pinnedCoinsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchSPSChanges.pending, (state) => {
        state.spsChangesLoading = true;
      })
      .addCase(fetchSPSChanges.fulfilled, (state, action) => {
        state.spsChanges = action.payload;
        state.spsChangesLoading = false;
      })
      .addCase(fetchSPSChanges.rejected, (state) => {
        state.spsChangesLoading = true;
      });
  },
});
export default cryptosSlice.reducer;

export const { setCoinStatistics } = cryptosSlice.actions;
