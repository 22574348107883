import { FasterEMA, FasterSMA, FasterStochasticRSI } from "trading-signals";
import {
  ao,
  rsi,
  stoch,
  cci,
  macd,
  willr,
  vwma,
  ichimokuCloud,
} from "indicatorts";

const calculateEMAorSMA = (
  data: any,
  period: any,
  type: any,
  current_price: any,
) => {
  const calculator =
    type === "EMA" ? new FasterEMA(period) : new FasterSMA(period);
  calculator.updates(data);

  return {
    value: calculator.getResult().toFixed(10).valueOf(),
    action: calculate_signal_ma(
      Number(calculator.getResult().toFixed(10).valueOf()),
      current_price,
    ),
  };
};

const calculate_signal_ma = (ma_value: number, price: number) => {
  if (ma_value < price) {
    return "Buy";
  } else if (ma_value > price) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_vwma = (vwma_value: number, price: number) => {
  if (Number.isNaN(vwma_value)) {
    return "_";
  } else if (vwma_value < price) {
    return "Buy";
  } else if (vwma_value > price) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_rsi = (rsi_value: number) => {
  if (rsi_value < 30) {
    return "Buy";
  } else if (rsi_value > 70) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_stoch = (stoch_k_value: number, stoch_d_value: number) => {
  if (stoch_k_value < 20 && stoch_d_value < 20) {
    return "Buy";
  } else if (stoch_k_value > 80 && stoch_d_value > 80) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_cci = (cci_value: number) => {
  if (cci_value < -100) {
    return "Buy";
  } else if (cci_value > 100) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_ao = (ao_value: number) => {
  if (ao_value > 0) {
    return "Buy";
  } else if (ao_value < 0) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_willr = (willr_value: number) => {
  if (willr_value > -80) {
    return "Buy";
  } else if (willr_value < -20) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_macd = (macdLine_value: number, signalLine_value: number) => {
  if (macdLine_value > signalLine_value) {
    return "Buy";
  } else if (macdLine_value < signalLine_value) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_stochRSI = (stochRSI_value: number) => {
  if (stochRSI_value < 20) {
    return "Buy";
  } else if (stochRSI_value > 80) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

const calculate_ichimoku = (
  ichimoku_ssa_value: number,
  ichimoku_ssb_value: number,
) => {
  if (ichimoku_ssb_value < ichimoku_ssa_value) {
    return "Buy";
  } else if (ichimoku_ssb_value > ichimoku_ssa_value) {
    return "Sell";
  } else {
    return "Neutral";
  }
};

export const tradingSignals = (
  open: any,
  high: any,
  low: any,
  close: any,
  current_price: number,
) => {
  // ---------- EMA 10 calculation
  const ema_10_result = calculateEMAorSMA(close, 10, "EMA", current_price);

  // ---------- EMA 20 calculation
  const ema_20_result = calculateEMAorSMA(close, 20, "EMA", current_price);

  // ---------- EMA 30 calculation
  const ema_30_result = calculateEMAorSMA(close, 30, "EMA", current_price);

  // ---------- EMA 50 calculation
  const ema_50_result = calculateEMAorSMA(close, 50, "EMA", current_price);

  // ---------- EMA 100 calculation
  const ema_100_result = calculateEMAorSMA(close, 100, "EMA", current_price);

  // ---------- SMA 10 calculation
  const sma_10_result = calculateEMAorSMA(close, 10, "SMA", current_price);

  // ---------- SMA 20 calculation
  const sma_20_result = calculateEMAorSMA(close, 20, "SMA", current_price);

  // ---------- SMA 30 calculation
  const sma_30_result = calculateEMAorSMA(close, 30, "SMA", current_price);

  // ---------- SMA 50 calculation
  const sma_50_result = calculateEMAorSMA(close, 50, "SMA", current_price);

  // ---------- SMA 100 calculation
  const sma_100_result = calculateEMAorSMA(close, 100, "SMA", current_price);

  // // ---------- VWMA calculation
  // const defaultConfigForVWMA = { period: 20 };
  // const vwma_result = {
  //   value: vwma(close[0], volume[0], defaultConfigForVWMA)[0],
  //   action: calculate_vwma(
  //     Number(vwma(close[0], volume[0], defaultConfigForVWMA)[0]),
  //     current_price
  //   ),
  // };

  // ---------- RSI calculations
  const defaultConfigForRSI = { period: 14 };
  const rsi_14 = rsi(close, defaultConfigForRSI);
  const rsi_sum = rsi_14.reduce((a, c) => a + c, 0);
  const rsi_result = {
    value: (rsi_sum / rsi_14.length).toFixed(2),
    action: calculate_rsi(Number((rsi_sum / rsi_14.length).toFixed(2))),
  };

  // ---------- Stochastic Oscillator calculations
  const defaultConfigForSTOCH = { kPeriod: 14, dPeriod: 3 };
  const { k, d } = stoch(high, low, close, defaultConfigForSTOCH);
  const stoch_k_sum = k.reduce((a, c) => a + c, 0);
  const stoch_d_sum = d.reduce((a, c) => a + c, 0);
  const stoch_result = {
    value: (stoch_k_sum / k.length).toFixed(2),
    action: calculate_stoch(
      Number((stoch_k_sum / k.length).toFixed(2)),
      Number((stoch_d_sum / d.length).toFixed(2)),
    ),
  };

  // ---------- CCI calculations
  const defaultConfigForCCI = { period: 20 };
  const cci_20 = cci(high, low, close, defaultConfigForCCI);
  const filtered_cci_20 = cci_20.filter((value) => !isNaN(value));
  const cci_sum = filtered_cci_20.reduce((a, c) => a + c, 0);
  const cci_result = {
    value: (cci_sum / filtered_cci_20.length).toFixed(2),
    action: calculate_cci(
      Number((cci_sum / filtered_cci_20.length).toFixed(2)),
    ),
  };

  // ---------- AO calculations
  const defaultConfigForAO = { fast: 5, slow: 34 };
  const ao_data = ao(high, low, defaultConfigForAO);
  const ao_sum = ao_data.reduce((a, c) => a + c, 0);
  const ao_result = {
    value: (ao_sum / ao_data.length).toFixed(2),
    action: calculate_ao(Number((ao_sum / ao_data.length).toFixed(2))),
  };

  // ---------- MACD calculations
  const defaultConfigForMACD = { fast: 12, slow: 26, signal: 9 };
  const { macdLine, signalLine } = macd(close, defaultConfigForMACD);

  const macdLine_sum = macdLine.reduce((a, c) => a + c, 0);
  const signalLine_sum = signalLine.reduce((a, c) => a + c, 0);

  const macd_result = {
    value: (signalLine_sum / signalLine.length).toFixed(2),
    action: calculate_macd(
      Number((macdLine_sum / macdLine.length).toFixed(2)),
      Number((signalLine_sum / signalLine.length).toFixed(2)),
    ),
  };

  // ---------- WILLR calculations
  const defaultConfigForwillr = { period: 14 };
  const willr_data = willr(high, low, close, defaultConfigForwillr);
  const willr_sum = willr_data.reduce((a, c) => a + c, 0);
  const willr_result = {
    value: (willr_sum / willr_data.length).toFixed(2),
    action: calculate_willr(Number((willr_sum / willr_data.length).toFixed(2))),
  };

  // ---------- StochRSI calculations
  // @ts-ignore
  const stochRSI = new FasterStochasticRSI(14);
  close.forEach((rsi: number) => {
    stochRSI.update(rsi);
  });
  const stochRSI_result = {
    value: Number(stochRSI.getResult().toFixed(4).valueOf()) * 100,
    action: calculate_stochRSI(
      Number(stochRSI.getResult().toFixed(4).valueOf()) * 100,
    ),
  };

  // ---------- Ichimoku calculations
  const defaultConfig = { short: 9, medium: 26, long: 52, close: 26 };
  const { tenkan, ssa, ssb } = ichimokuCloud(high, low, close, defaultConfig);

  const ichimoku_t_result = (
    tenkan.reduce((a, c) => a + c, 0) / tenkan.length
  ).toFixed(2);
  const ichimoku_ssa_result = ssa.reduce((a, c) => a + c, 0) / ssa.length;
  const ichimoku_ssb_result = ssb.reduce((a, c) => a + c, 0) / ssb.length;

  const ichimoku_result = {
    value: Number(ichimoku_t_result),
    action: calculate_ichimoku(
      Number(ichimoku_ssa_result),
      Number(ichimoku_ssb_result),
    ),
  };

  return {
    moving_averages: {
      "Exponential Moving Average (10)": [
        Number(ema_10_result.value),
        ema_10_result.action,
      ],
      "Simple Moving Average (10)": [sma_10_result.value, sma_10_result.action],

      "Exponential Moving Average (20)": [
        Number(ema_20_result.value),
        ema_20_result.action,
      ],
      "Simple Moving Average (20)": [sma_20_result.value, sma_20_result.action],

      "Exponential Moving Average (30)": [
        Number(ema_30_result.value),
        ema_30_result.action,
      ],
      "Simple Moving Average (30)": [sma_30_result.value, sma_30_result.action],

      "Exponential Moving Average (50)": [
        Number(ema_50_result.value),
        ema_50_result.action,
      ],
      "Simple Moving Average (50)": [
        Number(sma_50_result.value),
        sma_50_result.action,
      ],

      "Exponential Moving Average (100)": [
        Number(ema_100_result.value),
        ema_100_result.action,
      ],
      "Simple Moving Average (100)": [
        Number(sma_100_result.value),
        sma_100_result.action,
      ],

      "Ichimoku Base Line (9, 26, 52, 26)": [
        Number.isNaN(ichimoku_result.value)
          ? "_"
          : Number(ichimoku_result.value),
        ichimoku_result.action,
      ],

      // "Volume Weighted Moving Average (20)": [
      //   Number.isNaN(vwma_result.value) ? "_" : Number(vwma_result.value),
      //   vwma_result.action,
      // ],
    },

    oscillators: {
      "Relative Strength Index": [Number(rsi_result.value), rsi_result.action],
      "Stochastic %K": [Number(stoch_result.value), stoch_result.action],
      "Commodity Channel Index": [Number(cci_result.value), cci_result.action],
      "Awesome Oscillator": [Number(ao_result.value), ao_result.action],
      "MACD Level": [Number(macd_result.value), macd_result.action],
      "Stochastic RSI Fast": [
        Number(stochRSI_result.value),
        stochRSI_result.action,
      ],
      "Williams Percent Range": [
        Number(willr_result.value),
        willr_result.action,
      ],
    },
  };
};
