import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Loading from "../../common/loading";
import MoversMarquee from "../movers";
import styles from "../analytics.module.scss";

const Performers = ({
  moversData,
  type,
}: {
  moversData: any;
  type: string;
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return downLg ? (
    <MoversMarquee moversData={moversData} type={type} />
  ) : (
    <Box
      sx={{
        height: "403px",
        overflowY: "auto",
      }}
      className={`customScrollbar ${
        theme.palette.mode === "light"
          ? "customScrollbar-light"
          : "customScrollbar-dark"
      }`}
    >
      {moversData.loading ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Box>
      ) : moversData.data &&
        //@ts-ignore
        moversData.data[type].length > 1 ? (
        //@ts-ignore
        moversData.data[type]?.map((moverItem, idx) => (
          <Box key={moverItem.name} className={styles.performerWrapper}>
            <Typography
              component="span"
              sx={{
                color:
                  type === "top_gainers"
                    ? (theme: any) => theme.palette.common.greenText
                    : "#FA637A",
              }}
            >
              #{idx + 1}
            </Typography>
            <img src={moverItem.img} alt={moverItem.name} />
            <Stack
              direction="column"
              alignItems="flex-start"
              className={styles.performerInfo}
            >
              <Typography
                component="h5"
                color={theme.palette.text.primary}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {moverItem.name.length > 10
                  ? `${moverItem.name.slice(0, 10)}...`
                  : moverItem.name}
              </Typography>
              <Typography
                component="h6"
                color={(theme: any) => theme.palette.common.mute}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {moverItem.code.length > 10
                  ? `${moverItem.code.slice(0, 10)}...`
                  : moverItem.code}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              alignItems="flex-end"
              className={styles.performerPrice}
            >
              <Typography
                variant="h5"
                color={theme.palette.text.primary}
                sx={{ whiteSpace: "nowrap" }}
              >
                {moverItem.price}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  whiteSpace: "nowrap",
                  color:
                    type === "top_gainers"
                      ? (theme: any) => theme.palette.common.greenText
                      : "#FA637A",
                }}
              >
                {type === "top_gainers" ? "+" : "-"} {moverItem.percentage}
              </Typography>
            </Stack>
          </Box>
        ))
      ) : (
        <Typography
          align="center"
          variant="body2"
          my={2}
          color={theme.palette.text.primary}
        >
          No data
        </Typography>
      )}
    </Box>
  );
};

export default Performers;
