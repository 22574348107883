import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import FeaturesTableProps from "./interface";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import { LuInfo } from "react-icons/lu";
import DefaultTooltip from "../../common/tooltip";
import { subscriptionFeaturesInfo } from "../../../data/pricing";

const tableHead = ["Plans", "Free", "Basic", "Silver", "Gold"];

const FeaturesTable: React.FC<FeaturesTableProps> = ({
  pricesData,
  duration,
}) => {
  const theme = useTheme();

  const membershipState: any = useSelector(
    (state: RootState) => state.membership
  );

  const tableBody = useMemo(() => {
    if (isEmpty(membershipState.productsList)) return;
    const featuresList = Object.keys(
      membershipState.productsList?.at(0)?.metadata
    )?.sort((a, b) => a.localeCompare(b));

    const data = featuresList?.map((feature: string) => {
      const row: any = { feature };
      membershipState.productsList?.forEach((plan: any) => {
        row[plan.name] = plan.metadata[feature];
      });

      return row;
    });

    return data || [];
  }, [membershipState.productsList]);

  const sortedData = useMemo(() => {
    if (isEmpty(tableBody)) return;

    const modifyData = tableBody?.map((feature: any) => {
      let count: number = 0;
      for (const key in feature) {
        if (feature[key] === "True") {
          count += 1;
        }
      }

      return { ...feature, count };
    });

    const sorted = modifyData?.sort((a: any, b: any) => b.count - a.count);

    return sorted;
  }, [tableBody]);

  const sortedPriceData = useMemo(() => {
    const sortOrder = ["basic", "silver", "gold"];

    const sorted = pricesData
      ?.sort(
        (a: any, b: any) =>
          sortOrder.indexOf(a.nickname?.split(" ")?.at(0).toLowerCase()) -
          sortOrder.indexOf(b.nickname?.split(" ")?.at(0).toLowerCase())
      )
      .filter((price: any) => price.nickname !== null);

    return sorted;
  }, [pricesData]);

  const handleValue = (key: string, val: string): React.ReactElement | null => {
    let element: React.ReactElement | null = null;

    if (val.toLowerCase() === "true") {
      element = <FaRegCircleCheck style={{ color: "#2FDB7D" }} />;
    } else if (val.toLowerCase() === "false") {
      element = <CgCloseO size={18} style={{ color: "#FA637A" }} />;
    } else if (key.toLowerCase() === "cava-limit") {
      element = (
        <Typography
          fontSize={"0.9rem"}
          fontWeight={100}
          color={theme.palette.text.primary}
        >
          {val} Requests Per Day
        </Typography>
      );
    } else if (key.toLowerCase() === "trade-bot-limit") {
      element = (
        <Typography
          fontSize={"0.9rem"}
          fontWeight={100}
          color={theme.palette.text.primary}
        >
          {val} Bots Per Month
        </Typography>
      );
    } else {
      element = (
        <Typography
          fontSize={"0.9rem"}
          fontWeight={100}
          color={theme.palette.text.primary}
        >
          {val}
        </Typography>
      );
    }

    return element;
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {tableHead.map((head, index) => (
              <TableCell key={head} align={index === 0 ? "left" : "center"}>
                <Typography
                  fontSize={index === 0 ? "1.5rem" : "0.9rem"}
                  fontWeight={900}
                  color={theme.palette.text.primary}
                >
                  {head}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData?.map((row) => (
            <TableRow
              key={row.feature}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              hover
            >
              <TableCell align="left">
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <Typography
                    fontSize={"0.9rem"}
                    fontWeight={100}
                    color={theme.palette.text.primary}
                  >
                    {row.feature?.split("-")?.join(" ")}
                  </Typography>

                  <DefaultTooltip title={subscriptionFeaturesInfo[row.feature]}>
                    <Typography component={"span"}>
                      <LuInfo
                        style={{ cursor: "pointer" }}
                        color={theme.palette.text.primary}
                        size={12}
                      />
                    </Typography>
                  </DefaultTooltip>
                </Box>
              </TableCell>
              <TableCell align="center">
                {handleValue(row.feature, row.Free)}
              </TableCell>
              <TableCell align="center">
                {handleValue(row.feature, row.Basic)}
              </TableCell>
              <TableCell align="center">
                {handleValue(row.feature, row.Silver)}
              </TableCell>
              <TableCell align="center">
                {handleValue(row.feature, row.Gold)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left">
              <Typography
                fontSize={"1.5rem"}
                fontWeight={900}
                color={theme.palette.text.primary}
              >
                Price
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                color={theme.palette.text.primary}
                fontSize={"1.2rem"}
              >
                $0
              </Typography>
            </TableCell>
            {sortedPriceData?.map((price: any) => (
              <TableCell key={price.nickname} align="center">
                <Typography
                  color={theme.palette.text.primary}
                  fontSize={"1.2rem"}
                >
                  ${(price?.unit_amount / 100).toFixed(0)}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FeaturesTable;
