import { useNavigate } from "react-router-dom";
import coinfidentLogoUrl from "../../../assets/images/logo/Coinfiden New Logo.svg";

const Logo: React.FC<{ isAuth?: boolean; isDashboard?: boolean }> = ({
  isAuth,
  isDashboard = false,
}) => {
  const navigate = useNavigate();

  return (
    <img
      src={coinfidentLogoUrl}
      alt="Coinfident"
      style={{
        cursor: isDashboard ? "pointer" : "auto",
        width: isAuth ? "90%" : "auto",
        maxWidth: isAuth ? "140px" : "380px",
        height: isDashboard ? "29px" : "auto",
        marginTop: ".3rem",
        marginRight: "0px",
      }}
      onClick={() => {
        isDashboard && navigate("/");
      }}
    />
  );
};

export default Logo;
