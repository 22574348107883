import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useAccount, useDisconnect } from "wagmi";
import { Button, Typography, useTheme } from "@mui/material";
import { useWeb3Modal } from "@web3modal/scaffold-react";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../../features/store";
import { fetchWalletsList } from "../../../../../features/assetsManagement/assetsManagementSlice";
import { WalletConnectProps } from "../../../interfaces";
import { addNewWalletHandler } from "../../../../../api/assets";

const WalletConnect: React.FC<WalletConnectProps> = ({ hideInfo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();

  const userState: any = useSelector((state: RootState) => state.account);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isConnected) {
      setLoading(true);
      const findWallet = assetsManagementState.wallets?.findIndex(
        (wallet: any) => wallet.address === address
      );

      if (findWallet < 0) {
        const new_wallet_data = {
          user: userState.data.user.pk,
          name: "WalletConnect",
          category: "walletconnect",
          address: address ?? "",
          protocol: "ethereum",
        };

        addNewWalletHandler(new_wallet_data).then((response) => {
          if (response.status == 201) {
            dispatch(fetchWalletsList());
            disconnect();
            close();
            setLoading(false);
            navigate("/asset-tracking/connected-wallets");
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    }
  }, [isConnected]);

  return (
    <div>
      {!hideInfo && (
        <Typography variant="body2" color={theme.palette.grey[500]} my={2}>
          We are only requesting view permissions. <br /> This does not give us
          access to your private keys nor the ability to move your funds.
        </Typography>
      )}
      <Button
        variant="contained"
        fullWidth
        onClick={() => open()}
        disabled={loading}
        sx={{
          marginTop: "4px",
          "&:hover": {
            background: theme.palette.secondary.main,
          },
        }}
      >
        {loading ? "Connecting ..." : "Connect Wallet"}
      </Button>
    </div>
  );
};

export default WalletConnect;
