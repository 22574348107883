import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
import { arbitrum, mainnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { store } from "./features/store";
import App from "./App";

const projectId = process.env.REACT_APP_WALLETCONNECT_KEY ?? "";
const localeTheme = localStorage.getItem("theme");
const queryClient = new QueryClient();
const metadata = {
  name: "Coinfident",
  description: "Coinfident",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum] as const;
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableWalletConnect: true,
  enableInjected: true,
  enableEIP6963: true,
  enableCoinbase: true,
});

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  //@ts-ignore
  themeMode: localeTheme ?? "dark",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
      </Provider>
    </QueryClientProvider>
  </WagmiProvider>,
);
