import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useTheme } from "@mui/material";
import DashboardLayout from "../../layout/dashboardLayout";

const Features = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <DashboardLayout title="Features">
      <Typography
        component="h2"
        variant="h6"
        align="center"
        sx={{ color: theme.palette.text.primary }}
      >
        Features
      </Typography>

      <Box mt={8}>
        <Typography sx={{ color: theme.palette.text.primary }}>
          Besides the analytics dashboard, Coinfident also offers asset tracking
          services.
          <br />
          Through its asset tracking platform, you can connect your wallets and
          add your cash and cryptocurrencies either manually or automatically.
          This enables you to conveniently view all your assets in one unified
          location.
          <br />
          Coinfident's Asset Tracking is currently accessible exclusively on{" "}
          <strong>Desktop</strong> devices.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/asset-tracking")}
          style={{ display: "block", margin: "2rem auto" }}
        >
          Asset Tracking
        </Button>
      </Box>
    </DashboardLayout>
  );
};

export default Features;
