import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import React from "react";
import { DefaultTooltipProps } from "./interface";

const DefaultTooltip: React.FC<DefaultTooltipProps> = ({
  title,
  placement,
  children,
}) => {
  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      enterTouchDelay={0}
      {...props}
      classes={{ popper: className }}
      placement={placement ?? "top"}
      arrow
      onClick={(e) => e.stopPropagation()}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color:
        //@ts-ignore
        theme.palette.common.backdrop,
      boxShadow: theme.shadows[1],
      fontSize: 10,
      padding: "1rem",
    },
  }));

  return <CustomTooltip title={title}>{children}</CustomTooltip>;
};

export default DefaultTooltip;
