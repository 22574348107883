import {
  Paper,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
} from "@mui/material";
import { TimeFrameProps } from "../interfaces";
import { getDatePeriodTooltip } from "../../../utils/functions";
import CustomTooltip from "../../common/customeTooltip";
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));
const TimeFrame: React.FC<TimeFrameProps> = ({
  timePeriod,
  setTimePeriod,
  color,
  timeFrames,
  isFullChart,
}) => {
  const handleChangeType = (
    _: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setTimePeriod(newValue);
    }
  };

  return (
    <>
      {!isFullChart ? (
        <ToggleButtonGroup
          value={timePeriod}
          exclusive
          onChange={handleChangeType}
        >
          {timeFrames.map((timeFrame: string) => (
            <ToggleButton key={timeFrame} size="small" value={timeFrame}>
              <CustomTooltip title={getDatePeriodTooltip(timeFrame)}>
                <Typography variant="caption" color={color && color}>
                  {timeFrame}
                </Typography>
              </CustomTooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : (
        <Paper
          elevation={0}
          sx={(theme) => ({
            display: "flex",
            border: `1px solid ${theme.palette.divider}`,
            flexWrap: "wrap",
          })}
        >
          <StyledToggleButtonGroup
            size="small"
            value={timePeriod}
            exclusive
            onChange={handleChangeType}
          >
            {/*<ToggleButtonGroup*/}
            {/*  value={timePeriod}*/}
            {/*  exclusive*/}
            {/*  onChange={handleChangeType}*/}
            {/*>*/}
            {timeFrames.map((timeFrame: string) => (
              <ToggleButton key={timeFrame} size="small" value={timeFrame}>
                <CustomTooltip title={getDatePeriodTooltip(timeFrame)}>
                  <Typography variant="caption" color={color && color}>
                    {timeFrame}
                  </Typography>
                </CustomTooltip>
              </ToggleButton>
            ))}
            {/*</ToggleButtonGroup>*/}
          </StyledToggleButtonGroup>
        </Paper>
      )}
    </>
  );
};

export default TimeFrame;
