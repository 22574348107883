import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../features/store";
import { closeLimitAccessModal } from "../../../features/profile/profiletSlice";
import { GiUpgrade } from "react-icons/gi";
import upgradePlanImageLight from "../../../assets/images/upgrade_image_light.svg";
import upgradePlanImageDark from "../../../assets/images/upgrade_image_dark.svg";
import styles from "./limitAccessModal.module.scss";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { isEmpty } from "lodash";
import {
  fetchProductsList,
  fetchSubscriptionsList,
} from "../../../features/membership/membershipSlice";
import useAuth from "../../../utils/useAuth";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import Loading from "../loading";
import { getUniqueValues } from "../../../utils/functions";

const findNextPlan = (currentPlan: string): string => {
  switch (currentPlan) {
    case "free":
      return "basic";
    case "basic":
      return "silver";
    case "silver":
      return "gold";
    case "gold":
      return "gold";
    default:
      return "gold";
  }
};

const sortMetadataByTruthy = (metadata: {}) => {
  const priorityKeys = ["CAVA-limit", "Alert-limit", "Trade-Bot-Limit"];
  const sortedEntries = Object.entries(metadata).sort(
    ([keyA, valueA], [keyB, valueB]) => {
      const isAKeyPriority = priorityKeys.includes(keyA);
      const isBKeyPriority = priorityKeys.includes(keyB);

      if (isAKeyPriority && !isBKeyPriority) return -1;
      if (!isAKeyPriority && isBKeyPriority) return 1;

      const isATruthy = valueA === "True";
      const isBTruthy = valueB === "True";

      if (isATruthy && !isBTruthy) return -1;
      if (!isATruthy && isBTruthy) return 1;

      return 0;
    }
  );

  return Object.fromEntries(sortedEntries.slice(0, 6));
};

type SubscriptionType = {
  current_period_end: string;
  current_period_start: string;
  status: string;
  id: string;
  name: string;
};

const LimitAccessModal: React.FC = () => {
  const isAuthValid = useAuth();
  const profileSlice = useSelector((state: RootState) => state.profile);
  const membershipState = useSelector((state: RootState) => state.membership);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const mode = theme.palette.mode;
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isAuthValid) {
      if (isEmpty(membershipState.productsList)) {
        dispatch(fetchProductsList());
      }
      if (isEmpty(membershipState.subscriptionsList)) {
        dispatch(fetchSubscriptionsList());
      }
    }
  }, [isAuthValid]);

  const nextProductFeatures: any = useMemo(() => {
    if (isEmpty(membershipState.productsList)) return;
    if (isEmpty(membershipState.subscriptionsList)) return;

    const findActiveSubscription: any = membershipState.subscriptionsList?.find(
      (subs: SubscriptionType) =>
        subs.status === "active" || subs.status === "trialing"
    );

    const currentPlan = findActiveSubscription?.plan?.toLowerCase();
    const nextPlan = findNextPlan(findActiveSubscription?.plan?.toLowerCase());

    const currentProduct: any = membershipState.productsList?.find(
      (product: any) => product?.name?.toLowerCase() === currentPlan
    );
    const nextProduct: any = membershipState.productsList?.find(
      (product: any) => product?.name?.toLowerCase() === nextPlan
    );

    const getUniqueMetadata = getUniqueValues(
      currentProduct?.metadata,
      nextProduct?.metadata
    );

    const sortedMetadata = sortMetadataByTruthy(getUniqueMetadata);

    return sortedMetadata;
  }, [
    profileSlice.limitAccessData,
    membershipState.subscriptionsList,
    membershipState.productsList,
  ]);

  const handleValue = (key: string, val: string): React.ReactElement | null => {
    let element: React.ReactElement | null = null;

    if (val.toLowerCase() === "true") {
      element = <FaRegCircleCheck size={50} style={{ color: "#2FDB7D" }} />;
    } else if (val.toLowerCase() === "false") {
      element = <CgCloseO size={50} style={{ color: "#FA637A" }} />;
    } else if (key.toLowerCase() === "cava-limit") {
      element = (
        <Typography variant="h2" color={theme.palette.text.primary}>
          {val}
        </Typography>
      );
    } else if (key.toLowerCase() === "trade-bot-limit") {
      element = (
        <Typography variant="h2" color={theme.palette.text.primary}>
          {val}
        </Typography>
      );
    } else {
      element = (
        <Typography variant="h2" color={theme.palette.text.primary}>
          {val}
        </Typography>
      );
    }

    return element;
  };

  const handleUpgradeClick = (): void => {
    dispatch(closeLimitAccessModal());
    navigate("/subscription");
  };

  return (
    <Modal
      open={profileSlice.isLimitAccess}
      onClose={() => dispatch(closeLimitAccessModal)}
    >
      <Box
        className={styles.modalContainer}
        sx={{
          width: downMd ? "90vw" : "70vw",
          bgcolor: theme.palette.background.default,
        }}
      >
        <Grid container>
          <Grid item md={5} display={downMd ? "none" : "block"}>
            <Box className={styles.imageContainer}>
              <img
                src={
                  mode === "dark" ? upgradePlanImageDark : upgradePlanImageLight
                }
                alt="Upgrade"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box className={styles.contentContainer}>
              <Box className={styles.closeButton}>
                <IconButton onClick={() => dispatch(closeLimitAccessModal())}>
                  <MdClose />
                </IconButton>
              </Box>

              <Box>
                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  color={theme.palette.primary.main}
                  mb={1}
                >
                  UPGRADE YOUR PLAN
                </Typography>
                <Typography variant="body2" color={theme.palette.text.primary}>
                  {profileSlice.limitAccessData?.message}
                </Typography>
              </Box>

              <Box className={styles.features}>
                <Typography variant="h6" color={theme.palette.text.primary}>
                  Features You Can Get by Upgrading:
                </Typography>
                {membershipState.productsListLoading ||
                membershipState.subscriptionsListLoading ? (
                  <Loading />
                ) : (
                  <Box display={"flex"} flexWrap={"wrap"} flexGrow={5} gap={3}>
                    {nextProductFeatures &&
                      Object.entries(nextProductFeatures)?.map(
                        (feature: any) => (
                          <Box flexBasis={"30%"}>
                            {handleValue(feature?.at(0), feature?.at(1))}
                            <Typography
                              fontSize={"0.9rem"}
                              color={theme.palette.text.primary}
                            >
                              {feature?.at(0)?.split("-")?.join(" ")}
                            </Typography>
                            <Typography
                              fontSize={"0.7rem"}
                              color={theme.palette.grey[600]}
                            >
                              {feature.description}
                            </Typography>
                          </Box>
                        )
                      )}
                  </Box>
                )}
              </Box>

              <Box display={"flex"} gap={2}>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={
                    <GiUpgrade size={16} color={theme.palette.common.black} />
                  }
                  onClick={handleUpgradeClick}
                >
                  <Typography
                    variant="caption"
                    color={theme.palette.common.black}
                  >
                    UPGRADE
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default LimitAccessModal;
