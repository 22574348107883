import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { InitialAlertsState } from "./interfaces";
import {
  openLimitAccessModal,
  setLimitAccessData,
} from "../profile/profiletSlice";

const initialState: InitialAlertsState = {
  priceAlerts: null,
  engagementAlerts: null,
  priceAlertsLoading: false,
  engagementAlertsLoading: false,
  error: null,
};

export const fetchEngagementAlerts = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("engagement alerts", async (_, thunkAPI) => {
  try {
    const url = `/alerts/coin-engagement-alert-list/`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error: any) {
    let message: string = "";
    switch (error.response.status) {
      case 406:
        message =
          "You’re currently on our free plan. Upgrade your subscription to unlock additional features and benefits.";
        thunkAPI.dispatch(openLimitAccessModal());
        thunkAPI.dispatch(setLimitAccessData({ message }));
        break;
      case 429:
        if (error.response.data.response.data.code === 430) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else if (error.response.data.response.data.code === 431) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else {
          return thunkAPI.rejectWithValue(error);
        }
        break;
      default:
        return thunkAPI.rejectWithValue(error);
    }
  }
});

export const fetchPriceAlerts = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("price alerts", async (_, thunkAPI) => {
  try {
    const url = `/alerts/coin-price-alert-list/`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return data;
  } catch (error: any) {
    let message: string = "";
    switch (error.response.status) {
      case 406:
        message =
          "You’re currently on our free plan. Upgrade your subscription to unlock additional features and benefits.";
        thunkAPI.dispatch(openLimitAccessModal());
        thunkAPI.dispatch(setLimitAccessData({ message }));
        break;
      case 429:
        if (error.response.data.response.data.code === 430) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else if (error.response.data.response.data.code === 431) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else {
          return thunkAPI.rejectWithValue(error);
        }
        break;
      default:
        return thunkAPI.rejectWithValue(error);
    }
  }
});

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEngagementAlerts.pending, (state) => {
        state.engagementAlertsLoading = true;
        state.error = null;
      })
      .addCase(fetchEngagementAlerts.fulfilled, (state, action) => {
        state.engagementAlertsLoading = false;
        state.engagementAlerts = action.payload;
      })
      .addCase(fetchEngagementAlerts.rejected, (state, action) => {
        state.engagementAlertsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });

    builder
      .addCase(fetchPriceAlerts.pending, (state) => {
        state.priceAlertsLoading = true;
        state.error = null;
      })
      .addCase(fetchPriceAlerts.fulfilled, (state, action) => {
        state.priceAlertsLoading = false;
        state.priceAlerts = action.payload;
      })
      .addCase(fetchPriceAlerts.rejected, (state, action) => {
        state.priceAlertsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default alertsSlice.reducer;
