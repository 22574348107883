import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import appSlice from "./app/appSlice";
import cryptosSlice from "./crypto/cryptosSlice";
import accountSlice from "./auth/accountSlice";
import moversSlice from "./crypto/moversSlice";
import influencersSlice from "./influencers/influencersSlice";
import newsSlice from "./news/newsSlice";
import signalSlice from "./signal/signalSlice";
import cryptoNewsSlice from "./crypto/cryptoNews";
import cryptoSlice from "./crypto/cryptoSlice";
import cryptoIndicatorsSlice from "./crypto/cryptoIndicatorsSlice";
import assetsSlice from "./assets/assetsSlice";
import assetsManagementSlice from "./assetsManagement/assetsManagementSlice";
import alertsSlice from "./alerts/alertsSlice";
import profiletSlice from "./profile/profiletSlice";
import agentSlice from "./agent/agentSlice";
import membershipSlice from "./membership/membershipSlice";
import BotSlice from "./agent/agentBotSlice";
import { cryptoStaticsReducer } from "./crypto/cryptoStaticsData";

export const store = configureStore({
  reducer: {
    app: appSlice,
    profile: profiletSlice,
    account: accountSlice,
    cryptos: cryptosSlice,
    crypto: cryptoSlice,
    movers: moversSlice,
    influencers: influencersSlice,
    news: newsSlice,
    signal: signalSlice,
    cryptoNews: cryptoNewsSlice,
    cryptoIndicators: cryptoIndicatorsSlice,
    agent: agentSlice,
    assets: assetsSlice,
    alerts: alertsSlice,
    assetsManagement: assetsManagementSlice,
    membership: membershipSlice,
    BotSlice: BotSlice,
    cryptoStatics: cryptoStaticsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
