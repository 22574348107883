import { Link } from "react-router-dom";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Avatar from "../../common/avatar";
import { CryptoInfluencersProps } from "../interfaces";
import styles from "../crypto.module.scss";

const CryptoInfluencers: React.FC<CryptoInfluencersProps> = ({
  influencers,
}) => {
  const theme = useTheme();

  return (
    <Grid container mt={2}>
      {influencers?.length > 0 ? (
        influencers?.slice(0, 12).map((influencer: any) => (
          <Grid item xs={6} md={3} key={influencer.id_str} p={0.2}>
            <Link
              to={`/influencers/${influencer.screen_name}`}
              state={{ id: influencer.id_str }}
              className={styles.crypto_influencer}
              style={{
                background: theme.palette.background.paper,
                padding: ".5rem",
              }}
            >
              <Avatar
                screenName={influencer.screen_name}
                width={45}
                height={45}
              />
              <Typography
                variant="caption"
                color={theme.palette.common.white}
                ml={1}
              >
                {influencer.screen_name}
              </Typography>
            </Link>
          </Grid>
        ))
      ) : (
        <Box
          className={`default-card-style `}
          sx={{ background: theme.palette.background.paper, width: "100%" }}
        >
          <Typography align="center" py={3}>
            No data
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default CryptoInfluencers;
