import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import DashboardLayout from "../../layout/dashboardLayout";
import styles from "./security.module.scss";

const securityData = [
  {
    id: 1,
    question: "Coinfident DOES NOT have your crypto account credentials.",
    answer: `<p>When using crypto exchanges and wallets, you provide your account details on the official oAuth pages of the respective services. If a connection needs an API key and Secret key/Passphrase, or both, Coinfident only asks for permissions that allow it to “read” information. For some specific wallets such as those for Bitcoin, Ethereum, Ripple, Doge, and others, only the Blockchain Address is necessary.</p>`,
  },
  {
    id: 2,
    question: "Is my data encrypted?",
    answer: `<p>The data in Coinfident is <strong>encrypted at-rest</strong> and <strong>in-transit</strong>. <br/>It’s <strong>NOT end-to-end encrypted</strong>.</p>`,
  },
  {
    id: 3,
    question: "What happens if Coinfident servers are breached?",
    answer: `
    <p>To start with, Coinfident does not retain any of your bank login details (refer to the response to the inquiry regarding our access to your cryptocurrency accounts). In the event of a security compromise of our servers, rest assured that your credentials remain secure.</p>
    <p>Moreover, Coinfident safeguards your information by using encryption while it's stored on Amazon's servers. This means that even if a malicious actor manages to acquire a backup copy of our database, they would not be able to decrypt and read your data without the encryption key.</p>
    <p>For further protection, we employ HTTPS across all its webpages and uses HTTP Strict Transport Security (HSTS), which guarantees that your browser forms a secure connection with our services at all times. This substantially reduces the risk of someone intercepting your data during transmission.</p>
    `,
  },
  {
    id: 4,
    question: "Does Coinfident sell my data?",
    answer: `<p>Coinfident NEVER sells your data to any third-party company for any purpose.</p>`,
  },
  {
    id: 5,
    question:
      "If I delete my data, is it permanently erased or is there a possibility of recovery from a backup?",
    answer: `
    <p>Upon account deletion, we promptly remove all your information from our main database and instruct our aggregators to cease accessing your account and to eliminate any related data they hold.</p>
    <p>We maintain backup rotations for a duration of 30 days. Your information will be expunged during the forthcoming cycle of backup cleansing.</p>
    `,
  },
  {
    id: 6,
    question: "Does Coinfident conduct external security audits?",
    answer: `<p>We are actively collaborating with independent security companies such as Certik to assess the security of our applications. We pledge to regularly consult with eminent security specialists to examine our security measures, both internally and externally.</p>`,
  },
];

const Security = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel: number) => {
    setExpanded(panel);
  };

  return (
    <DashboardLayout title="Coinfident | Security">
      <Container className={styles.securityWrapper}>
        <Grid container my={5}>
          <Grid item xs={12} xl={2.5}>
            <Fade triggerOnce direction="down">
              <Typography
                variant="h4"
                pb={3}
                color={theme.palette.text.primary}
              >
                <strong>
                  Security <br /> Overview
                </strong>
              </Typography>
            </Fade>
          </Grid>
          <Grid item xs={12} xl={9.5}>
            <Fade triggerOnce direction="down">
              <Typography
                variant="h5"
                align="left"
                color={theme.palette.text.primary}
              >
                We truly understand that Coinfident is storing your sensitive
                data and protecting it is our highest priority.
              </Typography>
              <Typography
                variant="body1"
                align="left"
                my={4}
                sx={{ fontWeight: 100 }}
                color={theme.palette.text.primary}
              >
                These are answers to some of the questions you may have in mind
                right now. If you have more questions, just email us at
                Support@coinfident.ai. We are happy to answer them.
              </Typography>
            </Fade>
            <Box>
              <Fade triggerOnce direction="down" cascade damping={0.1}>
                {securityData.map((securityItem) => (
                  <Accordion
                    key={securityItem.id}
                    className={styles.securityItem}
                    expanded={expanded === securityItem.id ? true : false}
                    onChange={() =>
                      handleChange(
                        expanded === securityItem.id ? 0 : securityItem.id,
                      )
                    }
                  >
                    <AccordionSummary
                      expandIcon={<MdOutlineKeyboardArrowDown />}
                      aria-controls={`${securityItem.id}-content`}
                      id={`${securityItem.id}-header`}
                      className={styles.securityItemSummary}
                    >
                      <Typography
                        variant="h6"
                        color={theme.palette.text.primary}
                      >
                        {securityItem.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        color={theme.palette.text.primary}
                        dangerouslySetInnerHTML={{
                          __html: securityItem.answer,
                        }}
                      ></Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Fade>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </DashboardLayout>
  );
};

export default Security;
