import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import styles from "./planPreview.module.scss";
import { Slide } from "react-awesome-reveal";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../features/store";
import { FaRegCircleCheck } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import {
  createSubscription,
  fetchSubscriptionsList,
  saveCardInfo,
  setSubscriptionData,
} from "../../../features/membership/membershipSlice";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import useAuth from "../../../utils/useAuth";
import { LoadingButton } from "@mui/lab";
import SubPagesLayout from "../../../layout/subPagesLayout";

type SubscriptionType = {
  current_period_end: string;
  current_period_start: string;
  status: string;
  id: string;
};

const PlanPreview = () => {
  const theme = useTheme();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");
  const interval = searchParams.get("interval");
  const interval_count = searchParams.get("interval_count");

  const membershipState: any = useSelector(
    (state: RootState) => state.membership
  );
  const accountState: any = useSelector((state: RootState) => state.account);

  // states
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthValid) {
      if (!membershipState.subscriptionsList) {
        dispatch(fetchSubscriptionsList());
      }
    }
  }, [isAuthValid]);

  const activeSubscriptionData = useMemo<SubscriptionType | undefined>(() => {
    if (membershipState.subscriptionsList) {
      return membershipState.subscriptionsList?.find(
        (subscription: SubscriptionType) =>
          subscription?.status === "active" ||
          subscription?.status === "trialing"
      );
    }
  }, [membershipState.subscriptionsList]);

  const desiredPlan = useMemo(() => {
    return membershipState.productsList?.find(
      (product: any) => product.name === name
    );
  }, [membershipState.productsList]);

  const desiredPrice = useMemo(() => {
    const data = membershipState.pricesList?.find(
      (price: any) =>
        price.recurring.interval_count === Number(interval_count) &&
        price.recurring.interval === interval &&
        price.nickname?.split(" ")?.at(0)?.toLowerCase() === name?.toLowerCase()
    );

    return data;
  }, [interval, interval_count, membershipState.pricesList]);

  const handleValue = (val: string): React.ReactElement | null => {
    let element: React.ReactElement | null = null;

    if (val.toLowerCase() === "true") {
      element = <FaRegCircleCheck size={18} style={{ color: "#2FDB7D" }} />;
    } else if (val.toLowerCase() === "false") {
      element = <CgCloseO size={20} style={{ color: "#FA637A" }} />;
    } else {
      element = (
        <Typography color={theme.palette.text.primary} component={"h6"}>
          {val}
        </Typography>
      );
    }

    return element;
  };

  const saveCardInfoData = {
    cancel_url: "https://app.coinfident.ai/subscription/payment-cancel",
    success_url: "https://app.coinfident.ai/subscription/payment-success",
  };

  const subscriptionData = {
    product_name: name,
    product_interval: interval,
    product_interval_count: Number(interval_count),
    collection_method: "charge_automatically",
  };

  const handleSaveCardInfo = async () => {
    setPaymentLoading(true);
    if (!accountState.isAuthenticated) {
      toast.error("You need to be logged in to proceed with payment.");
      setPaymentLoading(false);
    } else {
      if (activeSubscriptionData) {
        toast.error(
          "You already have an active subscription. Please cancel your current subscription before trying to purchase a new one."
        );
        setPaymentLoading(false);
      } else {
        try {
          dispatch(setSubscriptionData(subscriptionData));
          dispatch(saveCardInfo(saveCardInfoData))
            .unwrap()
            .then((res) => {
              setPaymentLoading(false);
              if (res.status === 200) {
                const redirectUrl = res.data.checkout_url;
                window.location.href = redirectUrl;
              }
            })
            .catch((err) => {
              setPaymentLoading(false);
              toast.error("Failed to proceed to payment. Please try again");
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <SubPagesLayout title="Coinfident | Plan Preview">
      <Box className={styles.planReviewContainer}>
        <Container maxWidth="lg">
          <Box className={styles.planReviewWrapper} pt={3}>
            <Box className={styles.planReviewContent}>
              <Typography
                align="center"
                variant="h4"
                color={theme.palette.text.primary}
              >
                Review Subscription
              </Typography>
              <Typography
                variant="body2"
                component="h2"
                align="center"
                sx={{ color: theme.palette.text.primary }}
              >
                Confirm your subscription and proceed to checkout to complete
                your purchase
              </Typography>
            </Box>
            <Box mt={2}>
              <Slide duration={1500} direction="up" triggerOnce>
                <Box
                  className={styles.planReviewSubscription}
                  sx={{
                    background: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[800]
                        : `${theme.palette.primary.main}20`,
                  }}
                >
                  <Box className={styles.planHeader}>
                    <Box className={styles.planHeaderTitle}>
                      <Typography
                        variant="h5"
                        color={theme.palette.text.primary}
                      >
                        {desiredPlan?.name}
                      </Typography>
                      <Typography
                        component={"p"}
                        color={theme.palette.text.primary}
                      >
                        {desiredPlan?.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant={"h5"}
                        color={theme.palette.text.primary}
                      >
                        $
                        {isNaN(+(desiredPrice?.unit_amount / 100).toFixed(0))
                          ? 0
                          : +(desiredPrice?.unit_amount / 100).toFixed(0)}
                      </Typography>
                    </Box>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container className={styles.planDescription}>
                        {desiredPlan?.metadata &&
                          Object.entries(desiredPlan?.metadata)?.map(
                            (item: any, metadataIndex: number) => (
                              <Grid item xs={6} key={metadataIndex}>
                                <Box className={styles.planDescriptionItem}>
                                  <Typography
                                    component={"h6"}
                                    color={theme.palette.text.primary}
                                  >
                                    {item?.at(0)}
                                  </Typography>
                                  {handleValue(item?.at(1))}
                                </Box>
                              </Grid>
                            )
                          )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={styles.planPrice}>
                        <Box className={styles.planPriceWrapper}>
                          <Box sx={{ width: "100%" }}>
                            <LoadingButton
                              fullWidth
                              variant="contained"
                              loading={paymentLoading}
                              onClick={handleSaveCardInfo}
                              sx={{ color: theme.palette.common.white }}
                            >
                              SUBSCRIBE
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Slide>
            </Box>
          </Box>
        </Container>
      </Box>
    </SubPagesLayout>
  );
};

export default PlanPreview;
