import isEmpty from "lodash";

const TEXT_COLOR = "#e61010";
/**
 * This function draw in the input canvas 2D context a rectangle.
 * It only deals with tracing the path, and does not fill or stroke.
 */
function drawRoundRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}
// exports.drawRoundRect = drawRoundRect;

/**
 * Custom hover renderer
 */
export function drawHover(context, data, settings) {
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  const subLabelSize = size - 2;
  const { label } = data;
  const subLabel = data.tag !== "unknown" ? data.tag : "";
  const { clusterLabel } = data;
  // Then we draw the label background
  context.beginPath();
  context.fillStyle = "#fff";
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 2;
  context.shadowBlur = 8;
  context.shadowColor = "#000";
  context.font = "".concat(weight, " ").concat(size, "px ").concat(font);
  const labelWidth = context.measureText(label).width;
  context.font = ""
    .concat(weight, " ")
    .concat(subLabelSize, "px ")
    .concat(font);
  const subLabelWidth = subLabel ? context.measureText(subLabel).width : 0;
  context.font = ""
    .concat(weight, " ")
    .concat(subLabelSize, "px ")
    .concat(font);
  const clusterLabelWidth = clusterLabel
    ? context.measureText(clusterLabel).width
    : 0;
  const textWidth = Math.max(labelWidth, subLabelWidth, clusterLabelWidth);
  const x = Math.round(data.x);
  const y = Math.round(data.y);
  const w = Math.round(textWidth + size / 2 + data.size + 3);
  const hLabel = Math.round(size / 2 + 4);
  const hSubLabel = subLabel ? Math.round(subLabelSize / 2 + 9) : 0;
  const hClusterLabel = Math.round(subLabelSize / 2 + 9);
  drawRoundRect(
    context,
    x,
    y - hSubLabel - 12,
    w,
    hClusterLabel + hLabel + hSubLabel + 12,
    5,
  );
  context.closePath();
  context.fill();
  const width = context.measureText(data.label).width + 8;
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 0;
  context.shadowBlur = 0;
  // And finally we draw the labels
  context.fillStyle = TEXT_COLOR;
  context.font = "".concat(weight, " ").concat(size, "px ").concat(font);
  context.fillText(label, data.x + width + data.size - 3, data.y + size / 3);
  if (subLabel) {
    context.fillStyle = TEXT_COLOR;
    context.font = ""
      .concat(weight, " ")
      .concat(subLabelSize, "px ")
      .concat(font);
    context.fillText(
      subLabel,
      data.x + width + data.size - 3,
      data.y - (2 * size) / 3 - 2,
    );
  }
  context.fillStyle = data.color;
  context.font = ""
    .concat(weight, " ")
    .concat(subLabelSize, "px ")
    .concat(font);
  //   context.fillText(
  //     clusterLabel,
  //     data.x + width + data.size - 3,
  //     data.y + size / 3 + 3 + subLabelSize
  //   );
  context.fillText(
    clusterLabel,
    data.x + width + data.size - 3,
    data.y + size / 3,
  );
}
// exports.drawHover = drawHover;
/**
 * Custom label renderer
 */
function drawLabel(context, data, settings) {
  if (!data.label) return;
  const size = settings.labelSize;
  const font = settings.labelFont;
  const weight = settings.labelWeight;
  context.font = "".concat(weight, " ").concat(size, "px ").concat(font);
  const width = context.measureText(data.label).width;
  const nameWidth = context.measureText("name").width;
  const followingWidth = context.measureText("following").width;
  const followerWidth = context.measureText("follower").width;
  const keyNameWidth = context.measureText(data.details.name).width;
  const keyFollowingWidth = context.measureText(data.details.following).width;
  const keyFollowerWidth = context.measureText(data.details.follower).width;
  const getMaxWidth = Math.max(
    width + 15,
    nameWidth + keyNameWidth + 30,
    followingWidth + keyFollowingWidth + 30,
    followerWidth + keyFollowerWidth + 30,
  );
  const rectWidth = isEmpty(data.details) ? width + 10 : getMaxWidth;
  const rectHeight = isEmpty(data.details) ? 20 : 80;
  context.fillStyle = "#ffffffcc";
  context.fillRect(
    data.x + data.size,
    data.y + size / 3 - 15,
    rectWidth,
    rectHeight,
  );
  context.fillStyle = "#000";
  if (isEmpty(data.details)) {
    context.fillText(data.label, data.x + data.size + 5, data.y + size / 3);
  } else {
    context.fillText(
      data.label,
      data.x + width + data.size + 5,
      data.y + size / 3,
    );
    context.fillText(
      ": name",
      data.x + nameWidth + data.size + 15,
      data.y + size / 3 + 20,
    );
    context.fillText(
      data.details.name,
      data.x + data.size + 100,
      data.y + size / 3 + 20,
    );
    context.fillText(
      ": following",
      data.x + followingWidth + data.size + 15,
      data.y + size / 3 + 40,
    );
    context.fillText(
      data.details.following,
      data.x + data.size + 110,
      data.y + size / 3 + 40,
    );
    context.fillText(
      ": follower",
      data.x + followerWidth + data.size + 15,
      data.y + size / 3 + 60,
    );
    context.fillText(
      data.details.follower,
      data.x + data.size + 110,
      data.y + size / 3 + 60,
    );
  }
}

export default drawLabel;
