import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { Fade } from "react-awesome-reveal";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { wallets } from "../../../../data/wallets";
import Input from "../../../form/Input";
import SelectedWallet from "../selectedWallet";
import { WalletsListState } from "../../interfaces";
import styles from "../connectWallet.module.scss";

const WalletsList = () => {
  const theme = useTheme();

  const [selectedWallet, setSelectedWallet] = useState<any>(null);
  const [filteredWallets, setFilteredWallets] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<WalletsListState>({
    wallet: "",
  });

  const submitUserDataHandler = () => async () => {};

  useEffect(() => {
    if (initialValues.wallet === "") {
      setFilteredWallets(wallets.slice(0, 5));
    } else {
      const filteredWallets = wallets.filter((wallet: any) => {
        const nameLowercase = wallet.name.toLowerCase();
        const inputValueLowercase = initialValues.wallet.toLowerCase();

        return nameLowercase.includes(inputValueLowercase);
      });
      setFilteredWallets(filteredWallets.slice(0, 5));
    }
  }, [initialValues.wallet]);

  return (
    <Grid
      container
      sx={{ margin: "auto", display: "flex", justifyContent: "center" }}
    >
      <Box
        mt={2}
        sx={{
          background: (theme: any) =>
            theme.palette.mode === "dark"
              ? theme.palette.common.darkPrimary
              : theme.palette.primary.light,
          minWidth: 350,
          overflow: "hidden",
        }}
        className="default-card-style"
      >
        <Box
          className={
            selectedWallet
              ? styles.showSelectedWallet
              : styles.hideSelectedWallet
          }
        >
          {selectedWallet && (
            <SelectedWallet
              wallet={selectedWallet}
              setSelectedWallet={setSelectedWallet}
            />
          )}
        </Box>

        <Box
          className={
            selectedWallet ? styles.hideWalletsList : styles.showWalletsList
          }
        >
          <Fade triggerOnce>
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={submitUserDataHandler()}
            >
              {(formik) => (
                <Form id="wallets_list_form">
                  <Grid container justifyContent="center">
                    <Grid item xs={12} mt={2}>
                      <Input
                        name="wallet"
                        formik={formik}
                        label="Search for Wallet or Blockchain"
                        hasInlineLabel={false}
                        hasInnerLabel={true}
                        icon={<FiSearch color={theme.palette.text.primary} />}
                        variant="outlined"
                        initialValues={initialValues}
                        setInitialValues={setInitialValues}
                        inputTextColor={theme.palette.text.primary}
                      />
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <Box className={styles.walletsList}>
              {filteredWallets.length > 0 ? (
                filteredWallets.map((wallet: any) => (
                  <Button
                    fullWidth
                    size="small"
                    key={wallet.name}
                    className={styles.walletBtn}
                    onClick={() => {
                      setSelectedWallet(wallet);
                    }}
                  >
                    <img src={wallet.imageUrl} alt={wallet.name} />
                    <Typography
                      variant="body1"
                      component="h3"
                      color={theme.palette.text.primary}
                    >
                      {wallet.name}
                    </Typography>
                  </Button>
                ))
              ) : (
                <Typography
                  variant="body2"
                  align="center"
                  my={3}
                  color={theme.palette.text.primary}
                >
                  Couldn't find Wallet or Blockchain with this name
                </Typography>
              )}
              <Typography
                variant="body2"
                mt={3}
                color={theme.palette.text.primary}
              >
                Search for more Wallet and Blockchain
              </Typography>
            </Box>
          </Fade>
        </Box>
      </Box>
    </Grid>
  );
};

export default WalletsList;
