// @ts-nocheck
import { Box, useTheme } from "@mui/material";
import React, { useMemo, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import { BubbleChartProps } from "./interfaces";
import styles from "./charts.module.scss";

const BubbleChart: React.FC<BubbleChartProps> = React.memo(
  ({ data, height }) => {
    const theme = useTheme();
    const chartRef = useRef(null);

    const options = useMemo(
      () => ({
        annotations: {},
        chart: {
          fontFamily: "Poppins",
          toolbar: {
            show: true,
            offsetX: -5,
            offsetY: 5,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              pan: false,
              reset:
                '<img style="background: #555; border-radius: 50%; padding: .3rem" src="./assets/icons/reset.svg" width="32">',
              zoomin:
                '<img style=" background: #555; border-radius: 50%; padding: .3rem" src="./assets/icons/zoomin.svg" width="29">',
              zoomout:
                '<img style=" background: #555; border-radius: 50%; padding: .3rem" src="./assets/icons/zoomout.svg" width="29">',
            },
          },
          zoom: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter: (event, chartContext, config) => {
              const index = config.seriesIndex;
              const imageURL = config.w.config.series[index].data[0][2].image;
              const imageOverlay = document.getElementById("image-overlay");
              const chartElement =
                chartRef.current.chart.ctx.w.globals.dom.baseEl;
              const rect = chartElement.getBoundingClientRect();
              const offsetX = event.clientX - rect.left;
              const offsetY = event.clientY - rect.top;
              imageOverlay.src = imageURL;
              imageOverlay.style.left = `${offsetX}px`;
              imageOverlay.style.top = `${offsetY}px`;
              imageOverlay.style.display = "block";
            },
            dataPointMouseLeave: () => {
              const imageOverlay = document.getElementById("image-overlay");
              imageOverlay.style.display = "none";
            },
          },
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex, w }) => {
            return `<div class='${
              styles.linechart_tooltip
            }' style="background: ${theme.palette.common.solidCard}">
                  <h6 style="color: ${theme.palette.common.white} ">${
                    w.globals.initialSeries[seriesIndex].data[0][2].screen_name
                  }</h6>
                  <div>
                    <h4 style="color: ${
                      theme.palette.common.white
                    } ">Followers :</h4>
                    <h5 style="color: ${theme.palette.common.white} ">${Number(
                      w.globals.initialSeries[seriesIndex].data[0][2]
                        .followers_count
                    ).toLocaleString()}</h5>
                  </div></div>`;
          },
        },
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 50,
          },
        },
        markers: {
          size: 5,
          strokeWidth: 0,
          hover: {
            size: 15,
            sizeOffset: 3,
          },
        },
        fill: {
          type: "solid",
          opacity: 1,
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: "numeric",
          tickAmount: 4,
          labels: {
            show: true,
            style: {
              colors: theme.palette.text.primary,
            },
            formatter: (value) =>
              Number(Math.round(Math.exp(value))).toLocaleString(),
            offsetY: 5,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          crosshairs: {
            show: false,
          },
          title: {
            text: "Followers",
            offsetX: 0,
            offsetY: 5,
            style: {
              color: theme.palette.text.primary,
              fontSize: "12px",
              fontFamily: "Poppins",
              fontWeight: 600,
              cssClass: "apexcharts-xaxis-title",
            },
          },
        },
        yaxis: {
          type: "numeric",
          min: 0,
          max: 1,
          labels: {
            show: false,
            style: {
              colors: theme.palette.text.primary,
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      }),
      [theme]
    );

    const series = useMemo(() => data, [data]);

    return (
      <Box className={styles.bubbleChart} id="bubble-chart">
        <Box className={styles.gradientBorder} />
        <ReactApexChart
          ref={chartRef}
          options={options}
          series={series}
          type="scatter"
          height={height}
          width="100%"
        />
        <img
          id="image-overlay"
          className={styles.imageOverlay}
          alt="influencer-image"
        />
      </Box>
    );
  }
);

export default BubbleChart;
