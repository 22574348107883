import { Fade } from "react-awesome-reveal";
import { Box, Button, Typography, useTheme } from "@mui/material";
import NetWorthAreaChart from "../../../charts/NetWorthAreaChart";
import styles from "../../assetsManagement.module.scss";

interface NetworthChartProps {
  assets: any;
}

const NetworthChart: React.FC<NetworthChartProps> = ({ assets }) => {
  const theme = useTheme();

  return (
    <Fade triggerOnce>
      <Box
        className={`${styles.networthChart} networth_chart`}
        sx={{
          padding: "1rem 0 0",
          background: (theme: any) =>
            theme.palette.mode === "dark"
              ? theme.palette.common.darkPrimary
              : theme.palette.primary.light,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 2,
          }}
        >
          <Typography
            align="left"
            variant="h6"
            color={theme.palette.text.primary}
          >
            Net Worth
          </Typography>
          <Typography
            variant="caption"
            color={(theme: any) => theme.palette.common.mute}
          >
            Last 30 days
          </Typography>
        </Box>
        <NetWorthAreaChart
          chartData={[...assets.networth_history]?.reverse()}
        />
        {/* <Box sx={{ display: "flex", justifyContent: "center" }} mt={1}>
          <Button
            variant="text"
            size="small"
            sx={{ color: theme.palette.text.primary }}
          >
            1D
          </Button>
          <Button
            variant="text"
            size="small"
            sx={{ color: theme.palette.text.primary }}
          >
            1W
          </Button>
          <Button
            variant="text"
            size="small"
            sx={{ color: theme.palette.text.primary }}
          >
            1M
          </Button>
          <Button
            variant="text"
            size="small"
            sx={{ color: theme.palette.text.primary }}
          >
            1Y
          </Button>
          <Button
            variant="text"
            size="small"
            sx={{ color: theme.palette.text.primary }}
          >
            All
          </Button>
        </Box> */}
      </Box>
    </Fade>
  );
};

export default NetworthChart;
