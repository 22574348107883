import { useEffect, useState } from "react";
import { useSigma } from "@react-sigma/core";
import { Box, useTheme } from "@mui/material";
import { FaSearch } from "react-icons/fa";

/**
 * This component is basically a fork from React-sigma-v2's SearchControl
 * component, to get some minor adjustments:
 * 1. We need to hide hidden nodes from results
 * 2. We need custom markup
 */
const SearchField = ({ filters, selected, setSelected }) => {
  const sigma = useSigma();
  const theme = useTheme();

  // states
  const [search, setSearch] = useState("");
  const [values, setValues] = useState([]);

  const refreshValues = () => {
    const newValues = [];
    const lcSearch = search.toLowerCase();
    if (!selected && search.length > 1) {
      sigma.getGraph().forEachNode((key, attributes) => {
        if (
          !attributes.hidden &&
          attributes.label &&
          attributes.label.toLowerCase().indexOf(lcSearch) === 0
        )
          newValues.push({ id: key, label: attributes.label });
      });
    }
    setValues(newValues);
  };

  // Refresh values when search is updated:
  useEffect(() => refreshValues(), [search]);

  // Refresh values when filters are updated (but wait a frame first):
  useEffect(() => {
    requestAnimationFrame(() => {
      refreshValues();
    });
  }, [filters]);

  useEffect(() => {
    if (!selected) return;

    sigma.getGraph().setNodeAttribute(selected, "highlighted", true);
    const nodeDisplayData = sigma.getNodeDisplayData(selected);

    if (nodeDisplayData)
      sigma.getCamera().animate(
        { ...nodeDisplayData, ratio: 0.05 },
        {
          duration: 600,
        },
      );

    // return () => {
    //   sigma.getGraph().setNodeAttribute(selected, 'highlighted', false);
    // };
  }, [selected]);

  const onInputChange = (e) => {
    const searchString = e.target.value;
    const valueItem = values.find((value) => value.label === searchString);
    if (valueItem) {
      setSearch(valueItem.label);
      setValues([]);
      setSelected(valueItem.id);
    } else {
      setSelected(null);
      setSearch(searchString);
    }
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter" && values.length) {
      setSearch(values[0].label);
      setSelected(values[0].id);
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        borderRadius: "8px",
      }}
      className="search-wrapper"
    >
      <input
        type="search"
        placeholder="Search Coin..."
        list="nodes"
        value={search}
        onChange={onInputChange}
        onKeyPress={onKeyPress}
      />
      <FaSearch className="icon" />
      <datalist id="nodes">
        {values.map((value) => (
          <option key={value.id} value={value.label}>
            {value.label}
          </option>
        ))}
      </datalist>
    </Box>
  );
};

export default SearchField;
