import moment from "moment";
import { Box, useTheme } from "@mui/material";
import { NewsItem } from "../../interfaces";
import Loading from "../../../common/loading";
import NewsCard from "../../../common/newsCard";
import AnalyticsTitle from "../../../common/title/AnalyticsTitle";
import ComingSoon from "../../../common/comingSoon";

const CoinNews = ({ signalState }: { signalState: any }) => {
  const theme = useTheme();

  return (
    <>
      <AnalyticsTitle isLarge title="Posts" />
      {signalState.influencerSignalLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{ height: "450px", overflowY: "auto" }}
          className={`customScrollbar ${
            theme.palette.mode === "light"
              ? "customScrollbar-light"
              : "customScrollbar-dark"
          }`}
          p={2}
        >
          {signalState.influencerSignal?.relevant_tweets?.length > 0 ? (
            signalState.influencerSignal?.relevant_tweets
              .slice(0, 50)
              .map((news: NewsItem, idx: number) => (
                <NewsCard
                  key={idx}
                  screen_name={news.screen_name}
                  id={news.user_id_str}
                  name={news.screen_name}
                  media={news.media}
                  date={`${moment
                    .parseZone(news.created_at)
                    .format("DD MMMM YYYY")}`}
                  content={news.full_text}
                  reply_count={news.reply_count ? news.reply_count : 0}
                  favorite_count={news.favorite_count ? news.favorite_count : 0}
                  retweet_count={news.retweet_count ? news.retweet_count : 0}
                />
              ))
          ) : (
            <ComingSoon />
          )}
        </Box>
      )}
    </>
  );
};

export default CoinNews;
