import { useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import { tradingViewSymbol } from "../../../utils/functions";
import { FinancialChartProps } from "../interfaces";

//@ts-ignore
let tvScriptLoadingPromise;

const FinancialChart: React.FC<FinancialChartProps> = ({
  symbol,
  coinsList,
}) => {
  const onLoadScriptRef = useRef();
  const theme = useTheme();

  useEffect(
    //@ts-ignore
    () => {
      //@ts-ignore
      onLoadScriptRef.current = createWidget;

      //@ts-ignore
      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement("script");
          script.id = "tradingview-widget-loading-script";
          script.src = "https://s3.tradingview.com/tv.js";
          script.type = "text/javascript";
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(
        //@ts-ignore
        () => onLoadScriptRef.current && onLoadScriptRef.current(),
      );

      //@ts-ignore
      return () => (onLoadScriptRef.current = null);

      function createWidget() {
        if (
          document.getElementById("financial_chart") &&
          "TradingView" in window
        ) {
          //@ts-ignore
          new window.TradingView.widget({
            width: "100%",
            height: 330,
            symbol: coinsList
              ? tradingViewSymbol(
                  coinsList.find((coin: any) => coin.coin_id === symbol).label,
                )
              : tradingViewSymbol(symbol),
            interval: "D",
            timezone: "Etc/UTC",
            theme: theme.palette.mode === "light" ? "light" : "dark",
            style: "1",
            locale: "en",
            enable_publishing: false,
            save_image: false,
            details: false,
            hide_volume: true,
            show_popup_button: true,
            popup_width: "1800",
            popup_height: "650",
            container_id: "financial_chart",
          });
        }
      }
    },
    [theme.palette.mode, symbol],
  );

  return (
    <div
      className="tradingview-widget-container"
      style={{
        background: theme.palette.primary.light,
      }}
    >
      <div id="financial_chart" />
    </div>
  );
};

export default FinancialChart;
