import { useEffect, useMemo } from "react";
import moment from "moment";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Avatar,
  useMediaQuery,
  Skeleton,
  AvatarGroup,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineUserGroup } from "react-icons/hi";
import { PiSpeedometerLight } from "react-icons/pi";
import { TbChartLine, TbMessage2 } from "react-icons/tb";
import DefaultGauge from "../../../guage";
import NewsCard from "../../../common/newsCard";
import SkeletonLoading from "../../../common/SkeletonLoading";
import FinancialChart from "../../../crypto/financialChart";
import { getUserProfile } from "../../../../utils/userPhoto";
import { calculateGuageValue, getCoinImage } from "../../../../utils/functions";
import {
  fetchAgentInfluencers,
  fetchAgentSps,
  fetchCoinsStatistics,
  fetchInfluencers,
  fetchTopTweets,
  setAgentComponentsData,
  setModalComponentsData,
} from "../../../../features/agent/agentSlice";
import { RootState } from "../../../../features/store";
import { isEmpty } from "lodash";
import AgentBotChart from "../../../charts/AgentBotChart";
import Responcestyles from "./agentComp.module.scss";
const date_converter = (date: string) => {
  return date?.split(" ")[0].replace(/-/g, ".");
};

const convertToArray = (data: string): string[] => {
  if (!data || typeof data !== "string") {
    return [];
  }

  try {
    const validArrayString = data.replace(/'/g, '"');
    return JSON.parse(validArrayString);
  } catch (error) {
    return [];
  }
};

const AgentComponent = ({
  isModal,
  styles,
  type,
  coin,
  start_date,
  end_date,
}: {
  isModal: any;
  styles: any;
  type: string;
  coin: any;
  start_date: string;
  end_date: string;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const agentState: any = useSelector((state: RootState) => state.agent);
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const coinsListID = useMemo(() => {
    let arr: any = [];

    for (const availableCoin of convertToArray(coin)) {
      const availableCoinID = cryptosState?.coinsStatistics?.find(
        (crypto: any) => crypto.label.toLowerCase() === availableCoin
      )?.coin_id;

      arr.push(availableCoinID);
    }

    return arr;
  }, [type, coin]);

  const componentsData = isModal
    ? agentState.modalComponentsData
    : agentState.agentComponentsData;

  const fetchAgentSpsData = async (coinsID: number[]) => {
    let coinSpsResult: any = {
      signal: {},
      sentiment: {},
      relevant_tweets: [],
    };

    if (isEmpty(coinsID)) {
      const relevantTweets = await fetchTopTweets();
      const statistics = await fetchCoinsStatistics();
      coinSpsResult.signal = statistics["overall signal"];
      coinSpsResult.relevant_tweets = relevantTweets;
    } else {
      for (const id of coinsID) {
        const coinData = await fetchAgentSps(id);

        for (const key in coinData) {
          coinSpsResult = {
            ...coinSpsResult,
            [key]: coinData[key]
              ? Array.isArray(coinSpsResult[key])
                ? [...coinSpsResult[key], ...coinData[key]]
                : { ...coinSpsResult[key], ...coinData[key] }
              : coinSpsResult[key],
          };
        }
      }
    }

    if (isModal) {
      dispatch(
        setModalComponentsData({
          ...agentState.modalComponentsData,
          signal: coinSpsResult?.signal,
          tweets: coinSpsResult?.relevant_tweets?.slice(0, 50),
        })
      );
    } else {
      dispatch(
        setAgentComponentsData({
          ...agentState.agentComponentsData,
          signal: coinSpsResult?.signal,
          tweets: coinSpsResult?.relevant_tweets?.slice(0, 50),
        })
      );
    }
  };

  const fetchAgentInfluencersData = async (coinsID: number[]) => {
    let coinInfluencersResult: any = [];

    if (isEmpty(coinsID)) {
      const coinData = await fetchInfluencers("relevant_tweets");
      coinInfluencersResult = coinData;
    } else {
      for (const id of coinsID) {
        const coinData = await fetchAgentInfluencers(id);
        coinInfluencersResult = [...coinInfluencersResult, ...coinData];
      }
    }

    if (isModal) {
      dispatch(
        setModalComponentsData({
          ...agentState.modalComponentsData,
          top_influencers: coinInfluencersResult,
        })
      );
    } else {
      dispatch(
        setAgentComponentsData({
          ...agentState.agentComponentsData,
          top_influencers: coinInfluencersResult,
        })
      );
    }
  };

  useEffect(() => {
    if (type === "top_tweets" || type === "sps") {
      if (isModal) {
        if (!agentState.modalComponentsData?.signal) {
          fetchAgentSpsData(coinsListID);
        }
      } else {
        if (!agentState.agentComponentsData?.signal) {
          fetchAgentSpsData(coinsListID);
        }
      }
    }

    if (type === "top_influencers") {
      if (isModal) {
        if (!agentState.modalComponentsData?.top_influencers)
          fetchAgentInfluencersData(coinsListID);
      } else {
        if (!agentState.agentComponentsData?.top_influencers)
          fetchAgentInfluencersData(coinsListID);
      }
    }
  }, [coinsListID]);

  const coinSpsData = componentsData?.signal
    ? calculateGuageValue(
        componentsData?.signal[0] ?? 0,
        componentsData?.signal[1] ?? 0,
        componentsData?.signal.null ?? 0
      )
    : calculateGuageValue(0, 0, 0);

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const tableHeadData = [
    { title: "Influencer" },
    { title: "Follower" },
    { title: "Following" },
    { title: "Post Count" },
  ];

  const header_icon = () => {
    if (type === "price") {
      return (
        <TbChartLine
          size={24}
          style={{ marginRight: ".4rem" }}
          color={theme.palette.primary.main}
        />
      );
    } else if (type === "top_influencers") {
      return (
        <HiOutlineUserGroup
          size={24}
          style={{ marginRight: ".4rem" }}
          color={theme.palette.primary.main}
        />
      );
    } else if (type === "top_tweets") {
      return (
        <TbMessage2
          size={24}
          style={{ marginRight: ".4rem" }}
          color={theme.palette.primary.main}
        />
      );
    } else {
      return (
        <PiSpeedometerLight
          size={24}
          style={{ marginRight: ".4rem" }}
          color={theme.palette.primary.main}
        />
      );
    }
  };

  const header_title = (title: string) => {
    switch (title) {
      case "price":
        return "Price Chart";
      case "sps":
        return "SPS";
      case "top_influencers":
        return "Top Influencers";
      case "top_tweets":
        return "Top Tweets";
      case "agent-bot":
        return "Agent Bot";
    }
  };

  const header = () => {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 .8rem",
          borderBottom: (theme: any) => `1px solid ${theme.palette.grey.dark}`,
        }}
      >
        <Typography
          variant="body2"
          component="h3"
          align="center"
          color={theme.palette.primary.main}
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {header_icon()}
          {header_title(type)}
        </Typography>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            position: downLg ? "absolute" : "relative",
          }}
        >
          <AvatarGroup max={4}>
            {coinsListID.map((id: number) => (
              <Avatar
                key={id}
                alt="coin"
                src={getCoinImage(id)}
                sx={{ width: 28, height: 28 }}
              />
            ))}
          </AvatarGroup>
        </Box>

        {/* {!downLg && (
          <Typography
            variant="caption"
            align="right"
            color={(theme: any) => theme.palette.common.mute}
            sx={{ flex: 1, fontWeight: 100 }}
          >
            {date_converter(start_date)} - {date_converter(end_date)}
          </Typography>
        )} */}
      </Box>
    );
  };
  const isValidValue = (value: any) =>
    value !== undefined && value !== null && value !== 0;

  const selectedComponent = () => {
    const isValidValue = (value: any) =>
      value !== undefined && value !== null && value !== 0 && value !== "";
    switch (type) {
      case "price":
        return (
          <>
            {isEmpty(coin) && (
              <Box
                className={styles.component_wrapper}
                sx={{
                  background: (theme: any) =>
                    theme.palette.mode == "dark"
                      ? theme.palette.common.darkPrimary
                      : theme.palette.common.white,
                }}
              >
                {header()}
                <Box className={styles.component_content}>
                  <FinancialChart symbol={coin} />
                </Box>
              </Box>
            )}
          </>
        );
      case "sps":
        return (
          <Box
            className={styles.component_wrapper}
            sx={{
              background: (theme: any) =>
                theme.palette.mode == "dark"
                  ? theme.palette.common.darkPrimary
                  : theme.palette.common.white,
            }}
          >
            {header()}
            {componentsData?.signal ? (
              <Box
                className={styles.component_content}
                sx={{
                  width: 270,
                  minHeight: 200,
                  margin: "1.5rem auto",
                }}
              >
                <DefaultGauge
                  value={coinSpsData}
                  type="summaryTechnicalSignal"
                />
              </Box>
            ) : (
              <Box
                className={styles.component_content}
                sx={{
                  minHeight: 200,
                  margin: "1.5rem auto",
                }}
              >
                <Skeleton
                  variant="rounded"
                  height={160}
                  sx={{ width: "70%", margin: "auto" }}
                />
              </Box>
            )}
          </Box>
        );
      case "top_influencers":
        return (
          <Box
            className={styles.component_wrapper}
            sx={{
              background: (theme: any) =>
                theme.palette.mode == "dark"
                  ? theme.palette.common.darkPrimary
                  : theme.palette.common.white,
            }}
          >
            {header()}
            <Box className={styles.component_content}>
              <TableContainer
                sx={{
                  maxHeight: "410px",
                  overflowY: "auto",
                }}
                className={`customScrollbar ${
                  theme.palette.mode === "light"
                    ? "customScrollbar-light"
                    : "customScrollbar-dark"
                }`}
              >
                <Table aria-label="influencers_table">
                  <TableHead>
                    <TableRow>
                      {tableHeadData.map((cell) => (
                        <TableCell
                          component="th"
                          key={cell.title}
                          align={
                            cell.title === "Influencer" ? "left" : "center"
                          }
                        >
                          <Typography
                            variant="caption"
                            color={theme.palette.text.primary}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {cell.title}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {componentsData.top_influencers?.map(
                      (row: any, idx: number) => (
                        <TableRow
                          key={idx}
                          className={styles.component_content_table}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Box display="flex" alignItems="center">
                              <Avatar
                                //@ts-ignore
                                src={getUserProfile(row.screen_name)}
                                sx={{ width: 38, height: 38, mr: 1 }}
                              />
                              <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <Typography
                                  color={theme.palette.text.primary}
                                  variant="body2"
                                  sx={{
                                    fontSize: ".8rem !important",
                                    fontWeight: "500 !important",
                                    margin: "0 !important",
                                    padding: "0 !important",
                                  }}
                                >
                                  {row.name}
                                </Typography>
                                <Typography
                                  color={theme.palette.text.primary}
                                  variant="caption"
                                >
                                  @{row.screen_name}
                                </Typography>
                              </Stack>
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            {row.followers_count.toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {row.friends_count.toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {row.tweet_count.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        );
      case "top_tweets":
        return (
          <Box
            className={styles.component_wrapper}
            mb={2}
            sx={{
              background: (theme: any) =>
                theme.palette.mode == "dark"
                  ? theme.palette.common.darkPrimary
                  : theme.palette.common.white,
            }}
          >
            {header()}
            <Box className={styles.component_content}>
              <Box
                sx={{ height: "410px", overflowY: "auto", padding: "1rem" }}
                className={`customScrollbar ${scrollbarClassName}`}
              >
                {componentsData?.tweets?.length > 0 ? (
                  componentsData?.tweets
                    ?.slice(0, 50)
                    .map((tweet: any) => (
                      <NewsCard
                        key={tweet.id_str}
                        id={tweet.id_str}
                        screen_name={tweet.screen_name}
                        name={tweet.screen_name}
                        date={`${moment
                          .parseZone(tweet.created_at)
                          .format("DD MMMM YYYY")}`}
                        content={tweet.full_text}
                        media={tweet.media}
                        reply_count={tweet.reply_count}
                        favorite_count={tweet.favorite_count}
                        retweet_count={tweet.retweet_count}
                      />
                    ))
                ) : (
                  <SkeletonLoading />
                )}
              </Box>
            </Box>
          </Box>
        );
      case "agent-bot":
        return (
          <Box
            className={styles.component_wrapper}
            mb={2}
            sx={{
              background: (theme: any) =>
                theme.palette.mode == "dark"
                  ? theme.palette.common.darkPrimary
                  : theme.palette.common.white,
            }}
          >
            {agentState.agentList?.slice(-1).map((item: any) => (
              <Box className={styles.component_content}>
                <Box
                  sx={{ height: "610px", overflowY: "auto", padding: "1rem" }}
                  className={`customScrollbar ${scrollbarClassName}`}
                >
                  <Grid container>
                    {isValidValue(
                      item.answer?.trade_agent_inputs?.coin_name
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                      >
                        <Typography>coin name :</Typography>
                        <Typography>
                          {item.answer.trade_agent_inputs.coin_name}
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.current_balance
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                      >
                        <Typography>Initial Balance :</Typography>
                        <Typography>
                          {item.answer?.trade_agent_inputs?.current_balance}
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.duration
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                      >
                        <Typography>Trading Duration :</Typography>
                        <Typography>
                          {item.answer.trade_agent_inputs.duration}
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent?.rules?.sps?.lower
                    ) &&
                      isValidValue(
                        item.answer?.trade_agent?.rules?.sps?.upper
                      ) && (
                        <Grid
                          item
                          xs={12}
                          className={Responcestyles.AgentBotDetail}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Typography>SPS</Typography>
                          <Box
                            width="200px"
                            height="100px"
                            alignContent={"center"}
                          >
                            <AgentBotChart
                              value={[
                                item.answer.trade_agent?.rules.sps.lower,
                                item.answer.trade_agent?.rules.sps.upper,
                              ]}
                            />
                          </Box>
                        </Grid>
                      )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.rules?.sentiment_score
                        ?.negative_upper
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography>Sentiment:</Typography>
                        <Typography>
                          {
                            item.answer.trade_agent_inputs.rules.sentiment_score
                              .negative_upper
                          }{" "}
                          -{" "}
                          {
                            item.answer.trade_agent_inputs.rules.sentiment_score
                              .positive_upper
                          }
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.rules?.price_change
                        ?.upper
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Typography>Price Change Percentage:</Typography>
                        <Typography>
                          {
                            item.answer.trade_agent_inputs.rules.price_change
                              .lower
                          }{" "}
                          -{" "}
                          {
                            item.answer.trade_agent_inputs.rules.price_change
                              .upper
                          }
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.trade_percentage
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                      >
                        <Typography>Trade Percentage :</Typography>
                        <Typography>
                          {item.answer.trade_agent_inputs.trade_percentage}
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.stop_loss_portfolio_value
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                      >
                        <Typography>Stop Loss Portfolio :</Typography>
                        <Typography>
                          {
                            item.answer.trade_agent_inputs
                              .stop_loss_portfolio_value
                          }
                        </Typography>
                      </Grid>
                    )}

                    {isValidValue(
                      item.answer?.trade_agent_inputs?.stop_loss_price
                    ) && (
                      <Grid
                        item
                        xs={12}
                        className={Responcestyles.AgentBotDetail}
                      >
                        <Typography>Stop Loss Price :</Typography>
                        <Typography>
                          {item.answer.trade_agent_inputs.stop_loss_price}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>
        );
    }
  };

  // !isEmpty(coinsListID) &&
  return <Box>{selectedComponent()}</Box>;
};

export default AgentComponent;
