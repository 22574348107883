import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import InfluencersTimeFrame from "../influencersTimeFrame";
import CryptoList from "../../crypto/cryptoList";
import { fetchInfluencerTableData } from "../../../features/influencers/influencersSlice";
import { useSelector, useDispatch } from "react-redux";
import { LuInfo } from "react-icons/lu";
import DefaultTooltip from "../../common/tooltip";
import Loading from "../../common/loading";
import Avatar from "../../common/avatar";
import CountUp from "react-countup";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  FaAngleDown,
  FaAngleUp,
  FaArrowDown,
  FaArrowTrendDown,
  FaArrowTrendUp,
  FaArrowUp,
  FaChevronUp,
} from "react-icons/fa6";

const InfluencerFullTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const resData = useSelector(
    (state: any) => state.influencers.influencerTable
  );
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [timePeriod, setTimePeriod] = useState<1 | 3 | 7 | 30>(7);
  const [selectedCoins, setSelectedCoins] = useState<number>(3);
  const [sortedData, setSortedData] = useState([]);
  const [cachedData, setCachedData] = useState<Record<string, any>>({});
  const [currentPeriod, setCurrentPeriod] = useState(timePeriod);
  const coinSelectHandeler = (selectedCoin: number) => {
    setCachedData({});
    setSelectedCoins(selectedCoin);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const calculateEndDate = (timePeriod: number): string => {
    const now = new Date();
    now.setDate(now.getDate() - timePeriod);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const periodKey = timePeriod;
    if (cachedData[periodKey]) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      dispatch(
        // @ts-ignore
        fetchInfluencerTableData({
          coinid: selectedCoins,
          startdate: calculateEndDate(timePeriod),
          enddate: getCurrentDate(),
          period: timePeriod,
        })
      )
        .then((response: any) => {
          setCachedData((prevData) => ({
            ...prevData,
            [periodKey]: response.payload.data,
          }));
        })
        .finally(() => setIsLoading(false));
    }
  }, [selectedCoins, timePeriod, resData]);

  const relevantTweets = cachedData[timePeriod]?.relevant_tweets || [];
  const sellSignal = cachedData[timePeriod]?.sell_signal || [];
  const positiveSentiment = cachedData[timePeriod]?.positive_sentiment || [];
  const negativeSentiment = cachedData[timePeriod]?.negative_sentiment || [];
  const buySignal = cachedData[timePeriod]?.buy_signal || [];
  const raterank = cachedData[timePeriod]?.final_rank || [];
  const accurancyrank = cachedData[timePeriod]?.overall_accuracy_rank || [];
  const engagementrank = cachedData[timePeriod]?.engagement_rate_rank || [];

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const allSignals = [
      ...buySignal,
      ...sellSignal,
      ...relevantTweets,
      ...positiveSentiment,
      ...negativeSentiment,
      ...raterank,
      ...accurancyrank,
      ...engagementrank,
    ];
    const names = Array.from(
      new Set(allSignals.map((item: any) => item.name))
    ).sort();

    const getScreenName = (name: string) => {
      const matchedItem = allSignals.find((item: any) => item.name === name);
      return matchedItem?.screen_name || null;
    };
    const getDelta = (name: string) => {
      const matchedItem = allSignals.find((item: any) => item.name === name);
      return matchedItem?.rank_delta || null;
    };
    const getIdStr = (name: string) => {
      const matchedItem = allSignals.find((item: any) => item.name === name);
      return matchedItem?.id_str || null;
    };

    const getRankings = (arr: any[]) =>
      names.reduce<Record<string, number>>((acc, name) => {
        const rank = arr.findIndex((item: any) => item.name === name);
        if (rank !== -1) acc[name] = rank + 1;
        return acc;
      }, {});
    const buySignalRanks = getRankings(buySignal);
    const sellSignalRanks = getRankings(sellSignal);
    const relevantTweetsRanks = getRankings(relevantTweets);
    const positiveSentimentRanks = getRankings(positiveSentiment);
    const negativeSentimentRanks = getRankings(negativeSentiment);
    const rateRanks = getRankings(raterank);
    const accurancyRanks = getRankings(accurancyrank);
    const engagementRanks = getRankings(engagementrank);

    const data = names.map((name) => ({
      name,
      getIdStr: getIdStr(name),
      screenName: getScreenName(name),
      buySignalRank:
        { rank: buySignalRanks[name], delta: getDelta(name) } || null,
      sellSignalRank:
        { rank: sellSignalRanks[name], delta: getDelta(name) } || null,
      relevantTweets:
        { rank: relevantTweetsRanks[name], delta: getDelta(name) } || null,
      positiveSentiment:
        { rank: positiveSentimentRanks[name], delta: getDelta(name) } || null,
      negativeSentiment:
        { rank: negativeSentimentRanks[name], delta: getDelta(name) } || null,
      rateRank: { rank: rateRanks[name], delta: getDelta(name) } || null,
      accurancyRank:
        { rank: accurancyRanks[name], delta: getDelta(name) } || null,
      engagementRank:
        { rank: engagementRanks[name], delta: getDelta(name) } || null,
    }));

    // @ts-ignore
    setTableData(data);
    // sortData("rateRank");
  }, [
    buySignal,
    sellSignal,
    relevantTweets,
    positiveSentiment,
    negativeSentiment,
    raterank,
    accurancyrank,
    engagementrank,
  ]);

  function sortDataWithNulls(
    arr: any[],
    sortConfig: { key: string; direction: string }
  ) {
    const { key, direction } = sortConfig;

    const nonNullValues = arr.filter((item) => item[key] !== null);
    const nullValues = arr.filter((item) => item[key] === null);

    nonNullValues.sort((a, b) => {
      if (direction === "ascending") {
        return a[key] - b[key];
      } else if (direction === "descending") {
        return b[key] - a[key];
      }
      return 0;
    });

    return [...nonNullValues, ...nullValues];
  }
  const sortData = (key: any) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedData = [...tableData].sort((a, b) => {
      // @ts-ignore
      const aValue = a[key]?.rank;
      // @ts-ignore
      const bValue = b[key]?.rank;

      if (aValue == null && bValue == null) return 0;
      if (aValue == null) return 1;
      if (bValue == null) return -1;

      return direction === "ascending" ? aValue - bValue : bValue - aValue;
    });

    setSortConfig({ key, direction });
    setTableData(sortedData);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <CryptoList coinsSelectorHandler={coinSelectHandeler} isAnalytics />
        <InfluencersTimeFrame
          timePeriod={timePeriod}
          setTimePeriod={setTimePeriod}
        />
      </Box>
      {!isLoading ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 850 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    position: "sticky",
                    left: 0,
                    backgroundColor:
                      theme.palette.mode === "light" ? "#ffffff" : "#1b1e1d",
                    zIndex: 1,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",
                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "name" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>Influencer Name</Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("relevantTweets")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "relevantTweets" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Coin Tweet Volume{" "}
                      <DefaultTooltip
                        title="Ranks crypto influencers by the number of related tweets published in the past week, from highest to lowest."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("buySignalRank")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "buySignalRank" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Coin Tweet Volume Buy SPS{" "}
                      <DefaultTooltip
                        title="Ranks crypto influencers by the number of tweets related to coins with a 'Not-Buy SPS' signal published in the past week, from highest to lowest."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>

                <TableCell
                  align="center"
                  onClick={() => sortData("sellSignalRank")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "sellSignalRank" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Coin Tweet Volume Not-Buy SPS{" "}
                      <DefaultTooltip
                        title="Ranks crypto influencers by the number of tweets related to coins with a 'Not-Buy SPS' signal published in the past week, from highest to lowest."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("positiveSentiment")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "positiveSentiment" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Positive Emotional Sentiment{" "}
                      <DefaultTooltip
                        title="Ranks crypto influencers based on the number of tweets with positive emotional sentiment they published in the past week, from highest to lowest."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("negativeSentiment")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "negativeSentiment" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Negative Emotional Sentiment{" "}
                      <DefaultTooltip
                        title="Ranks crypto influencers based on the number of tweets with negative emotional sentiment they published in the past week, from highest to lowest."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("accurancyRank")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "accurancyRank" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Accurancy Rank{" "}
                      <DefaultTooltip
                        title="Indicates how reliable the influencer's signals are in predicting market movements based on their tweets and subsequent price changes."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("engagementRank")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "engagementRank" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Engagement Rank{" "}
                      <DefaultTooltip
                        title="Measures the level of audience interaction with the influencer's tweets, calculated based on the number of likes and retweets received."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => sortData("rateRank")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "light" ? "#cfcfcf" : "#303030",

                      color: theme.palette.mode === "dark" ? "white" : "black",
                    },
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sortConfig.key === "rateRank" ? (
                      sortConfig.direction === "ascending" ? (
                        <FaSortUp />
                      ) : (
                        <FaSortDown />
                      )
                    ) : (
                      " "
                    )}
                    <Box>
                      Final Rank{" "}
                      <DefaultTooltip
                        title="Ranks crypto influencers based on the number of tweets with negative emotional sentiment they published in the past week, from highest to lowest."
                        placement="top"
                      >
                        <IconButton size="small">
                          <LuInfo
                            size={13}
                            color={
                              theme.palette.mode === "light"
                                ? theme.palette.primary.main
                                : theme.palette.grey[500]
                            }
                          />
                        </IconButton>
                      </DefaultTooltip>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: any) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      position: "sticky",
                      left: 0,
                      backgroundColor:
                        theme.palette.mode === "light" ? "#ffffff" : "#1b1e1d",

                      zIndex: 1,
                    }}
                  >
                    <Link
                      to={`/influencers/${row.screenName}`}
                      state={{ id: row.id_str }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <Avatar
                        screenName={row.screenName}
                        width={36}
                        height={36}
                      />
                      <Box sx={{ marginLeft: "10px" }}>
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === "dark" ? "white" : "black",
                          }}
                        >
                          {row.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color:
                              theme.palette.mode === "dark" ? "white" : "black",
                          }}
                        >
                          @{row.screenName}
                        </Typography>
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {row.relevantTweets.rank === undefined ? (
                      "-"
                    ) : Number(row.relevantTweets?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.relevantTweets.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.relevantTweets?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.relevantTweets.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.relevantTweets.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.buySignalRank.rank === undefined ? (
                      "-"
                    ) : Number(row.buySignalRank?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.buySignalRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.buySignalRank?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.buySignalRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.buySignalRank.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.sellSignalRank.rank === undefined ? (
                      "-"
                    ) : Number(row.sellSignalRank?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.sellSignalRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.sellSignalRank?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.sellSignalRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.sellSignalRank.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.positiveSentiment.rank === undefined ? (
                      "-"
                    ) : Number(row.positiveSentiment?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.positiveSentiment.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.positiveSentiment?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.positiveSentiment.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.positiveSentiment.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.negativeSentiment.rank === undefined ? (
                      "-"
                    ) : Number(row.negativeSentiment?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.negativeSentiment.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.negativeSentiment?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.negativeSentiment.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.negativeSentiment.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.accurancyRank?.rank === undefined ? (
                      "-"
                    ) : Number(row.accurancyRank?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.accurancyRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.accurancyRank?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.accurancyRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.accurancyRank.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.engagementRank?.rank === undefined ? (
                      "-"
                    ) : Number(row.engagementRank?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.engagementRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.engagementRank?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.engagementRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.engagementRank.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.rateRank?.rank === undefined ? (
                      "-"
                    ) : Number(row.rateRank?.delta) > 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.rateRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="success"
                              icon={
                                <FaArrowTrendUp
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : Number(row.rateRank?.delta) < 0 ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Typography
                          color={theme.palette.text.primary}
                          fontWeight={200}
                        >
                          <CountUp end={Number(row.rateRank.rank)} />
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                            flexDirection: "column",
                            marginLeft: "6px",
                          }}
                        >
                          <Typography>
                            <Chip
                              label={row.relevantTweets?.delta}
                              color="error"
                              icon={
                                <FaArrowTrendDown
                                  size={12}
                                  style={{ marginRight: "0px" }}
                                />
                              }
                              variant="filled"
                              sx={{
                                fontSize: "11px",
                                height: "18px",
                                "& span": {
                                  padding: "0 6px",
                                },
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Typography
                        color={theme.palette.text.primary}
                        fontWeight={200}
                      >
                        <CountUp end={Number(row.rateRank.rank)} />
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Loading />
      )}
    </>
  );
};
export default InfluencerFullTable;
