import { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { MdClose } from "react-icons/md";
import Connector from "./connector";
import WalletConnect from "./walletConnect";
import BlockchainsList from "./blockchainsList";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../features/store";
import {
  fetchWalletNetworthStatistics,
  fetchWalletsList,
} from "../../../../features/assetsManagement/assetsManagementSlice";
import { SelectedWalletProps, WalletState } from "../../interfaces";
import {
  addNewWalletHandler,
  fetchWalletBalance,
} from "../../../../api/assets";
import styles from "../connectWallet.module.scss";

const SelectedWallet: React.FC<SelectedWalletProps> = ({
  wallet,
  setSelectedWallet,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch: AppDispatch = useAppDispatch();

  const userState: any = useSelector((state: RootState) => state.account);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );

  const [loading, setLoading] = useState(false);
  const [selectedBlockChain, setSelectedBlockChain] = useState(
    wallet.category === "Blockchain" ? wallet.name.toLocaleLowerCase() : ""
  );

  const connectorHandler = () => async (values: WalletState, formik: any) => {
    const new_wallet_data = {
      user: userState.data.user.pk,
      name: wallet.name,
      category: wallet.category,
      address: values.address.trim(),
      protocol: selectedBlockChain,
    };

    const wallet_data = {
      protocol: selectedBlockChain,
      network: "mainnet",
      wallet_address: values.address.trim(),
    };

    const findWallet = assetsManagementState.wallets?.findIndex(
      (wallet: any) => wallet.address === values.address
    );

    if (findWallet < 0) {
      setLoading(true);

      fetchWalletBalance(wallet_data).then((response) => {
        if (response.error) {
          toast.error(response.errorMessage);
          setLoading(false);
        } else {
          addNewWalletHandler(new_wallet_data).then((response) => {
            if (response.status == 201) {
              dispatch(fetchWalletsList()).then((response) => {
                setLoading(false);
                toast.success("Wallet connected successfully");
                navigate("/asset-tracking/connected-wallets");
              });
            } else {
              toast.error("Something went wrong");
            }
          });
        }
      });
    } else {
      toast.error("This wallet address is already connected");
      navigate("/asset-tracking/connected-wallets");
    }
  };

  return (
    <Box className={styles.selectedWalletWrapper}>
      <Box className={styles.selectedWalletHeader}>
        <Typography
          component="h2"
          variant="body1"
          color={theme.palette.text.primary}
        >
          <img src={wallet.imageUrl} alt={wallet.name} width={40} height={40} />
          {wallet.name}
        </Typography>
        <IconButton onClick={() => setSelectedWallet(null)}>
          <MdClose />
        </IconButton>
      </Box>

      {wallet.category === "WalletConnect" ? (
        <WalletConnect />
      ) : wallet.category === "Wallet" && !selectedBlockChain ? (
        <BlockchainsList
          name={wallet.name}
          supportedCryptoNetworks={wallet.info.supportedCryptoNetworks}
          setSelectedBlockChain={setSelectedBlockChain}
        />
      ) : (
        <Connector
          loading={loading}
          connectorHandler={connectorHandler}
          selectedBlockChain={selectedBlockChain}
        />
      )}
    </Box>
  );
};

export default SelectedWallet;
