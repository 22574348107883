import { useState } from "react";
import { Form, Formik } from "formik";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { useCreateSchema } from "../../../../../utils/useCreateSchema";
import { ConnectorProps, ConnectorState } from "../../../interfaces";
import Input from "../../../../form/Input";
import WalletConnect from "../walletConnect";

const Connector: React.FC<ConnectorProps> = ({
  loading,
  connectorHandler,
  selectedBlockChain,
}) => {
  const theme = useTheme();
  const [initialValues] = useState<ConnectorState>({
    address: "",
  });

  return (
    <>
      <Box>
        <Typography variant="body2" color={theme.palette.grey[500]} my={2}>
          We are only requesting view permissions. <br /> This does not give us
          access to your private keys nor the ability to move your funds.
        </Typography>
      </Box>

      {selectedBlockChain === "ethereum" && (
        <Box mt={4}>
          <Typography
            variant="body1"
            mb={0.5}
            color={theme.palette.text.primary}
          >
            Connect with Wallet Conncet:
          </Typography>
          <WalletConnect hideInfo />
        </Box>
      )}

      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={connectorHandler()}
        validationSchema={useCreateSchema(["address"])}
      >
        {(formik) => (
          <Form id="coin_list_form">
            <Grid item xs={12} mt={2}>
              <Input
                name="address"
                formik={formik}
                label="Public address:"
                hasInlineLabel={true}
                hasInnerLabel={false}
                variant="outlined"
              />
            </Grid>
            <Button
              fullWidth
              variant="contained"
              sx={{
                marginTop: "2rem",
                "&:hover": {
                  background: theme.palette.secondary.main,
                },
              }}
              type="submit"
              disabled={!formik.isValid || loading}
            >
              {loading ? "Connecting ..." : "Connect"}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Connector;
