import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { AssetsManagementState } from "./interfaces";
import {
  openLimitAccessModal,
  setLimitAccessData,
} from "../profile/profiletSlice";

export const fetchWalletsList = createAsyncThunk<
  any,
  void,
  { rejectValue: any }
>("wallets list", async (_, thunkAPI) => {
  try {
    const url = `/wallets/wallet-list/`;
    const response = await http.get(url);
    const data = response.data.response.data.results;
    return data;
  } catch (error: any) {
    let message: string = "";
    switch (error.response.status) {
      case 406:
        message =
          "You’re currently on our free plan. Upgrade your subscription to unlock additional features and benefits.";
        thunkAPI.dispatch(openLimitAccessModal());
        thunkAPI.dispatch(setLimitAccessData({ message }));
        break;
      case 429:
        if (error.response.data.response.data.code === 430) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else if (error.response.data.response.data.code === 431) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else {
          return thunkAPI.rejectWithValue(error);
        }
        break;
      default:
        return thunkAPI.rejectWithValue(error);
    }
  }
});

export const fetchWalletNetworthStatistics = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("networth statistics", async (wallets, thunkAPI) => {
  try {
    const url = `/wallets/networth-statistics/`;
    const response = await http.post(url, wallets);
    const data = response.data.response.data;
    return data;
  } catch (error: any) {
    let message: string = "";
    switch (error.response.status) {
      case 406:
        message =
          "You’re currently on our free plan. Upgrade your subscription to unlock additional features and benefits.";
        thunkAPI.dispatch(openLimitAccessModal());
        thunkAPI.dispatch(setLimitAccessData({ message }));
        break;
      case 429:
        if (error.response.data.response.data.code === 430) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else if (error.response.data.response.data.code === 431) {
          message =
            "Your current subscription limit has been reached. Upgrade your plan to continue using our services without interruption.";
          thunkAPI.dispatch(openLimitAccessModal());
          thunkAPI.dispatch(setLimitAccessData({ message }));
        } else {
          return thunkAPI.rejectWithValue(error);
        }
        break;
      default:
        return thunkAPI.rejectWithValue(error);
    }
  }
});

const initialState: AssetsManagementState = {
  wallets: null,
  networthStatistics: null,
  walletsLoading: true,
  networthStatisticsLoading: false,
  error: null,
};

export const assetsManagementSlice = createSlice({
  name: "Asset Tracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWalletsList.pending, (state) => {
        state.walletsLoading = true;
        state.error = null;
      })
      .addCase(fetchWalletsList.fulfilled, (state, action) => {
        state.walletsLoading = false;
        state.wallets = action.payload;
      })
      .addCase(fetchWalletsList.rejected, (state, action) => {
        state.walletsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
    builder
      .addCase(fetchWalletNetworthStatistics.pending, (state) => {
        state.networthStatisticsLoading = true;
        state.error = null;
      })
      .addCase(fetchWalletNetworthStatistics.fulfilled, (state, action) => {
        state.networthStatisticsLoading = false;
        state.networthStatistics = action.payload;
      })
      .addCase(fetchWalletNetworthStatistics.rejected, (state, action) => {
        state.networthStatisticsLoading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default assetsManagementSlice.reducer;
