import { useNavigate } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import { FaRegCircleCheck } from "react-icons/fa6";
import styles from "./plans.module.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../features/store";
import { PlansProps } from "./interface";
import { useMemo } from "react";
import { isEmpty } from "lodash";
import { IoArrowDown } from "react-icons/io5";
import toast from "react-hot-toast";

const sortMetadataByTruthy = (metadata: {}) => {
  const priorityKeys = ["CAVA-limit", "Alert-limit", "Trade-Bot-Limit"];
  const sortedEntries = Object.entries(metadata).sort(
    ([keyA, valueA], [keyB, valueB]) => {
      const isAKeyPriority = priorityKeys.includes(keyA);
      const isBKeyPriority = priorityKeys.includes(keyB);

      if (isAKeyPriority && !isBKeyPriority) return -1;
      if (!isAKeyPriority && isBKeyPriority) return 1;

      const isATruthy = valueA === "True";
      const isBTruthy = valueB === "True";

      if (isATruthy && !isBTruthy) return -1;
      if (!isATruthy && isBTruthy) return 1;

      return 0;
    }
  );

  return Object.fromEntries(sortedEntries.slice(0, 5));
};

const Plans = ({ duration, pricesData, scrollToFeatures }: PlansProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const membershipState: any = useSelector(
    (state: RootState) => state.membership
  );
  const accountState: any = useSelector((state: RootState) => state.account);

  const sortedProducts = useMemo(() => {
    if (
      membershipState.productsList &&
      !isEmpty(membershipState.productsList)
    ) {
      const sortOrder = ["Free", "Basic", "Silver", "Gold"];
      const copyData = [...membershipState.productsList];

      const sortData = copyData?.sort(
        (a: any, b: any) =>
          sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
      );

      const sortedMetadata = sortData.map((plan: any) => ({
        ...plan,
        metadata: sortMetadataByTruthy(plan.metadata),
      }));

      return sortedMetadata;
    }
  }, [membershipState.productsList]);

  const handleValue = (key: string, val: string): React.ReactElement | null => {
    let element: React.ReactElement | null = null;

    if (val.toLowerCase() === "true") {
      element = <FaRegCircleCheck style={{ color: "#2FDB7D" }} />;
    } else if (val.toLowerCase() === "false") {
      element = <CgCloseO size={18} style={{ color: "#FA637A" }} />;
    } else if (key.toLowerCase() === "cava-limit") {
      element = (
        <Typography variant="caption" color={theme.palette.text.primary}>
          {val} Requests Per Day
        </Typography>
      );
    } else if (key.toLowerCase() === "trade-bot-limit") {
      element = (
        <Typography variant="caption" color={theme.palette.text.primary}>
          {val} Bots Per Day
        </Typography>
      );
    } else {
      element = (
        <Typography variant="caption" color={theme.palette.text.primary}>
          {val}
        </Typography>
      );
    }

    return element;
  };

  const selectBasePrice = (plan: string): number => {
    switch (plan) {
      case "Free":
        return 0;
      case "Basic":
        return 10;
      case "Silver":
        return 20;
      case "Gold":
        return 50;
      default:
        return 0;
    }
  };

  const handleClickSubscribe = (planName: string): void => {
    if (accountState.isAuthenticated) {
      if (planName === "Free") {
        navigate("/");
      } else {
        navigate(
          `/subscription/review?name=${planName}&&interval=${duration.name}&&interval_count=${duration.count}`
        );
      }
    } else {
      toast.error("You’re not logged in! Please sign in to proceed");
    }
  };

  return (
    <Grid container mt={2}>
      {sortedProducts?.map((product: any, index: number) => (
        <Grid item xs={12} md={3} key={product.name}>
          <Slide duration={1500 / (index + 1)} direction="up" triggerOnce>
            <Box
              className={`${styles.pricingItem} ${product.name === "Silver" ? styles.suggested : ""}`}
              sx={{
                background: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.grey[800]
                    : `${theme.palette.primary.main}20`,
              }}
            >
              <Box className={styles.pricingHeader}>
                <Box flexGrow={1}>
                  <Typography color={theme.palette.text.primary} variant="h6">
                    {product?.name}
                  </Typography>
                </Box>
                {pricesData.map((price: any) => {
                  const priceItemName = price.nickname
                    ?.split(" ")
                    ?.at(0)
                    .toLowerCase();
                  if (priceItemName === product?.name?.toLowerCase()) {
                    return (
                      <Box className={styles.price}>
                        {!(
                          duration.name === "month" && duration.count === 1
                        ) && (
                          <Typography
                            sx={{
                              textDecoration: "line-through",
                              textDecorationThickness: "1px",
                            }}
                            variant="h6"
                            color={theme.palette.text.primary}
                          >
                            $
                            {selectBasePrice(product.name) *
                              (duration.name === "month" ? duration.count : 12)}
                          </Typography>
                        )}

                        <Typography
                          variant="h2"
                          color={theme.palette.text.primary}
                        >
                          ${(price?.unit_amount / 100).toFixed(0)}
                        </Typography>
                      </Box>
                    );
                  }
                })}
              </Box>
              <Box className={styles.contentWrapper}>
                {Object.entries(product?.metadata)?.map(
                  (item: any, metadataIndex: number) => (
                    <Box key={metadataIndex}>
                      <Typography
                        variant="caption"
                        color={theme.palette.text.primary}
                      >
                        {item?.at(0)?.split("-")?.join(" ")}
                      </Typography>
                      {handleValue(item?.at(0), item?.at(1))}
                    </Box>
                  )
                )}
                <br />
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    endIcon={<IoArrowDown color={theme.palette.text.primary} />}
                    onClick={scrollToFeatures}
                    sx={{
                      mx: "auto",
                      // background:
                      //   "linear-gradient(180deg, rgba(255,255,255,0) 25%, rgba(0,174,239,0.7) 100%)",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color={theme.palette.text.primary}
                    >
                      See All Feature
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box className={styles.action}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleClickSubscribe(product.name)}
                >
                  <Typography>
                    {product.name === "Free" ? "Try Now" : "Subscribe Now"}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Slide>
        </Grid>
      ))}
    </Grid>
  );
};

export default Plans;
