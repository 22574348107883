import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import DashboardLayout from "../../../layout/dashboardLayout";
import { Slide } from "react-awesome-reveal";
import styles from "./cancel.module.scss";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Cancel: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <DashboardLayout title="Coinfident | Cancle">
      <Box className={styles.cancelPaymentContainer}>
        <Container maxWidth="lg">
          <Slide duration={1500} direction="up" triggerOnce>
            <Box
              className={styles.cancelPayment}
              sx={{
                background: (theme: any) => theme.palette.common.darkPrimary,
                border: "3px solid transparent",
              }}
            >
              <Box>
                <Typography variant="h3" component="h2" mb={2}>
                  Subscription Error
                </Typography>
                <Typography variant="body2" mb={4}>
                  We’re sorry, but we couldn’t save your card info. Please
                  review your details and try again. Alternatively, you can
                  return to the homepage or contact support for further
                  assistance.
                </Typography>
                <Box display={"flex"} gap={2}>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/subscription")}
                  >
                    Try Again
                  </Button>
                  <Button variant="contained" onClick={() => navigate("/")}>
                    Go to Home
                  </Button>
                </Box>
              </Box>
              <Box>
                <MdCancel color={theme.palette.error.main} size={100} />
              </Box>
            </Box>
          </Slide>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Cancel;
