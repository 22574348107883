import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { OverallProps } from "../interfaces";
import { calculateGuageValue } from "../../../utils/functions";
import { RootState } from "../../../features/store";
import DefaultGauge from "../../guage";
import Loading from "../../common/loading";

const Overall: React.FC<OverallProps> = ({ overallSignal }) => {
  const theme = useTheme();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);

  const checkData: number = overallSignal
    ? calculateGuageValue(
        overallSignal[0] ?? 0,
        overallSignal[1] ?? 0,
        overallSignal.null ?? 0,
      )
    : calculateGuageValue(0, 0, 0);

  return (
    <Box
      sx={{
        padding: "1.2rem",
        paddingTop: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        // background: (theme: any) =>
        //   theme.palette.mode == "dark"
        //     ? theme.palette.common.darkPrimary
        //     : theme.palette.primary.light,
        height: "100%",
      }}
    >
      <Box>
        {cryptosState.coinsStatisticsLoading ? (
          <Loading />
        ) : (
          <DefaultGauge
            name="Overall"
            type="technicalSignal"
            value={checkData}
            isEmpty={overallSignal ? false : true}
            // width={300}
            // height={180}
          />
        )}
      </Box>
      <Box
        mt={5}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          component="h4"
          variant="h6"
          align="center"
          color={theme.palette.text.primary}
        >
          Overall SPS
        </Typography>
        <Typography
          mt={0.5}
          variant="body2"
          align="center"
          sx={{ fontWeight: 100, fontSize: ".8rem" }}
          color={theme.palette.text.primary}
        >
          SPS is our proprietary AI-driven index based on crypto influencers'
          and news outlets' tweets
        </Typography>

        {/* <TableTooltip title="Overall SPS is a general index of the average SPS of all coins displayed on Coinfident based on crypto influencers and news outlet’s hourly crypto-related tweets on X platform. The accuracy of this index is 86%.">
          <IconButton>
            <LuInfo
              size={18}
              color={
                theme.palette.mode === "light"
                  ? theme.palette.primary.main
                  : theme.palette.grey[500]
              }
            />
          </IconButton>
        </TableTooltip> */}
      </Box>
    </Box>
  );
};

export default Overall;
