//@ts-nocheck
import { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { GoHeart } from "react-icons/go";
import { TbMessageCircle } from "react-icons/tb";
import { HiOutlineDocumentText } from "react-icons/hi";
import { AiOutlineRetweet } from "react-icons/ai";

const InfluencerDoubleChart = ({
  data,
  averagesData,
  type,
  colors,
}: {
  data: any;
  averagesData: any;
  type: string;
  colors?: any;
}) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const [donutSeries, setDonutSeries] = useState(null);
  const [areaSeries, setAreaSeries] = useState(null);

  const formatLineChartData = (dataArray: any) => {
    const lineChartData = {
      all_tweets: [],
      relevant_tweets: [],
      positive_sentiment: [],
      negative_sentiment: [],
      neutral_sentiment: [],
      buy_signals: [],
      not_buy_signals: [],
      dates: [],
    };

    dataArray.forEach((item: any) => {
      lineChartData.all_tweets.push(item.all_tweets);
      lineChartData.relevant_tweets.push(item.relevant_tweets);
      lineChartData.positive_sentiment.push(item.positive_sentiment);
      lineChartData.negative_sentiment.push(item.negative_sentiment);
      lineChartData.neutral_sentiment.push(item.neutral_sentiment);
      lineChartData.buy_signals.push(item.buy_signals);
      lineChartData.not_buy_signals.push(item.not_buy_signals);
      lineChartData.dates.push(item.created_at__date);
    });

    return lineChartData;
  };

  const calculateTotals = (dataArray: any) => {
    const totals = {
      tweets: 0,
      relevant_tweets: 0,
      positive_sentiment: 0,
      negative_sentiment: 0,
      neutral_sentiment: 0,
      buy_signals: 0,
      not_buy_signals: 0,
    };

    dataArray.forEach((item) => {
      totals.tweets += item.all_tweets;
      totals.relevant_tweets += item.relevant_tweets;
      totals.positive_sentiment += item.positive_sentiment;
      totals.negative_sentiment += item.negative_sentiment;
      totals.neutral_sentiment += item.neutral_sentiment;
      totals.buy_signals += item.buy_signals;
      totals.not_buy_signals += item.not_buy_signals;
    });

    return {
      tweets: [
        { title: "All Tweets", count: totals.tweets },
        { title: "Relevant Tweets", count: totals.relevant_tweets },
      ],
      sentiment: [
        { title: "Positive Sentiment", count: totals.positive_sentiment },
        { title: "Neutral Sentiment", count: totals.neutral_sentiment },
        { title: "Negative Sentiment", count: totals.negative_sentiment },
      ],
      signals: [
        { title: "Buy SPS", count: totals.buy_signals },
        { title: "Not-buy SPS", count: totals.not_buy_signals },
      ],
    };
  };

  useEffect(() => {
    if (data) {
      if (type === "posts") {
        setDonutSeries([
          {
            name: calculateTotals(data)?.tweets[0]?.title,
            count: calculateTotals(data)?.tweets[0]?.count,
          },
          {
            name: calculateTotals(data)?.tweets[1]?.title,
            count: calculateTotals(data)?.tweets[1]?.count,
          },
        ]);

        setAreaSeries([
          {
            name: "All Tweets",
            data: formatLineChartData(data)?.all_tweets,
          },
          {
            name: "Relevant Tweets",
            data: formatLineChartData(data)?.relevant_tweets,
          },
        ]);
      } else if (type === "sentiment") {
        setDonutSeries([
          {
            name: calculateTotals(data)?.sentiment[0]?.title,
            count: calculateTotals(data)?.sentiment[0]?.count,
          },
          {
            name: calculateTotals(data)?.sentiment[1]?.title,
            count: calculateTotals(data)?.sentiment[1]?.count,
          },
          {
            name: calculateTotals(data)?.sentiment[2]?.title,
            count: calculateTotals(data)?.sentiment[2]?.count,
          },
        ]);

        setAreaSeries([
          {
            name: "Positive Sentiment",
            data: formatLineChartData(data)?.positive_sentiment,
          },
          {
            name: "Neutral Sentiment",
            data: formatLineChartData(data)?.neutral_sentiment,
          },
          {
            name: "Negative Sentiment",
            data: formatLineChartData(data)?.negative_sentiment,
          },
        ]);
      } else {
        setDonutSeries([
          {
            name: calculateTotals(data)?.signals[0]?.title,
            count: calculateTotals(data)?.signals[0]?.count,
          },
          {
            name: calculateTotals(data)?.signals[1]?.title,
            count: calculateTotals(data)?.signals[1]?.count,
          },
        ]);

        setAreaSeries([
          {
            name: "Buy SPS",
            data: formatLineChartData(data)?.buy_signals,
          },
          {
            name: "Not-buy SPS",
            data: formatLineChartData(data)?.not_buy_signals,
          },
        ]);
      }
    }
  }, [data]);

  const area_options = {
    chart: {
      height: 180,
      width: "100%",
      fontFamily: "Poppins",
      type: "area",
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: {
        easing: "easeout",
      },
    },
    labels: formatLineChartData(data).dates,
    colors: colors
      ? colors
      : [theme.palette.primary.main, theme.palette.info.main],
    grid: { show: false },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    yaxis: {
      tickAmount: 5,
      labels: {
        show: true,
        style: {
          //@ts-ignore
          colors: theme.palette.common.whiteToGreen,
          fontWeight: 100,
        },
      },
      axisBorder: { show: true },
      axisTicks: { show: false },
    },
    xaxis: {
      tickAmount: 3,
      labels: {
        show: true,
        rotate: 0,
        style: {
          //@ts-ignore
          colors: theme.palette.common.whiteToGreen,
          fontWeight: 100,
          fontSize: ".66rem",
        },
      },
      axisBorder: { show: true },
      axisTicks: { show: false },
    },
    legend: {
      show: true,
      fontSize: "11px",
      fontWeight: 100,
      labels: {
        //@ts-ignore
        colors: theme.palette.common.whiteToGreen,
      },
      markers: {
        size: 5,
        offsetX: -2,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0],
      },
    },
  };

  const donut_options = {
    chart: {
      type: "donut",
      fontFamily: "Poppins",
      fontWeight: 100,
    },
    labels: donutSeries?.map((item) => item.name),
    colors: colors
      ? colors
      : [theme.palette.primary.main, theme.palette.info.main],
    dataLabels: {
      rotate: "100deg",
      style: {
        fontWeight: 500,
        fontSize: ".75rem",
        fontFamily: "Poppins",
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: "55%",
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      fontSize: "9px",
      fontWeight: 100,
      labels: {
        //@ts-ignore
        colors: theme.palette.common.whiteToGreen,
      },
      markers: {
        size: 5,
        offsetX: -2,
      },
    },
  };

  const icon_styles = {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "12px",
    right: "-5px",
  };

  const posts_data = [
    {
      title: "Average Likes",
      count: parseFloat(averagesData?.average_likes?.toFixed(1)),
      icon: (
        <GoHeart
          size={24}
          //@ts-ignore
          style={icon_styles}
        />
      ),
    },
    {
      title: "Average Replies",
      count: parseFloat(averagesData?.average_replies?.toFixed(1)),
      icon: (
        <TbMessageCircle
          size={24}
          //@ts-ignore
          style={icon_styles}
        />
      ),
    },
    {
      title: "Average Posts Retweet",
      count: parseFloat(averagesData?.average_retweets?.toFixed(1)),
      icon: (
        <AiOutlineRetweet
          size={24}
          //@ts-ignore
          style={icon_styles}
        />
      ),
    },
    {
      title: "Posts Per Day",
      count: parseFloat(averagesData?.average_tweet_count_per_day?.toFixed(1)),
      icon: (
        <HiOutlineDocumentText
          size={24}
          //@ts-ignore
          style={icon_styles}
        />
      ),
    },
  ];

  return (
    <>
      <Grid
        container
        mb={2}
        // sx={{
        //   borderBottom: downLg
        //     ? "none"
        //     : `1px solid ${theme.palette.grey[800]}`,
        // }}
      >
        {type === "posts" &&
          posts_data.map((postItem: any, idx: number) => (
            <Grid
              key={postItem.title}
              item
              xl={6}
              xs={6}
              px={2}
              py={1}
              sx={{
                borderLeft: `1px solid ${
                  idx % 2 === 0 ? "transparent" : theme.palette.grey[800]
                }`,
                borderBottom: `1px solid ${theme.palette.grey[800]}`,
                // borderBottom: downLg
                //   ? `1px solid ${theme.palette.grey[800]}`
                //   : "none",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <Typography
                  variant={"h6"}
                  component={"h4"}
                  color={theme.palette.text.primary}
                >
                  {postItem.count?.toLocaleString()}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ fontSize: ".75rem", margin: 0 }}
                  color={theme.palette.text.primary}
                >
                  {postItem.title}
                </Typography>

                {postItem.icon}
              </Box>
            </Grid>
          ))}
      </Grid>

      <Grid container justifyContent="space-between" mt={4}>
        <Grid item xs={12} lg={4}>
          <div id="donut_chart">
            {donutSeries && (
              <ReactApexChart
                //@ts-ignore
                options={donut_options}
                series={donutSeries.map((item) => item.count)}
                type="donut"
                height={240}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={7.8}>
          <div id="area_chart">
            {areaSeries && (
              <ReactApexChart
                //@ts-ignore
                options={area_options}
                series={areaSeries}
                type="area"
                height={220}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default InfluencerDoubleChart;
