import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import UsersImage from "../../../assets/images/europe_users.png";
import { agentsList } from "../../../data/agentsList";
import styles from "../analytics.module.scss";

const ChatBanner = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid container className={styles.chatBannerWrapper}>
      <Grid item xs={12} lg={7} pt={downLg ? 9 : 13}>
        <Typography
          variant="h2"
          color={theme.palette.primary.main}
          sx={{ fontWeight: 900 }}
        >
          CAVA
        </Typography>
        <Typography
          variant="h4"
          color={theme.palette.primary.main}
          sx={{ fontWeight: 900 }}
        >
          Coinfident's Autonomous Virtual Agent
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontWeight: 300, lineHeight: 1.7, pr: downLg ? 0 : 2 }}
          color={theme.palette.text.primary}
          mt={1}
        >
          CAVA empowers you to explore in-depth crypto data, offering detailed
          analytics on influencer signals, price trends, technical indicators,
          security risks, and more
        </Typography>

        <Box mt={downLg ? 4 : 10} mb={2} className={styles.chatBannerAction}>
          <PrimaryButton
            text="TRY NOW"
            size="large"
            linkUrl="/agents"
            isFullWidth={downLg ? true : false}
          />
          <Box className={styles.chatBannerActionInfo}>
            <img src={UsersImage} alt="users" style={{ marginLeft: "1rem" }} />
            <Typography
              ml={1}
              variant="caption"
              color={(theme: any) => theme.palette.common.mute}
            >
              Used by over <br /> 1000 users in the Europe
            </Typography>
          </Box>
        </Box>

        <Swiper
          modules={[Autoplay]}
          spaceBetween={1}
          loop={true}
          speed={5000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          allowTouchMove={false}
          grabCursor={false}
          freeMode={false}
          breakpoints={{
            100: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 5,
            },
          }}
        >
          {agentsList.map((agent) => (
            <SwiperSlide key={agent.id}>
              <Typography
                align="center"
                variant="body2"
                sx={{ fontWeight: 100, whiteSpace: "nowrap" }}
                color={(theme: any) => theme.palette.common.mute}
              >
                {agent.title}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
      {!downLg && (
        <Grid
          item
          xs={12}
          lg={5}
          p={8}
          pr={0}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              height: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: ".5rem",
            }}
          >
            <Box className={styles.agentItem}></Box>
            {agentsList.map((agent) => (
              <Box
                key={agent.id}
                className={styles.agentItem}
                sx={{
                  background: (theme: any) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.common.agentModalBg
                      : theme.palette.common.agentBg,
                }}
              >
                <img src={agent.icon} alt={agent.title} />
              </Box>
            ))}
            <Box className={styles.agentItem}></Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ChatBanner;
