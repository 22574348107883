import { Box, Pagination } from "@mui/material";
import { MuiPaginationProps } from "../interfaces";

const MuiPagination: React.FC<MuiPaginationProps> = ({
  page,
  count,
  handleChange,
}) => {
  return (
    <Box
      sx={{
        marginTop: "1.5rem",
      }}
    >
      <Pagination
        onChange={handleChange}
        page={page}
        count={count}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      />
    </Box>
  );
};

export default MuiPagination;
