import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Form, Formik } from "formik";
import { Grid, IconButton, useTheme } from "@mui/material";
import Input from "../../form/Input";
import PrimaryButton from "../../common/buttons/PrimaryButton";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import { getUserData, updateUserData } from "../../../api/auth";
import { AppDispatch, useAppDispatch } from "../../../features/store";
import { setUserData } from "../../../features/auth/accountSlice";
import { ProfileDataProps, ProfileState } from "../interfaces";
import { LuInfo } from "react-icons/lu";
import Tooltip from "../../common/tooltip";

const ProfileData: React.FC<ProfileDataProps> = (data) => {
  const dispatch: AppDispatch = useAppDispatch();

  const accessToken = localStorage.getItem("access-token");
  const refreshToken = localStorage.getItem("refresh-token");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [initialValues, setInitialValues] = useState<ProfileState>({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
  });

  const submitUserDataHandler =
    () => async (values: ProfileState, formik: any) => {
      setLoading(true);
      updateUserData(values)
        .then(() => {
          getUserData().then((response) => {
            setLoading(false);
            toast.success("Your information has been updated");
            setInitialValues({
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              phone_number: response.data.phone_number,
              email: response.data.email,
            });

            const userData = {
              access: accessToken,
              refresh: refreshToken,
              user: response.data,
            };
            //@ts-ignore
            dispatch(setUserData(userData));
          });
        })
        .catch((err) => {
          toast.error("Can't update data");
          if (err.response.data) {
            Object.entries(err.response.data).forEach(([field, errors]) => {
              //@ts-ignore
              errors.forEach((error: string) => {
                formik.setFieldError(field, error);
              });
            });
          }
          setLoading(false);
        });
    };

  useEffect(() => {
    if (data.data.data)
      setInitialValues({
        first_name: data.data.data.user.first_name ?? "",
        last_name: data.data.data.user.last_name ?? "",
        phone_number: data.data.data.user.phone_number ?? "",
        email: data.data.data.user.email ?? "",
      });
  }, [data]);
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={true}
      enableReinitialize={true}
      validationSchema={useCreateSchema([
        "first_name",
        "last_name",
        "email",
        "phone_number",
      ])}
      onSubmit={submitUserDataHandler()}
    >
      {(formik) => (
        <Form id="profile_form">
          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12}>
              <Input
                name="first_name"
                formik={formik}
                label="First name:"
                hasInlineLabel={true}
                hasInnerLabel={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="last_name"
                formik={formik}
                label="Last name:"
                hasInlineLabel={true}
                hasInnerLabel={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="phone_number"
                formik={formik}
                label={`Phone number:`}
                icon={
                  <Tooltip
                    title={
                      "Your phone number is optional but will help verify your identity for secure trading."
                    }
                  >
                    <IconButton size="small">
                      <LuInfo
                        size={13}
                        color={
                          theme.palette.mode === "light"
                            ? theme.palette.primary.main
                            : theme.palette.grey[500]
                        }
                      />
                    </IconButton>
                  </Tooltip>
                }
                hasInlineLabel={true}
                hasInnerLabel={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="email"
                formik={formik}
                label="Email:"
                hasInlineLabel={true}
                hasInnerLabel={false}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                text="Submit"
                disabled={formik.isValid ? false : true}
                isFullWidth
                loading={loading}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileData;
