import { Box, Grid, Typography, useTheme } from "@mui/material";
import { MdOutlineShowChart } from "react-icons/md";
import { TbChartDonut4 } from "react-icons/tb";
import { TiChartBar } from "react-icons/ti";
import { formatNumber, formatPrice } from "../../../utils/functions";
import { cryptoItemProps, CryptoStatisticsProps } from "../interfaces";

const CryptoStatistics: React.FC<CryptoStatisticsProps> = ({ data }) => {
  const theme = useTheme();

  const cryptoInfoData = [
    {
      id: 1,
      title: "24h %",
      icon: <MdOutlineShowChart size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "24h %",
      popupContent: "The direction and value change in the last 24 hours",
      value: `${Number(data?.data.changePercent24Hr).toFixed(2)}%`,
    },
    {
      id: 2,
      title: "Market cap",
      icon: <MdOutlineShowChart size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "Market cap",
      popupContent:
        "The total market value of a cryptocurrency's circulating supply.",
      value: `${formatNumber(Number(data?.data.marketCapUsd))}`,
    },
    {
      id: 3,
      title: "Volume",
      icon: <TiChartBar size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "Volume",
      popupContent:
        "A measure of how much of a cryptocurrency was traded in the last 24 hours.",
      value: `${formatNumber(Number(data?.data.volumeUsd24Hr))}`,
    },
    {
      id: 4,
      title: "Supply",
      icon: <TbChartDonut4 size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "Supply",
      popupContent:
        "The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.",
      value: `${formatNumber(Number(data?.data.supply))}`,
    },
    {
      id: 5,
      title: "Max supply",
      icon: <TbChartDonut4 size={20} style={{ marginRight: "4px" }} />,
      popupTitle: " Max supply",
      popupContent: "Total quantity of asset issued.",
      value: `${formatNumber(Number(data?.data.maxSupply))}`,
    },
    {
      id: 6,
      title: "VWAP 24Hr",
      icon: <MdOutlineShowChart size={20} style={{ marginRight: "4px" }} />,
      popupTitle: "VWAP 24Hr",
      popupContent: "This number is directly associated with the marketcap",
      value: `$${formatPrice(Number(data?.data.vwap24Hr))}`,
    },
  ];

  return (
    <Grid container>
      {cryptoInfoData.map((cryptoItem: cryptoItemProps) => (
        <Grid item xs={6} md={4} p={0.4} key={cryptoItem.id}>
          <Box
            px={1}
            py={0.6}
            sx={{
              background: theme.palette.background.paper,
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: theme.palette.common.black,
                fontWeight: 100,
              }}
            >
              {cryptoItem.title}
            </Typography>
            <Typography
              variant="caption"
              component="h5"
              mt={0.5}
              sx={{ color: theme.palette.text.primary, fontWeight: 100 }}
            >
              {cryptoItem.value}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CryptoStatistics;
