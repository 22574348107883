import http from "../services/http";

export const pinInfluencer = (influencer: string | number | undefined) => {
  const url = "/crypto/pinned-influencer-create/";
  const requestBody = { influencer };

  return http.post(url, requestBody);
};

export const removeInfluencerPin = (
  influencer: string | number | undefined,
) => {
  const url = "/crypto/pinned-influencer-destroy/";
  const requestBody = { influencer };

  //@ts-ignore
  return http.delete(url, { data: requestBody });
};
