import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { IoArrowBackCircle } from "react-icons/io5";
import DashboardLayout from "../../layout/dashboardLayout";
import Loading from "../../components/common/loading";
import AssetsTable from "../../components/common/assetsTable";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchCoinsStatistics,
  fetchPinnedCoins,
  setCoinStatistics,
} from "../../features/crypto/cryptosSlice";
import useAuth from "../../utils/useAuth";

const Prices = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const isAuthValid = useAuth();
  const cryptosState = useSelector((state: RootState) => state.cryptos);
  const account: any = useSelector((state: RootState) => state.account);

  useEffect(() => {
    if (isAuthValid) {
      if (cryptosState.availableCoins) {
        if (!cryptosState.coinsStatistics) {
          dispatch(fetchCoinsStatistics());
        }
      }
    }
  }, [cryptosState.availableCoins, isAuthValid]);

  useEffect(() => {
    if (account.isAuthenticated) {
      if (!cryptosState.pinnedCoins) dispatch(fetchPinnedCoins());
    }
  }, [account.isAuthenticated, cryptosState.pinnedCoins]);

  return (
    <DashboardLayout title="Coinfident | Assets">
      <Box position="relative">
        <Box position="absolute" left={0} top={-5}>
          <IconButton onClick={() => navigate(-1)}>
            <IoArrowBackCircle size={30} color={theme.palette.primary.main} />
          </IconButton>
        </Box>
        <Typography
          variant="h5"
          align="center"
          mb={4}
          color={theme.palette.text.primary}
        >
          Assets List
        </Typography>
      </Box>
      {cryptosState.coinsStatisticsLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            margin: "1rem auto",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "16px",
            overflow: "hidden",
            margin: "2rem 0",
            boxSizing: "border-box",
          }}
        >
          {
            //@ts-ignore
            <AssetsTable
              type="Home"
              //@ts-ignore
              data={cryptosState.coinStatisticsState.result?.at(0)?.data}
            />
          }
        </Box>
      )}
    </DashboardLayout>
  );
};

export default Prices;
