import DashboardLayout from "../../../layout/dashboardLayout";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PrimaryButton from "../../../components/common/buttons/PrimaryButton";
import {
  createBot,
  fetchInAgentBotDetial,
  fetchInAgentTable,
  setBotData,
} from "../../../features/agent/agentBotSlice";
import { useCallback, useEffect, useState } from "react";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { useSelector } from "react-redux";
import {
  formatNumberThree,
  getCoinImage,
  getCoinName,
} from "../../../utils/functions";
import styles from "../agents.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { AgentBotProps } from "../../../features/agent/interfaces";
import useAuth from "../../../utils/useAuth";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import { TbTableOff } from "react-icons/tb";
import http from "../../../services/http";
import DefaultModal from "../../../components/common/modal";
import LineChart from "../../../components/charts/LineChart";
import { MdExpandMore } from "react-icons/md";
interface Transaction {
  id: number;
  status: string;
  balance: number;
  createdDat: string;
}

export const BotStatus = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isAuthValid = useAuth();
  const AgentBotState = useSelector((state: RootState) => state.BotSlice);
  const [open, setOpen] = useState(false);
  const dispatch: AppDispatch = useAppDispatch();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const [coin_name, setCoinName] = useState<number[]>([]);
  const [initial_balance, setInitialBalance] = useState<number | undefined>();
  const [duration, setDuration] = useState<number | undefined>();
  const [rules, setRules] = useState({
    SPS: { value: [20, 37], enabled: false },
    technicalScore: { value: [20, 37], enabled: false },
    sentiment: { value: [20, 37], enabled: false },
    priceChange: { value: [20, 37], enabled: false },
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [response, setResponse] = useState();
  const convertToNewStructure = (params: typeof rules) => {
    return {
      sps: {
        lower: params.SPS.value[0],
        upper: params.SPS.value[1],
        enabled: params.SPS.enabled ? true : false,
      },
      price_change: {
        lower: params.priceChange.value[0],
        upper: params.priceChange.value[1],
        enabled: params.priceChange.enabled ? true : false,
      },
      sentiment_score: {
        enabled: params.sentiment.enabled ? true : false,
        negative_upper: params.sentiment.value[0],
        positive_upper: params.sentiment.value[1],
      },
      technical_score: {
        enabled: params.technicalScore.enabled ? true : false,
        buy_upper: params.technicalScore.value[0],
        sell_upper: params.technicalScore.value[1],
      },
    };
  };

  const newStructure = convertToNewStructure(rules);
  const [stop_loss_portfolio_value, setStopLossPortfolioValue] = useState<
    number | undefined
  >();
  const [stop_loss_price, setStopLossPrice] = useState<number | undefined>();
  const [trade_percentage, setTradePercentage] = useState<number | undefined>();
  const [total_portfolio, setTotal_portfolio] = useState<number | undefined>();
  const [btnDis, setBtnDis] = useState(true);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  type TradeParametersKey = keyof typeof rules;

  const handleSliderEnabledChange = useCallback(
    (e: any, param: TradeParametersKey) => {
      setRules((prev) => ({
        ...prev,
        [param]: {
          ...prev[param],
          enabled: e.target.checked,
          value: e.target.checked ? prev[param].value : [0, 0], // reset value when disabled
        },
      }));
    },
    []
  );

  const handleParameterChange = useCallback(
    (e: any, param: TradeParametersKey, newValue: number | number[]) => {
      setRules((prev) => ({
        ...prev,
        [param]: {
          ...prev[param],
          value: newValue, // update value for the parameter
        },
      }));
    },
    []
  );
  useEffect(() => {
    if (isAuthValid) {
      const isRulesValid = Object.values(rules).some(
        (rule) => rule.enabled === true
      );
      const isBotDataValid =
        coin_name[0] &&
        initial_balance &&
        duration &&
        trade_percentage &&
        isRulesValid &&
        stop_loss_portfolio_value &&
        stop_loss_price;
      setBtnDis(!isBotDataValid);
      const calc_total_p =
        initial_balance &&
        trade_percentage &&
        (initial_balance * trade_percentage) / 100;
      setTotal_portfolio(calc_total_p);
    }
  }, [
    coin_name,
    initial_balance,
    duration,
    trade_percentage,
    rules,
    stop_loss_portfolio_value,
    stop_loss_price,
  ]);

  const handleBotClick = () => {
    const botData: AgentBotProps = {
      duration,
      coin_name,
      initial_balance,
      total_portfolio,
      rules: newStructure,
      trade_percentage,
      stop_loss_portfolio_value,
      stop_loss_price,
    };

    dispatch(setBotData(botData));
    dispatch(createBot())
      .then(() => {
        // After the bot is created, close the modal and fetch updated data
        setOpen(false);
        dispatch(fetchInAgentTable()); // Fetch the latest bot data
      })
      .catch((error) => {
        // Handle errors if any
        console.error("Error creating bot:", error);
      });
  };
  useEffect(() => {
    if (isAuthValid) {
      dispatch(fetchInAgentTable());
    }
  }, [isAuthValid]);

  const error = () => {
    // @ts-ignore
    if (trade_percentage > 100) {
      return true;
    }
  };
  // @ts-ignore
  const HandleTableRowClick = (id: number) => {
    navigate(`/agentStatus/${id}`);
    dispatch(fetchInAgentBotDetial(id));
  };
  const handleCoinSelectChange = async (e: any) => {
    setCoinName([Number(e.target.value)]);
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const last3Day = `${year}-${month}-${String(today.getDate() - 3).padStart(2, "0")}`;
    const url = `/crypto/trade-parameters/?coin_id=${Number(e.target.value)}&start=${last3Day}&end=${formattedDate}`;
    const response = await http.get(url);
    setResponse(response.data);
  };
  // @ts-ignore
  const SPS = response?.sps.map((item: any) => ({
    x: item.date,
    y: parseFloat(item.sps.toFixed(4)),
  }));

  // @ts-ignore
  const Tecnical = response?.technical.map((item: any) => ({
    x: item.date,
    y: parseFloat(item.technical.toFixed(4)),
  }));

  // @ts-ignore
  const Sentiment = response?.sentiment.map((item: any) => ({
    x: item.date,
    y: parseFloat(item.average_sentiment.toFixed(4)),
  }));

  // @ts-ignore
  const PriceData = response?.price_data.map((item: any) => ({
    x: item.date,
    y: parseFloat(item.avg_price.toFixed(4)),
  }));

  const handleNavigateToAi = () => {
    navigate("/agents", { state: true });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const sortedData = [...AgentBotState.TableData].sort((a: any, b: any) => {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    return dateB - dateA;
  });

  const paginatedData = sortedData.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );
  // @ts-ignore
  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            color={theme.palette.text.primary}
            sx={{ marginBottom: "30px" }}
          >
            Agents transactions status
          </Typography>
        </Box>
        <Grid container>
          <Grid item sm={2}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setOpen(true)}
              sx={{
                maxWidth: "200px",
                marginBottom: "15px",
              }}
              startIcon={
                <FaPlus
                  style={{ width: "10px", height: "10px", marginLeft: "5px" }}
                />
              }
            >
              create your bot
            </Button>
          </Grid>
          <Grid item sm={2}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleNavigateToAi}
              sx={{
                maxWidth: "200px",
                marginBottom: "15px",
              }}
              startIcon={
                <FaPlus
                  style={{ width: "10px", height: "10px", marginLeft: "5px" }}
                />
              }
            >
              create your bot With AI
            </Button>
          </Grid>
        </Grid>
        {open && downLg ? (
          <Box>
            <DefaultModal
              maxWidth="50"
              title={"Trading Parameter Configuration"}
              open={open}
              setOpen={setOpen}
              content={
                <Box
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                    padding: 2,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: "#00AEEF" }}
                    gutterBottom
                  >
                    Trading Parameter Configuration
                  </Typography>

                  {/* Select Coin */}
                  <FormControl
                    fullWidth
                    margin="normal"
                    sx={{ marginTop: "40px" }}
                  >
                    <InputLabel sx={{ color: "#00AEEF" }}>
                      Select Coin
                    </InputLabel>
                    <Select
                      value={coin_name.length > 0 ? coin_name[0] : ""}
                      onChange={handleCoinSelectChange}
                      label="Select Coin"
                      required
                      sx={{
                        color: "#00AEEF",
                        "& .MuiMenuItem-root": {
                          color: "#00AEEF",
                        },
                      }}
                    >
                      {cryptosState.availableCoins?.map((c: any) => {
                        if (c.calc_model === true) {
                          return (
                            <MenuItem
                              value={c.id}
                              sx={{
                                backgroundColor: (theme: any) =>
                                  theme.palette.common.agentBg,
                                "&:hover": {
                                  backgroundColor: (theme: any) =>
                                    theme.palette.primary.main,
                                  color: (theme: any) =>
                                    theme.palette.text.primary,
                                },
                                "&.Mui-selected": {
                                  backgroundColor: (theme: any) =>
                                    theme.palette.common.chatInput,
                                  color: (theme: any) =>
                                    theme.palette.common.white,
                                },
                              }}
                            >
                              <img
                                src={getCoinImage(c.id)}
                                alt={c.logo}
                                className={styles.coinLogo}
                              />
                              {c.name}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>

                  <Grid container spacing={2} sx={{ marginTop: "40px" }}>
                    {/* First Column: Existing Fields */}
                    <Grid item xs={12} md={6}>
                      {/* Initial Balance */}
                      <TextField
                        sx={{ marginTop: "20px" }}
                        id="standard-number"
                        type="number"
                        variant="standard"
                        fullWidth
                        label="Initial Balance"
                        value={initial_balance || null}
                        onChange={(e) =>
                          setInitialBalance(Number(e.target.value))
                        }
                        InputLabelProps={{ style: { color: "#00AEEF" } }}
                        InputProps={{ style: { color: "#00AEEF" } }}
                        required
                      />

                      {/* Auto-Trading Duration */}
                      <TextField
                        sx={{ marginTop: "20px" }}
                        fullWidth
                        margin="normal"
                        label="Auto-Trading Duration (days)"
                        value={duration || null}
                        onChange={(e) => setDuration(Number(e.target.value))}
                        type="number"
                        variant="standard"
                        InputLabelProps={{ style: { color: "#00AEEF" } }}
                        InputProps={{ style: { color: "#00AEEF" } }}
                        required
                      />

                      {/* Trading Parameters */}
                      <Typography
                        sx={{ marginTop: "20px", color: "#00AEEF" }}
                        variant="h6"
                        gutterBottom
                      >
                        Trading Parameters
                      </Typography>
                      <FormGroup>
                        {/* SPS Slider with Checkbox */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            label={
                              <Typography
                                variant="caption"
                                sx={{ color: "#00AEEF" }}
                              >
                                SPS{" "}
                              </Typography>
                            }
                            sx={{ direction: "ltr", color: "#00AEEF" }}
                            control={
                              <Checkbox
                                checked={rules.SPS.enabled}
                                onChange={(e) =>
                                  handleSliderEnabledChange(e, "SPS")
                                }
                                color="primary"
                              />
                            }
                          />
                          <Slider
                            track="inverted"
                            sx={{ width: "50%", color: "#00AEEF" }}
                            value={rules.SPS.value}
                            onChange={(e, newValue) =>
                              handleParameterChange(e, "SPS", newValue)
                            }
                            name="SPS"
                            aria-label="SPS range"
                            valueLabelDisplay="auto"
                            disabled={!rules.SPS.enabled}
                          />
                        </Box>

                        {/* Technical Score Slider with Checkbox */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            label={
                              <Typography
                                variant="caption"
                                sx={{ color: "#00AEEF" }}
                              >
                                Technical Score{" "}
                              </Typography>
                            }
                            sx={{ direction: "ltr", color: "#00AEEF" }}
                            control={
                              <Checkbox
                                checked={rules.technicalScore.enabled}
                                onChange={(e) =>
                                  handleSliderEnabledChange(e, "technicalScore")
                                }
                                color="primary"
                              />
                            }
                          />
                          <Slider
                            track="inverted"
                            sx={{ width: "50%", color: "#00AEEF" }}
                            value={rules.technicalScore.value}
                            onChange={(e, newValue) =>
                              handleParameterChange(
                                e,
                                "technicalScore",
                                newValue
                              )
                            }
                            name="technicalScore"
                            aria-label="Technical Score range"
                            valueLabelDisplay="auto"
                            disabled={!rules.technicalScore.enabled}
                          />
                        </Box>

                        {/* Sentiment Slider with Checkbox */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            label={
                              <Typography
                                variant="caption"
                                sx={{ color: "#00AEEF" }}
                              >
                                Sentiment{" "}
                              </Typography>
                            }
                            sx={{ direction: "ltr", color: "#00AEEF" }}
                            control={
                              <Checkbox
                                checked={rules.sentiment.enabled}
                                onChange={(e) =>
                                  handleSliderEnabledChange(e, "sentiment")
                                }
                                color="primary"
                              />
                            }
                          />
                          <Slider
                            track="inverted"
                            sx={{ width: "50%", color: "#00AEEF" }}
                            value={rules.sentiment.value}
                            onChange={(e, newValue) =>
                              handleParameterChange(e, "sentiment", newValue)
                            }
                            name="sentiment"
                            aria-label="Sentiment range"
                            valueLabelDisplay="auto"
                            disabled={!rules.sentiment.enabled}
                          />
                        </Box>

                        {/* Price Change Slider with Checkbox */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            label={
                              <Typography
                                variant="caption"
                                sx={{ color: "#00AEEF" }}
                              >
                                Price Change
                              </Typography>
                            }
                            sx={{ direction: "ltr", color: "#00AEEF" }}
                            control={
                              <Checkbox
                                checked={rules.priceChange.enabled}
                                onChange={(e) =>
                                  handleSliderEnabledChange(e, "priceChange")
                                }
                                color="primary"
                              />
                            }
                          />
                          <Slider
                            track="inverted"
                            sx={{ width: "50%", color: "#00AEEF" }}
                            value={rules.priceChange.value}
                            onChange={(e, newValue) =>
                              handleParameterChange(e, "priceChange", newValue)
                            }
                            name="priceChange"
                            aria-label="Price Change range"
                            valueLabelDisplay="auto"
                            disabled={!rules.priceChange.enabled}
                          />
                        </Box>
                      </FormGroup>

                      {/* Trade Percentage */}
                      <TextField
                        sx={{ marginTop: "20px" }}
                        fullWidth
                        margin="normal"
                        label="Trade Percentage"
                        InputLabelProps={{ style: { color: "#00AEEF" } }}
                        InputProps={{ style: { color: "#00AEEF" } }}
                        value={trade_percentage || null}
                        onChange={(e) =>
                          setTradePercentage(Number(e.target.value))
                        }
                        type="number"
                        variant="standard"
                        required
                      />

                      {/* Stop Loss Portfolio Value */}
                      <TextField
                        sx={{ marginTop: "20px" }}
                        fullWidth
                        margin="normal"
                        label="Stop Loss Portfolio Value"
                        InputLabelProps={{ style: { color: "#00AEEF" } }}
                        InputProps={{ style: { color: "#00AEEF" } }}
                        value={stop_loss_portfolio_value || null}
                        onChange={(e) =>
                          setStopLossPortfolioValue(Number(e.target.value))
                        }
                        type="number"
                        variant="standard"
                      />

                      {/* Stop Loss Price */}
                      <TextField
                        sx={{ marginTop: "20px" }}
                        fullWidth
                        margin="normal"
                        label="Stop Loss Price"
                        InputLabelProps={{ style: { color: "#00AEEF" } }}
                        InputProps={{ style: { color: "#00AEEF" } }}
                        value={stop_loss_price || null}
                        onChange={(e) =>
                          setStopLossPrice(Number(e.target.value))
                        }
                        type="number"
                        variant="standard"
                      />
                    </Grid>
                  </Grid>

                  {/* Accordion Section */}
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ color: "#00AEEF" }}>SPS</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LineChart
                        showAxis={true}
                        name="SPS"
                        data={SPS}
                        height={150}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ color: "#00AEEF" }}>
                        Technical Score
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LineChart
                        color={["#FF5733"]}
                        showAxis={true}
                        name="Technical"
                        data={Tecnical}
                        height={150}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography sx={{ color: "#00AEEF" }}>
                        Sentiment
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LineChart
                        color={["#ffdd33"]}
                        showAxis={true}
                        name="Sentiment"
                        data={Sentiment}
                        height={150}
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<MdExpandMore />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography sx={{ color: "#00AEEF" }}>Price</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <LineChart
                        color={["#30f400"]}
                        showAxis={true}
                        name="Price"
                        data={PriceData}
                        height={150}
                      />
                    </AccordionDetails>
                  </Accordion>

                  <PrimaryButton
                    text={"Create Bot"}
                    clickHandler={handleBotClick}
                    customstyle={{ marginTop: "20px" }}
                    disabled={btnDis || error()}
                  />
                </Box>
              }
            />
          </Box>
        ) : (
          <>
            <Box>
              <DefaultModal
                maxWidth="60%"
                title={"Trading Parameter Configuration"}
                open={open}
                setOpen={setOpen}
                content={
                  <Box
                    sx={{
                      width: "100%",
                      margin: "0 auto",
                      padding: 2,
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ color: "#00AEEF" }}
                      gutterBottom
                    >
                      Trading Parameter Configuration
                    </Typography>

                    {/* Select Coin */}
                    <FormControl
                      fullWidth
                      margin="normal"
                      sx={{ marginTop: "40px" }}
                    >
                      <InputLabel sx={{ color: "#00AEEF" }}>
                        Select Coin
                      </InputLabel>
                      <Select
                        value={coin_name.length > 0 ? coin_name[0] : ""}
                        onChange={handleCoinSelectChange}
                        label="Select Coin"
                        required
                        sx={{
                          color: "#00AEEF",
                          "& .MuiMenuItem-root": {
                            color: "#00AEEF",
                          },
                        }}
                      >
                        {cryptosState.availableCoins?.map((c: any) => {
                          if (c.calc_model === true) {
                            return (
                              <MenuItem
                                value={c.id}
                                sx={{
                                  backgroundColor: (theme: any) =>
                                    theme.palette.common.agentBg,
                                  "&:hover": {
                                    backgroundColor: (theme: any) =>
                                      theme.palette.primary.main,
                                    color: (theme: any) =>
                                      theme.palette.common.white,
                                  },
                                  "&.Mui-selected": {
                                    backgroundColor: (theme: any) =>
                                      theme.palette.common.chatInput,
                                    color: (theme: any) =>
                                      theme.palette.common.white,
                                  },
                                }}
                              >
                                <img
                                  src={getCoinImage(c.id)}
                                  alt={c.logo}
                                  className={styles.coinLogo}
                                />
                                {c.name}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>

                    <Grid container spacing={2} sx={{ marginTop: "40px" }}>
                      {/* First Column: Existing Fields */}
                      <Grid item xs={12} md={6}>
                        {/* Initial Balance */}
                        <TextField
                          sx={{ marginTop: "20px" }}
                          id="standard-number"
                          type="number"
                          variant="standard"
                          fullWidth
                          InputLabelProps={{ style: { color: "#00AEEF" } }}
                          InputProps={{ style: { color: "#00AEEF" } }}
                          label="Initial Balance"
                          value={initial_balance || null}
                          onChange={(e) =>
                            setInitialBalance(Number(e.target.value))
                          }
                          required
                        />

                        {/* Auto-Trading Duration */}
                        <TextField
                          sx={{ marginTop: "20px" }}
                          fullWidth
                          margin="normal"
                          label="Auto-Trading Duration (days)"
                          value={duration || null}
                          InputLabelProps={{ style: { color: "#00AEEF" } }}
                          InputProps={{ style: { color: "#00AEEF" } }}
                          onChange={(e) => setDuration(Number(e.target.value))}
                          type="number"
                          variant="standard"
                          required
                        />

                        {/* Trading Parameters */}
                        <Typography
                          sx={{ marginTop: "20px" }}
                          variant="h6"
                          gutterBottom
                        >
                          Trading Parameters
                        </Typography>
                        <FormGroup>
                          {/* SPS Slider with Checkbox */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              label={
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#00AEEF" }}
                                >
                                  SPS{" "}
                                </Typography>
                              }
                              sx={{ direction: "ltr", color: "#00AEEF" }}
                              control={
                                <Checkbox
                                  checked={rules.SPS.enabled}
                                  onChange={(e) =>
                                    handleSliderEnabledChange(e, "SPS")
                                  }
                                  color="primary"
                                />
                              }
                            />
                            <Slider
                              track="inverted"
                              sx={{ width: "50%", color: "#00AEEF" }}
                              value={rules.SPS.value}
                              onChange={(e, newValue) =>
                                handleParameterChange(e, "SPS", newValue)
                              }
                              name="SPS"
                              aria-label="SPS range"
                              valueLabelDisplay="auto"
                              disabled={!rules.SPS.enabled}
                            />
                          </Box>
                          {/* Technical Score Slider with Checkbox */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              label={
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#00AEEF" }}
                                >
                                  Technical Score{" "}
                                </Typography>
                              }
                              sx={{ direction: "ltr", color: "#00AEEF" }}
                              control={
                                <Checkbox
                                  checked={rules.technicalScore.enabled}
                                  onChange={(e) =>
                                    handleSliderEnabledChange(
                                      e,
                                      "technicalScore"
                                    )
                                  }
                                  color="primary"
                                />
                              }
                            />
                            <Slider
                              track="inverted"
                              sx={{ width: "50%", color: "#00AEEF" }}
                              value={rules.technicalScore.value}
                              onChange={(e, newValue) =>
                                handleParameterChange(
                                  e,
                                  "technicalScore",
                                  newValue
                                )
                              }
                              name="technicalScore"
                              aria-label="Technical Score range"
                              valueLabelDisplay="auto"
                              disabled={!rules.technicalScore.enabled}
                            />
                          </Box>
                          {/* Sentiment Slider with Checkbox */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              label={
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#00AEEF" }}
                                >
                                  Sentiment{" "}
                                </Typography>
                              }
                              sx={{ direction: "ltr", color: "#00AEEF" }}
                              control={
                                <Checkbox
                                  checked={rules.sentiment.enabled}
                                  onChange={(e) =>
                                    handleSliderEnabledChange(e, "sentiment")
                                  }
                                  color="primary"
                                />
                              }
                            />
                            <Slider
                              track="inverted"
                              sx={{ width: "50%", color: "#00AEEF" }}
                              value={rules.sentiment.value}
                              onChange={(e, newValue) =>
                                handleParameterChange(e, "sentiment", newValue)
                              }
                              name="sentiment"
                              aria-label="Sentiment range"
                              valueLabelDisplay="auto"
                              disabled={!rules.sentiment.enabled}
                            />
                          </Box>
                          {/* Price Change Slider with Checkbox */}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              label={
                                <Typography
                                  variant="caption"
                                  sx={{ color: "#00AEEF" }}
                                >
                                  Price Change
                                </Typography>
                              }
                              sx={{
                                direction: "ltr",
                                height: "45px",
                                color: "#00AEEF",
                              }}
                              control={
                                <Checkbox
                                  checked={rules.priceChange.enabled}
                                  onChange={(e) =>
                                    handleSliderEnabledChange(e, "priceChange")
                                  }
                                  color="primary"
                                />
                              }
                            />
                            <Slider
                              track="inverted"
                              sx={{
                                width: "50%",
                                textAlign: "center",
                                color: "#00AEEF",
                              }}
                              value={rules.priceChange.value}
                              onChange={(e, newValue) =>
                                handleParameterChange(
                                  e,
                                  "priceChange",
                                  newValue
                                )
                              }
                              min={-100}
                              name="priceChange"
                              aria-label="Price Change Percentage"
                              valueLabelDisplay="auto"
                              disabled={!rules.priceChange.enabled}
                            />
                          </Box>
                        </FormGroup>

                        {/* Trade Percentage */}
                        <TextField
                          sx={{ marginTop: "20px" }}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ style: { color: "#00AEEF" } }}
                          InputProps={{ style: { color: "#00AEEF" } }}
                          label="Trade Percentage"
                          value={trade_percentage || null}
                          onChange={(e) =>
                            setTradePercentage(Number(e.target.value))
                          }
                          type="number"
                          variant="standard"
                          required
                        />

                        {/* Stop Loss Portfolio Value */}
                        <TextField
                          sx={{ marginTop: "20px" }}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ style: { color: "#00AEEF" } }}
                          InputProps={{ style: { color: "#00AEEF" } }}
                          label="Stop Loss Portfolio Value"
                          value={stop_loss_portfolio_value || null}
                          onChange={(e) =>
                            setStopLossPortfolioValue(Number(e.target.value))
                          }
                          type="number"
                          variant="standard"
                        />

                        {/* Stop Loss Price */}
                        <TextField
                          sx={{ marginTop: "20px" }}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ style: { color: "#00AEEF" } }}
                          InputProps={{ style: { color: "#00AEEF" } }}
                          label="Stop Loss Price"
                          value={stop_loss_price || null}
                          onChange={(e) =>
                            setStopLossPrice(Number(e.target.value))
                          }
                          type="number"
                          variant="standard"
                        />
                      </Grid>

                      {/* Second Column: Empty Space for Future Data */}
                      <Grid item xs={12} md={6}>
                        <Grid
                          item
                          md={12}
                          sx={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <LineChart
                            showAxis={true}
                            name="SPS"
                            data={SPS}
                            height={150}
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          sx={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <LineChart
                            color={["#FF5733"]}
                            showAxis={true}
                            name="Tecnical Score"
                            height={150}
                            data={Tecnical}
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          sx={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <LineChart
                            color={["#ffdd33"]}
                            showAxis={true}
                            name="Sentiment"
                            height={150}
                            data={Sentiment}
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          sx={{ marginTop: "20px", marginBottom: "20px" }}
                        >
                          <LineChart
                            color={["#30f400"]}
                            showAxis={true}
                            name="PriceData"
                            height={150}
                            data={PriceData}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <PrimaryButton
                      text={"Create Bot"}
                      clickHandler={handleBotClick}
                      customstyle={{ marginTop: "20px" }}
                      disabled={btnDis || error()}
                    />
                  </Box>
                }
              />
            </Box>
          </>
        )}
        {AgentBotState.TableData.length ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Bot Id</TableCell>
                  <TableCell align="center">Coin</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Initial Balance</TableCell>
                  <TableCell align="center">Curent Balance</TableCell>
                  <TableCell align="center">Created Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedData.map((row: any, index: number) => {
                  const globalIndex = currentPage * rowsPerPage + index + 1;

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                        ":hover": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? theme.palette.primary.light
                              : theme.palette.primary.main,
                        },
                      }}
                      onClick={() => HandleTableRowClick(row.id)}
                    >
                      <TableCell component="th" scope="row">
                        {globalIndex}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          className={styles.coinLogo}
                          src={getCoinImage(row.coin_name)}
                        />
                        <Typography sx={{ color: "white" }}>
                          {getCoinName(
                            cryptosState.availableCoins,
                            row.coin_name[0]
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: row.active ? "green" : "red",
                          ":hover": {
                            color: row.active ? "green" : "red",
                          },
                        }}
                      >
                        {row.active ? "active" : "notactive"}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumberThree(row.initial_balance) + " $"}
                      </TableCell>
                      <TableCell align="center">
                        {formatNumberThree(row.current_balance) + " $"}
                      </TableCell>
                      <TableCell align="center">
                        {moment(row.created_at).format("YYYY-MM-DD")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={sortedData.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        ) : (
          <TableContainer component={Paper} sx={{ height: 250 }}>
            <Table
              sx={{ minWidth: 250, maxHeight: 250, height: 250 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Coin</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Initial Balance</TableCell>
                  <TableCell>Curent Balance</TableCell>
                  <TableCell>Created Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ textAlign: "center", height: "100%" }}>
                <TableRow sx={{ textAlign: "center", height: "100%" }}>
                  <TableCell
                    colSpan={7}
                    sx={{ textAlign: "center", height: "100%" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Typography variant="h6">Empty!</Typography>
                      <TbTableOff size={48} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </DashboardLayout>
  );
};
