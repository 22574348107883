import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../../services/http";
import { CryptoNewsResponseState } from "./interfaces";

export const fetchCryptoTweetCount = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("crypto News Count", async (requestData, thunkAPI) => {
  try {
    const url = `/crypto/get-tweet-count/?coin_id=${requestData?.coinId}&start_date=${requestData.duration?.startDate}&end_date=${requestData.duration?.endDate}`;
    const response = await http.get(url);
    const data = response.data.response.data;
    return { [requestData.timeframe]: data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const initialState: CryptoNewsResponseState = {
  cryptoNewsCount: {},
  cryptoNewsCountLoading: { "1W": true, "2W": true, "1M": true, "3M": true },
  error: null,
};

export const cryptoNewsSlice = createSlice({
  name: "crypto News",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoTweetCount.pending, (state, action) => {
        state.cryptoNewsCountLoading = {
          ...state.cryptoNewsCountLoading,
          [action.meta.arg.timeframe]: true,
        };
        state.error = null;
      })
      .addCase(fetchCryptoTweetCount.fulfilled, (state, action) => {
        state.cryptoNewsCount = { ...state.cryptoNewsCount, ...action.payload };
        state.cryptoNewsCountLoading = {
          ...state.cryptoNewsCountLoading,
          [action.meta.arg.timeframe]: false,
        };
      })
      .addCase(fetchCryptoTweetCount.rejected, (state, action) => {
        state.cryptoNewsCountLoading = {
          ...state.cryptoNewsCountLoading,
          [action.meta.arg.timeframe]: false,
        };
        state.cryptoNewsCount = {
          ...state.cryptoNewsCount,
          [action.meta.arg.timeframe]: [],
        };
        state.error = action.error.message || "Something went wrong";
      });
  },
});
export default cryptoNewsSlice.reducer;
