import React, { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  removeWalletHandler,
  walletStockUpdateHandler,
} from "../../../../api/assets";
import {
  fetchWalletNetworthStatistics,
  fetchWalletsList,
} from "../../../../features/assetsManagement/assetsManagementSlice";
import { RootState } from "../../../../features/store";
import Input from "../../../form/Input";
import AssetModal from "../../assetModal";
import { scrollToTop } from "../../../../utils/functions";

const AssetCashModal: React.FC<any> = ({
  open,
  setOpen,
  selectedAsset,
  styles,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const [initialValues] = useState<any>({
    currencyValue: selectedAsset.value,
  });

  const updateWalletData = (
    dollar_wallet_id: number,
    dollar_value: number,
    formik: any,
  ) => {
    setLoading(true);
    walletStockUpdateHandler(dollar_wallet_id, [], dollar_value).then(
      (response) => {
        const wallets = {
          wallets: assetsManagementState?.wallets.map(
            (wallet: any) => wallet.id,
          ),
        };

        setLoading(false);
        toast.success("Asset successfully updated");
        formik.resetForm();
        setOpen(false);

        // @ts-ignore
        dispatch(fetchWalletNetworthStatistics(wallets));
      },
    );
  };

  const cashValueUpdateHandler = () => async (values: any, formik: any) => {
    const findWallet = assetsManagementState.wallets?.findIndex(
      (wallet: any) => wallet.name === "Dollar_cash",
    );

    updateWalletData(
      assetsManagementState.wallets[findWallet].id,
      values.currencyValue,
      formik,
    );
  };

  const removeAssetHandler = () => {
    const wallet_id = assetsManagementState.wallets?.find(
      (wallet: any) => wallet.name === "Dollar_cash",
    ).id;

    setRemoveLoading(true);
    removeWalletHandler(wallet_id).then((response) => {
      if (response.status == 200) {
        setRemoveLoading(false);
        //@ts-ignore
        dispatch(fetchWalletsList()).then((response) => {
          const wallets = {
            wallets: response.payload.map((wallet: any) => wallet.id),
          };
          //@ts-ignore
          dispatch(fetchWalletNetworthStatistics(wallets));
          toast.success("Asset successfully Removed");
          scrollToTop();
          setOpen(false);
        });
      }
    });
  };

  return (
    <AssetModal open={open} setOpen={setOpen} title={selectedAsset?.symbol}>
      <Formik
        initialValues={initialValues}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={cashValueUpdateHandler()}
      >
        {(formik) => (
          <Form id="coin_list_form">
            <Box mb={4}>
              <Input
                name="currencyValue"
                formik={formik}
                label="Value:"
                hasInlineLabel={true}
                hasInnerLabel={false}
                variant="outlined"
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="small"
                variant="contained"
                disabled={removeLoading}
                sx={{
                  px: 3.5,
                  fontWeight: 100,
                  color: theme.palette.common.white,
                  background: theme.palette.error.main,

                  "&:hover": {
                    background: "#fc2a4a",
                  },
                }}
                onClick={removeAssetHandler}
              >
                {removeLoading ? "Removing..." : "REMOVE"}
              </Button>
              <Button
                type="submit"
                size="small"
                variant="contained"
                disabled={!formik.isValid || loading}
                sx={{
                  ml: 1,
                  px: 3.5,
                  fontWeight: 100,
                  color: theme.palette.common.white,
                  background: theme.palette.primary.main,

                  "&:hover": {
                    background: theme.palette.secondary.dark,
                  },
                }}
              >
                {loading ? "Updating ..." : "Update"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </AssetModal>
  );
};

export default AssetCashModal;
