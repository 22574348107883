import { styled } from "@mui/material/styles";

const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
  type: string;
}>(({ theme, open, type }) => ({
  [theme.breakpoints.up("sm")]: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: "0",
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: type === "assets" ? "100px" : "130px",
    }),
    minHeight: type === "agent" ? "580px" : "auto",
    display: type === "agent" ? "flex" : "block",
    flexDirection: type === "agent" ? "column" : "none",
    justifyContent: type === "agent" ? "flex-start" : "none",
  },
}));

export default ContentContainer;
