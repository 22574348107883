import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Fade } from "react-awesome-reveal";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { MdOutlineChevronLeft } from "react-icons/md";
import DashboardLayout from "../../../layout/dashboardLayout";
import Stars from "../../../components/common/svg/stars";
import ContentContainer from "../../../components/common/contentContainer";
import AssetDrawer from "../../../components/assetsManagement/assetDrawer";
import SelectBox from "../../../components/form/SelectBox";
import Loading from "../../../components/common/loading";
import Input from "../../../components/form/Input";
import ethIcon from "./../../../assets/images/icons/eth_icon.svg";
import { currencies } from "../../../data/currencies";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../features/store";
import { closeAssetsDrawer } from "../../../features/assets/assetsSlice";
import {
  addNewWalletHandler,
  walletStockUpdateHandler,
} from "../../../api/assets";
import {
  fetchWalletNetworthStatistics,
  fetchWalletsList,
} from "../../../features/assetsManagement/assetsManagementSlice";
import { useCreateSchema } from "../../../utils/useCreateSchema";
import { CashStateType } from "../interfaces";
import styles from "../assetsManagement.module.scss";

const AddCash = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const userState: any = useSelector((state: RootState) => state.account);
  const assetsManagementState: any = useSelector(
    (state: RootState) => state.assetsManagement
  );
  const open: any = useSelector((state: RootState) => state.assets.openDrawer);

  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<CashStateType>({
    currency: "",
    currencyValue: 0,
  });

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  useEffect(() => {
    if (!assetsManagementState.wallets) {
      dispatch(fetchWalletsList());
    }
  }, [assetsManagementState.wallets]);

  const updateWalletData = (
    dollar_wallet_id: number,
    dollar_value: number,
    formik: any
  ) => {
    walletStockUpdateHandler(dollar_wallet_id, [], dollar_value).then(
      (response) => {
        setLoading(false);
        toast.success("Successfully added to your assets");
        formik.resetForm();
      }
    );
  };

  const submitAssetsHandler = () => (values: any, formik: any) => {
    if (values.currency !== "" && values.currencyValue > 0) {
      setLoading(true);
      const findWallet = assetsManagementState.wallets?.findIndex(
        (wallet: any) => wallet.name === "Dollar_cash"
      );

      if (findWallet > -1) {
        updateWalletData(
          assetsManagementState.wallets[findWallet].id,
          values.currencyValue,
          formik
        );
      } else {
        setLoading(true);

        const new_wallet_data = {
          user: userState.data.user.pk,
          name: "Dollar_cash",
          category: "Cash",
          address: "",
          protocol: "Cash",
        };

        addNewWalletHandler(new_wallet_data).then((response) => {
          if (response?.status == 201) {
            dispatch(fetchWalletsList()).then((response) => {
              updateWalletData(
                response.payload?.find((item: any) => item.category == "Cash")
                  ?.id,
                values.currencyValue,
                formik
              );
            });
          }
        });
      }
    } else {
      toast.error("The entered values ​​are not valid");
    }
  };

  return (
    <DashboardLayout title="Coinfident | Asset Tracking">
      <Container
        maxWidth="lg"
        onClick={() => open && dispatch(closeAssetsDrawer())}
      >
        <Box className={styles.assets_management}>
          <Fade triggerOnce>
            <img src={ethIcon} alt="icon" className={styles.eth_logo} />
          </Fade>
          <Fade className={styles.stars}>
            <Stars width={320} height={320} />
          </Fade>
          <AssetDrawer />
          <ContentContainer open={open} type="assets">
            <Typography
              mt={3}
              mb={2}
              variant="h1"
              align="center"
              sx={{ color: theme.palette.text.primary }}
            >
              <IconButton
                disabled={loading}
                onClick={() => navigate("/asset-tracking")}
                sx={{
                  marginLeft: "-2rem",
                  marginRight: ".5rem",
                }}
              >
                <MdOutlineChevronLeft size={30} />
              </IconButton>
              Enter Manually
            </Typography>
            <Grid
              container
              sx={{ margin: "auto", display: "flex", justifyContent: "center" }}
            >
              {assetsManagementState.walletsLoading ? (
                <Box>
                  <Loading />
                </Box>
              ) : (
                <Fade triggerOnce>
                  <Box
                    mt={2}
                    sx={{
                      background: (theme: any) =>
                        theme.palette.mode === "dark"
                          ? theme.palette.common.darkPrimary
                          : theme.palette.primary.light,
                      minWidth: 350,
                    }}
                    className="default-card-style"
                  >
                    <Formik
                      initialValues={initialValues}
                      validateOnMount={true}
                      enableReinitialize={true}
                      onSubmit={submitAssetsHandler()}
                    >
                      {(formik) => (
                        <Form id="manual asset form">
                          <SelectBox
                            //@ts-ignore
                            selectItems={currencies
                              .filter(
                                (cur: any) => cur.CountryName == "United States"
                              )
                              .map((currency: any) => ({
                                value: currency.Symbol,
                                label: (
                                  <Typography
                                    variant="body2"
                                    color={theme.palette.text.primary}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={currency.Flag}
                                      width={18}
                                      height={18}
                                      style={{
                                        marginRight: "10px",
                                        objectFit: "cover",
                                        borderRadius: "50%",
                                      }}
                                    />
                                    {`${currency.Symbol} - ${currency.Currency}(${currency.Code}) `}
                                  </Typography>
                                ),
                              }))}
                            name="currency"
                            formik={formik}
                            label="Choose Currency:"
                            hasInlineLabel={true}
                            hasInnerLabel={false}
                            hasBorder
                            radius={4}
                          />
                          <br />
                          <Input
                            name="currencyValue"
                            type="number"
                            formik={formik}
                            label="Enter Value:"
                            hasInlineLabel={true}
                            hasInnerLabel={false}
                            variant="outlined"
                          />
                          <Button
                            fullWidth
                            type="submit"
                            size="small"
                            variant="contained"
                            disabled={!formik.isValid || loading}
                            sx={{
                              marginTop: "1.5rem",
                              "&:hover": {
                                background: theme.palette.secondary.main,
                              },
                            }}
                          >
                            {loading ? "Adding ..." : "ADD"}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                </Fade>
              )}
            </Grid>
          </ContentContainer>
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default AddCash;
