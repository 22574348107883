import { Box, Button, Typography, useTheme } from "@mui/material";
import { BlockchainsListProps } from "../../../interfaces";
import { scrollToTop } from "../../../../../utils/functions";

const BlockchainsList: React.FC<BlockchainsListProps> = ({
  name,
  supportedCryptoNetworks,
  setSelectedBlockChain,
}) => {
  const theme = useTheme();

  return (
    <Box mt={3}>
      <Typography
        variant="h6"
        sx={{ fontSize: ".9rem", mb: 2 }}
        color={theme.palette.text.primary}
      >
        Select Wallet's Network:
      </Typography>
      {supportedCryptoNetworks.map((network: string) => (
        <Button
          key={network}
          fullWidth
          variant="outlined"
          sx={{
            py: 1,
            mb: 1,
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 100,
            color: theme.palette.text.primary,
            //@ts-ignore
            borderColor: theme.palette.common.mute,
          }}
          onClick={() => {
            setSelectedBlockChain(network);
            scrollToTop();
          }}
        >
          {name} - {network}
        </Button>
      ))}
    </Box>
  );
};

export default BlockchainsList;
