import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Masonry } from "@mui/lab";
import {
  Box,
  Button,
  Container,
  Grid,
  ImageList,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IoWarning } from "react-icons/io5";
import { PiSpeedometer } from "react-icons/pi";
import { MdOutlineSentimentSatisfiedAlt } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import graphBanner from "../../assets/images/graph.png";
import DashboardLayout from "../../layout/dashboardLayout";
import InfluencerHeader from "../../components/influencer/header";
import CoinMention from "../../components/influencer/coinMention";
import AccountStatistics from "../../components/influencer/accountStatistics";
import NewsCard from "../../components/common/newsCard";
import NewsGallery from "../../components/common/newsGallery";
import Loading from "../../components/common/loading";
import Ranking from "../../components/influencer/ranking";
import InfluencerDoubleChart from "../../components/charts/InfluencerDoubleChart";
import InfluencerTitle from "../../components/common/title/InfluencerTitle";
import WordCloudComponent from "../../components/charts/WordCloud";
import { AppDispatch, RootState, useAppDispatch } from "../../features/store";
import {
  fetchInfluencerData,
  fetchInfluencerRanking,
  fetchInfluencerTweetsGraph,
  fetchInfluencerTweetStats,
} from "../../features/influencers/influencersSlice";
import { influencerDataProps, PostType } from "./interfaces";
import useAuth from "../../utils/useAuth";
import { isEmpty } from "lodash";
import CoOccurrence from "../../components/charts/CoOccurrence";

type RequestData = {
  id: number | string | null;
  screen_name: string | null;
};

const Influencer = () => {
  const theme = useTheme();
  const { username } = useParams();
  const { state } = useLocation();
  const isAuthValid = useAuth();
  const dispatch: AppDispatch = useAppDispatch();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  const influencerState: any = useSelector(
    (state: RootState) => state.influencers
  );

  const requestData: RequestData = {
    id: state?.id ?? null,
    screen_name: username ?? null,
  };

  const scrollbarClassName: string =
    theme.palette.mode === "light"
      ? "customScrollbar-light"
      : "customScrollbar-dark";

  const [loading, setLoading] = useState(false);
  const [influencerData, setInfluencerData] =
    useState<null | influencerDataProps>(null);
  const [tweets, setTweets] = useState<null | []>(null);
  const [view, setView] = useState("table");
  const [period, setPeriod] = useState<number>(7);

  useEffect(() => {
    if (isAuthValid) {
      if (cryptosState.availableCoins) {
        const requestData = {
          id: state?.id ?? null,
          screen_name: username ?? null,
          page: 1,
        };
        setLoading(true);
        dispatch(fetchInfluencerData(requestData))
          .then((response) => {
            setInfluencerData(response.payload);
            setTweets(response.payload.results.tweets);
            setLoading(false);
          })
          .catch((err) => {});
      }
    }
  }, [isAuthValid, cryptosState.availableCoins, state?.id, username]);

  const fetchNextPage = () => {
    const requestData = {
      id: state.id,
      page: influencerData?.next?.split("page=")[1],
    };
    dispatch(fetchInfluencerData(requestData)).then((response) => {
      //@ts-ignore
      setTweets(tweets?.concat(response.payload.results.tweets));
      //@ts-ignore
      setInfluencerData({
        ...influencerData,
        next: response.payload.next,
      });
    });
  };

  const wordcloudData = useMemo(() => {
    const wordcloudSchema =
      influencerState.influencerTweetsGraph?.wordcloud?.map(
        (i: { word: string; count: number }) => ({
          text: i.word,
          value: i.count,
        })
      );

    return wordcloudSchema;
  }, [influencerState.influencerTweetsGraph]);

  useEffect(() => {
    dispatch(fetchInfluencerTweetStats(requestData));
    dispatch(fetchInfluencerTweetsGraph(requestData));
  }, [state?.id, username]);

  useEffect(() => {
    dispatch(fetchInfluencerRanking({ ...requestData, period }));
  }, [state?.id, period, username]);

  const NoDataComponent = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Typography
          variant="body1"
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
          color={theme.palette.text.primary}
        >
          <IoWarning size={50} style={{ marginBottom: "10px" }} />
          At this time, there is no data available for this Feature.
        </Typography>
      </Box>
    );
  };

  return (
    <DashboardLayout title="Coinfident | Influencer">
      {loading ? (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Loading />
        </Box>
      ) : (
        influencerData && (
          <Container maxWidth="xl" sx={{ padding: downLg ? "0 0" : "0 16px" }}>
            <InfluencerHeader data={influencerData.results?.twitter_user} />
            <CoinMention data={influencerData.results?.coin_mention_count} />

            <Grid
              container
              mt={downLg ? 2 : 4}
              gap={1}
              justifyContent="space-between"
            >
              <Grid item lg={2.6} xs={12} sx={{ display: "flex" }}>
                <AccountStatistics data={influencerData} />
              </Grid>
              <Grid item lg={9.3} xs={12} sx={{ gap: 0.3 }}>
                <Ranking
                  period={period}
                  setPeriod={setPeriod}
                  data={influencerState.influencerRank}
                  loading={influencerState.influencerRankLoading}
                />
              </Grid>
            </Grid>

            <Grid container my={downLg ? 0 : 1} justifyContent="space-between">
              <Grid item lg={5.98} xs={12}>
                <Box
                  mt={1}
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <InfluencerTitle
                    title="Posts"
                    view={view}
                    setView={setView}
                  />
                  {tweets && tweets.length > 0 ? (
                    <Box
                      className={`customScrollbar ${scrollbarClassName}`}
                      sx={{
                        padding: "0.5rem",
                        height: "1040px",
                        overflowY: "scroll",
                        display: view === "tile" ? "flex" : "block",
                        justifyContent: "center",
                      }}
                    >
                      {view === "table" ? (
                        tweets?.map((post: PostType) => (
                          <NewsCard
                            key={post.id_str}
                            id={post.user_id_str}
                            screen_name={
                              influencerData.results?.twitter_user.screen_name
                            }
                            media={post.media}
                            name={influencerData.results?.twitter_user.name}
                            date={moment
                              .parseZone(post.created_at)
                              .format("DD MMMM YYYY")}
                            content={post.full_text}
                            reply_count={
                              post.reply_count ? post.reply_count : 0
                            }
                            favorite_count={
                              post.favorite_count ? post.favorite_count : 0
                            }
                            retweet_count={
                              post.retweet_count ? post.retweet_count : 0
                            }
                          />
                        ))
                      ) : downLg ? (
                        <Box>
                          {tweets?.map((post: PostType) => (
                            <NewsGallery
                              key={post.id_str}
                              id={post.user_id_str}
                              screen_name={
                                influencerData.results?.twitter_user.screen_name
                              }
                              media={post.media}
                              name={influencerData.results?.twitter_user.name}
                              date={moment
                                .parseZone(post.created_at)
                                .format("DD-MM-YYYY")}
                              content={post.full_text}
                              reply_count={
                                post.reply_count ? post.reply_count : 0
                              }
                              favorite_count={
                                post.favorite_count ? post.favorite_count : 0
                              }
                              retweet_count={
                                post.retweet_count ? post.retweet_count : 0
                              }
                            />
                          ))}
                        </Box>
                      ) : (
                        <Masonry columns={2} spacing={1}>
                          {tweets?.map((post: PostType) => (
                            <NewsGallery
                              key={post.id_str}
                              id={post.user_id_str}
                              screen_name={
                                influencerData.results?.twitter_user.screen_name
                              }
                              media={post.media}
                              name={influencerData.results?.twitter_user.name}
                              date={moment
                                .parseZone(post.created_at)
                                .format("DD-MM-YYYY")}
                              content={post.full_text}
                              reply_count={
                                post.reply_count ? post.reply_count : 0
                              }
                              favorite_count={
                                post.favorite_count ? post.favorite_count : 0
                              }
                              retweet_count={
                                post.retweet_count ? post.retweet_count : 0
                              }
                            />
                          ))}
                        </Masonry>
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: downLg ? "auto" : "760px",
                      }}
                    >
                      <NoDataComponent />
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item lg={5.98} xs={12}>
                <Box
                  mt={1}
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <InfluencerTitle
                    title="Activity & Engagement"
                    icon={
                      <FaXTwitter
                        size={20}
                        color={theme.palette.common.white}
                        style={{ marginRight: ".3rem" }}
                      />
                    }
                  />
                  {influencerState.influencerTweetStatsLoading ? (
                    <Loading />
                  ) : influencerState.influencerTweetStats?.tweet_stats.length >
                    0 ? (
                    <InfluencerDoubleChart
                      data={influencerState.influencerTweetStats?.tweet_stats}
                      type="posts"
                      averagesData={
                        influencerState.influencerTweetStats?.averages
                      }
                      colors={[
                        theme.palette.primary.main,
                        // @ts-ignore
                        theme.palette.common.purple,
                      ]}
                    />
                  ) : (
                    <NoDataComponent />
                  )}
                </Box>

                <Box
                  mt={1}
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <InfluencerTitle
                    title="AI-Powered Social Prediction Signal Breakdown"
                    icon={
                      <PiSpeedometer
                        size={20}
                        color={theme.palette.common.white}
                        style={{ marginRight: ".3rem" }}
                      />
                    }
                  />
                  {influencerState.influencerTweetStatsLoading ? (
                    <Loading />
                  ) : influencerState.influencerTweetStats?.tweet_stats.length >
                    0 ? (
                    <InfluencerDoubleChart
                      data={influencerState.influencerTweetStats?.tweet_stats}
                      averagesData={
                        influencerState.influencerTweetStats?.averages
                      }
                      type="Buy/Not-Buy"
                      colors={[
                        theme.palette.primary.main,
                        theme.palette.error.main,
                      ]}
                    />
                  ) : (
                    <NoDataComponent />
                  )}
                </Box>

                <Box
                  mt={1}
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <InfluencerTitle
                    title="AI-Powered Emotional Sentiment Breakdown"
                    icon={
                      <MdOutlineSentimentSatisfiedAlt
                        size={20}
                        color={theme.palette.common.white}
                        style={{ marginRight: ".3rem" }}
                      />
                    }
                  />
                  {influencerState.influencerTweetStatsLoading ? (
                    <Loading />
                  ) : influencerState.influencerTweetStats?.tweet_stats.length >
                    0 ? (
                    <InfluencerDoubleChart
                      data={influencerState.influencerTweetStats?.tweet_stats}
                      type="sentiment"
                      averagesData={
                        influencerState.influencerTweetStats?.averages
                      }
                      colors={[
                        theme.palette.success.main,
                        "#CED633",
                        theme.palette.error.main,
                      ]}
                    />
                  ) : (
                    <NoDataComponent />
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container my={downLg ? 1 : 2} justifyContent="space-between">
              <Grid item lg={7.98} xs={12}>
                <Box
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <InfluencerTitle title="Words Cloud" />
                  <Box sx={{ height: "400px" }}>
                    {isEmpty(
                      influencerState.influencerTweetsGraph?.wordcloud
                    ) ? (
                      <NoDataComponent />
                    ) : (
                      <WordCloudComponent data={wordcloudData} />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3.98} xs={12}>
                <Box
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    background: theme.palette.primary.light,
                    border: (theme: any) =>
                      `1px solid ${theme.palette.common.darkPrimary}`,
                  }}
                >
                  <InfluencerTitle title="Co-Occurrence" />
                  <Box sx={{ height: "400px" }}>
                    {isEmpty(
                      influencerState.influencerTweetsGraph?.cooccurrence
                    ) ? (
                      <NoDataComponent />
                    ) : (
                      <CoOccurrence
                        data={
                          influencerState.influencerTweetsGraph?.cooccurrence
                        }
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              {/*<Grid item xl={3.98} xs={12} mt={downLg ? 1 : 0}>*/}
              {/*  <Box*/}
              {/*    sx={{*/}
              {/*      borderRadius: "8px",*/}
              {/*      overflow: "hidden",*/}
              {/*      background: theme.palette.primary.light,*/}
              {/*      border: (theme: any) =>*/}
              {/*        `1px solid ${theme.palette.common.darkPrimary}`,*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <InfluencerTitle title="Posts Co-Mention" />*/}
              {/*    <Box sx={{ height: "400px" }}>*/}
              {/*      <Box*/}
              {/*        height={400}*/}
              {/*        position="relative"*/}
              {/*        sx={{*/}
              {/*          backgroundImage: `url('${graphBanner}')`,*/}
              {/*          backgroundRepeat: "no-repeat",*/}
              {/*          backgroundSize: "cover",*/}
              {/*          backgroundPosition: "center",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <Button*/}
              {/*          // onClick={() => setShowGraph(true)}*/}
              {/*          variant="contained"*/}
              {/*          sx={{*/}
              {/*            position: "absolute",*/}
              {/*            top: "50%",*/}
              {/*            left: "50%",*/}
              {/*            transform: "translate(-50% , -50%)",*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          Start*/}
              {/*        </Button>*/}
              {/*      </Box>*/}
              {/*    </Box>*/}
              {/*  </Box>*/}
              {/*</Grid>*/}
            </Grid>
          </Container>
        )
      )}
    </DashboardLayout>
  );
};

export default Influencer;
