import { Skeleton, Stack } from "@mui/material";
import React from "react";

const AssetsSkeleton: React.FC = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular" width="100%" height={40} />
      <Skeleton variant="rectangular" width="100%" height={40} />
    </Stack>
  );
};

export default AssetsSkeleton;
