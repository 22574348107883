import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { Box, Typography, useTheme } from "@mui/material";
import Loading from "../../common/loading";
import { PapCloseType, PapType, SrType } from "../interfaces";
import styles from "../crypto.module.scss";

const CryptoChart: React.FC<{
  data: { x: string; y: number }[];
  name: string;
  cryptoFilter: {
    type: string;
    interval: string;
  };
  cryptoIndicators: {
    supportAndResistance: {
      support: [] | null;
      resistance: [] | null;
    };
    priceActionPattern: {
      hh: [] | null;
      ll: [] | null;
      lh: [] | null;
      hl: [] | null;
      close: [] | null;
    };
    supportAndResistanceLoading: Boolean;
    priceActionPatternLoading: Boolean;
  };
  technichalMethod: string | null;
  cryptoFilterChangeHandler: Function;
  showTechnichalMethod: Boolean;
  loadingHistoricalChart: boolean;
  technichalMethodHandler: Function;
  isAvailable: undefined | {};
}> = ({
  data,
  name,
  cryptoFilter,
  cryptoIndicators,
  cryptoFilterChangeHandler,
  loadingHistoricalChart,
  technichalMethod,
  technichalMethodHandler,
  isAvailable,
  showTechnichalMethod,
}) => {
  const theme = useTheme();

  const _data = useMemo(() => {
    const _d = [
      {
        id: "Price",
        name: "Price",
        color: theme.palette.text.primary,
        data: data,
      },
    ];
    if (
      cryptoFilter.type !== "1H" &&
      cryptoFilter.type !== "1D" &&
      showTechnichalMethod
    ) {
      if (technichalMethod === "sr") {
        const supportResult =
          cryptoIndicators.supportAndResistance?.support?.map(
            (s: SrType, index: number) => {
              return {
                id: "Support-" + index,
                name: "Support",
                color: theme.palette.success.main,
                data: [
                  {
                    x: moment(s.start_date).format("YYYY/MM/DD"),
                    y: Number(s.price),
                  },
                  {
                    x: moment(s.end_date).format("YYYY/MM/DD"),
                    y: Number(s.price),
                  },
                ],
              };
            },
          );
        const resistanceResult =
          cryptoIndicators.supportAndResistance?.resistance?.map(
            (r: SrType, index: number) => {
              return {
                id: "Resistance-" + index,
                name: "Resistance",
                color: theme.palette.error.main,
                data: [
                  {
                    x: moment(r.start_date).format("YYYY/MM/DD"),
                    y: Number(r.price),
                  },
                  {
                    x: moment(r.end_date).format("YYYY/MM/DD"),
                    y: Number(r.price),
                  },
                ],
              };
            },
          );

        if (supportResult !== undefined) _d.push(...supportResult);

        if (resistanceResult !== undefined) _d.push(...resistanceResult);
      } else if (technichalMethod === "pap") {
        if (cryptoIndicators.priceActionPattern) {
          if (cryptoIndicators.priceActionPattern.hh?.length)
            _d.push({
              id: "hh",
              name: "hh",
              color: theme.palette.secondary.main,
              data: cryptoIndicators.priceActionPattern.hh.map(
                (p: PapType) => ({
                  x: moment(p.date).format("YYYY/MM/DD"),
                  y: p.price,
                }),
              ),
            });

          if (cryptoIndicators.priceActionPattern.ll?.length)
            _d.push({
              id: "ll",
              name: "ll",
              color: theme.palette.secondary.main,
              data: cryptoIndicators.priceActionPattern.ll.map(
                (p: PapType) => ({
                  x: moment(p.date).format("YYYY/MM/DD"),
                  y: p.price,
                }),
              ),
            });

          if (cryptoIndicators.priceActionPattern.lh?.length)
            _d.push({
              id: "lh",
              name: "lh",
              color: theme.palette.secondary.main,
              data: cryptoIndicators.priceActionPattern.lh.map(
                (p: PapType) => ({
                  x: moment(p.date).format("YYYY/MM/DD"),
                  y: p.price,
                }),
              ),
            });

          if (cryptoIndicators.priceActionPattern.hl?.length)
            _d.push({
              id: "hl",
              name: "hl",
              color: theme.palette.secondary.main,
              data: cryptoIndicators.priceActionPattern.hl.map(
                (p: PapType) => ({
                  x: moment(p.date).format("YYYY/MM/DD"),
                  y: p.price,
                }),
              ),
            });

          if (cryptoIndicators.priceActionPattern.close?.length)
            _d.push({
              name: "Close",
              id: "Close",
              color: "#ff0000",
              data: cryptoIndicators.priceActionPattern.close.map(
                (p: PapCloseType) => ({
                  x: moment(p.date).format("YYYY/MM/DD"),
                  y: p.close,
                }),
              ),
            });
        }
      }
    }

    return _d;
  }, [data, cryptoIndicators, technichalMethod, showTechnichalMethod]);

  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });

  useEffect(() => {
    setState({
      series: _data,
      options: {
        //@ts-ignore
        colors: [theme.palette.common.whiteToGreen],
        chart: {
          parentHeightOffset: 0,
          type: "line",
          zoom: {
            enabled: true,
          },
          animations: {
            easing: "easeout",
          },
          toolbar: {
            tools: {
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        tooltip: {
          //@ts-ignore
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class='${
              styles.linechart_tooltip
            }' style="background: ${
              //@ts-ignore
              theme.palette.common.solidCard
            }">
                <h4 style="color: ${theme.palette.common.white} ">
                    ${
                      cryptoFilter.type === "1H" || cryptoFilter.type === "1D"
                        ? data[dataPointIndex].x
                        : moment(data[dataPointIndex].x).format("YYYY/MM/DD")
                    }
            </h4>
                <div>
                <h6 style="color: ${theme.palette.common.white} ">${
                  w.globals.seriesNames[0]
                }:</h6>
                <h5 style="color: ${theme.palette.common.white} ">${series[
                  seriesIndex
                ][dataPointIndex].toLocaleString("en-us", {
                  style: "currency",
                  currency: "usd",
                  minimumFractionDigits:
                    Number(series[seriesIndex][dataPointIndex])
                      .toFixed(1)
                      .toString().length < 5
                      ? 8
                      : 4,
                })}
            </h5>
              </div>
              </div>`;
          },
        },
        stroke: {
          width: 2,
          curve: "straight",
        },
        legend: {
          show: technichalMethod && showTechnichalMethod ? true : false,
          showForSingleSeries: true,
          customLegendItems:
            technichalMethod === "pap"
              ? ["Price", "Close", "hh", "ll", "lh", "hl"]
              : ["Price", "Support", "Resistance"],
          labels: {
            colors:
              technichalMethod === "pap"
                ? [
                    theme.palette.text.primary,
                    theme.palette.error.main,
                    theme.palette.secondary.main,
                    theme.palette.secondary.main,
                    theme.palette.secondary.main,
                    theme.palette.secondary.main,
                  ]
                : [
                    theme.palette.text.primary,
                    theme.palette.success.main,
                    theme.palette.error.main,
                  ],
            useSeriesColors: false,
          },
          markers: {
            width: 6,
            height: 6,
            fillColors:
              technichalMethod === "pap"
                ? [
                    theme.palette.text.primary,
                    theme.palette.error.main,
                    theme.palette.secondary.main,
                    theme.palette.secondary.main,
                    theme.palette.secondary.main,
                    theme.palette.secondary.main,
                  ]
                : [
                    theme.palette.text.primary,
                    theme.palette.success.main,
                    theme.palette.error.main,
                  ],
          },
          position: "bottom",
          horizontalAlign: "left",
          offsetY: 10,
        },
        xaxis: {
          type:
            cryptoFilter.type !== "1H" && cryptoFilter.type !== "1D"
              ? "datetime"
              : "category",
          tickAmount: 13,
          tooltip: {
            enabled: false,
          },
          labels: {
            rotate: 0,
            rotateAlways: false,
            hideOverlappingLabels: true,
            offsetY: 0,
            minHeight: 35,
            style: {
              //@ts-ignore
              colors: theme.palette.common.white,
            },
            format: undefined,
            formatter: undefined,
            datetimeUTC: true,
            datetimeFormatter: {
              year: "yyyy",
              month: "MM/yyyy",
              day: "MM/dd",
              hour: "HH:mm",
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tickPlacement: "bottom",
        },
        yaxis: {
          labels: {
            align: "center",
            style: {
              //@ts-ignore
              colors: [theme.palette.common.white],
            },
            formatter: (value: number) => {
              return Number(value).toLocaleString("en-us", {
                style: "currency",
                currency: "usd",
                minimumFractionDigits:
                  Number(value).toFixed(1).toString().length < 5 ? 8 : 3,
              });
            },
          },
        },
        grid: {
          show: true,
          borderColor: theme.palette.mode === "dark" ? "#444" : "#ccc",
          strokeDashArray: 3,
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              chart: {
                height: "auto",
              },
              xaxis: {
                tickPlacement: "between",
                tickAmount: 3,
                labels: {
                  align: "center",
                  rotate: 0,
                  rotateAlways: false,
                  hideOverlappingLabels: true,
                  offsetY: 5,
                  style: {
                    fontWeight: 100,
                  },
                },
              },
            },
          },
        ],
      },
    });
  }, [data, cryptoIndicators, technichalMethod, showTechnichalMethod]);

  return (
    <>
      {loadingHistoricalChart ||
      cryptoIndicators.supportAndResistanceLoading ||
      cryptoIndicators.priceActionPatternLoading ? (
        <Box
          sx={{ minHeight: "220px", display: "flex", justifyContent: "center" }}
        >
          <Loading />
        </Box>
      ) : (
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height="350px"
        />
      )}
      <Box className={styles.chartHandlers}>
        <Typography
          variant="h6"
          onClick={() => cryptoFilterChangeHandler("1H", "m1")}
          sx={{
            background:
              cryptoFilter.type === "1H"
                ? theme.palette.text.primary
                : //@ts-ignore
                  theme.palette.grey.light,
            color:
              cryptoFilter.type === "1H"
                ? theme.palette.background.default
                : theme.palette.text.primary,
          }}
        >
          1H
        </Typography>
        <Typography
          variant="h6"
          onClick={() => cryptoFilterChangeHandler("1D", "h1")}
          sx={{
            background:
              cryptoFilter.type === "1D"
                ? theme.palette.text.primary
                : //@ts-ignore
                  theme.palette.grey.light,
            color:
              cryptoFilter.type === "1D"
                ? theme.palette.background.default
                : theme.palette.text.primary,
          }}
        >
          1D
        </Typography>
        <Typography
          variant="h6"
          onClick={() => cryptoFilterChangeHandler("1W", "d1")}
          sx={{
            background:
              cryptoFilter.type === "1W"
                ? theme.palette.text.primary
                : //@ts-ignore
                  theme.palette.grey.light,
            color:
              cryptoFilter.type === "1W"
                ? theme.palette.background.default
                : theme.palette.text.primary,
          }}
        >
          1W
        </Typography>
        <Typography
          variant="h6"
          onClick={() => cryptoFilterChangeHandler("1M", "d1")}
          sx={{
            background:
              cryptoFilter.type === "1M"
                ? theme.palette.text.primary
                : //@ts-ignore
                  theme.palette.grey.light,
            color:
              cryptoFilter.type === "1M"
                ? theme.palette.background.default
                : theme.palette.text.primary,
          }}
        >
          1M
        </Typography>
        <Typography
          variant="h6"
          onClick={() => cryptoFilterChangeHandler("1Y", "d1")}
          sx={{
            background:
              cryptoFilter.type === "1Y"
                ? theme.palette.text.primary
                : //@ts-ignore
                  theme.palette.grey.light,
            color:
              cryptoFilter.type === "1Y"
                ? theme.palette.background.default
                : theme.palette.text.primary,
          }}
        >
          1Y
        </Typography>
        <Typography
          variant="h6"
          onClick={() => cryptoFilterChangeHandler("All", "d1")}
          sx={{
            background:
              cryptoFilter.type === "All"
                ? theme.palette.text.primary
                : //@ts-ignore
                  theme.palette.grey.light,
            color:
              cryptoFilter.type === "All"
                ? theme.palette.background.default
                : theme.palette.text.primary,
          }}
        >
          All
        </Typography>
      </Box>

      {isAvailable !== undefined && (
        <Box className={styles.chartHandlers}>
          <Typography
            variant="h6"
            onClick={() => {
              technichalMethodHandler("sr");
            }}
            sx={{
              background:
                technichalMethod === "sr" && showTechnichalMethod
                  ? theme.palette.text.primary
                  : //@ts-ignore
                    theme.palette.grey.light,
              color:
                technichalMethod === "sr" && showTechnichalMethod
                  ? theme.palette.background.default
                  : theme.palette.text.primary,
            }}
          >
            Support-Resistance
          </Typography>
          <Typography
            variant="h6"
            onClick={() => {
              technichalMethodHandler("pap");
            }}
            sx={{
              background:
                technichalMethod === "pap" && showTechnichalMethod
                  ? theme.palette.text.primary
                  : //@ts-ignore
                    theme.palette.grey.light,
              color:
                technichalMethod === "pap" && showTechnichalMethod
                  ? theme.palette.background.default
                  : theme.palette.text.primary,
            }}
          >
            Price action pattern
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CryptoChart;
