import styles from "./buttons.module.scss";
import Tooltip from "../../../components/common/tooltip";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { Button, Typography, useTheme } from "@mui/material";
import { IoIosInformationCircleOutline } from "react-icons/io";

type FullChartBtnProps = {
  color: string;
  title: string;
  description: string;
  visiblity: boolean;
  onClick: any;
};

const FullChartBtn = ({
  color,
  title,
  description,
  visiblity,
  onClick,
}: FullChartBtnProps) => {
  const theme = useTheme();
  return (
    <Button
      variant="outlined"
      className={styles.fullChartBtn}
      sx={{
        color: color,
        paddingLeft: "0px",
        backgroundColor:
          theme.palette.mode === "dark"
            ? "#121413"
            : theme.palette.primary.light,
      }}
    >
      <div
        className={styles.fullChartBtn__wrapper}
        style={{ backgroundColor: color }}
      ></div>
      <Tooltip title={description} placement="top">
        <Typography
          sx={{
            color: theme.palette.mode === "dark" ? "white" : "black",
            marginRight: "15px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            gap: "3px",
          }}
        >
          {title}
          <IoIosInformationCircleOutline />
        </Typography>
      </Tooltip>

      {visiblity ? (
        <Tooltip title="Hide From Chart" placement="top">
          <button onClick={onClick} className={styles.fullChartBtn__visibel}>
            <MdOutlineVisibilityOff
              style={{
                width: "20px",
                height: "20px",
                color: color,
              }}
            />
          </button>
        </Tooltip>
      ) : (
        <Tooltip title="Show In Chart" placement="top">
          <button onClick={onClick} className={styles.fullChartBtn__visibel}>
            <MdOutlineVisibility
              style={{
                width: "20px",
                height: "20px",
                color: color,
              }}
            />
          </button>
        </Tooltip>
      )}
    </Button>
  );
};

export default FullChartBtn;
