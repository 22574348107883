import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import { Box, useTheme } from "@mui/material";
import { RootState } from "../../../features/store";
import { ComparisonChartType, ComparisonItemType } from "../interfaces";
import styles from "../shareOfVoice.module.scss";

const ComparisonChart: React.FC<{
  data: [];
  name: string;
  selectedCoins: { id: number }[];
}> = ({ data, name, selectedCoins }) => {
  const [state, setState] = useState<{
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    options: object;
  }>({
    series: [],
    options: {},
  });
  const theme = useTheme();
  const cryptosState: any = useSelector((state: RootState) => state.cryptos);
  let filteredData = [...data];

  function sortItemsByX(items: any) {
    items.sort((a: ComparisonItemType, b: ComparisonItemType) => {
      //@ts-ignore
      return new Date(a.x) - new Date(b.x);
    });
    return items;
  }

  useEffect(() => {
    setState({
      series: filteredData
        .filter((item: ComparisonItemType) => selectedCoins.includes(item.id))
        .map((chart: ComparisonChartType) => ({
          name: cryptosState.availableCoins?.find(
            (item: ComparisonItemType) => item.id === chart.id,
          ).name,
          data:
            chart.data &&
            sortItemsByX(
              [...chart.data]
                ?.sort((a: any, b: any) => {
                  //@ts-ignore
                  return new Date(a.x) - new Date(b.x);
                })
                .reverse()
                .map((dataItem) => ({
                  x: dataItem.date,
                  y: dataItem.tweet_cnt,
                })),
            ),
        })),
      options: {
        colors: [
          "#5C8984",
          "#5577aa",
          "#ffac81",
          "#a2d2ff",
          "#ff8fab",
          "#adf7b6",
          "#ee6055",
          "#ffef9f",
          "#cdb4db",
        ],
        chart: {
          parentHeightOffset: 30,
          type: "line",
          width: "100%",
          fontFamily: "Poppins",
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        tooltip: {
          //@ts-ignore
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class='${
              styles.linechart_tooltip
            }' style="background: ${
              //@ts-ignore
              theme.palette.common.solidCard
            }">
              <h6 style="color: ${theme.palette.common.white} ">${
                w.globals.categoryLabels[dataPointIndex]
              }</h6>
              <div>
                <h4 style="color: ${theme.palette.common.white} ">${
                  w.globals.seriesNames[seriesIndex]
                }:</h4>
                <h5 style="color: ${theme.palette.common.white} ">${
                  series[seriesIndex][dataPointIndex]
                }</h5>
              </div>
              </div>`;
          },
        },
        grid: { show: false },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          offsetY: 10,
          markers: {
            width: 6,
            height: 6,
          },
          labels: {
            useSeriesColors: true,
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          tickAmount: 1,
          tickPlacement: "between",
          labels: {
            show: true,
            rotate: 0,
            offsetY: 0,
            offsetX: 0,
            style: {
              fontSize: "9px",
              //@ts-ignore
              colors: theme.palette.grey[500],
            },
          },
          axisBorder: { show: true },
          axisTicks: { show: true },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "9px",
              //@ts-ignore
              colors: theme.palette.grey[500],
            },
          },
          axisBorder: { show: true },
          axisTicks: { show: true },
        },
      },
    });
  }, [data, selectedCoins]);

  return (
    <Box id="shareOfVoiceChart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        height="240px"
        width="100%"
      />
    </Box>
  );
};

export default ComparisonChart;
