import { Box, TextField, Typography, useTheme } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { SearchboxProps } from "../../interfaces";

const Searchbox: React.FC<SearchboxProps> = ({ rssFilters, setRssFilters }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingBottom: "0.7rem",
        maxWidth: "600px",
      }}
    >
      <Typography variant="h6" sx={{ marginRight: "0.5rem" }}>
        <FiSearch
          size={18}
          color={
            //@ts-ignore
            theme.palette.common.whiteToDarkGreen
          }
        />
      </Typography>
      <TextField
        id="search"
        label="Search"
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{
          style: {
            color: theme.palette.text.primary,
          },
        }}
        value={rssFilters.search ? rssFilters.search : ""}
        onChange={(e) =>
          setRssFilters({ ...rssFilters, search: e.target.value })
        }
      />
    </Box>
  );
};

export default Searchbox;
