import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { SignalTableProps } from "../../interfaces";

const SignalTable: React.FC<SignalTableProps> = ({ data, isDollarSign }) => {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography
                variant="body2"
                sx={{
                  //@ts-ignore
                  color: theme.palette.common.mute,
                }}
              >
                Name
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="body2"
                sx={{
                  //@ts-ignore
                  color: theme.palette.common.mute,
                }}
              >
                Value
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="body2"
                sx={{
                  //@ts-ignore
                  color: theme.palette.common.mute,
                }}
              >
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((signalItem: any) => (
            <TableRow
              key={signalItem.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 100, color: theme.palette.text.primary }}
                >
                  {signalItem.name}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 100, color: theme.palette.text.primary }}
                >
                  {isDollarSign
                    ? signalItem.value != "_"
                      ? `$${Number(signalItem.value)
                          .toFixed(2)
                          .toString()
                          .toLocaleString()}`
                      : signalItem.value
                    : signalItem.value != "_"
                      ? `${Number(signalItem.value)
                          .toFixed(2)
                          .toString()
                          .toLocaleString()}`
                      : signalItem.value}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 100,
                    color:
                      signalItem.action === "Sell"
                        ? theme.palette.error.main
                        : signalItem.action === "Buy"
                          ? theme.palette.success.main
                          : theme.palette.text.primary,
                  }}
                >
                  {signalItem.action}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SignalTable;
