import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  TablePagination,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layout/dashboardLayout";
import { PieAgentBot } from "../../../components/charts/PieAgentBot";
import { useEffect, useState, useMemo } from "react";
import useAuth from "../../../utils/useAuth";
import { LineBarChartAgent } from "../../../components/charts/LineBarChartAgent";
import { fetchInAgentBotDetial } from "../../../features/agent/agentBotSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TbTableOff } from "react-icons/tb";
import { formatNumberThree } from "../../../utils/functions";

interface BotDetail {
  id: number;
  balance: number;
  profit: number;
  loss: number;
  date: string;
}
type ChartData = {
  x: string; // The x-axis value (e.g., "Jan")
  y: number | [number, number]; // A single number (for line) or a range (for rangeBar)
};

type Series = {
  name: string; // Name of the series
  type: "rangeBar" | "line"; // Type of the chart for this series
  data: ChartData[]; // Array of data points
};

export const BotDetailPage = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("sm"));
  const isAuthValid = useAuth();
  const params = useParams();
  const dispatch = useDispatch();
  const [chartSeries, setChartSeries] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const botDetial = useSelector((state: any) => state.BotSlice.BotDetial);

  useEffect(() => {
    if (params.id) {
      // @ts-ignore
      dispatch(fetchInAgentBotDetial(Number(params.id)));
    }
  }, [dispatch, params.id]);

  useEffect(() => {
    if (botDetial && botDetial.length > 0) {
      const sortedData = [...botDetial].sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      const firstBalance = Number(sortedData[0].initial_balance);
      const currentBalance = Number(sortedData[0].balance);
      const profit =
        currentBalance > firstBalance ? currentBalance - firstBalance : 0;
      const loss =
        currentBalance < firstBalance ? firstBalance - currentBalance : 0;

      setChartSeries([firstBalance, profit, loss]);
      setLoading(false);
    }
  }, [botDetial]);

  const series: Series[] = [
    {
      name: "loss or profit",
      type: "rangeBar",
      data: botDetial?.map((Transaction: any) => ({
        x: Transaction.created_at,
        y: [Transaction.balance, Transaction.initial_balance],
      })),
    },
    {
      name: "balance",
      type: "line",
      data: botDetial?.map((Transaction: any) => ({
        x: Transaction.created_at,
        y: Transaction.balance,
      })),
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const paginatedData = useMemo(() => {
    if (botDetial === null) return;

    return [...botDetial]
      .reverse()
      .slice(
        currentPage * rowsPerPage,
        currentPage * rowsPerPage + rowsPerPage
      );
  }, [botDetial, currentPage, rowsPerPage]);

  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={7} height={"400px"}>
            <LineBarChartAgent series={series} />
          </Grid>
          <Grid item xs={12} sm={5}>
            {botDetial && botDetial.length ? (
              <PieAgentBot
                series={chartSeries}
                labels={["FirstBalance", "Profit", "Loss"]}
                isNull={false}
              />
            ) : (
              <PieAgentBot
                series={chartSeries}
                labels={["FirstBalance", "Profit", "Loss"]}
                isNull={true}
              />
            )}
          </Grid>
        </Grid>

        <Box sx={{ width: downLg ? "300px" : "100%" }}>
          {botDetial && botDetial.length ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}>Transaction Id</TableCell>
                    <TableCell align={"center"}>Status</TableCell>
                    <TableCell align={"center"}>Initial Balance</TableCell>
                    <TableCell align={"center"}>Balance</TableCell>
                    <TableCell align={"center"}>Profit</TableCell>
                    <TableCell align={"center"}>Loss</TableCell>
                    <TableCell align={"center"}>Transaction Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData?.map((row: any, index: number) => {
                    const globalIndex = currentPage * rowsPerPage + index + 1;

                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                          ":hover": {
                            backgroundColor:
                              theme.palette.mode === "light"
                                ? theme.palette.primary.light
                                : theme.palette.primary.main,
                          },
                        }}
                      >
                        <TableCell align={"center"} component="th" scope="row">
                          {globalIndex}
                        </TableCell>
                        <TableCell align={"center"} component="th" scope="row">
                          {row.action}
                        </TableCell>
                        <TableCell align={"center"}>
                          {row.initial_balance}
                        </TableCell>
                        <TableCell align={"center"}>
                          {formatNumberThree(row.balance) + " $"}
                        </TableCell>
                        <TableCell align={"center"} sx={{ color: "green" }}>
                          {row.initial_balance < row.balance
                            ? formatNumberThree(
                                row.balance - row.initial_balance
                              ) + " $"
                            : "0 $"}
                        </TableCell>
                        <TableCell align={"center"} sx={{ color: "red" }}>
                          {row.initial_balance > row.balance
                            ? formatNumberThree(
                                row.initial_balance - row.balance
                              ) + " $"
                            : "0 $"}
                        </TableCell>
                        <TableCell align={"center"}>
                          {moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={botDetial.length}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          ) : (
            <TableContainer component={Paper} sx={{ height: 250 }}>
              <Table
                sx={{ minWidth: 250, maxHeight: 250, height: 250 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}>Id</TableCell>
                    <TableCell align={"center"}>Status</TableCell>
                    <TableCell align={"center"}>InitialBalance</TableCell>
                    <TableCell align={"center"}>Balance</TableCell>
                    <TableCell align={"center"}>Profit</TableCell>
                    <TableCell align={"center"}>Loss</TableCell>
                    <TableCell align={"center"}>Created Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ textAlign: "center" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Typography variant="h6">Empty!</Typography>
                        <TbTableOff size={48} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
};
