import { useSelector } from "react-redux";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LuInfo } from "react-icons/lu";
import { RootState } from "../../../features/store";
import DefaultTooltip from "../../common/tooltip";
import styles from "../agents.module.scss";

const AgentHeader = ({ isSmall = false }: { isSmall?: boolean }) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const userState: any = useSelector((state: RootState) => state.account);

  const faq = (
    <>
      <Typography
        variant="body1"
        align="left"
        color={theme.palette.primary.light}
        sx={{ fontWeight: 700, mt: 1, mb: 2 }}
      >
        What is the difference between Agents and LLMs?
      </Typography>
      <Typography
        variant="body2"
        align="left"
        color={theme.palette.primary.light}
      >
        Unlike a standard LLM, which simply processes questions based on its
        pre-trained structure and general information, the Coinfident agent
        enhances its capabilities by connecting to a coinfident database. This
        allows the agent to fetch and utilize data relevant to the user's
        inquiries, facilitating more tailored and actionable responses.
      </Typography>
    </>
  );

  return (
    <Box className={styles.agentHeader}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{ fontWeight: 900, fontSize: isSmall ? "2rem" : "3rem" }}
        color={theme.palette.text.primary}
      >
        Hey
        {userState?.data?.user?.first_name &&
          `,${" "} ${userState?.data?.user?.first_name}`}
        !{" "}
        <Box
          component="span"
          sx={{
            display: "inline-block",
            [theme.breakpoints.down("sm")]: {
              display: "block",
            },
          }}
        >
          I'm{" "}
          <strong style={{ color: theme.palette.primary.main }}>CAVA</strong>
        </Box>
      </Typography>

      <Typography
        variant="body1"
        color={theme.palette.text.primary}
        align="center"
        sx={{
          maxWidth: downLg ? "100%" : "65%",
          mx: "auto",
        }}
      >
        The Coinfident Agent is an AI-powered assistant that adds value to a
        large language model (LLM) by enabling it to perform specific actions
        based on user queries.
        <DefaultTooltip title={faq} placement="bottom">
          <span style={{ marginLeft: "10px" }}>
            <LuInfo style={{ marginBottom: "-3px" }} />
          </span>
        </DefaultTooltip>
      </Typography>
    </Box>
  );
};

export default AgentHeader;
