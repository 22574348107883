import { AccordionDetails, Box, Typography, useTheme } from "@mui/material";
import NewsAvatar from "../../common/newsAvatar";
import { NewsRssCardProps } from "../interfaces";
import styles from "../news.module.scss";

const NewsRssCard: React.FC<NewsRssCardProps> = ({
  url,
  thumbnail_url,
  title,
  base_link,
  published,
  tags: [],
}) => {
  const theme = useTheme();
  const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

  function getThumbnailUrl(thumbnail_url: string) {
    if (thumbnail_url?.startsWith("/i/api/rest/media/")) {
      return WEBSITE_URL + thumbnail_url;
    } else {
      return thumbnail_url;
    }
  }

  return (
    <a href={url} target="_blank" className={styles.newsRssCardWrapper}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <AccordionDetails className={styles.newsCardWrapper}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Box className={styles.newsCardTitle}>
              <Typography
                variant="caption"
                component="h6"
                //@ts-ignore
                sx={{ color: theme.palette.common.whiteToGreen }}
              >
                {title}
              </Typography>
            </Box>
            <Box className={styles.newsRssCardFooter}>
              <Typography
                variant="caption"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey["300"]
                      : //@ts-ignore
                        theme.palette.common.whiteToDarkGreen,
                }}
              >
                {base_link}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.grey["400"]
                      : //@ts-ignore
                        theme.palette.common.whiteToDarkGreen,
                }}
              >
                {published}
              </Typography>
            </Box>
          </Box>
          <NewsAvatar
            type="rss"
            imageUrl={getThumbnailUrl(thumbnail_url)}
            width={120}
            height={120}
          />
        </AccordionDetails>
      </Box>
    </a>
  );
};

export default NewsRssCard;
