import React, { useState } from "react";
import DashboardLayout from "../../layout/dashboardLayout";
import {
  Box,
  Grid,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import AnalyticsTitle from "../../components/common/title/AnalyticsTitle";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import DefaultGauge from "../../components/guage";
import { IsRelevant } from "../../components/is Relevant";
import CardTitle from "../../components/common/title/CardTitle";
import "./indx.module.scss";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { getCoinImage } from "../../utils/functions";
import { Fade } from "react-awesome-reveal"; // Import Fade from react-awesome-reveal

interface ListItemType {
  title: string;
  gauge: {
    value: number;
    isEmpty: boolean;
  };
  isRelevant: boolean;
  imgsrc: { id: number; cmc_tag: string }[];
  sentiment: number;
}

interface InputFormProps {
  des: string;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickShowBtn: () => void;
}

const InputForm: React.FC<InputFormProps> = ({
  des,
  handleInputChange,
  handleClickShowBtn,
}) => {
  const theme = useTheme();
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item md={8} xs={12}>
        <TextField
          label="Your Text"
          color="secondary"
          multiline
          rows={5}
          onChange={handleInputChange}
          value={des}
          sx={{
            width: "100%",
            marginTop: "15px",
            "& .MuiInputLabel-root": {
              color: theme.palette.text.primary,
            },
          }}
          InputLabelProps={{
            style: { color: theme.palette.text.primary },
          }}
        />
        <PrimaryButton
          text="Submit"
          customstyle={{
            width: "100%",
            marginTop: "35px",
            marginBottom: "30px",
          }}
          clickHandler={handleClickShowBtn}
        />
      </Grid>
    </Grid>
  );
};

interface ListItemProps {
  item: ListItemType;
  spsInfo: string;
  emotionalSentimentInfo: string;
}

// کامپوننت ListItem
const ListItems: React.FC<ListItemProps> = ({
  item,
  spsInfo,
  emotionalSentimentInfo,
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      textAlign="center"
      spacing={3}
      sx={{
        backgroundColor: "#111413",
        marginTop: "20px",
        background: theme.palette.primary.light,
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={4}
        spacing={2}
        sx={{
          justifyContent: "space-evenly",
          alignContent: "space-evenly",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {item.imgsrc.map((coin, index) => (
          <Grid
            key={coin.id}
            item
            xs={4}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Avatar src={getCoinImage(coin.id)} style={{}} />
            <Typography
              sx={{ marginTop: "5px", textAlign: "start" }}
              color={theme.palette.text.primary}
            >
              {coin.cmc_tag}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
        <CardTitle isCenter hasTooltip tooltipInfo={spsInfo}>
          SPS
        </CardTitle>
        <Typography
          textAlign="center"
          variant="caption"
          color={theme.palette.text.primary}
        >
          (Social Prediction Signal)
        </Typography>
        <DefaultGauge isEmpty={item.gauge.isEmpty} value={item.gauge.value} />
      </Grid>
      <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
        <IsRelevant Icon={item.isRelevant} />
      </Grid>

      <Grid item md={12} sx={{ textAlign: "center", marginTop: "20px" }}>
        <CardTitle isCenter hasTooltip tooltipInfo={emotionalSentimentInfo}>
          In terms of Emotional Sentiment
        </CardTitle>
        {item.sentiment === 0 ? (
          <Typography sx={{ color: "red" }}>Negative</Typography>
        ) : item.sentiment === 1 ? (
          <Typography sx={{ color: "green" }}>Positive</Typography>
        ) : item.sentiment === 2 ? (
          <Typography sx={{ color: "yellow" }}>Neutral</Typography>
        ) : (
          <p>N/A</p>
        )}
      </Grid>

      <Grid item md={12} sx={{ textAlign: "center", marginBottom: "20px" }}>
        <Typography padding="20px" color={theme.palette.text.primary}>
          {item.title}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BackTestPage = () => {
  const [showData, setShowData] = useState(false);
  const [list, setList] = useState<ListItemType[]>([]);
  const [des, setDes] = useState<string>("");
  const [isNewItemLoading, setIsNewItemLoading] = useState(false);
  const theme = useTheme();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDes(e.target.value);

  const handleClickShowBtn = async () => {
    if (des.trim() === "") {
      return;
    }

    setIsNewItemLoading(true);

    try {
      const response = await axios.post(
        "https://ai.coinfident.ai/ai-model/predict/",
        {
          text: des,
        }
      );

      const isEmpty = response.status !== 200;
      const gaugeValue =
        response.data.signal[0] === 0
          ? 20
          : response.data.signal[0] === 1
            ? 80
            : 0;

      const newItem: ListItemType = {
        title: des,
        gauge: {
          value: gaugeValue,
          isEmpty: isEmpty,
        },
        isRelevant: response.data.relevancy[0],
        imgsrc: response.data.coins,
        sentiment: response.data.sentiment[0],
      };

      setList((prevList) => [newItem, ...prevList]);
      setDes("");
      setShowData(true);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsNewItemLoading(false);
    }
  };

  const spsInfo = `SPS (Social Prediction Signal) is an index...`;
  const emotionalSentimentInfo = `The Emotional Sentiment part is different from SPS (social prediction signal). This section discusses whether the text of the tweets published by the influencers was happier or sadder, and this is not necessarily related to the influencers' opinions about buying or not buying.`;

  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ height: "inherit" }}
        >
          <Grid
            item
            md={8}
            xs={12}
            sx={{ textAlign: "center", marginBottom: "50px" }}
          >
            <Typography variant="h4" color={theme.palette.text.primary}>
              Crypto Text Analyzer
            </Typography>
            <Typography
              sx={{ marginTop: "5px" }}
              variant="body1"
              color={theme.palette.text.primary}
            >
              Welcome to Coinfident BackTest, a powerful new feature designed to
              test our AI models. With just a few clicks, you can input any text
              and run our advanced models to gain insights into the crypto
              landscape.
            </Typography>
            <Typography
              sx={{ marginTop: "20px", textAlign: "start" }}
              variant="h5"
              color={theme.palette.text.primary}
            >
              How It Works:
              <ul style={{ marginTop: "20px", marginLeft: "50px" }}>
                <li style={{ fontSize: "15px", marginTop: "13px" }}>
                  <text style={{ fontWeight: "bold", fontSize: "15px" }}>
                    SPS Detection :{" "}
                  </text>
                  Identify key signals within your text. Our model scans for
                  actionable insights, providing you with crucial buy or sell
                  signals based on the context of your content.
                </li>
                <li style={{ fontSize: "15px", marginTop: "13px" }}>
                  <text style={{ fontWeight: "bold", fontSize: "15px" }}>
                    Emotional Sentiment Analysis :{" "}
                  </text>
                  Understand the emotional tone behind your text. Is it
                  positive, negative, or neutral? Our sentiment analysis will
                  help you gauge market sentiment and make informed decisions.
                </li>
                <li style={{ fontSize: "15px", marginTop: "13px" }}>
                  <text style={{ fontWeight: "bold", fontSize: "15px" }}>
                    Relevancy Assessment :{" "}
                  </text>
                  Determine the relevance of your text to the cryptocurrency
                  world. We analyze the context and provide a relevancy score to
                  help you focus on what truly matters.
                </li>
                <li style={{ fontSize: "15px", marginTop: "13px" }}>
                  <text style={{ fontWeight: "bold", fontSize: "15px" }}>
                    Coin Name Detection :{" "}
                  </text>
                  Using advanced regex algorithms, we detect and highlight the
                  top coin names mentioned in your text, ensuring you never miss
                  a relevant reference.
                </li>
              </ul>
            </Typography>
          </Grid>
          <Grid
            md={8}
            xs={12}
            className="analytics-card"
            sx={{
              marginBottom: "100px",
              background: theme.palette.primary.light,
            }}
          >
            <AnalyticsTitle
              title="Crypto Text Analyzer"
              customstyle={{ width: "100%" }}
            />
            <InputForm
              des={des}
              handleInputChange={handleInputChange}
              handleClickShowBtn={handleClickShowBtn}
            />
            {isNewItemLoading && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <Skeleton variant="circular" width={150} height={150} />
                  <Skeleton variant="rectangular" width={200} height={150} />
                  <Skeleton variant="circular" width={150} height={150} />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "15px ",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Skeleton
                    variant="text"
                    width={300}
                    height={100}
                    sx={{ marginBottom: "10px" }}
                  />
                  <Skeleton variant="text" width={400} height={100} />
                </div>
              </div>
            )}

            {showData && list.length > 0 && (
              <ul style={{ marginTop: "30px", padding: 0 }}>
                {list.map((item, index) => (
                  <Fade key={`${index}-${item.title}`}>
                    <ListItems
                      item={item}
                      spsInfo={spsInfo}
                      emotionalSentimentInfo={emotionalSentimentInfo}
                    />
                  </Fade>
                ))}
              </ul>
            )}
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default BackTestPage;
