import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { getDifferenceInMonth } from "../../../utils/functions";
import { InvoiceItem, InvoiceTableProps } from "./interface";
import moment from "moment";

const InvoiceTable: React.FC<InvoiceTableProps> = ({ tHead, tBody }) => {
  const theme = useTheme();
  return (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tHead.map((head: string, index: number) => (
              <TableCell key={head} align={index === 0 ? "left" : "center"}>
                {head}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tBody?.map((row: InvoiceItem, index: number) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="left">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                ></Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {`${getDifferenceInMonth(row.current_period_start, row.current_period_end)} Month`}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {moment(row.current_period_start).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="caption"
                  color={theme.palette.text.primary}
                >
                  {moment(row.current_period_end).format("YYYY-MM-DD HH:mm:ss")}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceTable;
