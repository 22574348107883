import { useLocation } from "react-router";
import { Box, Typography, useTheme } from "@mui/material";
import { formatPrice, getCoinImage } from "../../../utils/functions";
import { CryptoHeaderProps } from "../interfaces";
import styles from "../crypto.module.scss";

const CryptoHeader: React.FC<CryptoHeaderProps> = ({
  data,
  coinId,
  cryptosState,
}) => {
  const theme = useTheme();
  const location = useLocation();

  const str = location.pathname;
  const result = str.split("/")[2];
  const capitalizedCoinName = result.charAt(0).toUpperCase() + result.slice(1);

  const price = Number(
    cryptosState?.coinsStatistics?.find((coin: any) => coin.coin_id == coinId)
      ?.latest_price,
  );

  return (
    <Box className={styles.cryptoHeaderWrapper}>
      <img src={getCoinImage(coinId)} alt={data?.data.symbol} />
      <Box>
        <Typography
          variant="h6"
          component="h1"
          sx={{ color: theme.palette.text.primary }}
        >
          {data?.data?.name ? data?.data?.name : capitalizedCoinName}
        </Typography>
        <Typography
          variant="body2"
          component="h2"
          sx={{ color: theme.palette.text.primary }}
        >
          {data?.data?.symbol}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h6"
          component="h3"
          sx={{ color: theme.palette.text.primary }}
          align="right"
        >
          $
          {
            //@ts-ignore
            formatPrice(price) > 1
              ? Number(formatPrice(price)).toLocaleString()
              : formatPrice(price)
          }
        </Typography>
      </Box>
    </Box>
  );
};

export default CryptoHeader;
